import { gql } from "@apollo/client";

export const ADMIN_GET_PROGRAMS_ON_BULK_EDIT_OF_REPORTING_PERIODS = gql`
  query AdminGetProgramsOnBulkEditOfReportingPeriods {
    programsOnBulkEditOfReportingPeriods {
      id
      name
      reportingPeriodType
      reportingPeriods {
        id
        begin
        end
        range
      }
      datasets {
        id
        publishedRecordSets {
          id
          end
        }
      }
    }
  }
`;
