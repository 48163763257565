define(function(require) {
  'use strict';

  var DEBUG = require('./debug');

  // Just copy an object
  function clone(obj) {
    var ret = {};

    for (var p in obj) {
      try {
        if (obj[p].constructor === Object) {
          ret[p] = clone(obj[p]);
        } else {
          ret[p] = obj[p];
        }
      } catch (e) {
        ret[p] = obj[p];
      }
    }

    return ret;
  }

  function combineObjects(obj1, obj2, noclone) {
    var base = noclone ? obj1 : clone(obj1);
    for (var p in obj2) {
      try {
        if (obj2[p].constructor === Object) {
          base[p] = combineObjects(base[p], obj2[p]);
        } else {
          base[p] = obj2[p];
        }
      } catch (e) {
        base[p] = obj2[p];
      }
    }

    return base;
  }

  function containsValue(obj, val) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop) && obj[prop] === val) {
        return true;
      }
    }

    return false;
  }

  function startsWith(str, word) {
    return str.indexOf(word) === 0;
  }

  function extend(obj, fresh) {
    // If fresh, clone it dont alter it
    var o = (fresh ? clone(obj) : obj) || {};
    var args = arguments;

    for (var i = 2, t = args.length; i < t; i++) {
      if (typeof args[i] === 'object') {
        combineObjects(o, args[i], true);
      } else {
        o[args[i]] = args[++i];
      }
    }

    return o;
  }

  function addKV(obj, a, b) {
    if (b) {
      return extend(obj, true, a, b);
    } else {
      return extend(obj, true, a);
    }
  }

  /**
   * Checks a condition and DEBUGs an error
   * if it fails. returns the result
   */
  function assert(test, message, warn) {
    if (!test) {
      if (warn) {
        DEBUG.warn('AssertionFailed: ' + message);
      } else {
        DEBUG.error('AssertionFailed: ' + message);
      }
    }

    return test;
  }

  /**
   * Checks that an object contains a value and DEBUGs
   * an error if not. Returns the result
   */
  function assertContainsValue(object, value, message) {
    return assert(containsValue(object, value), message);
  }

  /**
   * Use native trim() if supported, else use regexp
   * String.trim() was added in ECMAScript 5.1 and is not available in older browsers (e.g. IE8)
   */

  function trim(value) {
    if (typeof value === 'string') {
      if (String.prototype.trim) {
        value = value.trim();
      } else {
        value = value.replace(/^\s+|\s+$/g, '');
      }

      return value;
    }
  }

  return {
    clone: clone,
    combineObjects: combineObjects,
    addKV: addKV,
    containsValue: containsValue,
    startsWith: startsWith,
    extend: extend,
    assert: assert,
    assertContainsValue: assertContainsValue,
    trim: trim
  };
});
