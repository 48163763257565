import { gql } from "@apollo/client";

export const ADMIN_UPDATE_DIVERSITY_CATEGORY = gql`
  mutation AdminUpdateDiversityCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      id
      name
      deleted
      description
      displayName
      inTargetColor
      outTargetColor
      priority
    }
  }
`;
