import { gql } from "@apollo/client";

export const ADMIN_RESTORE_DIVERSITY_CATEGORY = gql`
  mutation AdminRestoreDiversityCategory($id: ID!) {
    restoreCategory(id: $id) {
      id
      name
      displayName
      description
      deleted
    }
  }
`;
