import { DownloadOutlined, EditOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Alert, Button, Col, Divider, Form, FormProps, Input, Row, Select, Table, TableColumnsType } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useCreateDepartment } from "../../../hooks/programHooks";
import CustomHelmet from "../../../components/CustomHelmet";
import { AdminGetDepartments, AdminGetDepartments_departments } from "../../../graphql/__generated__/AdminGetDepartments";
import { AdminGetDivisions } from "../../../graphql/__generated__/AdminGetDivisions";
import { ADMIN_GET_DEPARTMENTS } from "../../../graphql/__queries__/AdminGetDepartments.gql";
import { ADMIN_GET_DIVISIONS } from "../../../graphql/__queries__/AdminGetDivisions.gql";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";

const { Search } = Input;


type ExportDepartment = {
    "Department": string;
    "Division": string;
    "Dataset": string;
};

type FormField = {
    departmentName: string;
    divisionId: string;
}

type MappedDepartment = {
    id: string;
    name: string;
    division: string;
    numberOfDatasets: number;
};


const useQueries = () => {
    const divisionsResponse = useQueryWithErrorHandling<AdminGetDivisions>(
        ADMIN_GET_DIVISIONS,
        "divisions",
        { fetchPolicy: "network-only" }
    );

    const departmentsResponse = useQueryWithErrorHandling<AdminGetDepartments>(
        ADMIN_GET_DEPARTMENTS,
        "departments",
        { fetchPolicy: "network-only" }
    );

    return {
        loading: departmentsResponse.loading || divisionsResponse.loading,
        departments: [...(departmentsResponse.data?.departments || [])],
        divisions: [...(divisionsResponse.data?.divisions || [])],
        refresh: () => departmentsResponse.refetch(),
    };
};

const applySearchFilter = (departments: MappedDepartment[], searchTerms: string[]) => (
    departments?.filter(({ name, division }) => (
        searchTerms.every(term => (`${name}:${division}`).toLocaleLowerCase().includes(term.toLocaleLowerCase()))
    )) || []
);

const convertDepartmentsToCsv = (departments: ExportDepartment[]) => {
    const titleKeys = Object.keys(departments[0]);
    const exportDepartments: string[][] = [];

    exportDepartments.push(titleKeys);
    departments.forEach(department => {
        const attributes = Object.values(department).map(attr => `"${attr}"`);
        exportDepartments.push(attributes);
    });

    let csvContent = "";
    exportDepartments.forEach(row => {
        csvContent += (row.join(",") + "\n");
    });
    
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    return URL.createObjectURL(blob);
};

const getExportDepartments = (departments: AdminGetDepartments_departments[]): ExportDepartment[] => (
    departments.flatMap(department => {
        const exportDepartment: ExportDepartment = { 
            "Department": department.name, 
            "Division": department.division.name, 
            "Dataset": ""
        };
        return department.programs && department.programs.length > 0
            ? department.programs.flatMap(program => program.datasets.map(dataset => (
                { ...exportDepartment, "Dataset": dataset.name }
            ))) : [exportDepartment];
    })
);

const mapDepartments = (departments: AdminGetDepartments_departments[]): MappedDepartment[] => (
    departments
        .map(department => (
            {
                id: department.id,
                name: department.name,
                division: department.division.name,
                numberOfDatasets: (department.programs || []).flatMap(program => program.datasets.length)
                    .reduce((acc, curr) => acc + curr ,0)
            }
        ))
);

const getColumns = (): TableColumnsType<MappedDepartment> => [
    {
        title: "Department Name",
        dataIndex: "name",
        key: "name",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.name.localeCompare(b.name),
        width: "30%",
    },
    {
        title: "Assigned Division",
        dataIndex: "division",
        key: "division",
        sorter: (a, b) => a.name.localeCompare(b.name),
        width: "30%",
    },
    {
        title: "Number of Datasets",
        dataIndex: "numberOfDatasets",
        key: "datasets",
        sorter: (a, b) =>  a.numberOfDatasets - b.numberOfDatasets,
    },
    {
        title: "Edit Department",
        key: "edit",
        render: (record: MappedDepartment) => (
            <a href={`/admin/tags/departments/${record.id}`}>
                <EditOutlined />
            </a>
        ),
    },
];


export const ManageDepartments = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [createNewDepartmentForm] = Form.useForm<FormField>();

    const [searchTerms, setSearchTerms] = useState<string[]>([]);
    const [openCreateNewDepartment, setOpenCreateNewDepartment] = useState<boolean>(false);
    const [exportAllDepartmentsUrl, setExportAllDepartmentsUrl] = useState<string>("");

    const { departments, divisions, loading, refresh } = useQueries();
    const createNewDepartment = useCreateDepartment();

    const filteredDepartments = useMemo(() => {
        const mappedDepartments = mapDepartments(departments);
        return searchTerms.length === 0 ? mappedDepartments : applySearchFilter(mappedDepartments, searchTerms);
    }, [searchTerms, departments]);

    const exportAllDepartments = () => {
        const exportDepartments = getExportDepartments(departments);
        const url = convertDepartmentsToCsv(exportDepartments);
        setExportAllDepartmentsUrl(url);
    };

    const onFinish: FormProps<FormField>["onFinish"] = async (values: FormField): Promise<void> => {
        const success = await createNewDepartment.run({ name: values.departmentName, divisionId: values.divisionId });

        if (success) {
            createNewDepartmentForm.resetFields();
            setOpenCreateNewDepartment(false);
            refresh();
        }
    };

    return (
        <Row gutter={[16, 32]}>
            {/* Page Title */}
            <CustomHelmet title={t("admin.tags.departments.index.title")} />

            {/* Page Header */}
            <Col span={24}>
                <PageHeader 
                    onBack={() => navigate("/admin/tags")}
                    title={t("admin.tags.departments.index.title")}
                    // Export All Departments
                    extra={[
                        <Button
                            title={t("admin.tags.departments.index.actions.export")}
                            key="export-all-departments"
                            type="primary"
                            shape="round"
                            disabled={loading || createNewDepartment.inFlight}
                            download="all-departments.csv"
                            href={exportAllDepartmentsUrl}
                            icon={<DownloadOutlined />}
                            onClick={exportAllDepartments}
                        >
                            {t("admin.tags.departments.index.actions.export")}
                        </Button>
                    ]}
                />
            </Col>

            {/* Error */}
            {
                !!createNewDepartment.error && 
                <Col key="create-new-department-error" span={24}>
                    <Alert
                        closable
                        description={createNewDepartment.error?.message}
                        message={t("admin.tags.departments.index.actions.createDepartment.error")}
                        showIcon
                        style={{ marginBottom: 16 }}
                        type="error"
                    />
                </Col>
            }

            {/* Create New Department */}
            <Col span={24}>
                <Form
                    form={createNewDepartmentForm}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    {
                        openCreateNewDepartment ? 
                            <Row gutter={[16, 16]}>
                                <Col offset={1} span={6}>
                                    <Form.Item
                                        name="departmentName"
                                        rules={[{ required: true, message: t("admin.tags.departments.index.inputs.department.required") }]}
                                    >
                                        <Input
                                            disabled={loading || createNewDepartment.inFlight}
                                            placeholder={t("admin.tags.departments.index.inputs.department.placeholder")}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name="divisionId"
                                        rules={[{ required: true, message: t("admin.tags.departments.index.inputs.division.required") }]}
                                    >
                                        <Select
                                            disabled={loading || createNewDepartment.inFlight}
                                            filterOption={(input, option) => (option?.label ?? "")
                                                .toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={divisions.map(({ id, name }) => ({ label: name, value: id }))}
                                            placeholder={t("admin.tags.departments.index.inputs.division.placeholder")}
                                            showSearch
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            loading={loading || createNewDepartment.inFlight}
                                            title={t("admin.tags.departments.index.actions.createDepartment.submit")}
                                            type="primary"
                                        >
                                            {t("admin.tags.departments.index.actions.createDepartment.submit")}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row> :
                            <Row gutter={[16, 16]}>
                                <Col offset={1} span={6}>
                                    <Button
                                        loading={loading || createNewDepartment.inFlight}
                                        onClick={() => setOpenCreateNewDepartment(true)}
                                        title={t("admin.tags.departments.index.actions.createDepartment.title")}
                                        type="primary"
                                    >
                                        {t("admin.tags.departments.index.actions.createDepartment.title")}
                                    </Button>
                                </Col>                          
                            </Row>
                    }
                </Form>
            </Col>

            {/* View All Departments */}
            <Col span={24}>
                <Row gutter={[16, 16]}>
                    <Divider orientation="left">{t("admin.tags.departments.index.divider")}</Divider>
                    <Col span={6}>
                        <Search 
                            allowClear
                            disabled={loading || createNewDepartment.inFlight}
                            onSearch={(input) => setSearchTerms(input.split(" "))} 
                            placeholder={t("admin.tags.departments.index.actions.search")}
                        />
                    </Col>
                    <Col span={24}>
                        <Table 
                            dataSource={filteredDepartments}
                            columns={getColumns()}
                            loading={loading || createNewDepartment.inFlight}
                            rowKey={department => department.id}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};