define(function(require) {
  'use strict';

  var CSLabelKeys = require('./label-keys');
  var ConfigKeys = require('../../config/keys');
  var Environment = require('../../environment');
  var Utils = require('../../util/methods');
  var LabelValidator = require('../../util/cleansing/label-validator.js');
  var Enums = require('../../enumerations');
  var INVALID_DATA_STRING = 'invalid-data';
  var DEBUG = require('../../util/debug');
  var CookieHelper = require('../../util/cookies');

  var DOMAIN_SA = 'sa.bbc.co.uk/bbc';
  var DOMAIN_SCR = 'scorecardresearch.com';

  var WEB_TYPES = [
    Enums.ApplicationType.WEB,
    Enums.ApplicationType.MOBILE_WEB,
    Enums.ApplicationType.RESPONSIVE
  ];

  /*
   * ------------------------------------------------------------------------
   * Private Helpers
   * ------------------------------------------------------------------------
   */

  function dummy() {
    return -1;
  }

  function _getScoreCardUrl(protocol) {
    var protocolPrefix = (protocol === 'https:') ? 's' : '';

    return protocol + '//' + protocolPrefix + 'b.' + DOMAIN_SCR + '/p?';
  }

  function _getSAUrl(protocol, csSite) {
    return protocol + '//' + DOMAIN_SA + '/' + csSite + '/s';
  }

  function _getCSEndpoint(comscoreHost, envProtocol, csSite) {
    if (comscoreHost === DOMAIN_SCR) {
      return _getScoreCardUrl(envProtocol);
    }

    return _getSAUrl(envProtocol, csSite);
  }

  function _validateClipLabel(_clipLabel) {}; // jshint ignore:line

  function _getIdObjectValue(_idObject) {}; // jshint ignore:line

  function _getMostPrecedentIdObject(media) {}; // jshint ignore:line

  function _hasAmbiguousPrecedentId(media) {}; // jshint ignore:line

  function _buildStreamSensePlaylist(media) {} // jshint ignore:line

  /**
   * Build a set of labels from a media object.
   *
   * @param {Media} media
   * @returns {Object}
   * @private
   */
  function _buildStreamSenseClip(media) {} // jshint ignore:line

  /**
   * Comscore delegate.
   * @namespace ComScoreDelegate
   * @constructor
   *
   */
  function ComScoreDelegate(appName, appType, config, environment) {
    var csSite = config[ConfigKeys.COMSCORE_SITE];
    var endpoint;
    var offlineEndpoint;
    var derivedEndpoint;
    var isScorecardDomain;
    var csHost;
    var customGet;
    this._deviceId = null;
    this._broker = null;
    this.media = null;
    this._clips = [];
    this._isEnabled = true;
    this._hasStartedAppTag = false;
    this._hasCreatedAsset = false;
    this._storedAssetLabels = {};

    csHost = config[ConfigKeys.COMSCORE_HOST];
    csSite = config[ConfigKeys.COMSCORE_SITE];

    if (config[ConfigKeys.USE_SSC] === true) {
      DEBUG.warn('Setting USE_SSC is now deprecated and will have no effect');
    }

    // set device id
    this._deviceId = config[ConfigKeys.ECHO_DEVICE_ID] || undefined;

    if (!Utils.containsValue(Enums.ApplicationType, appType)) {
      appType = INVALID_DATA_STRING;
    }

    endpoint = _getCSEndpoint(csHost, environment.getProtocol(), csSite);

    // if using test service, save the endpoint then replace with test url
    if (config[ConfigKeys.TEST_SERVICE_ENABLED] === true) {
      derivedEndpoint = endpoint;
      endpoint = config[ConfigKeys.TEST_SERVICE_URL] + '/comscore';
      if (this._isUsingComscoreOTTLibrary) {
        offlineEndpoint = config[ConfigKeys.TEST_SERVICE_URL] + '/offline';
      }
    }

    // Are we an App (as ComScore defines it, which is, not a web page?
    // (ECHO-56)
    this._isApp = !Utils.containsValue(WEB_TYPES, appType);

    customGet = environment.getHttpGet();
    isScorecardDomain = (csHost === DOMAIN_SCR);

    // ternary operator ensures this is a binary value and not
    // undefined
    this._isUsingComscoreOTTLibrary =
      (ComScoreDelegate.AppTag && ComScoreDelegate.AppTag.ns_) ? true : false;

    if (this._isUsingComscoreOTTLibrary) {
      this.appTag = ComScoreDelegate.AppTag.ns_.comScore;
      this.appTag.setPlatformAPI(ComScoreDelegate.AppTag.ns_.PlatformAPIs.html5);
      if (customGet) {
        this.appTag.getPlatformAPI().httpGet = customGet;
      }

      this.appTag.setPublisherSecret(config[ConfigKeys.COMSCORE_PUBLISHER_SECRET]);
      this.appTag.setAppContext();
    } else {
      this.appTag = new ComScoreDelegate.AppTag(
        customGet || Environment._defaultHttpGet,
        this._isApp,
        isScorecardDomain
      );
    }

    // set C1 and C2 if host is SCR
    if (isScorecardDomain) {
      this.appTag.setCustomerC2(config[ConfigKeys.COMSCORE_CUSTOMER_ID]);
      this.addLabel(CSLabelKeys.COMSCORE_C1, '2');
      this.addLabel(CSLabelKeys.COMSCORE_SITE, csSite);
    }

    this.appTag.setPixelURL(endpoint);

    if (this._deviceId) {
      this.addLabel(CSLabelKeys.DEVICE_ID, this._deviceId);
    } else if (csHost === 'sa.bbc.co.uk') {
      this._deviceId = CookieHelper.getCookieValueByName('s1');
    }

    if (config[ConfigKeys.TEST_SERVICE_ENABLED] === true) {
      this.addLabel(CSLabelKeys.COMSCORE_ENDPOINT, derivedEndpoint);
      if (this._isUsingComscoreOTTLibrary) {
        this.appTag.setOfflineURL(offlineEndpoint);
      }
    }

    this.addLabel(CSLabelKeys.BBC_APPLICATION_NAME, appName);
    this.addLabel(CSLabelKeys.BBC_APPLICATION_TYPE, appType);
    this.setCacheMode(config[ConfigKeys.ECHO_CACHE_MODE]);
    this.addLabel(
      CSLabelKeys.BBC_MEASUREMENT_LIB_NAME,
      config[ConfigKeys.ECHO_NAME]
    );
    this.addLabel(
      CSLabelKeys.BBC_MEASUREMENT_LIB_VERSION,
      config[ConfigKeys.ECHO_VERSION]
    );

    // Labels to set for "Apps" only
    if (this._isApp) {

      if (this._isUsingComscoreOTTLibrary) {
        this.appTag.setAppName(appName);
      }  else {
        this.addLabel(CSLabelKeys.APP_NAME, appName);
      }

      this.addLabel(
        CSLabelKeys.APP_PLATFORM_NAME,
        environment.getPlatformName()
      );
      this.addLabel(
        CSLabelKeys.APP_PLATFORM_RUNTIME,
        environment.getPlatformRuntimeEnvironment()
      );
      this.addLabel(
        CSLabelKeys.APP_OS_VERSION,
        environment.getPlatformOSVersion()
      );
      this.addLabel(CSLabelKeys.APP_DEVICE_NAME, environment.getDeviceName());
      this.addLabel(CSLabelKeys.APP_SCREEN_RESOLUTION,
        environment.getScreenResolution());
      this.addLabel(CSLabelKeys.APP_LANGUAGE, environment.getLanguage());
    } else {
      this.addLabel(
        CSLabelKeys.WEB_SCREEN_RES,
        environment.getScreenResolution()
      );
    }

    this.addLabel(CSLabelKeys.ENV_CHAR_SET, environment.getCharSet());
    this.addLabel(CSLabelKeys.ENV_TITLE, environment.getTitle());
    this.addLabel(CSLabelKeys.ENV_REFERRER, environment.getReferrer());

    // hide file paths in c7
    if (environment.getURL().indexOf('file:', 0) === -1) {
      this.addLabel(CSLabelKeys.ENV_URL, environment.getURL());
    }

    if (config[ConfigKeys.ECHO_TRACE]) {
      this.setTraceId(config[ConfigKeys.ECHO_TRACE]);
    }

    // Default is to send invalid-data for site
    this.addLabel(Enums.ManagedLabels.BBC_SITE, INVALID_DATA_STRING);

    this._mediaIsLive = null;

    if (config && config[ConfigKeys.ECHO_ENABLED] === false) {
      this._isEnabled = false;
    } else {
      this._isEnabled = true;
    }
  }

  ComScoreDelegate.prototype.start = function() {
    if (this._isEnabled && !this._hasStartedAppTag) {
      this.appTag.start();
      this._hasStartedAppTag = true;
    }
  };

  ComScoreDelegate.prototype.enable = function() {
    if (!this._isEnabled) {
      this._isEnabled = true;
    }
  };

  ComScoreDelegate.prototype.disable = function() {
    if (this._isEnabled) {
      this.clearMedia();
      this._isEnabled = false;
    }
  };

  /**
   * Gets the device ID that was used to report to ComScore.
   *
   * @returns {String|null}
   */
  ComScoreDelegate.prototype.getDeviceId = function() {
    return this._deviceId;
  };

  /**
   * @param {Media} media
   * @return {Object}
   * @private
   */
  ComScoreDelegate.prototype._buildStreamSenseClip = function(media) {}; // jshint ignore:line

  ComScoreDelegate.prototype.setTraceId = function(id) {
    this.addLabel(ConfigKeys.ECHO_TRACE, id);
  };

  ComScoreDelegate.AppTag = require('app-tag');

  /**
   * Set multiple persistent labels.
   *
   * @param {Object} labels
   */
  ComScoreDelegate.prototype.addLabels = function(labels) {
    this.addProperties(labels);
  };

  /**
   * Set multiple persistent labels.
   *
   * @param {Object} labels
   */
  ComScoreDelegate.prototype.addProperties = function(labels) {

    if (typeof labels === 'object') {
      for (var label in labels) {
        if (labels.hasOwnProperty(label)) {
          this.addProperty(label, labels[label]);
        }
      }
    }

  };

  ComScoreDelegate.prototype.addCampaignProperties = dummy;

  /**
   * Set a persistent label
   *
   * @param {String} label
   * @param {*} value
   */
  ComScoreDelegate.prototype.addLabel = function(label, value) {

    this.addProperty(label, value);

  };

  /**
   * Set a persistent label
   *
   * @param {String} label
   * @param {*} value
   */
  ComScoreDelegate.prototype.addProperty = function(label, value) {
    this.appTag.setLabel(label, value);
  };

  ComScoreDelegate.prototype.addManagedLabel = function(label, value) {
    var labelIsValid = LabelValidator.isValidManagedLabel(label, value);
    var valueToUse = labelIsValid ? value : INVALID_DATA_STRING;

    for (var key in Enums.ManagedLabels) {
      if (Enums.ManagedLabels[key] === label) {
        this.addLabel(label, valueToUse);
      }
    }
  };

  ComScoreDelegate.prototype.removeLabels = function(keysArray) {
    this.removeProperties(keysArray);
  };

  ComScoreDelegate.prototype.removeProperties = function(keysArray) {
    for (var i = 0, len = keysArray.length; i < len; i++) {
      this.removeProperty(keysArray[i]);
    }
  };

  ComScoreDelegate.prototype.removeLabel = function(label) {
    this.removeProperty(label);
  };

  ComScoreDelegate.prototype.removeProperty = function(label) {
    this.appTag.setLabel(label, null);
  };

  ComScoreDelegate.prototype.setLoggedInToBBCId = function(hid) {
    this.addLabel(CSLabelKeys.BBC_ID_LOGGED_IN, '1');
    if (hid && hid.length > 0) {
      this.addManagedLabel(Enums.ManagedLabels.BBC_HASHED_ID, hid);
    } else {
      this.removeLabel(Enums.ManagedLabels.BBC_HASHED_ID);
    }
  };

  ComScoreDelegate.prototype.setLoggedOutOfBBCId = function() {
    this.removeLabels([
      CSLabelKeys.BBC_ID_LOGGED_IN,
      Enums.ManagedLabels.BBC_HASHED_ID
    ]);
  };

  ComScoreDelegate.prototype.setAppVersion = function (version) {
    if (typeof this.appTag.setAppVersion === 'function') {
      this.appTag.setAppVersion(version);
    } else if (version && this._isApp) {
      this.addLabel(CSLabelKeys.APP_VERSION, version.toString());
    } else if (version) {
      this.addLabel(CSLabelKeys.BBC_APPLICATION_VERSION, version.toString());
    }
  };

  ComScoreDelegate.prototype.setCounterName = function(name) {
    if (!this._isEnabled) {
      return;
    }

    this.addLabel(CSLabelKeys.BBC_COUNTER_NAME, name);
  };

  ComScoreDelegate.prototype.setDestination = function(destinationId) {
    this.addLabel(CSLabelKeys.DESTINATION, destinationId);
  };

  ComScoreDelegate.prototype.setProducer = function(producerId) {
    this.addLabel(CSLabelKeys.PRODUCER, producerId);
  };

  ComScoreDelegate.prototype.setContentLanguage = function(language) {
    this.addLabel(CSLabelKeys.BBC_LANGUAGE, language);
  };

  ComScoreDelegate.prototype.setCacheMode = function(mode) {
    if (!this._isUsingComscoreOTTLibrary || mode === this._cacheMode) {
      return;
    }

    switch (mode) {
      case Enums.EchoCacheMode.ALL:
        this.appTag.allowLiveTransmission(this.appTag.TransmissionMode.NEVER);
        this.appTag.allowOfflineTransmission(this.appTag.TransmissionMode.NEVER);
        break;
      case Enums.EchoCacheMode.OFFLINE:
        this.appTag.allowLiveTransmission(this.appTag.TransmissionMode.DEFAULT);
        this.appTag.allowOfflineTransmission(this.appTag.TransmissionMode.DEFAULT);
        break;
    }

    this._cacheMode = mode;
  };

  ComScoreDelegate.prototype.flushCache = function() {
    if (!this._isUsingComscoreOTTLibrary) {
      return;
    }

    this.appTag.flushCache();
  };

  ComScoreDelegate.prototype.clearCache = dummy;

  /*
   * ------------------------------------------------------------------------
   * Basic Analytics Methods
   * ------------------------------------------------------------------------
   */

  ComScoreDelegate.prototype.viewEvent = function(counterName, eventLabels) {
    if (!this._eventsEnabled()) {
      return;
    }

    /*
     * Set countername as a persistent label so it's included on all
     * subsequent events.
     */
    this.addLabel(CSLabelKeys.BBC_COUNTER_NAME, counterName);
    var clonedLabels = Utils.extend(
      eventLabels, true,
      CSLabelKeys.ECHO_EVENT_NAME, 'view'
    );

    clonedLabels.name = counterName;
    this.appTag.view(clonedLabels);
  };

  ComScoreDelegate.prototype.errorEvent = dummy;

  ComScoreDelegate.prototype.userActionEvent =
  function(actionType, actionName, eventLabels) {
    if (!this._eventsEnabled()) {
      return;
    }

    var clonedLabels = Utils.extend(
      eventLabels, true,
      CSLabelKeys.USER_ACTION_TYPE, actionType,
      CSLabelKeys.USER_ACTION_NAME, actionName,
      CSLabelKeys.ECHO_EVENT_NAME, 'userAct'
    );

    this.appTag.hidden(clonedLabels);
  };

  /*
   * ------------------------------------------------------------------------
   * Media Player Attributes
   * ------------------------------------------------------------------------
   */

  /**
   * Media player information converted to Application i.e. Persistent labels
   * on Stream Sense. Included in all measurement events sent
   */
  ComScoreDelegate.prototype.setPlayerName = function(name) {}; // jshint ignore:line

  ComScoreDelegate.prototype.setPlayerVersion = function(version) {}; // jshint ignore:line

  ComScoreDelegate.prototype.setPlayerIsPopped = function(isPopped) {}; // jshint ignore:line

  ComScoreDelegate.prototype.setPlayerWindowState = function(windowState) {}; // jshint ignore:line

  ComScoreDelegate.prototype.setPlayerVolume = function(volume) {}; // jshint ignore:line

  ComScoreDelegate.prototype.setPlayerIsSubtitled = function(isSubtitled) {}; // jshint ignore:line

  /**
   * Sets the broker.
   *
   * This method should only be called by the EchoClient class to
   * ensure the interface is implemented.
   *
   * @param {Object} broker An object conforming to the broker interface.
   *
   * @return {ComScoreDelegate}
   */
  ComScoreDelegate.prototype.setBroker = function(broker) {
    this._broker = broker;
    return this;
  };

  /*
   * ------------------------------------------------------------------------
   * Media Attributes
   * ------------------------------------------------------------------------
   */
  ComScoreDelegate.prototype.setMedia = function(media) {}; // jshint ignore:line

  /**
   * Handles an updated clip from the LiveBroker
   *
   * @param {Media} newMedia
   * @param {Number} newPosition
   * @param {Number} oldPosition
   */
  ComScoreDelegate.prototype.liveMediaUpdate = function(newMedia, newPosition, oldPosition) {}; // jshint ignore:line

  ComScoreDelegate.prototype.liveEnrichmentFailed = dummy;

  /**
   * Update the media length of the clip and playlist.
   *
   * @param {Number} length
   */
  ComScoreDelegate.prototype.setMediaLength = function(length) {}; // jshint ignore:line

  /*
   * ------------------------------------------------------------------------
   * Media Events
   * ------------------------------------------------------------------------
   */
  /*
   * Uses the addLabel method to add to both av and non-av events
   */
  ComScoreDelegate.prototype.avPlayEvent = function(position, eventLabels) {}; // jshint ignore:line

  ComScoreDelegate.prototype.avPauseEvent = function(position, eventLabels) {}; // jshint ignore:line

  ComScoreDelegate.prototype.avBufferEvent = function(position, eventLabels) {}; // jshint ignore:line

  ComScoreDelegate.prototype.avEndEvent = function(position, eventLabels) {}; // jshint ignore:line

  ComScoreDelegate.prototype.avRewindEvent = function(position, rate, eventLabels) {}; // jshint ignore:line

  ComScoreDelegate.prototype.avFastForwardEvent = function(position, rate, eventLabels) {}; // jshint ignore:line

  ComScoreDelegate.prototype.avSeekEvent = function(position, eventLabels) {}; // jshint ignore:line

  ComScoreDelegate.prototype.avUserActionEvent = function(actionType, actionName, position, eventLabels) {}; // jshint ignore:line

  ComScoreDelegate.prototype.clearMedia = function() {}; // jshint ignore:line

  ComScoreDelegate.prototype.setDeviceId = dummy;

  ComScoreDelegate.prototype.requiresLabelCleansing = function() {
    return true;
  };

  ComScoreDelegate.prototype._eventsEnabled = function() {
    return this._isEnabled && this._hasStartedAppTag;
  };

  return ComScoreDelegate;
});
