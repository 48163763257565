define(function() {
  'use strict';

  function PageContext(namespace, env) {
    this.namespace = namespace;
    this.env = env;
  }

  PageContext.prototype.preloadImage = function (s) {
    var httpGet = this.env.getHttpGet();
    return httpGet(s);
  };

  PageContext.prototype.getLocalStorageItem = function (key) {
    var getLocalStorageItem = this.env.getGetLocalStorageItem();
    return getLocalStorageItem(key);
  };

  PageContext.prototype.setLocalStorageItem = function (key, value) {
    var setLocalStorageItem = this.env.getSetLocalStorageItem();
    return setLocalStorageItem(key, value);
  };

  PageContext.prototype.getWindowLocationURL = function () {
    return this.env.getURL();
  };

  return PageContext;
});
