import { DownloadOutlined, EditOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Alert, Button, Col, Divider, Form, FormProps, Input, Row, Table, TableColumnsType } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useCreatePlatform } from "../../../hooks/programHooks";
import CustomHelmet from "../../../components/CustomHelmet";
import { AdminGetPlatforms, AdminGetPlatforms_platforms } from "../../../graphql/__generated__/AdminGetPlatforms";
import { ADMIN_GET_PLATFORMS } from "../../../graphql/__queries__/AdminGetPlatforms.gql";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";

const { Search } = Input;


type ExportPlatform = {
    "Platform": string;
    "Dataset": string;
};

type FormField = {
    platform: string;
}

type MappedPlatform = {
    id: string;
    name: string;
    numberOfDatasets: number;
};


const useQueries = () => {
    const platformsResponse = useQueryWithErrorHandling<AdminGetPlatforms>(
        ADMIN_GET_PLATFORMS,
        "platforms",
        { fetchPolicy: "network-only" }
    );

    return {
        loading: platformsResponse.loading,
        platforms: [...(platformsResponse.data?.platforms || [])],
        refresh: () => platformsResponse.refetch(),
    };
};

const applySearchFilter = (platforms: MappedPlatform[], searchTerms: string[]) => (
    platforms?.filter(({ name }) => (
        searchTerms.every(term => name.toLocaleLowerCase().includes(term.toLocaleLowerCase()))
    )) || []
);

const convertPlatformsToCsv = (platforms: ExportPlatform[]) => {
    const titleKeys = Object.keys(platforms[0]);
    const exportPlatforms: string[][] = [];

    exportPlatforms.push(titleKeys);
    platforms.forEach(platform => {
        const attributes = Object.values(platform).map(attr => `"${attr}"`);
        exportPlatforms.push(attributes);
    });

    let csvContent = "";
    exportPlatforms.forEach(row => {
        csvContent += (row.join(",") + "\n");
    });
    
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    return URL.createObjectURL(blob);
};

const getExportPlatforms = (platforms: AdminGetPlatforms_platforms[]): ExportPlatform[] => (
    platforms.flatMap(platform => {
        const exportPlatform: ExportPlatform = { 
            "Platform": platform.name,
            "Dataset": ""
        };
        return platform.programs && platform.programs.length > 0
            ? platform.programs.flatMap(program => program.datasets.map(dataset => (
                { ...exportPlatform, "Dataset": dataset.name }
            ))) : [exportPlatform];
    })
);

const mapPlatforms = (platforms: AdminGetPlatforms_platforms[]): MappedPlatform[] => (
    platforms
        .map(platform => (
            {
                id: platform.id,
                name: platform.name,
                numberOfDatasets: (platform.programs || []).flatMap(program => program.datasets.length)
                    .reduce((acc, curr) => acc + curr ,0)
            }
        ))
);

const getColumns = (): TableColumnsType<MappedPlatform> => [
    {
        title: "Platform Name",
        dataIndex: "name",
        key: "name",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.name.localeCompare(b.name),
        width: "30%",
    },
    {
        title: "Number of Datasets",
        dataIndex: "numberOfDatasets",
        key: "datasets",
        sorter: (a, b) =>  a.numberOfDatasets - b.numberOfDatasets,
    },
    {
        title: "Edit Platform",
        key: "edit",
        render: (record: MappedPlatform) => (
            <a href={`/admin/tags/platforms/${record.id}`}>
                <EditOutlined />
            </a>
        ),
    },
];


export const ManagePlatforms = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [createNewPlatformForm] = Form.useForm<FormField>();

    const [searchTerms, setSearchTerms] = useState<string[]>([]);
    const [openCreateNewPlatform, setOpenCreateNewPlatform] = useState<boolean>(false);
    const [exportAllPlatformsUrl, setExportAllPlatformsUrl] = useState<string>("");

    const { platforms, loading, refresh } = useQueries();
    const createNewPlatform = useCreatePlatform();

    const filteredPlatforms = useMemo(() => {
        const mappedPlatforms = mapPlatforms(platforms);
        return searchTerms.length === 0 ? mappedPlatforms : applySearchFilter(mappedPlatforms, searchTerms);
    }, [searchTerms, platforms]);

    const exportAllPlatforms = () => {
        const exportPlatforms = getExportPlatforms(platforms);
        const url = convertPlatformsToCsv(exportPlatforms);
        setExportAllPlatformsUrl(url);
    };

    const onFinish: FormProps<FormField>["onFinish"] = async (values: FormField): Promise<void> => {
        const success = await createNewPlatform.run(values.platform);

        if (success) {
            createNewPlatformForm.resetFields();
            setOpenCreateNewPlatform(false);
            refresh();    
        }
    };

    return (
        <Row gutter={[16, 32]}>
            {/* Page Title */}
            <CustomHelmet title={t("admin.tags.platforms.index.title")} />

            {/* Page Header */}
            <Col span={24}>
                <PageHeader 
                    onBack={() => navigate("/admin/tags")}
                    title={t("admin.tags.platforms.index.title")}
                    // Export All Platforms
                    extra={[
                        <Button
                            title={t("admin.tags.platforms.index.actions.export")}
                            key="export-all-platforms"
                            type="primary"
                            shape="round"
                            disabled={loading || createNewPlatform.inFlight}
                            download="all-platforms.csv"
                            href={exportAllPlatformsUrl}
                            icon={<DownloadOutlined />}
                            onClick={exportAllPlatforms}
                        >
                            {t("admin.tags.platforms.index.actions.export")}
                        </Button>
                    ]}
                />
            </Col>

            {/* Error */}
            {
                !!createNewPlatform.error && 
                <Col key="create-new-platform-error" span={24}>
                    <Alert
                        closable
                        description={createNewPlatform.error?.message}
                        message={t("admin.tags.platforms.index.actions.createPlatform.error")}
                        showIcon
                        style={{ marginBottom: 16 }}
                        type="error"
                    />
                </Col>
            }

            {/* Create New Platform */}
            <Col span={24}>
                <Form
                    form={createNewPlatformForm}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    {
                        openCreateNewPlatform ? 
                            <Row gutter={[16, 16]}>
                                <Col offset={1} span={6}>
                                    <Form.Item
                                        name="platform"
                                        rules={[{ required: true, message: t("admin.tags.platforms.index.input.required") }]}
                                    >
                                        <Input
                                            disabled={loading || createNewPlatform.inFlight}
                                            placeholder={t("admin.tags.platforms.index.input.placeholder")}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item>
                                        <Button 
                                            htmlType="submit"
                                            loading={loading || createNewPlatform.inFlight}
                                            title={t("admin.tags.platforms.index.actions.createPlatform.submit")}
                                            type="primary"
                                        >
                                            {t("admin.tags.platforms.index.actions.createPlatform.submit")}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row> : 
                            <Row gutter={[16, 16]}>
                                <Col offset={1} span={6}>
                                    <Button
                                        loading={loading || createNewPlatform.inFlight}
                                        onClick={() => setOpenCreateNewPlatform(true)}
                                        title={t("admin.tags.platforms.index.actions.createPlatform.title")}
                                        type="primary"
                                    >
                                        {t("admin.tags.platforms.index.actions.createPlatform.title")}
                                    </Button>
                                </Col>
                            </Row>
                    }
                </Form>
            </Col>

            {/* View All Platforms */}
            <Col span={24}>
                <Row gutter={[16, 16]}>
                    <Divider orientation="left">{t("admin.tags.platforms.index.divider")}</Divider>
                    <Col span={6}>
                        <Search 
                            allowClear
                            disabled={loading || createNewPlatform.inFlight}
                            onSearch={(input) => setSearchTerms(input.split(" "))} 
                            placeholder={t("admin.tags.platforms.index.actions.search")}
                        />
                    </Col>
                    <Col span={24}>
                        <Table 
                            dataSource={filteredPlatforms}
                            columns={getColumns()}
                            loading={loading || createNewPlatform.inFlight}
                            rowKey={platform => platform.id}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};