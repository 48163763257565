import { CloseCircleOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { ApolloError } from "@apollo/client";
import { Alert, Button, Col, Divider, Form, Input, List, Popconfirm, Row, Select, Space } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import CustomHelmet from "../../../components/CustomHelmet";
import { Loading } from "../../../components/Loading/Loading";
import { AdminGetAllDatasets, AdminGetAllDatasets_datasets, AdminGetAllDatasets_datasets_program } from "../../../graphql/__generated__/AdminGetAllDatasets";
import { AdminGetTag, AdminGetTag_tag } from "../../../graphql/__generated__/AdminGetTag";
import { ADMIN_GET_ALL_DATASETS } from "../../../graphql/__queries__/AdminGetAllDatasets.gql";
import { ADMIN_GET_TAG } from "../../../graphql/__queries__/AdminGetTag.gql";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";
import { useDeleteMiscellaneousTag, useUpdateMiscellaneousTag } from "../../../hooks/programHooks";

const { Search } = Input;


type Entity = {
    id: string;
    name: string;
}

type Dataset = Entity & { 
    programId: string;
};

type MiscellaneousTag = Entity & { 
    datasets: Dataset[];
};

type EditMiscellaneousTagForm = {
    miscellaneousTag: MiscellaneousTag;
};

type ValidDataset = AdminGetAllDatasets_datasets & {
    program: AdminGetAllDatasets_datasets_program;
};

type QueryParams = {
    miscellaneousTagId: string;
};

type QueryResult = {
    queryDatasets: readonly AdminGetAllDatasets_datasets[] | undefined;
    queryMiscellaneousTag: AdminGetTag_tag | undefined;
    error: ApolloError | undefined;
    loading: boolean;
    refresh: () => Promise<void>;
};


const useQueries = ({ miscellaneousTagId }: QueryParams): QueryResult => {
    const {
        data: miscellaneousTagData, error: miscellaneousTagError, loading: miscellaneousTagLoading, refetch: miscellaneousTagRefetch
    } = useQueryWithErrorHandling<AdminGetTag>(
        ADMIN_GET_TAG,
        "tag",
        { variables: { id: miscellaneousTagId }, fetchPolicy: "network-only" }
    );

    const { 
        data: datasetsData, error: datasetsError, loading: datasetsLoading, refetch: datasetsRefetch
    } = useQueryWithErrorHandling<AdminGetAllDatasets>(
        ADMIN_GET_ALL_DATASETS,
        "datasets",
        { fetchPolicy: "network-only", variables: { onlyUnassigned: false } }
    );

    const error = miscellaneousTagError || datasetsError;
    const loading = miscellaneousTagLoading || datasetsLoading;
    const refresh = async () => {
        await miscellaneousTagRefetch();
        await datasetsRefetch();
    };

    const queryDatasets = datasetsData?.datasets;
    const queryMiscellaneousTag = miscellaneousTagData?.tag;

    return { queryDatasets, error, loading, queryMiscellaneousTag, refresh };
};

const applySearchFilter = (datasets: Dataset[], searchTerms: string[]) => (
    datasets?.filter(({ name }) => (
        searchTerms.every(term => name.toLocaleLowerCase().includes(term.toLocaleLowerCase()))
    )) || []
);


export const EditMiscellaneousTag = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { miscellaneousTagId } = useParams() as QueryParams;
    const [editMiscellaneousTagForm] = Form.useForm<EditMiscellaneousTagForm>();

    const [searchTerms, setSearchTerms] = useState<string[]>([]);
    const [selectedDataset, setSelectedDataset] = useState<Dataset | undefined>(undefined);
    const [selectDatasets, setSelectDatasets] = useState<Dataset[]>([]);
    const [miscellaneousTagDatasets, setMiscellaneousTagDatasets] = useState<Dataset[]>([]);

    const { queryDatasets, queryMiscellaneousTag, error, loading, refresh } = useQueries({ miscellaneousTagId });
    const updateMiscellaneousTag = useUpdateMiscellaneousTag();
    const deleteMiscellaneousTag = useDeleteMiscellaneousTag();

    const miscellaneousTag: MiscellaneousTag = useMemo(() => ({ 
        id: queryMiscellaneousTag?.id || miscellaneousTagId,
        name: queryMiscellaneousTag?.name || "",
        datasets: (queryMiscellaneousTag?.programs || []).flatMap(program => (
            program.datasets.map(({ id, name }) => ({ id, name, programId: program.id }))
        ))
    }), [miscellaneousTagId, queryMiscellaneousTag]);

    const initialValues: EditMiscellaneousTagForm = useMemo(() => ({ miscellaneousTag }), [miscellaneousTag]);

    useMemo(() => {
        const validDatasets = (queryDatasets || []).filter((dataset): dataset is ValidDataset => !!dataset.program);
        const filteredDatasets = validDatasets.filter(dataset => !miscellaneousTag.datasets.some(({ id }) => dataset.id === id));
        const mappedDatasets = filteredDatasets.map(({ id, name, program }) => ({ id, name, programId: program.id }));
        const sortedDatasets = mappedDatasets.sort((a, b) => a.name.localeCompare(b.name));
        setSelectDatasets(sortedDatasets);
    }, [miscellaneousTag, queryDatasets]);

    useMemo(() => {
        const sortedMiscellaneousTagDatasets = miscellaneousTag.datasets.sort((a, b) => a.name.localeCompare(b.name));
        setMiscellaneousTagDatasets(sortedMiscellaneousTagDatasets);
    }, [miscellaneousTag]);

    const filteredDatasets = useMemo(() => (
        searchTerms.length === 0 ? miscellaneousTagDatasets : applySearchFilter(miscellaneousTagDatasets, searchTerms)
    ), [miscellaneousTagDatasets, searchTerms]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        throw error;
    }

    const addDataset = (selectedDataset: Dataset | undefined) => {
        if (selectedDataset) {
            const selectDatasetsCopy = [...selectDatasets];
            const selectDatasetIndex = selectDatasetsCopy.findIndex(({ id }) => selectedDataset.id === id);
            if (selectDatasetIndex > -1) {
                const dataset = selectDatasetsCopy.splice(selectDatasetIndex, 1);
                setSelectDatasets(selectDatasetsCopy);

                const miscellaneousTagDatasetsCopy = [...miscellaneousTagDatasets];
                miscellaneousTagDatasetsCopy.push(...dataset);
                miscellaneousTagDatasetsCopy.sort((a, b) => a.name.localeCompare(b.name));
                setMiscellaneousTagDatasets(miscellaneousTagDatasetsCopy);
            }

            setSelectedDataset(undefined);
        }
    };

    const removeDataset = (datasetId: string) => {
        const miscellaneousTagDatasetsCopy = [...miscellaneousTagDatasets];
        const miscellaneousTagDatasetIndex = miscellaneousTagDatasetsCopy.findIndex(({ id }) => datasetId === id);
        if (miscellaneousTagDatasetIndex > -1) {
            const dataset = miscellaneousTagDatasetsCopy.splice(miscellaneousTagDatasetIndex, 1);
            setMiscellaneousTagDatasets(miscellaneousTagDatasetsCopy);

            const selectDatasetsCopy = [...selectDatasets];
            selectDatasetsCopy.push(...dataset);
            selectDatasetsCopy.sort((a, b) => a.name.localeCompare(b.name));
            setSelectDatasets(selectDatasetsCopy);
        }
    };

    const onDelete = async () => {
        await deleteMiscellaneousTag.run(miscellaneousTagId);
        navigate("/admin/tags/miscellaneous-tags");
    };

    const onFinish = async (values: EditMiscellaneousTagForm) => {
        const programIds = miscellaneousTagDatasets.map(({ programId }) => programId);
        await updateMiscellaneousTag.run({ id: miscellaneousTagId, name: values.miscellaneousTag.name, programIds });
        refresh();    
    };

    return (
        <Row gutter={[16, 16]}>
            {/* Page Title */}
            <CustomHelmet title={`${t("admin.tags.miscellaneousTags.edit.title")} - ${miscellaneousTag.name} Misc. Tag`} />

            {/* Page Header */}
            <Col span={24}>
                <PageHeader
                    onBack={() => navigate("/admin/tags/miscellaneous-tags")}
                    subTitle={`${miscellaneousTag.name} Misc. Tag`}
                    title={t("admin.tags.miscellaneousTags.edit.title")}
                />
            </Col>

            {/* Errors */}
            {
                [
                    { action: "save", error: updateMiscellaneousTag.error },
                    { action: "delete", error: deleteMiscellaneousTag.error }
                ]
                    .filter(err => !!err.error)
                    .map(({ action, error }) => (
                        <Col key={action} span={24}>
                            <Alert
                                closable
                                description={error?.message}
                                message={t(`admin.tags.miscellaneousTags.edit.actions.${action}.error`)}
                                showIcon
                                style={{ marginBottom: 16 }}
                                type="error"
                            />
                        </Col>
                    ))
            }

            {/* Edit Misc. Tag Form */}
            <Col span={24}>
                <Form
                    form={editMiscellaneousTagForm}
                    initialValues={initialValues}
                    onFinish={async (values) => await onFinish(values)}
                    scrollToFirstError
                >
                    <Row gutter={[16, 16]}>
                        <Col offset={1} span={11}>
                            {/* Edit Misc. Tag Name */}
                            <Form.Item
                                label={t("admin.tags.miscellaneousTags.edit.input.label")}
                                labelAlign="right"
                                labelCol={{ span: 7 }}
                                name={["miscellaneousTag", "name"]}
                                rules={[{ required: true, message: t("admin.tags.miscellaneousTags.edit.input.required") }]}
                                validateTrigger="onBlur"
                            >
                                <Input
                                    disabled={updateMiscellaneousTag.inFlight || deleteMiscellaneousTag.inFlight}
                                    placeholder={t("admin.tags.miscellaneousTags.edit.input.placeholder")}
                                    title={t("admin.tags.miscellaneousTags.edit.input.label")}        
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[32, 64]}>
                        <Col span={12}>
                            <Row gutter={[16, 16]}>
                                <Divider orientation="left">
                                    {`${miscellaneousTag.name} ${t("admin.tags.miscellaneousTags.edit.sections.miscellaneousTagDatasets.title")}`}
                                </Divider>
                                <Col span={16}>
                                    <Search
                                        allowClear
                                        disabled={updateMiscellaneousTag.inFlight || deleteMiscellaneousTag.inFlight}
                                        onSearch={(input) => setSearchTerms(input.split(" "))} 
                                        placeholder={
                                            t("admin.tags.miscellaneousTags.edit.actions.search")
                                                .replace("Misc. Tag", miscellaneousTag.name)
                                        }
                                        title={t("admin.tags.miscellaneousTags.edit.actions.search")}
                                    />
                                </Col>
                                <Col span={24}>
                                    <List
                                        bordered
                                        dataSource={filteredDatasets}
                                        itemLayout="horizontal"
                                        pagination={{ position: "bottom", align: "end" }}
                                        renderItem={(item) => (
                                            <List.Item
                                                actions={[
                                                    <a
                                                        href={`/admin/programs/${item.programId}`}
                                                        key={item.id}
                                                        title={t("admin.tags.miscellaneousTags.edit.actions.editDataset")}
                                                    >
                                                        <EditOutlined />
                                                    </a>                                            
                                                ]}
                                            >
                                                <Space>
                                                    <Popconfirm
                                                        cancelText={t("admin.tags.miscellaneousTags.edit.actions.removeDataset.cancelText")}
                                                        description={t("admin.tags.miscellaneousTags.edit.actions.removeDataset.description")}
                                                        disabled={updateMiscellaneousTag.inFlight}
                                                        okText={t("admin.tags.miscellaneousTags.edit.actions.removeDataset.confirmText")}
                                                        onConfirm={() => removeDataset(item.id)}
                                                        title={t("admin.tags.miscellaneousTags.edit.actions.removeDataset.title")}
                                                    >
                                                        <Button
                                                            danger
                                                            icon={<CloseCircleOutlined />}
                                                            title={t("admin.tags.miscellaneousTags.edit.actions.removeDataset.title")}
                                                            type="text"
                                                        />
                                                    </Popconfirm>
                                                    {item.name}
                                                </Space>
                                            </List.Item>
                                        )}
                                        rowKey={(item) => item.id}
                                        size="small"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Divider orientation="left">
                                        {t("admin.tags.miscellaneousTags.edit.sections.otherDatasets.title")}
                                    </Divider>
                                    <em>{t("admin.tags.miscellaneousTags.edit.sections.otherDatasets.description")}</em>
                                </Col>
                                <Col span={16}>
                                    <Select
                                        disabled={updateMiscellaneousTag.inFlight || deleteMiscellaneousTag.inFlight}
                                        filterOption={(input, option) => (
                                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                        )}
                                        onChange={(_value, option: DefaultOptionType) => {
                                            setSelectedDataset(selectDatasets.find(({ id }) => id === option.value));    
                                        }}
                                        options={selectDatasets.map(({ id, name }) => ({ label: name, value: id }))}
                                        placeholder={t("admin.tags.miscellaneousTags.edit.actions.addDataset.select")}
                                        showSearch
                                        style={{ width: "100%" }}
                                        title={t("admin.tags.miscellaneousTags.edit.actions.addDataset.select")}
                                        value={selectedDataset?.name}
                                    />
                                </Col>
                                <Col span={8}>
                                    <Button
                                        disabled={updateMiscellaneousTag.inFlight || deleteMiscellaneousTag.inFlight}
                                        ghost={true}
                                        key="add-dataset"
                                        onClick={() => addDataset(selectedDataset)}
                                        title={t("admin.tags.miscellaneousTags.edit.actions.addDataset.title")}
                                        type="primary"
                                    >
                                        {t("admin.tags.miscellaneousTags.edit.actions.addDataset.title")}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row align="middle" gutter={[16, 16]} justify="space-evenly">
                                <Form.Item>
                                    <Space size={[64, 64]}>
                                        <Space>
                                            {/* Save Button */}
                                            <Button
                                                disabled={deleteMiscellaneousTag.inFlight}
                                                htmlType="submit"
                                                icon={<SaveOutlined />}
                                                loading={updateMiscellaneousTag.inFlight}
                                                title={t("admin.tags.miscellaneousTags.edit.actions.save.title")}
                                                type="primary"
                                            >
                                                {t("admin.tags.miscellaneousTags.edit.actions.save.title")}
                                            </Button>

                                            {/* Cancel Button */}
                                            <Button
                                                disabled={updateMiscellaneousTag.inFlight || deleteMiscellaneousTag.inFlight}
                                                onClick={() => navigate("/admin/tags/miscellaneous-tags")}
                                                title={t("admin.tags.miscellaneousTags.edit.actions.cancel")}
                                            >
                                                {t("admin.tags.miscellaneousTags.edit.actions.cancel")}
                                            </Button>
                                        </Space>

                                        {/* Delete Button */}
                                        <Popconfirm
                                            cancelText={t("admin.tags.miscellaneousTags.edit.actions.delete.cancelText")}
                                            description={t("admin.tags.miscellaneousTags.edit.actions.delete.description")}
                                            disabled={updateMiscellaneousTag.inFlight}
                                            okText={t("admin.tags.miscellaneousTags.edit.actions.delete.confirmText")}
                                            onConfirm={async () => await onDelete()}
                                            title={t("admin.tags.miscellaneousTags.edit.actions.delete.title")}
                                        >
                                            <Space>
                                                <Button
                                                    danger
                                                    disabled={updateMiscellaneousTag.inFlight}
                                                    icon={<DeleteOutlined />}
                                                    loading={deleteMiscellaneousTag.inFlight}
                                                    title={t("admin.tags.miscellaneousTags.edit.actions.delete.title")}
                                                >
                                                    {t("admin.tags.miscellaneousTags.edit.actions.delete.title")}
                                                </Button>
                                            </Space>
                                        </Popconfirm>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};