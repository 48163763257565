import { gql } from "@apollo/client";

export const ADMIN_UPDATE_DIVISION = gql`
  mutation AdminUpdateDivision($input: UpdateDivisionInput!) {
    updateDivision(input: $input) {
      id
      name
      departments {
        id
        name
        programs {
          id
          name
          datasets {
            id
            name
          }
        }
      }      
    }
  }
`;
