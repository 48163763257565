/**
 * Exports the Media Id class
 * @exports Echo/Media
 * @author Dai Williams <dai.williams@bbc.co.uk>
 */
define(function(require) {
  'use strict';

  var Utils = require('./util/methods');
  var LabelCleanser = require('./util/cleansing/label-cleanser');

  /**
   * Initialise a Media Id Object.
   * @namespace MediaId
   * @constructor
   * @param {MediaIdType} _idType The Media type identifier
   * for a piece of media. 'Enums.MediaIdType'
   *
   * @description The MediaId object is used within the Media object
   * so set values for specific identifiers within the Media object itself.
   * these types can be:
   *
   *   VERSION
   *   CLIP
   *   EPISODE
   *   SERVICE
   *   VPID
   *   NONPIPSCONTENTID
   *
   */
  var MediaId = function(_idType, _requiresValidation) {
    this.idType = _idType;
    this.value = null;
    this.isSet = false;
    this.isValid = false;
    this.requiresValidation = _requiresValidation;
  };

  /**
   * @memberOf MediaId
   * @instance
   * @method getIdType
   *
   * @returns {MediaIdType} The MediaId type
   */
  MediaId.prototype.getIdType = function() {
    return this.idType;
  };

  /**
   * @memberOf MediaId
   * @instance
   * @method setValue
   *
   * @param {string} _value The value to set for MediaId
   * @returns {MediaIdType} The MediaId type
   */
  MediaId.prototype.setValue = function(_value) {
    this.isSet = true;

    if (_value == null || typeof _value !== 'string' || _value.length === 0) {
      Utils.assert(false, 'MediaId: The value for ' + this.idType + 'Id is invalid', true);
      this.isValid = false;
    } else {
      this.isValid = true;
    }

    this.value = LabelCleanser.cleanMediaId(_value);
  };

  /**
   * @memberOf MediaId
   * @instance
   * @method getValue
   *
   * @returns {string} The MediaId value
   */
  MediaId.prototype.getValue = function() {
    return this.value;
  };

  /**
   * @memberOf MediaId
   * @instance
   * @method isValueSet
   *
   * @description Confirms whether a value has been set for the value
   * invalid or valid
   * @returns {bool}
   */
  MediaId.prototype.isValueSet = function() {
    return this.isSet;
  };

  /**
   * @memberOf MediaId
   * @instance
   * @method isValueValid
   *
   * @description Confirms whether the value set is valid. If not set or invalid
   * it returns false
   * @returns {bool}
   */
  MediaId.prototype.isValueValid = function() {
    return this.isValid;
  };

  /**
   * @memberOf MediaId
   * @private
   * @method isValueSet
   *
   * @description Private helper to check if the value being set is
   * a valid value
   * @returns {bool}
   */
  MediaId.prototype._validateValue = function(value) {
    if (typeof value === 'string') {
      return this.validValueRegex.test(value);
    } else {
      return false;
    }
  };

  return MediaId;
});
