define(function() {
  'use strict';

  var DEBUG = require('../../util/debug');

  function MediaEvent() {}

  MediaEvent.sendEvent = function(tag, commonMediaParams, action, additionalEventArgs) {
    if (commonMediaParams) {
      var event = {
        action: action,
        playerId: commonMediaParams.playerId,
        mediaLabel: commonMediaParams.mediaLabel,
        mediaTheme1: commonMediaParams.mediaTheme1,
        mediaTheme2: commonMediaParams.mediaTheme2,
        mediaTheme3: commonMediaParams.mediaTheme3
      };

      // Merge in any additional event arguments
      for (var key in additionalEventArgs) {
        if (additionalEventArgs.hasOwnProperty(key)) {
          event[key] = additionalEventArgs[key];
        }
      }

      tag.richMedia.send(event);
      DEBUG.info('Sending ' + action + ' media event');
    }
  };

  return MediaEvent;
});
