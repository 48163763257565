define({
  BBC_APPLICATION_NAME: 'app_name', // Legacy BBC label
  BBC_APPLICATION_TYPE: 'app_type', // Legacy BBC label
  BBC_APPLICATION_VERSION: 'app_version', // Legacy BBC label
  BBC_COUNTER_NAME: 'name', // Legacy BBC label
  DESTINATION: 'bbc_destination', // Level 1 site
  PRODUCER: 'bbc_producer',  // Level 2 site
  BBC_LANGUAGE: 'language', //ECHO-151
  DEVICE_ID: 'ns_alias', // Overrides using s1
  COMSCORE_C1: 'c1',
  COMSCORE_C7: 'c7',

  BBC_MEASUREMENT_LIB_NAME: 'ml_name',
  BBC_MEASUREMENT_LIB_VERSION: 'ml_version',
  ECHO_TRACE: 'trace',
  ECHO_EVENT_NAME: 'echo_event',

  // Event was triggered by user interaction
  EVENT_TRIGGERED_BY_USER: 'ns_st_ui', //ComScore label

  // See https://confluence.dev.bbc.co.uk/display/iStats/Measurement+Events
  STREAMSENSE_CUSTOM_EVENT_TYPE: 'ns_st_ev', //ComScore label
  USER_ACTION_TYPE: 'action_type', // Legacy BBC label
  USER_ACTION_NAME: 'action_name', // Legacy BBC label
  PLAYLIST_END: 'ns_st_pe', //ComScore label

  REWIND_FF_RATE: 'ffrw_rate', // BBC label - made up by Echo team

  //Site key
  COMSCORE_SITE: 'ns_site', //ComScore label
  COMSCORE_CUSTOMER: 'c2', //ComScore label

  // Test service label keys
  COMSCORE_ENDPOINT: 'comscore_endpoint', //ComScore endpoint

  // Player Keys
  PLAYER_NAME: 'ns_st_mp', //ComScore label
  PLAYER_VERSION: 'ns_st_mv', //ComScore label
  PLAYER_WINDOW_STATE: 'ns_st_ws', //ComScore label
  PLAYER_VOLUME: 'ns_st_vo', //ComScore label
  PLAYER_POPPED: 'bbc_st_pop',
  PLAYER_SUBTITLED: 'bbc_st_sub',

  // Playlist Keys
  PLAYLIST_NAME: 'ns_st_pl', //ComScore label
  PLAYLIST_CLIP_COUNT: 'ns_st_cp', //ComScore label
  PLAYLIST_LENGTH: 'ns_st_ca', //ComScore label

  // Media Keys
  MEDIA_PID: 'ns_st_ci', //ComScore label
  MEDIA_LENGTH: 'ns_st_cl', //ComScore label
  MEDIA_STREAM_TYPE: 'ns_st_ty', //ComScore label
  MEDIA_IS_LIVE: 'ns_st_li', //ComScore label
  MEDIA_EPISODE_ID: 'episode_id', //Legacy BBC label
  MEDIA_CLIP_ID: 'clip_id', //Legacy BBC label
  MEDIA_CLIP_NUMBER: 'ns_st_cn', //ComScore label
  MEDIA_PART_NUMBER: 'ns_st_pn', //ComScore label
  MEDIA_TOTAL_PARTS: 'ns_st_tp', //ComScore label
  MEDIA_MEDIUM: 'bbc_st_med', //New BBC label from Echo
  MEDIA_LIVE_OR_ONDEMAND: 'bbc_st_lod', //New BBC label from Echo
  MEDIA_FORM: 'bbc_st_mf', //New BBC label from Echo
  MEDIA_RETRIEVAL_TYPE: 'bbc_st_ret', //New BBC label from Echo
  MEDIA_SCHEDULE_INDICATOR: 'bbc_st_sch', //New BBC label from Echo
  MEDIA_VERSION_ID: 'version_id', //NKDATA-467
  MEDIA_SERVICE_ID: 'service_id', //NKDATA-467
  MEDIA_NON_PIPS_CONTENT_ID: 'non_pips_content_id', //MYSTATS-1513
  MEDIA_AMBIGUOUS_ID: 'ambig_ci', // MYSTATS-891
  MEDIA_TIMESTAMP: 'bbc_st_live_ts', // MYSTATECHO-36 live timestamp
  ESS_SUCCESS: 'ess_success',
  ESS_ERROR: 'ess_error',
  ESS_STATUS_CODE: 'ess_status_code',

  // App Tag SDK compatibility
  APP_PLATFORM_NAME: 'ns_ap_pn',
  APP_PLATFORM_RUNTIME: 'ns_ap_pfm',
  APP_OS_VERSION: 'ns_ap_pfv',
  APP_DEVICE_NAME: 'ns_ap_device',
  APP_SCREEN_RESOLUTION: 'ns_ap_res',
  APP_LANGUAGE: 'ns_ap_lang',
  ENV_CHAR_SET: 'ns_c',
  ENV_TITLE: 'c8',
  ENV_URL: 'c7',
  ENV_REFERRER: 'c9',

  APP_NAME: 'ns_ap_an',
  APP_VERSION: 'ns_ap_ver',
  NO_COOKIES: 'ns_nc',
  COOKIE_POLICY: 'bbc_mc',
  BBC_ID_LOGGED_IN: 'bbc_identity',

  // WEB istats compatibility
  WEB_SCREEN_RES: 'screen_resolution',

  ESS_ENABLED: 'ess_enabled',
  ESS_ENRICHED: 'ess_enriched'
});
