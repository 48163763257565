import { GetAllPublishedRecordSets_publishedRecordSets } from "../graphql/__generated__/GetAllPublishedRecordSets";
import { flattenPublishedDocumentEntries, IPublishedRecordset, IPublishedRecordSetDocument, ISerialisedPublishedRecordset } from "../pages/DatasetDetails/PublishedRecordSet";
import dayjs from "dayjs";

export interface IChartData {
    attribute: string
    category: string
    count: number
    datasets: Set<string>
    date: dayjs.Dayjs
    exceeds: number
    groupedDate: string
    gt10: number
    lt10: number
    lt5: number
    ootPercent: number
    percent: number
    personType: string
    progressSegmentCount: number
    summedPercent: number
    targetMember: boolean
    targetState: string
    teams: Set<string>
    value: number;
}


export const grouped = (chartData: IChartData[] | undefined) => {
    return (
        chartData?.
            sort((a, b) => Number(a.targetMember) - Number(b.targetMember))
            .reduce((group, entry) => {
                const month = entry.date.month();
                const monthName = entry.date.format("MMMM");
                const year = entry.date.year();
                const monthYear = `${monthName} ${year}`;
                const key = `${monthYear} ${entry.attribute}`;
                if (!group[key]) {
                    group[key] = {} as IChartData;
                    group[key] = {
                        ...entry,
                        groupedDate: `${year}-${month + 1}-1`,
                        count: 1,
                        summedPercent: entry.percent
                    };
                    return group;
                }

                group[key].count += 1;
                group[key].summedPercent += entry.percent;
                group[key].percent = (group[key].summedPercent) / group[key].count;
                return group;
            }, {} as Record<string, IChartData>)
    ) ?? {} as Record<string, IChartData>;
};

export interface ICategoryGroup {
    percent: number
    count: number
    sum: number
    datasets: Set<string>
    teams: Set<string>
}

const expectedDateTimeFormat = "YYYY-M-DTHH:mm:ss";

export const groupedByCategory = (
    filteredData: ISerialisedPublishedRecordset[], timezoneName: string
): Record<string, ICategoryGroup> => {
    if (filteredData.length === 0) {
        return {} as Record<string, ICategoryGroup>;
    }

    return filteredData.reduce((groupedRecords, prs) => {
        const groupedEntries = flattenPublishedDocumentEntries((prs.document as IPublishedRecordSetDocument).record)
            .map((r) => ({ ...r, date: dayjs(prs.end, expectedDateTimeFormat, timezoneName) }))
            .reduce((groupedEntries, entry) => {
                if (!(entry.category in groupedEntries)) {
                    groupedEntries[entry.category] = { it: 0, oot: 0 };
                }

                if (entry.targetMember) {
                    groupedEntries[entry.category].it += entry.percent;
                } else {
                    groupedEntries[entry.category].oot += entry.percent;
                }

                return groupedEntries;
            }, {} as Record<string, { it: number, oot: number }>);

        Object.entries(groupedEntries)
            .forEach(([category, percent]) => {
                if (!(percent.it + percent.oot)) return;
                if (!(category in groupedRecords)) {
                    groupedRecords[category] = {} as ICategoryGroup;
                    groupedRecords[category].sum = percent.it;
                    groupedRecords[category].count = 1;
                    groupedRecords[category].percent = percent.it;
                    groupedRecords[category].datasets = new Set<string>();
                    groupedRecords[category].teams = new Set<string>();
                }
                else {
                    groupedRecords[category].sum += percent.it;
                    groupedRecords[category].count += 1;
                    groupedRecords[category].percent = groupedRecords[category].sum / groupedRecords[category].count;
                }
                const team = prs.dataset?.program?.team?.name;
                team && groupedRecords[category].teams.add(team);
                groupedRecords[category].datasets.add(prs.datasetId);
            });

        return groupedRecords;
    }, {} as Record<string, ICategoryGroup>);
};

export const groupedByYearCategory = (filteredData: (GetAllPublishedRecordSets_publishedRecordSets[] | undefined)[]) => {
    if (!filteredData) {
        return [{}] as Record<string, Record<string, { percent: number, count: number, sum: number, datasets: Set<string> }>>[];
    }

    return filteredData.map(x => x?.reduce((groupedRecords, prs) => {
        const groupedEntries = flattenPublishedDocumentEntries((prs.document as IPublishedRecordSetDocument).record)
            .map((r) => ({ ...r, date: new Date(prs.end) }))
            .reduce((groupedEntries, entry) => {
                const year = entry.date.getFullYear();
                if (!(year in groupedEntries)) {
                    groupedEntries[year] = {} as Record<string, number>;
                }
                if (!(entry.category in groupedEntries[year]) && entry.targetMember) {
                    groupedEntries[year][entry.category] = entry.percent;
                    return groupedEntries;
                }
                if (entry.targetMember) {
                    groupedEntries[year][entry.category] += entry.percent;
                }
                return groupedEntries;
            }, {} as Record<string, Record<string, number>>);

        Object.entries(groupedEntries)
            .forEach(([year, categories]) => {
                if (!(year in groupedRecords)) {
                    groupedRecords[year] = {} as Record<string, { percent: number, count: number, sum: number, datasets: Set<string> }>;
                }
                Object.entries(categories)
                    .forEach(([category, percent]) => {
                        if (!(category in groupedRecords[year])) {
                            groupedRecords[year][category] = {} as { percent: number, count: number, sum: number, datasets: Set<string> };
                            groupedRecords[year][category].sum = percent;
                            groupedRecords[year][category].count = 1;
                            groupedRecords[year][category].percent = percent;
                            groupedRecords[year][category].datasets = new Set<string>();
                            groupedRecords[year][category].datasets.add(prs.datasetId);
                        }
                        else {
                            groupedRecords[year][category].sum += percent;
                            groupedRecords[year][category].count += 1;
                            groupedRecords[year][category].percent = groupedRecords[year][category].sum / groupedRecords[year][category].count;
                            groupedRecords[year][category].datasets.add(prs.datasetId);
                        }

                    });
            });

        return groupedRecords;

    }, {} as Record<string, Record<string, { percent: number, count: number, sum: number, datasets: Set<string> }>>));

};

export const groupedByMonthYearCategory = (
    parsedSortedFilteredData: IPublishedRecordset[] | undefined,
    categories: readonly string[],
    setOfDatasetIdsInLastMonthYearOfDateRange?: Record<string, Set<string>>
) => {
    if (!parsedSortedFilteredData) {
        return {} as Record<string, Record<string, IChartData>>;
    }

    return parsedSortedFilteredData
        .reduce((groupedRecords, x) => {
            const prsDoc = x.document as IPublishedRecordSetDocument;
            const groupedEntries = flattenPublishedDocumentEntries(prsDoc.record)
                .map((r) => ({ ...r, date: x.end }))
                .reduce((groupedEntries, entry) => {
                    if (categories.length && !categories.includes(entry.category)) {
                        return groupedEntries;
                    }

                    if (setOfDatasetIdsInLastMonthYearOfDateRange && (
                        !(entry.category in setOfDatasetIdsInLastMonthYearOfDateRange) ||
                        !setOfDatasetIdsInLastMonthYearOfDateRange[entry.category].has(x.datasetId)
                    )) {
                        return groupedEntries;
                    }

                    const year = entry.date.year();
                    const month = entry.date.month();
                    const monthName = entry.date.format("MMMM");
                    let monthYear = `${monthName} ${year}`;
                    let groupedDateString = `${year}-${month + 1}-1`;

                    if (setOfDatasetIdsInLastMonthYearOfDateRange) {
                        monthYear = "First entry";
                        groupedDateString = "First entry";
                    }

                    if (!(monthYear in groupedEntries)) {
                        groupedEntries[monthYear] = {} as Record<string, IChartData>;
                    }

                    if (!(entry.category in groupedEntries[monthYear])) {
                        groupedEntries[monthYear][entry.category] = {
                            ...entry,
                            percent: 0,
                            ootPercent: 0,
                            summedPercent: 0,
                            count: 0,
                            groupedDate: groupedDateString,
                            date: x.end
                        } as IChartData;
                    }

                    entry.targetMember ?
                        groupedEntries[monthYear][entry.category].percent += entry.percent :
                        groupedEntries[monthYear][entry.category].ootPercent += entry.percent;

                    return groupedEntries;
                }, {} as Record<string, Record<string, IChartData>>);
            
            Object.entries(groupedEntries)
                .forEach(([monthYear, categories]) => {
                    if (!(monthYear in groupedRecords)) {
                        groupedRecords[monthYear] = {} as Record<string, IChartData>;
                    }
                    Object.entries(categories)
                        .forEach(([category, groupedEntry]) => {
                            if (!(groupedEntry.ootPercent + groupedEntry.percent)) {
                                return;
                            }

                            const target = prsDoc.targets.find(x => x.category === category);
                            if (!target || !target.target) {
                                return;
                            }

                            if (!(category in groupedRecords[monthYear])) {
                                groupedRecords[monthYear][category] = { ...groupedEntry } as IChartData;
                                groupedRecords[monthYear][category].summedPercent = groupedEntry.percent;
                                groupedRecords[monthYear][category].count = 1;
                                groupedRecords[monthYear][category].percent = groupedEntry.percent;
                                groupedRecords[monthYear][category].exceeds = 0;
                                groupedRecords[monthYear][category].lt5 = 0;
                                groupedRecords[monthYear][category].lt10 = 0;
                                groupedRecords[monthYear][category].gt10 = 0;
                                groupedRecords[monthYear][category].progressSegmentCount = 0;
                                groupedRecords[monthYear][category].datasets = new Set<string>();
                                groupedRecords[monthYear][category].teams = new Set<string>();
                            } else {
                                groupedRecords[monthYear][category].summedPercent += groupedEntry.percent;
                                groupedRecords[monthYear][category].count += 1;
                                groupedRecords[monthYear][category].percent = groupedRecords[monthYear][category].summedPercent / groupedRecords[monthYear][category].count;
                            }

                            const team = x.dataset?.program?.team?.name;
                            team && groupedRecords[monthYear][category].teams.add(team);                
                            groupedRecords[monthYear][category].datasets.add(x.datasetId);

                            const roundedPercent = Math.round(groupedEntry.percent);
                            if (roundedPercent >= target.target) {
                                groupedRecords[monthYear][category].exceeds += 1;
                            } else if (roundedPercent >= target.target - 5) {
                                groupedRecords[monthYear][category].lt5 += 1;
                            } else if (roundedPercent >= target.target - 10) {
                                groupedRecords[monthYear][category].lt10 += 1;
                            } else {
                                groupedRecords[monthYear][category].gt10 += 1;
                            }
                        });
                });

            return groupedRecords;
        }, {} as Record<string, Record<string, IChartData>>);
};


export const flattenProgressChartData = (records: Record<string, Record<string, IChartData>>, firstEntry?: boolean): IChartData[] =>
    ["exceeds", "lt5", "lt10", "gt10"]
        .map(targetState => Object.values(records)
            .filter((_, i, arr) => firstEntry || i === arr.length - 1)
            .flatMap(byMonthYear => Object.values(byMonthYear))
            .map(y => ({
                ...y,
                targetState: [targetState, y.category].join("_"),
                value: y[targetState]
            }))
        )
        .flat();


export const flattened = (grouped: Record<string, IChartData>) => {
    return Object.values(grouped).sort((a, b) => a.date.valueOf() - b.date.valueOf());
};