import { PageHeader } from "@ant-design/pro-layout";
import { Card, Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";

import "./ManageTags.css";
import CustomHelmet from "../../../components/CustomHelmet";

const { Text } = Typography;


enum TagType {
    divisions = "divisions",
    departments = "departments",
    platforms = "platforms",
    contentTypes = "content-types",
    miscellaneousTags = "miscellaneous-tags",
}

type ManageTagTypeCardProps = {
    title: string;
    value: TagType
};

const TAG_TYPES = ["divisions", "departments", "platforms", "contentTypes", "miscellaneousTags"];


export const ManageTags = () => {
    const { t } = useTranslation();

    const cards: ManageTagTypeCardProps[] = TAG_TYPES.map(tagType => (
        { title: t(`admin.tags.index.navigation.${tagType}`), value: TagType[tagType]}
    ));

    return (
        <Row style={{ height: "75%" }}>
            
            {/* Page Title */}
            <CustomHelmet  title={t("admin.tags.index.title")} />

            {/* Page Header */}
            <Col span={24}>
                <PageHeader title={t("admin.tags.index.title")} />
            </Col>

            {/* Manage Tags */}
            <Col offset={1} span={22}>
                <Row align="stretch" justify="space-evenly">
                    {
                        cards.map(({ title, value }) => (
                            <Col key={`manage-${value}-link`} span={4}>
                                <a href={`/admin/tags/${value}`}>
                                    <Card className="navigation-card">
                                        <Text strong>
                                            {title}
                                        </Text>
                                    </Card>
                                </a>
                            </Col>              
                        ))
                    }
                </Row>
            </Col>
        </Row>
    );
};
