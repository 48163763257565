import { gql } from "@apollo/client";

export const ADMIN_UPDATE_USER_ROLES = gql`
  mutation AdminUpdateUserRoles($input: UpdateUserRolesInput!) {
    updateUserRoles(input: $input) {
      id
      name
      description
    }
  }
`;
