import { gql } from "@apollo/client";

export const ADMIN_UPDATE_DEPARTMENT = gql`
  mutation AdminUpdateDepartment($input: UpdateDepartmentInput!) {
    updateDepartment(input: $input) {
      id
      name
      division {
        id
        name
      }
      programs {
        id
        name
        datasets {
          id
          name
        }
      }
    }
  }
`;
