import { gql } from "@apollo/client";

export const ADMIN_DELETE_USER_ROLES = gql`
  mutation AdminDeleteUserRoles($input: DeleteUserRolesInput!) {
    deleteUserRoles(input: $input) {
      id
      name
      description
    }
  }
`;
