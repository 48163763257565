import { 
    AppstoreAddOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    DownloadOutlined,
    EditOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { useLazyQuery } from "@apollo/client";
import { Button, Col, Form, Input, Modal, Row, Table, TableColumnsType } from "antd";
import { FilterValue } from "antd/lib/table/interface";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";

import { CreateProgram, CreateProgramFormValues } from "./CreateProgram";
import "./ManageDatasets.css";
import { mapCsvPublishedRecordSets } from "../../DatasetDetails/datasetDetailsExport";
import CustomHelmet from "../../../components/CustomHelmet";
import { AdminGetAllPrograms, AdminGetAllPrograms_programs } from "../../../graphql/__generated__/AdminGetAllPrograms";
import { AdminGetDatasetToExport, AdminGetDatasetToExportVariables } from "../../../graphql/__generated__/AdminGetDatasetToExport";
import { ADMIN_GET_ALL_PROGRAMS } from "../../../graphql/__queries__/AdminGetAllPrograms.gql";
import { ADMIN_GET_DATASET_TO_EXPORT } from "../../../graphql/__queries__/AdminGetDatasetToExport.gql";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";
import { CsvExportItem, generateCsvExport } from "../../../utils/csvExport";
import { sortDiversityCategories } from "../../../utils/sortDiversityCategories";

dayjs.extend(customParseFormat);

const { Search } = Input;


type DownloadDatasetButtonProps = {
    program: Program;
}

type Category = {
    deleted: boolean;
    displayName: string;
    priority: number | null;
}

type Program = {
    programId: string;
    datasetId: string;
    datasetName: string;
    datasetLastUpdated: string;
    active: boolean;
    categories: Category[];
    contentTypes: string[];
    department: string;
    division: string;
    miscellaneousTags: string[];
    platforms: string[];
}

type TableFilters = Record<string, FilterValue | null>;


const DATE_FORMAT = "DD/MM/YYYY";
const TABLE_FILTERS_DEFAULT: TableFilters = { active: ["true"] };

const mapCsvPrograms = (programs: Program[]): CsvExportItem[] => programs.map(program => (
    {
        ["Dataset Name"]: "\"" + program.datasetName + "\"",
        ["Last Updated"]: "\"" + program.datasetLastUpdated + "\"",
        ["Categories"]: "\"" + program.categories.map(({ displayName }) => displayName).join(", ") + "\"",
        ["Content Types"]: "\"" + program.contentTypes.join(", ") + "\"",
        ["Department"]: "\"" + program.department + "\"",
        ["Division"]: "\"" + program.division + "\"",
        ["Misc. Tags"]: "\"" + program.miscellaneousTags.join(", ") + "\"",
        ["Platforms"]: "\"" + program.platforms.join(", ") + "\"",
    }
));

const mapRawPrograms = (rawPrograms: readonly AdminGetAllPrograms_programs[]): Program[] => (
    rawPrograms
        .filter(program => program.datasets.length > 0)
        .map(program => (
            {
                programId: program.id,
                datasetId: program.datasets[0].id,
                datasetName: program.datasets[0].name,
                datasetLastUpdated: program.datasets[0].lastUpdated 
                    ? dayjs(program.datasets[0].lastUpdated).format(DATE_FORMAT) : "N/A",
                active: !program.deleted,
                categories: [...program.targets]
                    .map(({ category }) => ({
                        deleted: category.deleted !== null,
                        displayName: category.displayName,
                        priority: category.priority,
                    })).sort((a, b) => sortDiversityCategories(a.priority, b.priority)),
                contentTypes: (program.contentTypes || []).map(contentType => contentType.name),
                department: program.department?.name || "",
                division: program.department?.division.name || "",
                miscellaneousTags: program.tags.map(tag => tag.name),
                platforms: (program.platforms || []).map(platform => platform.name),
            }
        ))
);

const applySearchFilter = (programs: Program[] | undefined, searchTerms: string[]) => (
    programs?.filter(({ datasetName, categories, contentTypes, miscellaneousTags, platforms }) => (
        searchTerms.every(term => (
            `${datasetName}
            :${categories.join(":")}
            :${contentTypes.join(":")}
            :${miscellaneousTags.join(":")}
            :${platforms.join(":")}`
        ).toLocaleLowerCase().includes(term.toLocaleLowerCase()))
    )) || []
);

const stringSorter = (a: string, b: string) => {
    if (a !== "") {
        if (b !== "") {
            return a.localeCompare(b);
        }
        return -1;
    }
    return 1;
};

const dateSorter = (a: dayjs.Dayjs, b: dayjs.Dayjs) => {
    if (a.isValid()) {
        if (b.isValid()) {
            if (a.isSame(b)) {
                return 0;
            }
            if (a.isBefore(b)) {
                return -1;
            }
            if (a.isAfter(b)) {
                return 1;
            }
        }
        return 1;
    }
    return -1;
};

const DownloadDatasetButton = ({ program }: DownloadDatasetButtonProps) => {
    const [downloadDatasetUrl, setDownloadDatasetUrl] = useState<string>("");
    const csvLink = useRef<HTMLAnchorElement | null>(null);

    const [
        getDataset, { loading: getDatasetLoading }
    ] = useLazyQuery<AdminGetDatasetToExport, AdminGetDatasetToExportVariables>(ADMIN_GET_DATASET_TO_EXPORT);

    const downloadDataset = async (datasetId: string) => {
        const rawDataset = await getDataset({ variables: { id: datasetId }, fetchPolicy: "network-only" });
        const { titleKeys, mappedRows } = mapCsvPublishedRecordSets(rawDataset.data?.dataset);
        if (!titleKeys || !mappedRows) {
            return;
        }

        const url = generateCsvExport({ items: mappedRows, headers: titleKeys });
        setDownloadDatasetUrl(url);
        (csvLink.current as HTMLAnchorElement).click();
    };

    return (
        <>
            <Button
                disabled={getDatasetLoading}
                icon={<DownloadOutlined />}
                key={`download-${program.datasetId}`}
                onClick={async () => await downloadDataset(program.datasetId)}
                shape="round"
                title="Export Published Records for Dataset"
                type="primary"
            />
            <a
                download={`${program.datasetName}.csv`}
                hidden 
                href={downloadDatasetUrl}
                ref={csvLink}
                title="Export Published Records for Dataset"
            >
                Export Published Records for Dataset
            </a>
        </>
    );
};


export const ManageDatasets = () => {
    const navigate = useNavigate();
    const [createProgramForm] = Form.useForm<CreateProgramFormValues>();

    const [activeTableFilters, setActiveTableFilters] = useState<TableFilters>(TABLE_FILTERS_DEFAULT);
    const [exportAllDatasetsUrl, setExportAllDatasetsUrl] = useState<string>("");
    const [searchTerms, setSearchTerms] = useState<string[]>([]);
    const [showCreateProgram, setShowCreateProgram] = useState(false);
    
    const rawPrograms = useQueryWithErrorHandling<AdminGetAllPrograms>(
        ADMIN_GET_ALL_PROGRAMS, "programs", { fetchPolicy: "cache-first" }
    );

    const programs = useMemo(() => mapRawPrograms(rawPrograms.data?.programs || []), [rawPrograms]);

    const categories = useMemo(() => {
        const seenCategories = new Set<string>();
        const uniqueCategories: Category[] = [];
        programs.forEach(program => program.categories.forEach(category => {
            if (!seenCategories.has(category.displayName)) {
                seenCategories.add(category.displayName);
                uniqueCategories.push(category);
            }
        }));

        const activeCategories = uniqueCategories.filter(category => !category.deleted);
        setActiveTableFilters({ ...TABLE_FILTERS_DEFAULT, categories: activeCategories.map(({ displayName }) => displayName )});

        return uniqueCategories;
    }, [programs]);

    const contentTypes = useMemo(() => {
        const deduped = [...new Set(programs.flatMap(program => program.contentTypes.map(contentType => contentType)))];
        return deduped.filter(contentType => contentType !== "").sort((a, b) => stringSorter(a, b));
    }, [programs]);

    const departments = useMemo(() => {
        const deduped = [...new Set(programs.map(program => program.department))];
        return deduped.filter(department => department !== "").sort((a, b) => stringSorter(a, b));
    }, [programs]);

    const divisions = useMemo(() => {
        const deduped = [...new Set(programs.map(program => program.division))];
        return deduped.filter(division => division !== "").sort((a, b) => stringSorter(a, b));
    }, [programs]);

    const miscellaneousTags = useMemo(() => {
        const deduped = [...new Set(programs.flatMap(program => program.miscellaneousTags.map(tag => tag)))];
        return deduped.filter(tag => tag !== "").sort((a, b) => stringSorter(a, b));
    }, [programs]);

    const platforms = useMemo(() => {
        const deduped = [...new Set(programs.flatMap(program => program.platforms.map(platform => platform)))];
        return deduped.filter(platform => platform !== "").sort((a, b) => stringSorter(a, b));
    }, [programs]);

    const filteredPrograms = useMemo(() => (
        searchTerms.length ? applySearchFilter(programs, searchTerms) : programs
    ), [programs, searchTerms]);

    const exportAllDatasets = () => {
        const csvPrograms = mapCsvPrograms(programs);
        const url = generateCsvExport({ items: csvPrograms });
        setExportAllDatasetsUrl(url);
    };

    const handleTableColumnFiltering = (_pagination, filters: TableFilters) => setActiveTableFilters(filters);

    const columns: TableColumnsType<Program> = [
        {
            title: "Dataset Name",
            key: "dataset-name",
            dataIndex: "datasetName",
            defaultSortOrder: "ascend",
            fixed: "left",
            onCell: () => ({ id: "datasets-index-fixed-column-cell" }),
            sorter: (a, b) => stringSorter(a.datasetName, b.datasetName),
        },
        {
            title: "Last Updated",
            key: "dataset-last-updated",
            dataIndex: "datasetLastUpdated",
            fixed: "left",
            onCell: () => ({ id: "datasets-index-fixed-column-cell" }),
            sorter: (a, b) => dateSorter(dayjs(a.datasetLastUpdated, DATE_FORMAT), dayjs(b.datasetLastUpdated, DATE_FORMAT)),
            width: 140,
        },
        {
            title: "Categories",
            key: "categories",
            dataIndex: "categories",
            filteredValue: activeTableFilters["categories"] || null,
            filters: categories.sort((a, b) => sortDiversityCategories(a.priority, b.priority))
                .map(({ deleted, displayName }) => ({ 
                    text: deleted ? `${displayName} [DELETED]` : displayName,
                    value: displayName
                })),
            onFilter: (_value: boolean | string | number, record: Program) => (
                (activeTableFilters["categories"] || []).some(category => (
                    record.categories.map(({ displayName }) => displayName).includes(category as string)
                ))
            ),
            render: (categories: Category[]) => categories
                .map(({ deleted, displayName }, index, array) => (
                    <span key={displayName}>
                        <span style={{ color: deleted ? "lightgrey" : "black" }}>{displayName}</span>
                        <span>{index < array.length - 1 ? ", " : ""}</span>
                    </span>
                )),
            width: 210,
        },
        {
            title: "Division",
            key: "division",
            dataIndex: "division",
            filteredValue: activeTableFilters["division"] || null,
            filters: divisions.map(division => ({ text: division, value: division })),
            onFilter: (value: boolean | string | number, record: Program) => record.division === value,
            width: 120,
        },
        {
            title: "Department",
            key: "department",
            dataIndex: "department",
            filteredValue: activeTableFilters["department"] || null,
            filters: departments.map(department => ({ text: department, value: department })),
            filterSearch: true,
            onFilter: (value: boolean | string | number, record: Program) => record.department === value,
            width: 180,
        },
        {
            title: "Misc. Tags",
            key: "miscellaneous-tags",
            dataIndex: "miscellaneousTags",
            filteredValue: activeTableFilters["miscellaneous-tags"] || null,
            filters: miscellaneousTags.map(tag => ({ text: tag, value: tag })),
            filterSearch: true,
            onFilter: (value: boolean | string | number, record: Program) => (
                record.miscellaneousTags.some(tag => tag === value)
            ),
            render: (miscellaneousTags: string[]) => miscellaneousTags.join(", "),
            width: 220,
        },
        {
            title: "Content Types",
            key: "content-types",
            dataIndex: "contentTypes",
            filteredValue: activeTableFilters["content-types"] || null,
            filters: contentTypes.map(contentType => ({ text: contentType, value: contentType })),
            filterSearch: true,
            onFilter: (value: boolean | string | number, record: Program) => (
                record.contentTypes.some(contentType => contentType === value)
            ),
            render: (contentTypes: string[]) => contentTypes.join(", "),
            width: 140,
        },
        {
            title: "Platforms",
            key: "platforms",
            dataIndex: "platforms",
            filteredValue: activeTableFilters["platforms"] || null,
            filters: platforms.map(platform => ({ text: platform, value: platform })),
            filterSearch: true,
            onFilter: (value: boolean | string | number, record: Program) => (
                record.platforms.some(platform => platform === value)
            ),
            render: (platforms: string[]) => platforms.join(", "),
            width: 110,
        },
        {
            title: "View Details",
            key: "view-details",
            dataIndex: "datasetId",
            align: "center",
            fixed: "right",
            onCell: () => ({ id: "datasets-index-fixed-column-cell" }),
            render: (datasetId: string) => (
                <a href={`/dataset/${datasetId}/details`}>
                    <Button icon={<InfoCircleOutlined />} title="View Details">
                        View Details
                    </Button>
                </a>
            ),
            width: 150,
        },
        {
            title: "Active",
            key: "active",
            dataIndex: "active",
            align: "center",
            filteredValue: activeTableFilters["active"] || null,
            filters: [{ text: "Active", value: "true" }, { text: "Inactive", value: "false" }],
            fixed: "right",
            onCell: () => ({ id: "datasets-index-fixed-column-cell" }),
            onFilter: (value: boolean | string | number, record: Program) => String(record.active) === value,
            render: (active: boolean) => (active ? <CheckCircleOutlined /> : <CloseCircleOutlined />),
            width: 80,
        },
        {
            title: "Edit",
            key: "edit-dataset-link",
            dataIndex: "programId",
            align: "center",
            fixed: "right",
            onCell: () => ({ id: "datasets-index-fixed-column-cell" }),
            render: (programId: string) => (
                <a href={`/admin/programs/${programId}`}>
                    <EditOutlined />
                </a>
            ),
            width: 60,
        },
        {
            title: "Records",
            key: "export-dataset",
            align: "center",
            fixed: "right",
            onCell: () => ({ id: "datasets-index-fixed-column-cell" }),
            render: (_, program: Program) => <DownloadDatasetButton program={program}/>,
            width: 100,
        },
    ];
    
    return (
        <Row gutter={[16, 16]}>
            {/* Page Title */}
            <CustomHelmet title="Manage Datasets" />

            {/* Page Header */}
            <Col span={24}>
                <PageHeader 
                    title="Manage Datasets"
                    extra={[
                        // Create New Programme //
                        <Button
                            title="Create Dataset Group"
                            key="show-create-new-programme"
                            disabled={rawPrograms.loading}
                            icon={<AppstoreAddOutlined />}
                            onClick={() => setShowCreateProgram(true)}
                            type="primary"
                        >
                            Create Dataset Group
                        </Button>,
                        // Bulk Edit Reporting Periods //
                        <Button
                            title="Bulk Edit Reporting Periods"
                            key="bulk-edit-reporting-periods"
                            icon={<EditOutlined />}
                            onClick={() => navigate("/admin/programs/reporting-periods")}
                            type="primary"
                        >
                            Bulk Edit Reporting Periods
                        </Button>,
                        // Export All Datasets //
                        <Button
                            title="Export All Datasets"
                            key="export-all-datasets"
                            disabled={rawPrograms.loading}
                            download="all-datasets.csv"
                            href={exportAllDatasetsUrl}
                            icon={<DownloadOutlined />}
                            onClick={exportAllDatasets}
                            shape="round"
                            type="primary"
                        >
                            Export All Datasets
                        </Button>
                    ]}
                />
            </Col>

            {/* Create New Programme Modal */}
            <Modal
                title="Create Dataset Group"
                key="create-new-programme"
                cancelText="Cancel"
                forceRender
                okText="Create Dataset Group"
                onOk={() => createProgramForm.submit()}
                onCancel={() => {
                    setShowCreateProgram(false);
                    createProgramForm.resetFields();
                }}
                open={showCreateProgram}
            >
                <CreateProgram form={createProgramForm} />
            </Modal>

            {/* Search Datasets */}
            <Col span={8}>
                <Search 
                    allowClear
                    disabled={rawPrograms.loading}
                    onSearch={(input) => setSearchTerms(input.split(" "))} 
                    placeholder="Search Datasets"
                />
            </Col>

            {/* View Datasets */}
            <Col span={24}>
                <Table
                    columns={columns}
                    dataSource={filteredPrograms}
                    loading={rawPrograms.loading}
                    onChange={handleTableColumnFiltering}
                    rowKey={({ programId }) => programId}
                    scroll={{ x: 1700 }}
                    size="small"
                    tableLayout="fixed"
                />
            </Col>
        </Row>
    );
};