define(function(require) {
  'use strict';

  var DEBUG = require('../../util/debug');
  var ATILabelKeys = require('./label-keys');
  var InvalidATIPropertyKeys = require('./invalid-property-keys');
  var LabelCleanser = require('../../util/cleansing/label-cleanser');
  var LibraryInfo = require('../../util/library-info');

  var customVariables = {

    APP_NAME: undefined,
    APP_CATEGORY: undefined,

    // NB - These custom variable keys are defined as string values in label-key.js
    bbc_content_id: 1,
    bbc_app_type: 2,
    bbc_app_name: 3,
    language: 4,
    bbc_url: 5,
    bbc_referrer_url: 6,
    bbc_content_type: 7,
    bbc_library_version: 8,
    page_title: 9,

    custom_var_1: 11,
    custom_var_2: 12,
    custom_var_3: 13,
    custom_var_4: 14,
    custom_var_5: 15,
    custom_var_6: 16,
    custom_var_7: 17,
    custom_var_8: 18,
    custom_var_9: 19,
    custom_var_10: 20,

    // Creates a custom variables object
    create: function(appName, appType, customParams, eventLabels, orbitVars, document) {
      var customVars;
      this.APP_NAME = appName;

      if (customParams && customParams[ATILabelKeys.APP_CATEGORY]) {
        this.APP_CATEGORY = customParams[ATILabelKeys.APP_CATEGORY];
      } else {
        this.APP_CATEGORY = '';
      }

      // The _setCustomVariable method will lazily initialie the custom variables object and return the
      // complete set of custom variables added so far
      customVars = this._setCustomVariable(customVars, ATILabelKeys.APP_TYPE, appType);
      customVars = this._setCustomVariable(customVars, ATILabelKeys.APP_NAME, appName);

      customVars = this._setCustomVariable(customVars, ATILabelKeys.LIBRARY_VERSION, LibraryInfo.getLibraryName() + '-' + LibraryInfo.getLibraryVersion());

      if (document) {
        customVars = this._setDocumentCustomVariables(customVars, document);
      }

      if (orbitVars) {
        customVars = this._setOrbitCustomVariables(customVars, orbitVars);
      }

      this._applyLabels(customVars, customParams);
      this._applyLabels(customVars, eventLabels);

      return customVars;
    },

    // Initialises a custom variables object ready for custom variables to be added
    _initialise: function() {
      // Initialise the custom variables object
      return {
        site: {}
      };
    },

    // Iterates through the labels and adds recognised custom variables
    _applyLabels: function(customVars, labels) {
      if (labels) {
        var appName = this._getAppName(labels);
        if (labels[ATILabelKeys.APP_CATEGORY]) {
          customVars = this._setCustomVariable(customVars, ATILabelKeys.APP_NAME, appName);
          delete labels[ATILabelKeys.APP_CATEGORY];
        }

        Object.keys(labels).forEach(function(key) {
          // Check for invalid custom property being set
          // https://jira.dev.bbc.co.uk/browse/MYSTATS-5008
          if (this._isInvalidPropertyKey(key)) {
            delete labels[key];
          }

          if (this._isCustomVariable(key)) {
            if (key === ATILabelKeys.APP_NAME) {
              labels[key] = appName;
            }

            customVars = this._setCustomVariable(customVars, key, labels[key]);

            // Remove the label as it has been added to the custom variables
            delete labels[key];
          }

        }, this);
      }
    },

    // Fetch the app category and app name from the provided labels, app category prepended to app name
    _getAppName: function(labels) {

      var appName = '';
      if (labels[ATILabelKeys.APP_CATEGORY]) {
        appName = labels[ATILabelKeys.APP_CATEGORY] + '-';
      } else if (this.APP_CATEGORY) {
        appName = this.APP_CATEGORY + '-';
      }

      if (labels[ATILabelKeys.APP_NAME]) {
        appName += labels[ATILabelKeys.APP_NAME];
      } else {
        appName += this.APP_NAME;
      }

      return appName;
    },

    // Tests whether the label passed in represents the key of a custom variable
    _isCustomVariable: function(label) {
      return (this[label] !== undefined && this.hasOwnProperty(label) && typeof this[label] !== 'function');
    },

    // Checks whether the custom property passed in is valid
    _isInvalidPropertyKey: function(label) {
      if (InvalidATIPropertyKeys.hasOwnProperty(label)) {
        DEBUG.warn(InvalidATIPropertyKeys[label].message);
        return true;

      }

      return false;

    },

    // Lazily initialies the custom variables object and returns the custom variables object containing the complete
    // set of custom variables added so far
    _setCustomVariable: function(customVars, key, value) {
      if (value) {
        if (!customVars) {
          customVars = this._initialise();
        }

        var nonSensitive = LabelCleanser.removeSensitiveInfo(value);
        var cleansedValue = LabelCleanser.cleanCustomVariable(nonSensitive);

        var siteKey = this[key];
        customVars.site[siteKey] = '[' + encodeURIComponent(cleansedValue) + ']';
      }

      return customVars;
    },

    // Sets the document-based properties on the custom variables object
    _setDocumentCustomVariables: function(customVars, document) {
      if (document) {
        customVars = this._setCustomVariable(customVars, ATILabelKeys.URL, document.URL);
        customVars = this._setCustomVariable(customVars, ATILabelKeys.REFERRER_URL, document.referrer);
        customVars = this._setCustomVariable(customVars, ATILabelKeys.PAGE_TITLE, document.title);
      }

      return customVars;
    },

    // Sets the Orbit-based properties on the custom variables object (if available)
    _setOrbitCustomVariables: function(customVars, orbitVars) {
      if (orbitVars) {
        customVars = this._setCustomVariable(customVars, ATILabelKeys.CONTENT_ID, orbitVars.contentId);
        customVars = this._setCustomVariable(customVars, ATILabelKeys.CONTENT_TYPE, orbitVars.contentType);
        customVars = this._setCustomVariable(customVars, ATILabelKeys.LANGUAGE, orbitVars.language);
        customVars = this._setProductCustomVariables(customVars, orbitVars.additionalProperties);
      }

      return customVars;
    },

    _setProductCustomVariables: function(customVars, productVars) {
      if (productVars) {
        if (productVars.app_category) {
          customVars = this._setCustomVariable(customVars, ATILabelKeys.APP_NAME, this._getAppName(productVars));
        }

        if (productVars.content_language) {
          customVars = this._setCustomVariable(customVars, ATILabelKeys.LANGUAGE, productVars.content_language);
        }

        customVars = this._setCustomVariable(customVars, ATILabelKeys.CUSTOM_VAR_1, productVars.custom_var_1);
        customVars = this._setCustomVariable(customVars, ATILabelKeys.CUSTOM_VAR_2, productVars.custom_var_2);
        customVars = this._setCustomVariable(customVars, ATILabelKeys.CUSTOM_VAR_3, productVars.custom_var_3);
        customVars = this._setCustomVariable(customVars, ATILabelKeys.CUSTOM_VAR_4, productVars.custom_var_4);
        customVars = this._setCustomVariable(customVars, ATILabelKeys.CUSTOM_VAR_5, productVars.custom_var_5);
        customVars = this._setCustomVariable(customVars, ATILabelKeys.CUSTOM_VAR_6, productVars.custom_var_6);
        customVars = this._setCustomVariable(customVars, ATILabelKeys.CUSTOM_VAR_7, productVars.custom_var_7);
        customVars = this._setCustomVariable(customVars, ATILabelKeys.CUSTOM_VAR_8, productVars.custom_var_8);
        customVars = this._setCustomVariable(customVars, ATILabelKeys.CUSTOM_VAR_9, productVars.custom_var_9);
        customVars = this._setCustomVariable(customVars, ATILabelKeys.CUSTOM_VAR_10, productVars.custom_var_10);
      }

      return customVars;
    }
  };

  return customVariables;
});
