define(function(require) {
  'use strict';

  var Keys = require('./label-keys');
  var ConfigKeys = require('../../config/keys');
  var DEBUG = require('../../util/debug');
  var Enums = require('../../enumerations');
  var PageContext = require('./page-context');
  var SHORT_MEDIA_THRESHOLD = 30000;

  //function to use when we are ignoring a function
  function dummy() {
    return -1;
  }

  function SpringDelegate(appName, appType, config, env) { // jshint ignore:line

    // No point in initialising this until we know
    // we are going to play some media
    this._sensors = null;
    this._broker = null;
    this._media = null;
    this._meta = {};
    this._stream = null;
    this._env = env;
    this._siteCode = config[ConfigKeys.BARB_SITE_CODE];
    this._debug = config[ConfigKeys.BARB_DEBUG];
    this._debugEnableShortMedia = config[ConfigKeys.BARB_DEBUG_ENABLE_SHORT_MEDIA];
    this._traceId = null;
    this._isPlaying = false;
    this._isEssEnabled = false;
    this._isEnabled = true;
    this._hasStarted = false;

    if (config[ConfigKeys.ECHO_TRACE]) {
      this.setTraceId(config[ConfigKeys.ECHO_TRACE]);
    }

    if (config[ConfigKeys.USE_ESS]) {
      this._isEssEnabled = true;
    }

    if (config && config[ConfigKeys.ECHO_ENABLED] === false) {
      this._isEnabled = false;
    } else {
      this._isEnabled = true;
    }
  }

  SpringDelegate.prototype.start = function() {
    if (!this._hasStarted) {
      this._hasStarted = true;
    }
  };

  SpringDelegate.prototype.enable = function() {
    if (!this._isEnabled) {
      this._isEnabled = true;
    }
  };

  SpringDelegate.prototype.disable = function() {
    if (this._isEnabled) {
      this._isEnabled = false;
      this.clearMedia();
    }
  };

  SpringDelegate.prototype.setTraceId = function(id) {
    this._traceId = id;
  };

  SpringDelegate.SpringStreams = require('springstreams');

  // Application State Methods
  SpringDelegate.prototype.addLabels = dummy;
  SpringDelegate.prototype.addLabel = dummy;
  SpringDelegate.prototype.addProperties = dummy;
  SpringDelegate.prototype.addCampaignProperties = dummy;
  SpringDelegate.prototype.addProperty = dummy;
  SpringDelegate.prototype.addManagedLabel = dummy;
  SpringDelegate.prototype.removeLabels = dummy;
  SpringDelegate.prototype.removeLabel = dummy;
  SpringDelegate.prototype.removeProperties = dummy;
  SpringDelegate.prototype.removeProperty = dummy;
  SpringDelegate.prototype.setLoggedInToBBCId = dummy;
  SpringDelegate.prototype.setLoggedOutOfBBCId = dummy;
  SpringDelegate.prototype.setAppVersion = dummy;
  SpringDelegate.prototype.setCounterName = dummy;
  SpringDelegate.prototype.setContentLanguage = dummy;
  SpringDelegate.prototype.setCacheMode = dummy;
  SpringDelegate.prototype.clearCache = dummy;
  SpringDelegate.prototype.flushCache = dummy;
  SpringDelegate.prototype.setESSEnabled = dummy;

  // Analytics Methods
  SpringDelegate.prototype.viewEvent = dummy;
  SpringDelegate.prototype.errorEvent = dummy;
  SpringDelegate.prototype.userActionEvent = dummy;

  // Media Player attributes
  SpringDelegate.prototype.setPlayerName = function(name) {
    this._meta[Keys.PLAYER_DESC] = name;
  };

  SpringDelegate.prototype.setPlayerVersion = function(version) {
    this._meta[Keys.PLAYER_VERSION] = version;
  };

  SpringDelegate.prototype.setPlayerIsPopped = dummy;
  SpringDelegate.prototype.setPlayerWindowState = dummy;
  SpringDelegate.prototype.setPlayerVolume = dummy;
  SpringDelegate.prototype.setPlayerIsSubtitled = dummy;

  SpringDelegate.prototype.setDestination = dummy;
  SpringDelegate.prototype.setProducer = dummy;

  /**
   * Sets the broker.
   *
   * This method should only be called by the EchoClient class to
   * ensure the interface is implemented.
   *
   * @param {Object} broker An object conforming to the broker interface.
   *
   * @return {SpringDelegate}
   */
  SpringDelegate.prototype.setBroker = function(broker) {
    this._broker = broker;
    return this;
  };

  // Media Attributes
  SpringDelegate.prototype.setMedia = function(media) {
    if (!this._eventsEnabled()) {
      return;
    }

    this.clearMedia();
    this._media = media.getClone();
  };

  SpringDelegate.prototype.setMediaLength = function(length) {
    this._media.setLength(length);
  };

  /**
   * Handles an updated clip from the LiveBroker
   *
   * @param {Media} newMedia
   */
  SpringDelegate.prototype.liveMediaUpdate = function(newMedia) {
    this._media = newMedia.getClone();

    if (this._isPlaying) {
      this._startTracking();
    }
  };

  SpringDelegate.prototype.liveEnrichmentFailed = function() {

    if (!this._media.getServiceId()) {
      this._media.setServiceId('no-service-id-found');
    }

    if (this._isPlaying) {
      this._startTracking();
    }
  };

  // Media Events
  SpringDelegate.prototype.avPlayEvent = function(position, eventLabels) { // jshint ignore:line
    // MYSTATS-5057 don't record short, on demand media
    if (!this._eventsEnabled() || (!this._debugEnableShortMedia && this._isShortMedia() && this._media.isOnDemand())) {
      return;
    }

    if (this.isValidMedia() && this._broker) {

      this._initSensors();

      this._isPlaying = true;

      if (this._media.isLive() && !this._media.getServiceId()) {
        if (!this._isEssEnabled || (!this._media.getVersionId() && !this._media.getVpId())) {
          this._media.setServiceId('invalid-data');
          this._startTracking();
        }
      } else {
        this._startTracking();
      }
    }
  };

  // PRIVATE
  SpringDelegate.prototype._initSensors = function() {
    if (this._sensors === null) {
      this._sensors = new SpringDelegate.SpringStreams(this._siteCode);

      if (this._env.getSetLocalStorageItem() !== null &&
        this._env.getGetLocalStorageItem() !== null &&
        this._env.getHttpGet() !== null) {
        DEBUG.info('Using custom PageContext for BARB');
        var pageContext = new PageContext('com.kma.springstreams', this._env);
        this._sensors.setPageContext(pageContext);
      }

      if (this._debug) {
        this._sensors.debug = function(x) {
          DEBUG.info('Spring DEBUG: ' + x);
        };
      }
    }
  };

  SpringDelegate.prototype._startTracking = function() {
    if (this._stream === null) {
      var attr = this._getAttributes();
      var id = attr[Keys.PID] || ' ';

      // Start to track the media
      this._stream = this._sensors.track(id, attr, this._getAdaptor());
    }

  };

  SpringDelegate.prototype._getMostPrecedentIdObject = function() {
    var versionId = this._media.getVersionIdObject();
    var episodeId = this._media.getEpisodeIdObject();
    var clipId    = this._media.getClipIdObject();
    var vpId      = this._media.getVpIdObject();
    var serviceId = this._media.getServiceIdObject();

    if (versionId.isValueValid()) {
      return versionId;
    } else if (episodeId.isValueValid()) {
      return episodeId;
    } else if (clipId.isValueValid()) {
      return clipId;
    } else if (vpId.isValueValid()) {
      return vpId;
    } else if (serviceId.isValueValid()) {
      return serviceId;
    }

    return null;
  };

  SpringDelegate.prototype._getAttributes = function() {
    var media = this._media;

    // Create desc object
    var desc = {};

    if (this._traceId) {
      desc[ConfigKeys.ECHO_TRACE] = this._traceId;
    }

    if (!media.isLive()) {
      var precedentId = this._getMostPrecedentIdObject();

      if (precedentId !== null) {
        desc[Keys.PID] = precedentId.getValue();
      }
    }

    if (media.isOnDemand()) {
      desc[Keys.STREAM] = 'od';
    } else if (media.isDownload()) {
      desc[Keys.STREAM] = 'dwn';
    } else if (media.isLive()) {
      if (media.getServiceIdObject().isValueValid()) {
        desc[Keys.STREAM] = 'live/' + media.getServiceId();
      }
    }

    return desc;
  };

  SpringDelegate.prototype._getAdaptor = function() {
    // Create adaptor
    var self = this;
    var positionGetter;

    if (this._media.isLive()) {
      positionGetter = function() {
        return self._broker.getTimestamp();
      };
    } else {
      positionGetter = function() {
        return self._broker.getPosition();
      };
    }

    return {
      getMeta: function(id) { // jshint ignore:line
        return self._meta;
      },

      getDuration: function(id) { // jshint ignore:line
        var secs = 0;

        if (!self._media.isLive()) {
          secs = Math.floor(self._media.getLength() / 1000);
        }

        return secs;
      },

      getPosition: function(id) { // jshint ignore:line
        return Math.floor(positionGetter() / 1000);
      }
    };
  };

  SpringDelegate.prototype._eventsEnabled = function() {
    return this._isEnabled && this._hasStarted;
  };

  SpringDelegate.prototype.avPauseEvent = function() {
  };

  SpringDelegate.prototype.avBufferEvent = function() {
  };

  SpringDelegate.prototype.avEndEvent = function() {
    // MYSTATS-5057 don't record short, on demand media
    if (!this._eventsEnabled() || (!this._debugEnableShortMedia && this._isShortMedia() && this._media.isOnDemand())) {
      return;
    }

    this._isPlaying = false;

    if (this.isValidMedia()) {
      this.clearMedia();
    }
  };

  SpringDelegate.prototype.avRewindEvent = function() {
  };

  SpringDelegate.prototype.avFastForwardEvent = function() {
  };

  SpringDelegate.prototype.avSeekEvent = function() {
  };

  SpringDelegate.prototype.avUserActionEvent  = dummy;

  SpringDelegate.prototype.clearMedia = function() {
    if (this.isValidMedia()) {
      if (this._stream) {
        this._stream.stop();
      }

      this._stream = null;
      this._media = null;
    }
  };

  SpringDelegate.prototype.isValidMedia = function() {
    return this._media !== null && this._media.avType === Enums.AvType.VIDEO;
  };

  SpringDelegate.prototype.isValidLiveMedia = function() {
    return this.isValidMedia() && this._media.isLive();
  };

  SpringDelegate.prototype.setDeviceId = dummy;

  SpringDelegate.prototype.requiresLabelCleansing = function() {
    return true;
  };

  SpringDelegate.prototype._isShortMedia = function() {
    return this._media !== null && this._media.getLength() < SHORT_MEDIA_THRESHOLD;
  };

  return SpringDelegate;
});
