import { Bar } from "@ant-design/plots";
import { useQuery } from "@apollo/client";
import { Card, Col, Divider, Radio, Row, Skeleton, Space, Statistic, Typography } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import "./Home.css";
import CustomHelmet from "../../components/CustomHelmet";
import { GetBasicStats } from "../../graphql/__generated__/GetBasicStats";
import { GetConsistencies } from "../../graphql/__generated__/GetConsistencies";
import { GetHeadlineTotals, GetHeadlineTotals_headlineTotals_category } from "../../graphql/__generated__/GetHeadlineTotals";
import { GetOverviews, GetOverviews_overviews_category } from "../../graphql/__generated__/GetOverviews";
import { GET_BASIC_STATS } from "../../graphql/__queries__/GetBasicStats.gql";
import { GET_CONSISTENCIES } from "../../graphql/__queries__/GetConsistencies";
import { GET_HEADLINE_TOTALS } from "../../graphql/__queries__/GetHeadlineTotals.gql";
import { GET_OVERVIEWS } from "../../graphql/__queries__/GetOverviews";
import { sortDiversityCategories } from "../../utils/sortDiversityCategories";

const { Title } = Typography;


const gradientStyle = (inTargetColor: string, outTargetColor: string) => ({
    backgroundImage: `linear-gradient(to right, ${inTargetColor},${outTargetColor})`,
    backgroundSize: "100%",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    MozBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    MozTextFillColor: "transparent",
    width: "fit-content",
    fontSize: "5rem",
    textAlign: "center" as const,
    fontWeight: 600,
    margin: "auto",
    lineHeight: 1
});

export const cardStyle = ({ border: "3px solid #f0f0f0", borderRadius: "5px", background: "#fafafa" });


export const Home = () => {
    const { t } = useTranslation();

    const [selectedOverviewCategory, setSelectedOverviewCategory] = useState<string>("");

    const { data: basicStats, loading: loadingBasicStats } = useQuery<GetBasicStats>(GET_BASIC_STATS);
    const { data: headlineTotals, loading: loadingHeadlineTotals } = useQuery<GetHeadlineTotals>(GET_HEADLINE_TOTALS);
    const { data: consistencies, loading: loadingConsistencies } = useQuery<GetConsistencies>(GET_CONSISTENCIES);
    const { data: overviews, loading: loadingOverviews } = useQuery<GetOverviews>(GET_OVERVIEWS);

    const sortedHeadlineTotals = useMemo(() => {
        return Array.from(headlineTotals?.headlineTotals || [])
            .sort((a, b) => sortDiversityCategories(a.category.priority, b.category.priority));
    }, [headlineTotals]);

    const overviewCategories = useMemo(() => {
        const seenCategories = new Set<string>();
        const overviewCategories: GetOverviews_overviews_category[] = [];
        overviews?.overviews.forEach(({ category }) => {
            if (!seenCategories.has(category.name)) {
                overviewCategories.push(category);
                seenCategories.add(category.name);
            }
        });

        const sortedOverviewCategories = overviewCategories.sort((a, b) => sortDiversityCategories(a.priority, b.priority));
        
        if (sortedOverviewCategories.length > 0) {
            setSelectedOverviewCategory(sortedOverviewCategories[0].name);
        }

        return sortedOverviewCategories;
    }, [overviews]);

    const overviewFilters = useMemo(() => {
        return Array.from(new Set(overviews?.overviews.map(x => x.filter)));
    }, [overviews]);

    const getHeadlineTotal = (
        { displayName, inTargetColor, outTargetColor }: GetHeadlineTotals_headlineTotals_category,
        totals: { percent: number, noOfDatasets: number }
    ) => (
        <Space direction="vertical">
            <div style={gradientStyle(inTargetColor, outTargetColor)}>
                <div>{Math.round(totals.percent)}%</div>
            </div>
            <div style={{ color: inTargetColor, fontSize: "0.7rem" }}>
                {`${totals.noOfDatasets} ${displayName} ${t("datasets")}`}
            </div>
        </Space>
    );

    return (
        <Row gutter={[32, 32]}>
            <CustomHelmet title="Home" />
            <Col span={24} style={{ marginTop: "12px", textAlign: "center" }}>
                <Skeleton
                    loading={loadingHeadlineTotals}
                    paragraph={{ rows: 1 }}
                >
                    <Space direction="vertical">
                        <Space
                            direction="horizontal"
                            size={50}
                        >
                            {
                                sortedHeadlineTotals.length &&
                                sortedHeadlineTotals.map(({ category, percent, noOfDatasets }) => (
                                    getHeadlineTotal(category, { percent, noOfDatasets })
                                ))
                            }
                        </Space>
                        {
                            sortedHeadlineTotals.length 
                                ? "Figures shown are percentages for all the records published last month"
                                : "No records published in the last month"
                        }
                    </Space>
                </Skeleton>
            </Col>
            <Col span={12}>
                <Card style={cardStyle}>
                    <Statistic
                        loading={loadingBasicStats}
                        title={t("teams")}
                        value={basicStats?.basicStats.teams}
                    />
                </Card>
            </Col>
            <Col span={12}>
                <Card style={cardStyle}>
                    <Statistic
                        loading={loadingBasicStats}
                        title={t("datasets")}
                        value={basicStats?.basicStats.datasets}
                    />
                </Card>
            </Col>
            <Col span={12}>
                <Divider orientation="left"><Title level={3}>{t("consistencyChallenge")}</Title></Divider>
            </Col>
            <Col span={12}>
                <Divider orientation="left"><Title level={3}>{t("progress")}</Title></Divider>
            </Col>
            <Col span={12}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Typography>
                            Datasets that meet the Gender target for at least three months and do not drop below 45% in any other month.
                        </Typography>
                    </Col>
                    <Col span={24}>
                        <Skeleton loading={loadingConsistencies} paragraph={{ rows: 1 }}>
                            {
                                consistencies && 
                                consistencies.consistencies.length &&
                                <Bar
                                    data={
                                        [...consistencies.consistencies].sort((a, b) => a.year - b.year)
                                    }
                                    xField="value"
                                    yField="year"
                                    seriesField="consistencyState"
                                    isPercent
                                    isStack
                                    autoFit={false}
                                    height={250}
                                    barWidthRatio={1 / 2}
                                    color={[ "#CA1619", "rgba(0,0,0,0)" ]}
                                    label={{
                                        formatter: (v) => (
                                            Number(v.value) > 0 && v.consistencyState === "consistent" 
                                                ? `${Math.round(Number(v.value) * 100)}%` : ""
                                        )
                                    }}
                                    xAxis={false}
                                    legend={false}
                                    tooltip={{ showContent: false }}
                                    animation={false}
                                />
                            }
                        </Skeleton>
                    </Col>
                </Row>
            </Col>
            <Col span={12}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Typography>
                            Compares how datasets performed
                            in relation to hitting their targets.
                            The charts compare their performance last month,
                            with that in the first month that they published data
                        </Typography>
                    </Col>
                    <Col span={24} style={{ textAlign: "center" }}>
                        <Radio.Group
                            value={selectedOverviewCategory}
                            onChange={(e) => setSelectedOverviewCategory(e.target.value)}
                        >
                            {
                                overviewCategories.map(category => (
                                    <Radio key={category.name} value={category.name}>{category.displayName}</Radio>
                                ))
                            }
                        </Radio.Group>
                    </Col>
                    <Col span={24} >
                        <Skeleton loading={loadingOverviews} paragraph={{ rows: 1 }}>
                            {
                                overviews && 
                                overviews.overviews.length &&
                                overviewFilters.map(filter => (
                                    <Card
                                        key={`${selectedOverviewCategory + filter}`}
                                        title={filter}
                                        size="small"
                                    >
                                        <Bar
                                            data={
                                                overviews.overviews
                                                    .filter(x => x.filter === filter)
                                                    .filter(x => x.category.name === selectedOverviewCategory)
                                                    .sort((a, b) => b.date.localeCompare(a.date))
                                            }
                                            xField="value"
                                            yField="date"
                                            seriesField="targetState"
                                            isPercent
                                            isStack
                                            height={150}
                                            width={300}
                                            barWidthRatio={1 / 3}
                                            label={{
                                                formatter: (v) => (
                                                    Number(v.value) > 0 ? `${Math.round(Number(v.value) * 100)}%` : ""
                                                )
                                            }}
                                            xAxis={false}
                                            yAxis={{
                                                label: {
                                                    formatter: (v) => {
                                                        switch (v) {
                                                        case "min":
                                                            return "First Entry";
                                                        case "max":
                                                            return "Last Month";
                                                        default:
                                                            return v;
                                                        }
                                                    }
                                                }
                                            }}
                                            legend={{
                                                position: "top-right",
                                                itemName: {
                                                    formatter: (v) => {
                                                        switch (v) {
                                                        case "exceeds":
                                                            return "Exceeded";
                                                        case "lt5":
                                                            return "Within 5%";
                                                        case "lt10":
                                                            return "Within 10%";
                                                        case "gt10":
                                                            return "More than 10%";
                                                        default:
                                                            return v;
                                                        }
                                                    }
                                                }
                                            }}
                                            tooltip={{ showContent: false }}
                                            animation={false}
                                            color={
                                                ({ targetState }) => {
                                                    switch (targetState) {
                                                    case "exceeds":
                                                        return "#477302";
                                                    case "lt5":
                                                        return "#e9edbb";
                                                    case "lt10":
                                                        return "#ead1d1";
                                                    case "gt10":
                                                        return "#850002";
                                                    default:
                                                        return "white";
                                                    }
                                                }}
                                        />
                                    </Card>
                                ))
                            }
                        </Skeleton>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};