import { gql } from "@apollo/client";

export const GET_HEADLINE_TOTALS = gql`
  query GetHeadlineTotals {
    headlineTotals {
      category {
        name
        displayName
        inTargetColor
        outTargetColor
        priority
      }
      percent
      noOfDatasets
    }
  }
`;
