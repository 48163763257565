import { gql } from "@apollo/client";

export const ADMIN_CREATE_DIVERSITY_CATEGORY = gql`
  mutation AdminCreateDiversityCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      id
      name
      deleted
      description
      displayName
      inTargetColor
      outTargetColor
      priority
    }
  }
`;
