import { gql } from "@apollo/client";

export const ADMIN_GET_CONTENT_TYPE = gql`
  query AdminGetContentType($id: ID!) {
    contentType(id: $id) {
      id
      name
      programs {
        id
        name
        datasets {
          id
          name
        }
      }
    }
  }
`;
