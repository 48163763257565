import { gql } from "@apollo/client";

export const ADMIN_CREATE_CONTENT_TYPE = gql`
  mutation AdminCreateContentType($name: String!) {
    createContentType(name: $name) {
      id
      name
    }
  }
`;
