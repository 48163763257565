import { Helmet } from "react-helmet";


interface CustomHelmetProps {
    title?: string;
    subTitle?: string;
}


const CustomHelmet = ({ title, subTitle }: CustomHelmetProps) => {
    const _title = title ? ` - ${title}` : "";
    const _subTitle = subTitle ? ` - ${subTitle}` : "";

    return (
        <Helmet>
            <title>{"50:50 Tracker" + `${_title}` + `${_subTitle}`}</title>
        </Helmet>
    );
};

export default CustomHelmet;