define(function() {
  'use strict';

  var Producers = require('./producers');

  var destinations = {
    // Permitted values for setting Destination site ids
    DEFAULT: {
      id: '596068', defaultProducer: Producers.BBC, isTest: true
    },
    ACCOUNT: {
      id: '598250', defaultProducer: Producers.ACCOUNT, isTest: false
    },
    ACCOUNT_TEST: {
      id: '598252', defaultProducer: Producers.ACCOUNT, isTest: true
    },
    AUDIENCE_PORTAL: {
      id: '602136', defaultProducer: Producers.AUDIENCE_SERVICES, isTest: false
    },
    AUDIENCE_PORTAL_TEST: {
      id: '602137', defaultProducer: Producers.AUDIENCE_SERVICES, isTest: true
    },
    AUDIENCE_SERVICES_PS: {
      id: '602167', defaultProducer: Producers.AUDIENCE_SERVICES, isTest: false
    },
    AUDIENCE_SERVICES_PS_TEST: {
      id: '602168', defaultProducer: Producers.AUDIENCE_SERVICES, isTest: true
    },
    BBC_ARCHIVE_PS: {
      id: '605565', defaultProducer: Producers.BBC_ARCHIVE, isTest: false
    },
    BBC_ARCHIVE_PS_TEST: {
      id: '605566', defaultProducer: Producers.BBC_ARCHIVE, isTest: true
    },
    BBC_CORPORATE_PS: {
      id: '603550', defaultProducer: Producers.BBC, isTest: false
    },
    BBC_CORPORATE_PS_TEST: {
      id: '603551', defaultProducer: Producers.BBC, isTest: true
    },
    BBC_SYNDICATION: {
      id: '601718', defaultProducer: Producers.BBC, isTest: false
    },
    BBC_SYNDICATION_TEST: {
      id: '601719', defaultProducer: Producers.BBC, isTest: true
    },
    BBC_STUDIOS_PLAYER: {
      id: '606296', defaultProducer: Producers.BBC_STUDIOS, isTest: false
    },
    BBC_STUDIOS_PLAYER_TEST: {
      id: '606293', defaultProducer: Producers.BBC_STUDIOS, isTest: true
    },
    BBC_THREE: {
      id: '598255', defaultProducer: Producers.BBC_THREE, isTest: false
    },
    BBC_THREE_TEST: {
      id: '598256', defaultProducer: Producers.BBC_THREE, isTest: true
    },
    BITESIZE: {
      id: '598257', defaultProducer: Producers.BITESIZE, isTest: false
    },
    BITESIZE_TEST: {
      id: '598258', defaultProducer: Producers.BITESIZE, isTest: true
    },
    BLOGS_PS: {
      id: '603546', defaultProducer: Producers.BBC, isTest: false
    },
    BLOGS_PS_TEST: {
      id: '603547', defaultProducer: Producers.BBC, isTest: true
    },
    BRITBOX: {
      id: '598259', defaultProducer: Producers.BRITBOX, isTest: false
    },
    BRITBOX_TEST: {
      id: '598260', defaultProducer: Producers.BRITBOX, isTest: true
    },
    CBBC: {
      id: '598261', defaultProducer: Producers.CBBC, isTest: false
    },
    CBBC_TEST: {
      id: '598262', defaultProducer: Producers.CBBC, isTest: true
    },
    CBEEBIES: {
      id: '598263', defaultProducer: Producers.CBEEBIES, isTest: false
    },
    CBEEBIES_TEST: {
      id: '598264', defaultProducer: Producers.CBEEBIES, isTest: true
    },
    FEATURE_SITES_GNL: {
      id: '598265', defaultProducer: Producers.GNL_HOMEPAGE, isTest: false
    },
    FEATURE_SITES_GNL_TEST: {
      id: '598266', defaultProducer: Producers.GNL_HOMEPAGE, isTest: true
    },
    FOOD: {
      id: '598267', defaultProducer: Producers.BBC_FOOD, isTest: false
    },
    FOOD_TEST: {
      id: '598268', defaultProducer: Producers.BBC_FOOD, isTest: true
    },
    GAMES_PS: {
      id: '599452', defaultProducer: Producers.BBC, isTest: false
    },
    GAMES_PS_TEST: {
      id: '599454', defaultProducer: Producers.BBC, isTest: true
    },
    GATEWAY: {
      id: '598269', defaultProducer: Producers.GATEWAY, isTest: false
    },
    GATEWAY_TEST: {
      id: '598270', defaultProducer: Producers.GATEWAY, isTest: true
    },
    HOMEPAGE_GNL: {
      id: '598271', defaultProducer: Producers.GNL_HOMEPAGE, isTest: false
    },
    HOMEPAGE_GNL_TEST: {
      id: '598272', defaultProducer: Producers.GNL_HOMEPAGE, isTest: true
    },
    HOMEPAGE_PS: {
      id: '598273', defaultProducer: Producers.PS_HOMEPAGE, isTest: false
    },
    HOMEPAGE_PS_TEST: {
      id: '598274', defaultProducer: Producers.PS_HOMEPAGE, isTest: true
    },
    IDEAS: {
      id: '598275', defaultProducer: Producers.BBC, isTest: false
    },
    IDEAS_TEST: {
      id: '598276', defaultProducer: Producers.BBC, isTest: true
    },
    IPLAYER: {
      id: '598277', defaultProducer: Producers.IPLAYER, isTest: false
    },
    IPLAYER_TEST: {
      id: '598278', defaultProducer: Producers.IPLAYER, isTest: true
    },
    MEDIA_ACTION: {
      id: '598279', defaultProducer: Producers.MEDIA_ACTION, isTest: false
    },
    MEDIA_ACTION_TEST: {
      id: '598280', defaultProducer: Producers.MEDIA_ACTION, isTest: true
    },
    MONITORING: {
      id: '598281', defaultProducer: Producers.MONITORING, isTest: false
    },
    MONITORING_TEST: {
      id: '598282', defaultProducer: Producers.MONITORING, isTest: true
    },
    MUSIC: {
      id: '598283', defaultProducer: Producers.BBC_MUSIC, isTest: false
    },
    MUSIC_TEST: {
      id: '598284', defaultProducer: Producers.BBC_MUSIC, isTest: true
    },
    NATIONAL_MOMENTS_PS: {
      id: '605567', defaultProducer: Producers.BBC, isTest: false
    },
    NATIONAL_MOMENTS_PS_TEST: {
      id: '605568', defaultProducer: Producers.BBC, isTest: true
    },
    NEWS_PS: {
      id: '598285', defaultProducer: Producers.NEWS, isTest: false
    },
    NEWS_PS_TEST: {
      id: '598286', defaultProducer: Producers.NEWS, isTest: true
    },
    NEWS_GNL: {
      id: '598287', defaultProducer: Producers.NEWS, isTest: false
    },
    NEWS_GNL_TEST: {
      id: '598288', defaultProducer: Producers.NEWS, isTest: true
    },
    NEWS_LANGUAGES_GNL: {
      id: '598289', defaultProducer: Producers.NEWS, isTest: false
    },
    NEWS_LANGUAGES_GNL_TEST: {
      id: '598290', defaultProducer: Producers.NEWS, isTest: true
    },
    NEWS_LANGUAGES_PS: {
      id: '598291', defaultProducer: Producers.NEWS, isTest: false
    },
    NEWS_LANGUAGES_PS_TEST: {
      id: '598292', defaultProducer: Producers.NEWS, isTest: true
    },
    NEWSROUND: {
      id: '598293', defaultProducer: Producers.NEWSROUND, isTest: false
    },
    NEWSROUND_TEST: {
      id: '598294', defaultProducer: Producers.NEWSROUND, isTest: true
    },
    OTHER: {
      id: '598295', defaultProducer: Producers.OTHER, isTest: false
    },
    OTHER_TEST: {
      id: '598297', defaultProducer: Producers.OTHER, isTest: true
    },
    OWN_IT: {
      id: '598298', defaultProducer: Producers.CBBC, isTest: false
    },
    OWN_IT_TEST: {
      id: '598299', defaultProducer: Producers.CBBC, isTest: true
    },
    PROGRAMMES_PS: {
      id: '598300', defaultProducer: Producers.PROGRAMMES, isTest: false
    },
    PROGRAMMES_PS_TEST: {
      id: '598301', defaultProducer: Producers.PROGRAMMES, isTest: true
    },
    PS_ARCHIVE_SEARCH: {
      id: '632030', defaultProducer: Producers.BBC, isTest: false
    },
    PS_AUDIENCE_ENGAGEMENT: {
      id: '609581', defaultProducer: Producers.PARTICIPATION, isTest: false
    },
    PS_AUDIENCE_ENGAGEMENT_TEST: {
      id: '609582', defaultProducer: Producers.PARTICIPATION, isTest: true
    },
    RED_BUTTON_PS: {
      id: '601192', defaultProducer: Producers.BBC, isTest: false
    },
    RED_BUTTON_PS_TEST: {
      id: '601193', defaultProducer: Producers.BBC, isTest: true
    },
    REWIND_PS: {
      id: '603552', defaultProducer: Producers.BBC, isTest: false
    },
    REWIND_PS_TEST: {
      id: '603553', defaultProducer: Producers.BBC, isTest: true
    },
    R_AND_D_PS: {
      id: '607103', defaultProducer: Producers.RD, isTest: false
    },
    R_AND_D_PS_TEST: {
      id: '607104', defaultProducer: Producers.RD, isTest: true
    },
    SEARCH_GNL: {
      id: '598302', defaultProducer: Producers.SEARCH, isTest: false
    },
    SEARCH_GNL_TEST: {
      id: '598303', defaultProducer: Producers.SEARCH, isTest: true
    },
    SEARCH_PS: {
      id: '598304', defaultProducer: Producers.SEARCH, isTest: false
    },
    SEARCH_PS_TEST: {
      id: '598305', defaultProducer: Producers.SEARCH, isTest: true
    },
    SILVER_PS: {
      id: '607105', defaultProducer: Producers.BBC, isTest: false
    },
    SILVER_PS_TEST: {
      id: '607106', defaultProducer: Producers.BBC, isTest: true
    },
    SOUNDS: {
      id: '598306', defaultProducer: Producers.SOUNDS, isTest: false
    },
    SOUNDS_TEST: {
      id: '598307', defaultProducer: Producers.SOUNDS, isTest: true
    },
    SPORT_GNL: {
      id: '598308', defaultProducer: Producers.SPORT, isTest: false
    },
    SPORT_GNL_TEST: {
      id: '598309', defaultProducer: Producers.SPORT, isTest: true
    },
    SPORT_PS: {
      id: '598310', defaultProducer: Producers.SPORT, isTest: false
    },
    SPORT_PS_TEST: {
      id: '598311', defaultProducer: Producers.SPORT, isTest: true
    },
    STORYWORKS_GNL: {
      id: '598312', defaultProducer: Producers.GNL_STORYWORKS, isTest: false
    },
    STORYWORKS_GNL_TEST: {
      id: '598313', defaultProducer: Producers.GNL_STORYWORKS, isTest: true
    },
    SUBTITLES_PS: {
      id: '603548', defaultProducer: Producers.PROGRAMMES, isTest: false
    },
    SUBTITLES_PS_TEST: {
      id: '603549', defaultProducer: Producers.PROGRAMMES, isTest: true
    },
    SYNDICATION_PARTNERS_GNL: {
      id: '598314', defaultProducer: Producers.WS_PARTNERS_B2B, isTest: false
    },
    SYNDICATION_PARTNERS_GNL_TEST: {
      id: '598315', defaultProducer: Producers.WS_PARTNERS_B2B, isTest: true
    },
    TASTER: {
      id: '598316', defaultProducer: Producers.TASTER, isTest: false
    },
    TASTER_TEST: {
      id: '598317', defaultProducer: Producers.TASTER, isTest: true
    },
    TEACH: {
      id: '598318', defaultProducer: Producers.BBC, isTest: false
    },
    TEACH_TEST: {
      id: '598320', defaultProducer: Producers.BBC, isTest: true
    },
    TOPICS_GNL: {
      id: '612991', defaultProducer: Producers.BBC, isTest: false
    },
    TOPICS_GNL_TEST: {
      id: '614559', defaultProducer: Producers.BBC, isTest: true
    },
    TOPICS_PS: {
      id: '612261', defaultProducer: Producers.BBC, isTest: false
    },
    TOPICS_PS_TEST: {
      id: '612262', defaultProducer: Producers.BBC, isTest: true
    },
    VOICE: {
      id: '598326', defaultProducer: Producers.VOICE, isTest: false
    },
    VOICE_TEST: {
      id: '598328', defaultProducer: Producers.VOICE, isTest: true
    },
    WEATHER_GNL: {
      id: '598330', defaultProducer: Producers.WEATHER, isTest: false
    },
    WEATHER_GNL_TEST: {
      id: '598332', defaultProducer: Producers.WEATHER, isTest: true
    },
    WEATHER_PS: {
      id: '598338', defaultProducer: Producers.WEATHER, isTest: false
    },
    WEATHER_PS_TEST: {
      id: '598339', defaultProducer: Producers.WEATHER, isTest: true
    },
    WS_LEARNING_ENGLISH: {
      id: '598340', defaultProducer: Producers.WS_LEARNING_ENGLISH, isTest: false
    },
    WS_LEARNING_ENGLISH_TEST: {
      id: '598341', defaultProducer: Producers.WS_LEARNING_ENGLISH, isTest: true
    },
    WS_NEWS_LANGUAGES: {
      id: '598342', defaultProducer: Producers.BBC_WORLD_NEWS, isTest: false
    },
    WS_NEWS_LANGUAGES_TEST: {
      id: '598343', defaultProducer: Producers.BBC_WORLD_NEWS, isTest: true
    },
    WS_PARTNERS_B2B: {
      id: '598820', defaultProducer: Producers.WS_PARTNERS_B2B, isTest: false
    },
    WS_PARTNERS_B2B_TEST: {
      id: '598823', defaultProducer: Producers.WS_PARTNERS_B2B, isTest: true
    },
    WS_PROGRAMMES: {
      id: '598344', defaultProducer: Producers.PROGRAMMES, isTest: false
    },
    WS_PROGRAMMES_TEST: {
      id: '598345', defaultProducer: Producers.PROGRAMMES, isTest: true
    },
    WS_SYNDICATION_PARTNERS: {
      id: '598346', defaultProducer: Producers.WS_PARTNERS_B2B, isTest: false
    },
    WS_SYNDICATION_PARTNERS_TEST: {
      id: '598347', defaultProducer: Producers.WS_PARTNERS_B2B, isTest: true
    },

    get: function(destinationCode) {
      var destination;
      if (destinationCode) {
        destination = this[destinationCode.toUpperCase()];
      }

      return destination;
    }
  };

  return destinations;
});
