import { gql } from "@apollo/client";

export const ADMIN_GET_PLATFORMS = gql`
  query AdminGetPlatforms {
    platforms {
      id
      name
      programs {
        id
        name
        datasets {
          id
          name
        }
      }
    }
  }
`;
