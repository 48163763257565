import { PictureOutlined } from "@ant-design/icons";
import { BarCustomLayerProps, BarTooltipProps, ResponsiveBar } from "@nivo/bar";
import { BasicTooltip } from "@nivo/tooltip";
import { Button, Col, Divider, Row, Skeleton } from "antd";
import { Dayjs } from "dayjs";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { getBarChartTheme } from "./styling";
import { FilterState } from "../Reports";
import { GetAllCategories_categories } from "../../../graphql/__generated__/GetAllCategories";
import { IChartData } from "../../../selectors/ChartData";
import { joinArrayStringItems } from "../../../utils/array";
import { createExportFilename, downloadSvgAsImage, ImageFormat } from "../../../utils/imageExport";
import { sortDiversityCategories } from "../../../utils/sortDiversityCategories";


type BarChartDatum = BarChartDatumPartial & {
    date: string;
    containerId: string;
    mainDescription: string;
    filterDescription: string;
}

type BarChartDatumPartial = {
    [key: string]: number | string;
}

type BarChartProps = {
    barChartData: BarChartDatum[];
    barChartKeys: string[];
}

type DatasetsOnTargetBarChartProps = {
    categories: GetAllCategories_categories[];
    data: Record<string, Record<string, IChartData>>;
    filterState: FilterState;
    loading: boolean;
}

type DateDescription = {
    dateDescription: string;
    isSingleMonth: boolean;
}

type ImageDescriptionProps = BarChartProps & {
    baseDescription: string;
    dateDescription: string;
    filterState: FilterState;
}

type SectionDescriptionParts = {
    baseDescription: string;
    dateDescription: string;
    filterDescription: string;
}


const HEIGHT_PER_TEXT_LINE = 20;
const IMAGE_FORMATS = [ImageFormat.PNG, ImageFormat.JPEG];
const WIDTH_PER_CHAR = 9;


const isDateSelectionSingleMonth = (begin: Dayjs, end: Dayjs): boolean => (
    begin.month() === end.month() && begin.year() === end.year()
);

const getDateDescription = (begin: Dayjs, end: Dayjs): DateDescription => {
    const format = "MMMM YYYY";
    const isSingleMonth = isDateSelectionSingleMonth(begin, end);
    const dateDescription = isSingleMonth ? begin.format(format) : `${begin.format(format)} and ${end.format(format)}`;

    return { isSingleMonth, dateDescription };
};

const getShortFilterDescription = (filterState: FilterState): string => {
    const { divisions, departments, miscTags, programs } = filterState;

    const filters = [
        ...divisions.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...departments.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...miscTags.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...programs.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
    ].filter(name => !!name);

    const filterStringBase = filters.length > 0 ? filters[0] : "the whole BBC";
    const filterString = filters.length > 1 ? `${filterStringBase} and ${filters.length - 1} other(s)` : filterStringBase;

    return filterString;
};

const getFilterDescription = (filterState: FilterState): string => {
    const { categories, contentTypes, departments, divisions, miscTags, platforms, programs, teams } = filterState;

    const filters = [
        ...divisions.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...departments.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...miscTags.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...programs.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...categories.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...contentTypes.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...platforms.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...teams.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
    ].filter(name => !!name);

    return filters.length === 0 ? "BBC Overall" : `${joinArrayStringItems(filters)}.`;
};

const getSectionDescriptionParts = (filterState: FilterState): SectionDescriptionParts => {
    const { dateDescription, isSingleMonth } = getDateDescription(filterState.range.begin, filterState.range.end);

    return {
        baseDescription: `Proportion of datasets on target ${isSingleMonth ? "in" : "between"}`,
        dateDescription,
        filterDescription: getFilterDescription(filterState),
    };
};

const getCategoryDisplayName = (categoryName: string, categories: GetAllCategories_categories[]): string => {
    const category = categories.find(cat => cat.name === categoryName);
    return category ? category.displayName : categoryName;
};

const getCategoryAttributes = (
    categoryName: string,
    categories: GetAllCategories_categories[],
): GetAllCategories_categories | undefined => categories.find(category => category.displayName ===  categoryName);

const getBarChartProps = (
    categories: GetAllCategories_categories[],
    containerId: string,
    data: Record<string, Record<string, IChartData>>,
    sectionDescriptionParts: SectionDescriptionParts
): BarChartProps => {
    const { baseDescription, dateDescription, filterDescription } = sectionDescriptionParts;
    const mainDescription = `${baseDescription} ${dateDescription}.`;

    const barChartKeys = new Set<string>();
    const barChartData = Object.entries(data).map(([date, groupedByCategory]) => {
        const categoryValues = Object.fromEntries(
            Object.entries(groupedByCategory).map(([category, data]) => {
                const categoryName = getCategoryDisplayName(category, categories);
                barChartKeys.add(categoryName);
                return [categoryName, Math.round((data.exceeds / data.count) * 100)];
            })
        );

        return { date, ...categoryValues, containerId, mainDescription, filterDescription };
    });

    const sortedBarChartKeys = Array.from(barChartKeys).sort((a, b) => {
        const priorityA = getCategoryAttributes(a, categories)?.priority || null;
        const priorityB = getCategoryAttributes(b, categories)?.priority || null;
        return sortDiversityCategories(priorityA, priorityB);
    });

    return { barChartData, barChartKeys: sortedBarChartKeys };
};

const calculateFontSizeModifier = (numberOfBars: number): number => {
    const numberOfBarsLowerLimit = 24;
    
    const fontSizeAdjustment = 2;
    const numberOfBarsPerFontSizeAdjustment = 6;

    let modifier = 0;
    let numberOfBarsInExcess = numberOfBars < numberOfBarsLowerLimit ? -1 : numberOfBars - numberOfBarsLowerLimit;
    while (numberOfBarsInExcess >= 0) {
        modifier = modifier - fontSizeAdjustment;
        numberOfBarsInExcess = numberOfBarsInExcess - numberOfBarsPerFontSizeAdjustment;
    }

    return modifier;
};

const calculateBarChartWidth = (numberOfBars: number): number => {
    const maxContainerWidthPercent = 100;

    let containerWidth = 10;
    for (let i = 1; i < numberOfBars; i++) {
        if (containerWidth === maxContainerWidthPercent) {
            break;
        } else {
            containerWidth += 5;
        }
    }

    return containerWidth;
};

const splitTextByMaxLength = (text: string, maxLength: number): string[] => {
    const words = text.split(" ");

    const lines: string[] = [];
    let currentString = "";
    for (const word of words) {
        if (currentString.length + 1 + word.length > maxLength) {
            lines.push(currentString);
            currentString = word;
        } else {
            currentString += " " + word;
        }
    }
    lines.push(currentString);

    return lines;
};

const getImageDescription = (
    { barChartData, barChartKeys, baseDescription, dateDescription, filterState }: ImageDescriptionProps
): string => {
    const shortFilterDescription = getShortFilterDescription(filterState);

    const dataContext = `A vertical bar chart showing the ${baseDescription.toLowerCase()} ${dateDescription.toLowerCase()}, for ${shortFilterDescription}`;
    const chartContext = `The chart displays a group of ${barChartKeys.length} bars for each selected month, with each bar representing one of ${joinArrayStringItems(barChartKeys)}`;
    const monthDescriptions = barChartData.map(datum => {
        const resultDescriptions: string[] = [];
        barChartKeys.forEach(key => {
            if (datum[key]) {
                resultDescriptions.push(`${datum[key]}% of datasets achieved their ${key} target`);
            }
        });

        return resultDescriptions.length > 0 ? `For ${datum.date}, ${joinArrayStringItems(resultDescriptions)}` : "";
    });
    const chartDescription = `${monthDescriptions.join(". ")}`;

    return `${dataContext}. ${chartContext}. ${chartDescription}.`;
};


const BarChartDescription = ({ bars, width } : BarCustomLayerProps<BarChartDatum>): JSX.Element | null => {
    if (bars.length > 0) {
        const maxLength = width / WIDTH_PER_CHAR;
        const { mainDescription, filterDescription } = bars[0].data.data;
        const mainDescriptionLines = splitTextByMaxLength(mainDescription, maxLength);
        const filterDescriptionLines = splitTextByMaxLength(filterDescription, maxLength);
        const lines = mainDescriptionLines.concat(filterDescriptionLines);
    
        return (
            <>
                {
                    lines.map((line, index, array) => (
                        <text
                            key={uuidv4()}
                            className={`${bars[0].data.data.containerId} svg-chart-top-text`}
                            x={0}
                            y={(HEIGHT_PER_TEXT_LINE * -1) - ((array.length - index) * HEIGHT_PER_TEXT_LINE)}
                            style={{ fontFamily: "sans-serif", fontSize: 16 }}
                        >
                            {line}
                        </text>
                    ))
                }
            </>
        );    
    } else {
        return null;
    }
};

const BarChartTooltip = ({ id, formattedValue, color }: BarTooltipProps<BarChartDatum>): JSX.Element => (
    <BasicTooltip
        id={id}
        value={`${formattedValue}%`}
        enableChip={true}
        color={color}
    />
);


export const DatasetsOnTargetBarChart = ({ categories, data, filterState, loading }: DatasetsOnTargetBarChartProps) => {
    const id = "datasets-on-target-bar-chart";
    const containerId = `${id}-container`;

    const [imageLoading, setImageLoading] = useState<boolean>(false);

    const sectionDescription = getSectionDescriptionParts(filterState);
    const { barChartData, barChartKeys } = getBarChartProps(categories, containerId, data, sectionDescription);
    const { baseDescription, dateDescription, filterDescription } = sectionDescription;

    const totalNumberOfBars = barChartKeys.length * barChartData.length;

    const fontSizeModification = calculateFontSizeModifier(totalNumberOfBars);
    const theme = getBarChartTheme(fontSizeModification);

    const imageDescription = getImageDescription({ barChartData, barChartKeys, baseDescription, dateDescription, filterState });

    const onClickDownloadImage = async (imageFormat: ImageFormat): Promise<void> => {
        setImageLoading(true);
        const filename = createExportFilename({ suffix: id, filterState });
        await downloadSvgAsImage(containerId, filename, imageFormat);
        setImageLoading(false);
    };

    const barChartWidth = calculateBarChartWidth(totalNumberOfBars);
    const legendTextWidth = Math.max(...barChartKeys.map(key => key.length)) * ((Number(theme.legends?.text?.fontSize) || 14) * 0.6);
    const legendSymbolWidth = (15 + fontSizeModification) < 10 ? 10 : (15 + fontSizeModification);
    const containerWidth = `calc(${barChartWidth < 100 ? `${barChartWidth}% + ${legendTextWidth}px` : `${barChartWidth}%`})`;

    return (
        <Row id={id} style={{ marginBottom: 10 }}>
            <Divider orientation="left"><h2>Proportion of Datasets on Target</h2></Divider>
            <Skeleton loading={loading} active>
                <Col span={20} style={{ marginBottom: 16 }}>
                    <p aria-hidden style={{ marginBottom: 0 }}>{baseDescription} <b>{dateDescription}</b>.</p>
                    <p aria-hidden style={{ marginBottom: 0 }}>{filterDescription}</p>
                </Col>
                <Col span={4} style={{ display: "flex", justifyContent: "end" }}>
                    {
                        IMAGE_FORMATS.map(format => (
                            <Button
                                key={`download-${id}-${format}`}
                                disabled={imageLoading}
                                icon={<PictureOutlined />}
                                loading={imageLoading}
                                onClick={async () => await onClickDownloadImage(format)}
                                size="small"
                                style={{ marginLeft: 10, marginBottom: 10, width: "100%" }}
                            >
                                {`.${format}`}
                            </Button>
                        ))
                    }
                </Col>
                <Col span={24}>
                    <div
                        id={containerId}
                        style={{ height: 300, width: containerWidth }}
                    >
                        <ResponsiveBar
                            // Base
                            data={barChartData}
                            indexBy="date"
                            keys={barChartKeys}
                            groupMode="grouped"
                            layout="vertical"
                            valueScale={{ type: "linear" }}
                            indexScale={{ type: "band", round: true }}
                            minValue={0}
                            maxValue={100}
                            margin={{
                                top: 20,
                                bottom: 40,
                                left: 35 + (Number(theme.axis?.legend?.text?.fontSize) || 0),
                                right: legendTextWidth + legendSymbolWidth
                            }}
                            // Style
                            theme={theme}
                            colors={
                                (bar) => categories.find(category => category.displayName === bar.id)?.inTargetColor || "000000"
                            }
                            colorBy="id"
                            borderColor={{ from: "color", modifiers: [["darker", 1]] }}
                            borderWidth={1}
                            // Customisation
                            layers={
                                ["grid", "axes", "bars", "totals", "markers", "legends", "annotations", BarChartDescription]
                            }
                            // Labels
                            enableLabel
                            label={(datum) => `${datum.value}%`}
                            labelSkipHeight={1}
                            labelTextColor="#FFFFFF"
                            // Grid & Axes
                            enableGridY
                            gridYValues={[0, 20, 40, 60, 80, 100]}
                            axisBottom={{ tickSize: 5, tickPadding: 5 }}
                            axisLeft={{ 
                                tickSize: 5,
                                tickPadding: 5,
                                tickValues: [0, 20, 40, 60, 80, 100],
                                legend: "% Datasets on Target",
                                legendPosition: "middle",
                                legendOffset: -30 - (Number(theme.axis?.legend?.text?.fontSize) || 0),
                            }}
                            // Interactivity
                            tooltip={BarChartTooltip}
                            // Legends
                            legends={[{
                                anchor: "top-right",
                                dataFrom: "keys",
                                direction: "column",
                                justify: false, 
                                itemDirection: "left-to-right",
                                itemHeight: legendSymbolWidth + 5,
                                itemsSpacing: 0,
                                itemWidth: legendTextWidth,
                                symbolSize: legendSymbolWidth,
                                translateX: legendTextWidth,
                            }]}
                            // Accessibility
                            isFocusable
                            role="img"
                            ariaLabel={imageDescription}
                            barAriaLabel={(datum) => `${datum.data.date}, ${datum.id}, ${datum.formattedValue}%`}
                        />
                    </div>
                </Col>
            </Skeleton>
        </Row>
    );
};
