import { CloseCircleOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { ApolloError } from "@apollo/client";
import { Alert, Button, Col, Divider, Form, Input, List, Popconfirm, Row, Select, Space } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import CustomHelmet from "../../../components/CustomHelmet";
import { Loading } from "../../../components/Loading/Loading";
import { AdminGetAllDatasets, AdminGetAllDatasets_datasets, AdminGetAllDatasets_datasets_program } from "../../../graphql/__generated__/AdminGetAllDatasets";
import { AdminGetPlatform, AdminGetPlatform_platform } from "../../../graphql/__generated__/AdminGetPlatform";
import { ADMIN_GET_ALL_DATASETS } from "../../../graphql/__queries__/AdminGetAllDatasets.gql";
import { ADMIN_GET_PLATFORM } from "../../../graphql/__queries__/AdminGetPlatform.gql";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";
import { useDeletePlatform, useUpdatePlatform } from "../../../hooks/programHooks";

const { Search } = Input;


type Entity = {
    id: string;
    name: string;
}

type Dataset = Entity & { 
    programId: string;
};

type Platform = Entity & { 
    datasets: Dataset[];
};

type EditPlatformForm = {
    platform: Platform;
};

type ValidDataset = AdminGetAllDatasets_datasets & {
    program: AdminGetAllDatasets_datasets_program;
};

type QueryParams = {
    platformId: string;
};

type QueryResult = {
    queryDatasets: readonly AdminGetAllDatasets_datasets[] | undefined;
    queryPlatform: AdminGetPlatform_platform | undefined;
    error: ApolloError | undefined;
    loading: boolean;
    refresh: () => Promise<void>;
};


const useQueries = ({ platformId }: QueryParams): QueryResult => {
    const {
        data: platformData, error: platformError, loading: platformLoading, refetch: platformRefetch
    } = useQueryWithErrorHandling<AdminGetPlatform>(
        ADMIN_GET_PLATFORM,
        "platform",
        { variables: { id: platformId }, fetchPolicy: "network-only" }
    );

    const { 
        data: datasetsData, error: datasetsError, loading: datasetsLoading, refetch: datasetsRefetch
    } = useQueryWithErrorHandling<AdminGetAllDatasets>(
        ADMIN_GET_ALL_DATASETS,
        "datasets",
        { fetchPolicy: "network-only", variables: { onlyUnassigned: false } }
    );

    const error = platformError || datasetsError;
    const loading = platformLoading || datasetsLoading;
    const refresh = async () => {
        await platformRefetch();
        await datasetsRefetch();
    };

    const queryDatasets = datasetsData?.datasets;
    const queryPlatform = platformData?.platform;

    return { queryDatasets, error, loading, queryPlatform, refresh };
};

const applySearchFilter = (datasets: Dataset[], searchTerms: string[]) => (
    datasets?.filter(({ name }) => (
        searchTerms.every(term => name.toLocaleLowerCase().includes(term.toLocaleLowerCase()))
    )) || []
);


export const EditPlatform = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { platformId } = useParams() as QueryParams;
    const [editPlatformForm] = Form.useForm<EditPlatformForm>();

    const [searchTerms, setSearchTerms] = useState<string[]>([]);
    const [selectedDataset, setSelectedDataset] = useState<Dataset | undefined>(undefined);
    const [selectDatasets, setSelectDatasets] = useState<Dataset[]>([]);
    const [platformDatasets, setPlatformDatasets] = useState<Dataset[]>([]);

    const { queryDatasets, queryPlatform, error, loading, refresh } = useQueries({ platformId });
    const updatePlatform = useUpdatePlatform();
    const deletePlatform = useDeletePlatform();

    const platform: Platform = useMemo(() => ({ 
        id: queryPlatform?.id || platformId,
        name: queryPlatform?.name || "",
        datasets: (queryPlatform?.programs || []).flatMap(program => (
            program.datasets.map(({ id, name }) => ({ id, name, programId: program.id }))
        ))
    }), [platformId, queryPlatform]);

    const initialValues: EditPlatformForm = useMemo(() => ({ platform }), [platform]);

    useMemo(() => {
        const validDatasets = (queryDatasets || []).filter((dataset): dataset is ValidDataset => !!dataset.program);
        const filteredDatasets = validDatasets.filter(dataset => !platform.datasets.some(({ id }) => dataset.id === id));
        const mappedDatasets = filteredDatasets.map(({ id, name, program }) => ({ id, name, programId: program.id }));
        const sortedDatasets = mappedDatasets.sort((a, b) => a.name.localeCompare(b.name));
        setSelectDatasets(sortedDatasets);
    }, [platform, queryDatasets]);

    useMemo(() => {
        const sortedPlatformDatasets = platform.datasets.sort((a, b) => a.name.localeCompare(b.name));
        setPlatformDatasets(sortedPlatformDatasets);
    }, [platform]);

    const filteredDatasets = useMemo(() => (
        searchTerms.length === 0 ? platformDatasets : applySearchFilter(platformDatasets, searchTerms)
    ), [platformDatasets, searchTerms]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        throw error;
    }

    const addDataset = (selectedDataset: Dataset | undefined) => {
        if (selectedDataset) {
            const selectDatasetsCopy = [...selectDatasets];
            const selectDatasetIndex = selectDatasetsCopy.findIndex(({ id }) => selectedDataset.id === id);
            if (selectDatasetIndex > -1) {
                const dataset = selectDatasetsCopy.splice(selectDatasetIndex, 1);
                setSelectDatasets(selectDatasetsCopy);

                const platformDatasetsCopy = [...platformDatasets];
                platformDatasetsCopy.push(...dataset);
                platformDatasetsCopy.sort((a, b) => a.name.localeCompare(b.name));
                setPlatformDatasets(platformDatasetsCopy);
            }

            setSelectedDataset(undefined);
        }
    };

    const removeDataset = (datasetId: string) => {
        const platformDatasetsCopy = [...platformDatasets];
        const platformDatasetIndex = platformDatasetsCopy.findIndex(({ id }) => datasetId === id);
        if (platformDatasetIndex > -1) {
            const dataset = platformDatasetsCopy.splice(platformDatasetIndex, 1);
            setPlatformDatasets(platformDatasetsCopy);

            const selectDatasetsCopy = [...selectDatasets];
            selectDatasetsCopy.push(...dataset);
            selectDatasetsCopy.sort((a, b) => a.name.localeCompare(b.name));
            setSelectDatasets(selectDatasetsCopy);
        }
    };

    const onDelete = async () => {
        await deletePlatform.run(platformId);
        navigate("/admin/tags/platforms");
    };

    const onFinish = async (values: EditPlatformForm) => {
        const programIds = platformDatasets.map(({ programId }) => programId);
        await updatePlatform.run({ id: platformId, name: values.platform.name, programIds });
        refresh();    
    };

    return (
        <Row gutter={[16, 16]}>
            {/* Page Title */}
            <CustomHelmet title={`${t("admin.tags.platforms.edit.title")} - ${platform.name} Platform`} />

            {/* Page Header */}
            <Col span={24}>
                <PageHeader
                    onBack={() => navigate("/admin/tags/platforms")}
                    subTitle={`${platform.name} Platform`}
                    title={t("admin.tags.platforms.edit.title")}
                />
            </Col>

            {/* Errors */}
            {
                [
                    { action: "save", error: updatePlatform.error },
                    { action: "delete", error: deletePlatform.error }
                ]
                    .filter(err => !!err.error)
                    .map(({ action, error }) => (
                        <Col key={action} span={24}>
                            <Alert
                                closable
                                description={error?.message}
                                message={t(`admin.tags.platforms.edit.actions.${action}.error`)}
                                showIcon
                                style={{ marginBottom: 16 }}
                                type="error"
                            />
                        </Col>
                    ))
            }

            {/* Edit Platform Form */}
            <Col span={24}>
                <Form
                    form={editPlatformForm}
                    initialValues={initialValues}
                    onFinish={async (values) => await onFinish(values)}
                    scrollToFirstError
                >
                    <Row gutter={[16, 16]}>
                        <Col offset={1} span={11}>
                            {/* Edit Platform Name */}
                            <Form.Item
                                label={t("admin.tags.platforms.edit.input.label")}
                                labelAlign="right"
                                labelCol={{ span: 7 }}
                                name={["platform", "name"]}
                                rules={[{ required: true, message: t("admin.tags.platforms.edit.input.required") }]}
                                validateTrigger="onBlur"
                            >
                                <Input
                                    disabled={updatePlatform.inFlight || deletePlatform.inFlight}
                                    placeholder={t("admin.tags.platforms.edit.input.placeholder")}
                                    title={t("admin.tags.platforms.edit.input.label")}        
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[32, 64]}>
                        <Col span={12}>
                            <Row gutter={[16, 16]}>
                                <Divider orientation="left">
                                    {`${platform.name} ${t("admin.tags.platforms.edit.sections.platformDatasets.title")}`}
                                </Divider>
                                <Col span={16}>
                                    <Search
                                        allowClear
                                        disabled={updatePlatform.inFlight || deletePlatform.inFlight}
                                        onSearch={(input) => setSearchTerms(input.split(" "))} 
                                        placeholder={
                                            t("admin.tags.platforms.edit.actions.search")
                                                .replace("Platform", `${platform.name} Platform`)
                                        }
                                        title={t("admin.tags.platforms.edit.actions.search")}
                                    />
                                </Col>
                                <Col span={24}>
                                    <List
                                        bordered
                                        dataSource={filteredDatasets}
                                        itemLayout="horizontal"
                                        pagination={{ position: "bottom", align: "end" }}
                                        renderItem={(item) => (
                                            <List.Item
                                                actions={[
                                                    <a
                                                        href={`/admin/programs/${item.programId}`}
                                                        key={item.id}
                                                        title={t("admin.tags.platforms.edit.actions.editDataset")}
                                                    >
                                                        <EditOutlined />
                                                    </a>                                            
                                                ]}
                                            >
                                                <Space>
                                                    <Popconfirm
                                                        cancelText={t("admin.tags.platforms.edit.actions.removeDataset.cancelText")}
                                                        description={t("admin.tags.platforms.edit.actions.removeDataset.description")}
                                                        disabled={updatePlatform.inFlight}
                                                        okText={t("admin.tags.platforms.edit.actions.removeDataset.confirmText")}
                                                        onConfirm={() => removeDataset(item.id)}
                                                        title={t("admin.tags.platforms.edit.actions.removeDataset.title")}
                                                    >
                                                        <Button
                                                            danger
                                                            icon={<CloseCircleOutlined />}
                                                            title={t("admin.tags.platforms.edit.actions.removeDataset.title")}
                                                            type="text"
                                                        />
                                                    </Popconfirm>
                                                    {item.name}
                                                </Space>
                                            </List.Item>
                                        )}
                                        rowKey={(item) => item.id}
                                        size="small"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Divider orientation="left">
                                        {t("admin.tags.platforms.edit.sections.otherDatasets.title")}
                                    </Divider>
                                    <em>{t("admin.tags.platforms.edit.sections.otherDatasets.description")}</em>
                                </Col>
                                <Col span={16}>
                                    <Select
                                        disabled={updatePlatform.inFlight || deletePlatform.inFlight}
                                        filterOption={(input, option) => (
                                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                        )}
                                        onChange={(_value, option: DefaultOptionType) => {
                                            setSelectedDataset(selectDatasets.find(({ id }) => id === option.value));    
                                        }}
                                        options={selectDatasets.map(({ id, name }) => ({ label: name, value: id }))}
                                        placeholder={t("admin.tags.platforms.edit.actions.addDataset.select")}
                                        showSearch
                                        style={{ width: "100%" }}
                                        title={t("admin.tags.platforms.edit.actions.addDataset.select")}
                                        value={selectedDataset?.name}
                                    />
                                </Col>
                                <Col span={8}>
                                    <Button
                                        disabled={updatePlatform.inFlight || deletePlatform.inFlight}
                                        ghost={true}
                                        key="add-dataset"
                                        onClick={() => addDataset(selectedDataset)}
                                        title={t("admin.tags.platforms.edit.actions.addDataset.title")}
                                        type="primary"
                                    >
                                        {t("admin.tags.platforms.edit.actions.addDataset.title")}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row align="middle" gutter={[16, 16]} justify="space-evenly">
                                <Form.Item>
                                    <Space size={[64, 64]}>
                                        <Space>
                                            {/* Save Button */}
                                            <Button
                                                disabled={deletePlatform.inFlight}
                                                htmlType="submit"
                                                icon={<SaveOutlined />}
                                                loading={updatePlatform.inFlight}
                                                title={t("admin.tags.platforms.edit.actions.save.title")}
                                                type="primary"
                                            >
                                                {t("admin.tags.platforms.edit.actions.save.title")}
                                            </Button>

                                            {/* Cancel Button */}
                                            <Button
                                                disabled={updatePlatform.inFlight || deletePlatform.inFlight}
                                                onClick={() => navigate("/admin/tags/platforms")}
                                                title={t("admin.tags.platforms.edit.actions.cancel")}
                                            >
                                                {t("admin.tags.platforms.edit.actions.cancel")}
                                            </Button>
                                        </Space>

                                        {/* Delete Button */}
                                        <Popconfirm
                                            cancelText={t("admin.tags.platforms.edit.actions.delete.cancelText")}
                                            description={t("admin.tags.platforms.edit.actions.delete.description")}
                                            disabled={updatePlatform.inFlight}
                                            okText={t("admin.tags.platforms.edit.actions.delete.confirmText")}
                                            onConfirm={async () => await onDelete()}
                                            title={t("admin.tags.platforms.edit.actions.delete.title")}
                                        >
                                            <Space>
                                                <Button
                                                    danger
                                                    disabled={updatePlatform.inFlight}
                                                    icon={<DeleteOutlined />}
                                                    loading={deletePlatform.inFlight}
                                                    title={t("admin.tags.platforms.edit.actions.delete.title")}
                                                >
                                                    {t("admin.tags.platforms.edit.actions.delete.title")}
                                                </Button>
                                            </Space>
                                        </Popconfirm>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};