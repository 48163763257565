import { createRandomPassword } from "../utils/string";

/**
 * Type of the union of values of an object
 */
type ValueOf<T> = T[keyof T];

/**
 * Any of the acceptable http status codes.
 */
type HttpStatusCode = ValueOf<typeof httpStatus>;

/**
 * Status codes
 */
export const httpStatus = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_DATA: 204,
    BAD_REQUEST: 400,
    NOT_AUTHORIZED: 401,
    FORBIDDEN: 403,
    UNPROCESSABLE: 422,
    INTERNAL_ERROR: 500,
    BAD_GATEWAY: 502,
};

/**
 * Validate the response status, throwing an error if one occurred.
 */
const checkError = async (
    response: Response,
    expectedCode?: HttpStatusCode
) => {
    let json;

    // If there's a specific code specified, make sure that it matches, even if
    // the response is a 2xx code.
    const validateCode = () => {
        if (expectedCode && expectedCode !== response.status) {
            throw new Error("UNKNOWN_ERROR");
        }
    };

    switch (response.status) {
    case httpStatus.OK:
        return validateCode();
    case httpStatus.CREATED:
        return validateCode();
    case httpStatus.ACCEPTED:
        return validateCode();
    case httpStatus.NO_DATA:
        return validateCode();
    case httpStatus.BAD_REQUEST:
        json = await response.json();
        if (json.detail && json.detail.code) {
        // NOTE: there's more detail here that might be interesting to pass
        // along to the UI.
            throw new Error(json.detail.code);
        } else {
            throw new Error(json.detail || "BAD_REQUEST");
        }
    case httpStatus.UNPROCESSABLE:
        throw new Error("VALIDATION_ERROR");
    case httpStatus.NOT_AUTHORIZED:
        throw new Error("NOT_AUTHORIZED");
    case httpStatus.FORBIDDEN:
        throw new Error("FORBIDDEN");
    default:
        throw new Error("UNKNOWN_ERROR");
    }
};

// CREATE USER //

type CreateUser = Readonly<{
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    roles?: Readonly<{ id: string }>[];
    teams?: Readonly<{ id: string }>[];
}>;

export enum CreateUserErrors {
    REGISTER_USER_ALREADY_EXISTS = "REGISTER_USER_ALREADY_EXISTS"
}

/**
 * Request handler to create a user.
 */
export const createUser = async (user: CreateUser) => {
    const response = await fetch("/api/auth/register", {
        method: "POST",
        credentials: "same-origin",
        headers: [["Content-Type", "application/json"]],
        body: JSON.stringify({
            ...user,
            password: createRandomPassword(),
        }),
    });

    await checkError(response, httpStatus.CREATED);

    const data = await response.json();
    return data["id"] as string;
};

// EDIT USER //

export type EditUser = Readonly<{
    first_name: string;
    last_name: string;
    email?: string;
    roles?: string[];
    teams?: string[];
    username?: string;
}>;

/**
 * Request handler to edit a user.
 */
export const edit = async (userId: string, values: EditUser) => {
    const response = await fetch(`/api/users/${userId}`, {
        method: "PATCH",
        body: JSON.stringify({
            ...values,
            roles: values.roles?.map((id) => ({ id })),
            teams: values.teams?.map((id) => ({ id })),
        }),
        credentials: "same-origin",
        headers: [["Content-Type", "application/json"]],
    });

    await checkError(response, httpStatus.OK);
};

// DELETE USER //

/**
 * De-active a user account.
 *
 * This will fail if the user doesn't have permission.
 */
export const deleteUser = async (userId: string) => {
    const response = await fetch(`/api/users/${userId}`, {
        method: "DELETE",
        credentials: "same-origin",
    });

    await checkError(response, httpStatus.NO_DATA);
};

// RESTORE USER //

/**
 * Re-active a user account.
 *
 * This will fail if the user doesn't have permission.
 */
export const restoreUser = async (userId: string) => {
    const r = await fetch(`/api/users/${userId}`, {
        method: "PATCH",
        body: JSON.stringify({
            is_active: true,
        }),
        credentials: "same-origin",
        headers: [["Content-Type", "application/json"]],
    });

    await checkError(r, httpStatus.OK);
};

// VERIFY EMAIL //

/**
 * Request handler to verify a user's email address.
 */
export const requestVerifyUser = async (email: string) => {
    // NOTE: the response from the token request is not super interesting, since
    // it always returns 202 regardless of whether the payload was incorrect.
    // Still worry about 500s, though.
    const requestVerifyResponse = await fetch("/api/auth/request-verify-token", {
        method: "POST",
        credentials: "same-origin",
        body: JSON.stringify({ email: email }),
        headers: [["Content-Type", "application/json"]],
    });

    await checkError(requestVerifyResponse, httpStatus.ACCEPTED);
};

// VERIFY TOKEN //

/**
 * Request handler to verify a user's token.
 */
export const verify = async (token: string) => {
    const r = await fetch("/api/auth/verify", {
        method: "POST",
        credentials: "same-origin",
        body: JSON.stringify({ token }),
        headers: [["Content-Type", "application/json"]],
    });

    await checkError(r, httpStatus.OK);
};

// USER FORGOT PASSWORD //

/**
 * Request that a password reset email be sent to the given user, if they exist.
 */
export const requestPasswordReset = async (email: string) => {
    const r = await fetch("/api/auth/forgot-password", {
        method: "POST",
        body: JSON.stringify({ email }),
        credentials: "same-origin",
        headers: [["Content-Type", "application/json"]],
    });

    await checkError(r, httpStatus.ACCEPTED);
};

// USER RESET PASSWORD //

export type ResetPasswordRequest = Readonly<{
    token: string;
    password: string;
  }>;

/**
 * Reset a user's password with the given token.
 */
export const resetPassword = async (params: ResetPasswordRequest) => {
    const r = await fetch("/api/auth/reset-password", {
        method: "POST",
        body: JSON.stringify(params),
        credentials: "same-origin",
        headers: [["Content-Type", "application/json"]],
    });

    await checkError(r, httpStatus.OK);
};

/**
 * The client bundles together all user account management methods.
 */
const client = {
    verify,
    requestVerifyUser,
    createUser,
    edit,
    deleteUser,
    restoreUser,
    resetPassword,
    requestPasswordReset,
};

export type UserAccountManager = typeof client;

export default client;
