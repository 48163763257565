define(function() {
  'use strict';

  function CustomEvent() {}

  CustomEvent.sendRefreshEvent = function(tag, httpGet, lastPlayHitUrl) {
    if (lastPlayHitUrl) {
      var refreshHitUrl = lastPlayHitUrl;
      refreshHitUrl = refreshHitUrl.replace(/a\=play/g, 'a=refresh');
      refreshHitUrl = refreshHitUrl.replace(/ts\=[0-9\.]+/g, 'ts=' + new Date().getTime());
      refreshHitUrl = refreshHitUrl.replace(/(prich\=|s2rich\=).*?(&|$)/g, '');

      httpGet(refreshHitUrl, function() {
        tag.emit('Tracker:Hit:Sent:Ok', refreshHitUrl);
      }.bind(tag));
    }
  };

  return CustomEvent;
});
