import { CloseCircleOutlined, PlusOutlined, SnippetsOutlined, SyncOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { useQuery } from "@apollo/client";
import { 
    Alert,
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Form,
    FormInstance,
    FormListFieldData,
    FormListOperation,
    Input,
    Popconfirm,
    Row,
    Select,
    Space
} from "antd";
import { ValidateStatus } from "antd/lib/form/FormItem";
import { useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";

import CustomHelmet from "../../../components/CustomHelmet";
import { AdminGetAllRoles, AdminGetAllRoles_roles } from "../../../graphql/__generated__/AdminGetAllRoles";
import { AdminGetAllTeams, AdminGetAllTeams_teams } from "../../../graphql/__generated__/AdminGetAllTeams";
import { AdminGetUserByEmail } from "../../../graphql/__generated__/AdminGetUserByEmail";
import { ADMIN_GET_ALL_ROLES } from "../../../graphql/__queries__/AdminGetAllRoles.gql";
import { ADMIN_GET_ALL_TEAMS } from "../../../graphql/__queries__/AdminGetAllTeams.gql";
import { ADMIN_GET_USER_BY_EMAIL } from "../../../graphql/__queries__/AdminGetUserByEmail.gql";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";
import { useUserAccountManager } from "../../../hooks/UserAccountManagerProvider";
import { CreateUserErrors } from "../../../services/account";
import { isUserRoleWithPermissions } from "../../../typeguards";
import { capitaliseFirstLetterOfEachWord, forceStringLowercase, forceStringUppercase } from "../../../utils/string";


type CreateUserForm = Readonly<{
    users: UserInputCard[] | undefined;
}>;

type EmailValidation = {
    status: ValidateStatus,
    message: string;
};

type UserInputCard = Readonly<{
    id: UserInputCardId;
    email: string;
    firstName: string;
    lastName: string;
    permissions?: string[];
    roles?: string[];
    teams?: string[];
    username: string;
}>;

type UserInputCardId = number;

type UserInputCardProps = {
    cardId: string;
    data: {
        roles: readonly AdminGetAllRoles_roles[];
        teams: readonly AdminGetAllTeams_teams[];
    };
    field: FormListFieldData;
    form: FormInstance<CreateUserForm>;
    operations: FormListOperation;
    saveCompleted: UserInputCardId[];
    saveFailed: UserInputCardId[];
    t: TFunction<"translation", undefined>;
}


const UserInputCard = ({ cardId, data, field, form, operations, saveCompleted, saveFailed, t }: UserInputCardProps) => {
    const [email, setEmail] = useState<string>("");
    const [emailValidationResult, setEmailValidationResult] = useState<EmailValidation>({ message: "", status: "" });

    const user = useQuery<AdminGetUserByEmail>(
        ADMIN_GET_USER_BY_EMAIL, { variables: { email }, fetchPolicy: "network-only" }
    ).data?.userByEmail || null;

    useMemo(() => {
        if (email) {
            const emailAlreadyExists = user !== null;
            setEmailValidationResult({ 
                message: t(`admin.user.add.actions.checkEmailAlreadyExists.${emailAlreadyExists}`),
                status: emailAlreadyExists ? "warning" : "success", 
            });
        }
    }, [email, user, t]);

    const { roles, teams } = data;
    const { name, ...restField } = field;

    const permissions = roles.filter(({ name }) => isUserRoleWithPermissions(name));

    const hasUserCreationSucceeded = saveCompleted.includes(form.getFieldValue(["users", name, "id"]));
    const hasUserCreationFailed = saveFailed.includes(form.getFieldValue(["users", name, "id"]));

    const copyTeamsAcrossAllUsers = () => {
        const selectedTeams = form.getFieldValue(["users", name, "teams"]);
        const userCards = form.getFieldValue(["users"]);

        for (const [key] of Object.entries(userCards)) {
            form.setFieldValue(["users", key, "teams"], selectedTeams);
        }
    };

    return (
        <Card style={{ marginBottom: 16 }}>
            {/* Alerts */}
            {hasUserCreationSucceeded && 
                <Row>
                    <Col span={24}>
                        <Alert 
                            message={t("admin.user.add.actions.save.success")}
                            showIcon
                            style={{ marginBottom: 16 }}
                            type="success"
                        />
                    </Col>
                </Row>
            }
            {hasUserCreationFailed && 
                <Row>
                    <Col span={24}>
                        <Alert 
                            message={t("admin.user.add.actions.save.failure")} 
                            showIcon
                            style={{ marginBottom: 16 }}
                            type="error"
                        />
                    </Col>
                </Row>
            }

            {/* Hidden Card ID Input */}
            <Form.Item {...restField} initialValue={cardId} name={[name, "id"]} noStyle>
                <Input type="hidden"/>
            </Form.Item>

            {/* Inputs */}
            <Row gutter={16}>
                <Col span={11}>
                    <Row>
                        <Col span={22}>
                            <Form.Item
                                {...restField}
                                label={t("admin.user.add.inputs.firstName.label")}
                                labelAlign="right"
                                labelCol={{ span: 5 }}
                                name={[name, "firstName"]}
                                rules={[{ required: true, message: t("admin.user.add.inputs.firstName.required") }]}
                                validateTrigger="onBlur"
                            >
                                <Input
                                    disabled={hasUserCreationSucceeded}
                                    placeholder={t("admin.user.add.inputs.firstName.placeholder")}
                                    title={t("admin.user.add.inputs.firstName.label")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={22}>
                            <Form.Item
                                {...restField}
                                label={t("admin.user.add.inputs.username.label")}
                                labelAlign="right"
                                labelCol={{ span: 5 }}
                                name={[name, "username"]}
                                rules={[{ required: true, message: t("admin.user.add.inputs.username.required") }]}
                                validateTrigger="onBlur"
                            >
                                <Input
                                    disabled={hasUserCreationSucceeded}
                                    placeholder={t("admin.user.add.inputs.username.placeholder")}
                                    title={t("admin.user.add.inputs.username.label")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={22}>
                            <Form.Item
                                {...restField} 
                                label={t("admin.user.add.inputs.teams.label")}
                                labelAlign="right"
                                labelCol={{ span: 5 }}
                                name={[name, "teams"]}
                                shouldUpdate
                            >
                                <Select
                                    disabled={hasUserCreationSucceeded}
                                    filterOption={(input, option) => (option?.label ?? "")
                                        .toLowerCase().includes(input.toLowerCase())
                                    }
                                    filterSort={(a, b) => a.label.localeCompare(b.label)}
                                    mode="multiple"
                                    options={teams.map(({ id, name }) => ({ label: name, value: id }))}
                                    placeholder={t("admin.user.add.inputs.teams.placeholder")}
                                    title={t("admin.user.add.inputs.teams.label")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Popconfirm
                                title={t("admin.user.add.actions.copyTeams.title")}
                                description={t("admin.user.add.actions.copyTeams.description")}
                                okText={t("admin.user.add.actions.copyTeams.confirmText")}
                                cancelText={t("admin.user.add.actions.copyTeams.cancelText")}
                                onConfirm={copyTeamsAcrossAllUsers}
                            >
                                <Button
                                    icon={<SnippetsOutlined />}
                                    title={t("admin.user.add.actions.copyTeams.hint")}
                                />
                            </Popconfirm>                            
                        </Col>
                    </Row>
                </Col>
                <Col span={1}>
                    <Divider style={{ height: "100%" }} type="vertical"/>
                </Col>
                <Col span={11}>
                    <Row>
                        <Col span={22}>
                            <Form.Item
                                {...restField}
                                label={t("admin.user.add.inputs.lastName.label")}
                                labelAlign="right"
                                labelCol={{ span: 5 }}
                                name={[name, "lastName"]}
                                rules={[{ required: true, message: t("admin.user.add.inputs.lastName.required") }]}
                                validateTrigger="onBlur"
                            >
                                <Input
                                    disabled={hasUserCreationSucceeded}
                                    placeholder={t("admin.user.add.inputs.lastName.placeholder")}
                                    title={t("admin.user.add.inputs.lastName.label")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={22}>
                            <Form.Item
                                {...restField}
                                label={t("admin.user.add.inputs.email.label")}
                                name={[name, "email"]}
                                labelAlign="right"
                                labelCol={{ span: 5 }}
                                required
                                hasFeedback={!!emailValidationResult.status}
                                help={emailValidationResult.message}
                                rules={[
                                    { 
                                        validator: (_, value) => {
                                            if (!value) {
                                                setEmailValidationResult({
                                                    message: t("admin.user.add.inputs.email.required"),
                                                    status: "error",
                                                });
                                                return Promise.reject();
                                            }
                                            setEmailValidationResult({ message: "", status: "" });
                                            return Promise.resolve();
                                        }
                                    }
                                ]}
                                validateStatus={emailValidationResult.status}
                                validateTrigger="onBlur"
                            >
                                <Input
                                    disabled={hasUserCreationSucceeded}
                                    placeholder={t("admin.user.add.inputs.email.placeholder")}
                                    title={t("admin.user.add.inputs.email.label")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Button
                                icon={<SyncOutlined />}
                                title={t("admin.user.add.actions.checkEmailAlreadyExists.title")}
                                onClick={() => {
                                    const email = form.getFieldValue(["users", name, "email"]);
                                    if (email) {
                                        setEmail(email);
                                    }
                                }}
                            />                            
                        </Col>
                    </Row>
                    <Row>
                        <Col span={22}>
                            <Form.Item 
                                {...restField}
                                label={t("admin.user.add.inputs.permissions.label")}
                                labelAlign="right"
                                labelCol={{ span: 5 }}
                                name={[name, "permissions"]}
                            >
                                <Checkbox.Group disabled={hasUserCreationSucceeded}>
                                    {
                                        permissions
                                            .sort((a, b) => a.name.localeCompare(b.name) * -1)
                                            .map(({ id, name }) => (
                                                <Checkbox 
                                                    key={`role-checkbox-${id}`} 
                                                    title={capitaliseFirstLetterOfEachWord(name)} 
                                                    value={id}
                                                >
                                                    {capitaliseFirstLetterOfEachWord(name)}
                                                </Checkbox>
                                            ))
                                    }
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={1} style={{ textAlign: "right" }}>
                    <Button
                        aria-label={t("admin.user.add.actions.removeUser")}
                        danger
                        icon={<CloseCircleOutlined />}
                        onClick={() => operations.remove(field.name)}
                        title={t("admin.user.add.actions.removeUser")}
                        type="text"
                    />
                </Col>
            </Row>
        </Card>
    );
};

const useAdminQueries = () => {
    const rolesResponse = useQueryWithErrorHandling<AdminGetAllRoles>(
        ADMIN_GET_ALL_ROLES, "roles", { fetchPolicy: "cache-and-network" }
    );
    const teamsResponse = useQueryWithErrorHandling<AdminGetAllTeams>(
        ADMIN_GET_ALL_TEAMS, "teams", { fetchPolicy: "cache-and-network" }
    );

    return {
        loading: rolesResponse.loading || teamsResponse.loading,
        roles: rolesResponse.data?.roles || [],
        teams: teamsResponse.data?.teams || [],
    };
};

const useBulkAddNewUsers = (t: TFunction) => {
    const [saving, setSaving] = useState(false);
    const [saveError, setSaveError] = useState("");
    const accountManager = useUserAccountManager();

    const saveUsers = async (
        values: CreateUserForm, 
        saveCompleted: UserInputCardId[],
    ) => {
        setSaving(true);
        setSaveError("");

        const { users } = values;

        if (!users || !users.length) {
            setSaveError(t("admin.user.add.actions.addUser.error"));
            setSaving(false);
            return { saveCompleted: [], saveFailed: [] };
        }

        const saveFailed: UserInputCardId[] = [];
        for (const user of users) {
            const { id, firstName, lastName, email, username, permissions, roles, teams } = user;
            try {
                await accountManager.createUser({
                    first_name: firstName,
                    last_name: lastName,
                    email: forceStringLowercase(email),
                    username: forceStringUppercase(username),
                    roles: permissions?.concat(roles ?? []).map((id) => ({ id })),
                    teams: teams?.map((id) => ({ id })),
                });
                saveCompleted.push(id);
            } catch (err) {
                if (err instanceof Error && err.message === CreateUserErrors.REGISTER_USER_ALREADY_EXISTS) {
                    saveFailed.push(id);
                } else {
                    setSaveError(t("admin.user.add.unknownError"));
                }
            }
        }
        
        setSaving(false);
        return { saveCompleted, saveFailed };
    };

    return {
        saving,
        saveError,
        saveUsers
    };
};


export const AddUsers = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [addUsersForm] = Form.useForm<CreateUserForm>();

    const [saveCompleted, setSaveCompleted] = useState<UserInputCardId[]>([]);
    const [saveFailed, setSaveFailed] = useState<UserInputCardId[]>([]);

    const { loading, roles, teams } = useAdminQueries();
    const { saving, saveError, saveUsers } = useBulkAddNewUsers(t);
    
    const onFinish = async (values: CreateUserForm) => {
        const { saveCompleted: updatedSaveCompleted, saveFailed: updatedSaveFailed } = await saveUsers(values, saveCompleted);

        setSaveCompleted(updatedSaveCompleted);
        setSaveFailed(updatedSaveFailed);

        if (values.users?.every(({ id }) => saveCompleted.includes(id))) {
            navigate("/admin/users");
        }
    };

    return (
        <>
            {/* Page Title */}
            <CustomHelmet title={t("admin.user.add.title")}/>

            {/* Page Header */}
            <PageHeader onBack={() => navigate("/admin/users")} title={t("admin.user.add.title")}/>

            {/* Add Users Form */}
            <Form form={addUsersForm} onFinish={onFinish}>

                {/* Errors */}
                {saveError && 
                    <Row gutter={[16, 16]} style={{ paddingTop: 16 }}>
                        <Col offset={1} span={22}>
                            <Alert closable message={saveError} showIcon type="error"/>
                        </Col>
                    </Row>
                }

                {/* Input Fields */}
                <Form.List name="users">
                    {(fields, operations) =>
                        <Row gutter={[16, 16]} style={{ paddingTop: 16, paddingBottom: 16 }}>
                            <Col offset={1} span={22}>
                                {fields.map(field => 
                                    <UserInputCard
                                        key={field.key}
                                        cardId={uuidv4()}
                                        data={{ roles, teams }}
                                        field={field}
                                        form={addUsersForm}
                                        operations={operations}
                                        saveCompleted={saveCompleted}
                                        saveFailed={saveFailed}
                                        t={t}
                                    />
                                )}
                            </Col>
                            <Col offset={1} span={22}>
                                <Form.Item>
                                    <Button
                                        block
                                        icon={<PlusOutlined/>}
                                        loading={loading || saving}
                                        onClick={operations.add}
                                        title={t("admin.user.add.actions.addUser.title")}
                                        type="dashed"
                                    >
                                        {t("admin.user.add.actions.addUser.title")}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                </Form.List>

                {/* Save Button */}
                <Row justify="center">
                    <Form.Item>
                        <Space>
                            <Button
                                htmlType="submit"
                                loading={loading || saving}
                                title={t("admin.user.add.actions.save.title")}
                                type="primary"
                            >
                                {t("admin.user.add.actions.save.title")}
                            </Button>
                            <Button
                                loading={loading || saving}
                                onClick={() => navigate("/admin/users")}
                                title={t("admin.user.add.actions.cancel")}
                            >
                                {t("admin.user.add.actions.cancel")}
                            </Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form>
        </>
    );
};