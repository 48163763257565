import { gql } from "@apollo/client";

export const ADMIN_UPDATE_PLATFORM = gql`
  mutation AdminUpdatePlatform($input: UpdatePlatformInput!) {
    updatePlatform(input: $input) {
      id
      name
      programs {
        id
        name
        datasets {
          id
          name
        }
      }
    }
  }
`;
