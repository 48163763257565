import { Pie, PieConfig } from "@ant-design/plots";
import { AnnotationPosition, Datum } from "@antv/g2plot";


interface IProps {
    attribute?: string,
    attrsInTarget?: string[],
    attrsOOTarget?: string[]
    categoryName: string,
    legend?: boolean,
    inTargetColor: string;
    outTargetColor: string;
    personType?: string,
    status: number,
    target?: number,
}


const tooltipText = (targetName: string, category: string) => {
    switch (targetName) {
    case "Gender":
        return "Women";
    case "Ethnicity":
        return "B.A.M.E.";
    case "Disability":
        return "Disabled";
    case "Other":
        switch (category) {
        case "Gender":
            return "Men, Non-Binary";
        case "Ethnicity":
            return "White";
        case "Disability":
            return "Non-Disabled";
        default:
            return targetName;
        }
    default:
        return targetName;
    }
};


export const Pie5050 = (props: IProps) => {
    const { attribute, attrsInTarget, attrsOOTarget, categoryName, inTargetColor, outTargetColor, status, target } = props;

    const config: PieConfig = {
        width: 200,
        height: 200,
        animation: false,
        data: [
            { targetName: attribute ? attribute : categoryName, value: status },
            { targetName: "Other", value: 100 - status },
        ],
        innerRadius: 0.5,
        angleField: "value",
        colorField: "targetName",
        color: (datum: Datum) => datum.targetName === "Other" ? outTargetColor : inTargetColor,
        tooltip: {
            formatter: (datum: Datum) => datum.targetName === "Other" ?
                {
                    name: attrsOOTarget ? attrsOOTarget.join(", ") : tooltipText(datum.targetName, categoryName),
                    value: `${Number(datum.value).toFixed(2)}%`
                }
                :
                {
                    name: attrsInTarget ? attrsInTarget.join(", ") : tooltipText(datum.targetName, categoryName),
                    value: `${Number(datum.value).toFixed(2)}%`
                }
        },
        legend: false,
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "x-large"
                },
                customHtml: () => Math.round(status).toString()
            },
        },
        label: false,
        annotations: target ? [{
            type: "line",
            end: [0, target] as AnnotationPosition,
            start: [1.2, target] as AnnotationPosition,
            text: {
                content: `${Math.round(target)}% `,
                autoRotate: false,
                style: { fontSize: 10 },
                offsetX: 1,
                offsetY: 5
            }
        }] : [],
        appendPadding: [15, 0]
    };
    return <Pie {...config} />;
};
