export type CsvExportItem = {
    [key: string]: string;
};

type GenerateCsvExportProps = {
    items: CsvExportItem[],
    headers?: string[]
};

export const generateCsvExport = ({ items, headers }: GenerateCsvExportProps) => {
    const content: string[][] = [];
    const titleKeys: string[] = headers ? headers : Object.keys(items[0]);

    content.push(titleKeys);
    items.forEach(item => {
        const attributes = headers ? titleKeys.map(titleKey => item[titleKey] || "") : Object.values(item);
        content.push(attributes);
    });

    let csvContent = "";
    content.forEach(row => {
        csvContent += (row.join(",") + "\n");
    });
    
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    return URL.createObjectURL(blob);
};
