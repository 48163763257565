import { gql } from "@apollo/client";

export const ADMIN_CREATE_SYSTEM_MESSAGE = gql`
  mutation AdminCreateSystemMessage($input: CreateSystemMessageInput!) {
    createSystemMessage(input: $input) {
      id
      message
      description
      type
    }
  }
`;
