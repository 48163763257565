define(function() {

  'use strict';

  function Playhead() {
    this.reset();
  }

  /**
   * Start
   */
  Playhead.prototype.start = function() {

    if (this._isPlaying === true) {
      return;
    }

    this._isPlaying = true;
    this._startTime = this._getCurrentTime();
  };

  /**
   * Stop and reset the timer.
   */
  Playhead.prototype.reset = function() {

    this._isPlaying = false;
    this._position = 0;
    this._startTime = 0;
  };

  /**
   * Simulate a pause in time.
   */
  Playhead.prototype.stop = function() {

    if (!this._isPlaying) {
      return;
    }

    this._isPlaying = false;
    this._position += this._getCurrentTime() - this._startTime;
    this._startTime = 0;
  };

  /**
   * @returns {Number} the current position
   */
  Playhead.prototype.getPosition = function() {

    var position = this._position;

    if (this._isPlaying === true) {
      position += this._getCurrentTime() - this._startTime;
    }

    return position;
  };

  /**
   * Get the current unix epoch time.
   *
   * @returns {Number} the time in milliseconds
   * @private
   */
  Playhead.prototype._getCurrentTime = function() {

    // Date.now not available in IE <= 8
    if (!Date.now) {
      Date.now = function() {
        return (new Date()).getTime();
      };
    }

    return Date.now();
  };

  return Playhead;
});
