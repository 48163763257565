import { DownloadOutlined, EditOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Alert, Button, Col, Divider, Form, FormProps, Input, Row, Table, TableColumnsType } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useCreateDivision } from "../../../hooks/programHooks";
import CustomHelmet from "../../../components/CustomHelmet";
import { AdminGetDivisions, AdminGetDivisions_divisions } from "../../../graphql/__generated__/AdminGetDivisions";
import { ADMIN_GET_DIVISIONS } from "../../../graphql/__queries__/AdminGetDivisions.gql";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";

const { Search } = Input;


type ExportDivision = {
    "Division": string;
    "Department": string;
    "Dataset": string;
};

type FormField = {
    division: string;
}

type MappedDivision = {
    id: string;
    name: string;
    numberOfDepartments: number;
    numberOfDatasets: number;
};


const useQueries = () => {
    const divisionsResponse = useQueryWithErrorHandling<AdminGetDivisions>(
        ADMIN_GET_DIVISIONS,
        "divisions",
        { fetchPolicy: "network-only" }
    );

    return {
        loading: divisionsResponse.loading,
        divisions: [...(divisionsResponse.data?.divisions || [])],
        refresh: () => divisionsResponse.refetch(),
    };
};

const applySearchFilter = (divisions: MappedDivision[], searchTerms: string[]) => (
    divisions?.filter(({ name }) => (
        searchTerms.every(term => name.toLocaleLowerCase().includes(term.toLocaleLowerCase()))
    )) || []
);

const convertDivisionsToCsv = (divisions: ExportDivision[]) => {
    const titleKeys = Object.keys(divisions[0]);
    const exportDivisions: string[][] = [];

    exportDivisions.push(titleKeys);
    divisions.forEach(division => {
        const attributes = Object.values(division).map(attr => `"${attr}"`);
        exportDivisions.push(attributes);
    });

    let csvContent = "";
    exportDivisions.forEach(row => {
        csvContent += (row.join(",") + "\n");
    });
    
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    return URL.createObjectURL(blob);
};

const getExportDivisions = (divisions: AdminGetDivisions_divisions[]): ExportDivision[] => (
    divisions.flatMap(division => {
        const exportDivision: ExportDivision = { "Division": division.name, "Department": "", "Dataset": "" };
        return division.departments && division.departments.length > 0 
            ? division.departments.flatMap(department => {
                const nestedExportDivision: ExportDivision = { ...exportDivision, "Department": department.name };
                return department.programs && department.programs.length > 0
                    ? department.programs.flatMap(program => program.datasets.map(dataset => (
                        { ...nestedExportDivision, "Dataset": dataset.name }
                    ))) : [nestedExportDivision];
            }) : [exportDivision];
    })
);

const mapDivisions = (divisions: AdminGetDivisions_divisions[]): MappedDivision[] => (
    divisions
        .map(division => (
            {
                id: division.id,
                name: division.name,
                numberOfDepartments: division.departments?.length || 0,
                numberOfDatasets: (division.departments || [])
                    .flatMap(department => (department.programs || []).flatMap(program => program.datasets.length))
                    .reduce((acc, curr) => acc + curr ,0)
            }
        ))
);

const getColumns = (): TableColumnsType<MappedDivision> => [
    {
        title: "Division Name",
        dataIndex: "name",
        key: "name",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.name.localeCompare(b.name),
        width: "30%",
    },
    {
        title: "Number of Departments",
        dataIndex: "numberOfDepartments",
        key: "departments",
        sorter: (a, b) =>  a.numberOfDepartments - b.numberOfDepartments,
    },
    {
        title: "Number of Datasets",
        dataIndex: "numberOfDatasets",
        key: "datasets",
        sorter: (a, b) =>  a.numberOfDatasets - b.numberOfDatasets,
    },
    {
        title: "Edit Division",
        key: "edit",
        render: (record: MappedDivision) => (
            <a href={`/admin/tags/divisions/${record.id}`}>
                <EditOutlined />
            </a>
        ),
    },
];


export const ManageDivisions = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [createNewDivisionForm] = Form.useForm<FormField>();

    const [searchTerms, setSearchTerms] = useState<string[]>([]);
    const [openCreateNewDivision, setOpenCreateNewDivision] = useState<boolean>(false);
    const [exportAllDivisionsUrl, setExportAllDivisionsUrl] = useState<string>("");

    const { divisions, loading, refresh } = useQueries();
    const createNewDivision = useCreateDivision();

    const filteredDivisions = useMemo(() => {
        const mappedDivisions = mapDivisions(divisions);
        return searchTerms.length === 0 ? mappedDivisions : applySearchFilter(mappedDivisions, searchTerms);
    }, [searchTerms, divisions]);

    const exportAllDivisions = () => {
        const exportDivisions = getExportDivisions(divisions);
        const url = convertDivisionsToCsv(exportDivisions);
        setExportAllDivisionsUrl(url);
    };

    const onFinish: FormProps<FormField>["onFinish"] = async (values: FormField): Promise<void> => {
        const success = await createNewDivision.run(values.division);

        if (success) {
            createNewDivisionForm.resetFields();
            setOpenCreateNewDivision(false);
            refresh();    
        }
    };

    return (
        <Row gutter={[16, 32]}>
            {/* Page Title */}
            <CustomHelmet title={t("admin.tags.divisions.index.title")} />

            {/* Page Header */}
            <Col span={24}>
                <PageHeader 
                    onBack={() => navigate("/admin/tags")}
                    title={t("admin.tags.divisions.index.title")}
                    // Export All Divisions
                    extra={[
                        <Button
                            title={t("admin.tags.divisions.index.actions.export")}
                            key="export-all-divisions"
                            type="primary"
                            shape="round"
                            disabled={loading || createNewDivision.inFlight}
                            download="all-divisions.csv"
                            href={exportAllDivisionsUrl}
                            icon={<DownloadOutlined />}
                            onClick={exportAllDivisions}
                        >
                            {t("admin.tags.divisions.index.actions.export")}
                        </Button>
                    ]}
                />
            </Col>

            {/* Error */}
            {
                !!createNewDivision.error && 
                <Col key="create-new-division-error" span={24}>
                    <Alert
                        closable
                        description={createNewDivision.error?.message}
                        message={t("admin.tags.divisions.index.actions.createDivision.error")}
                        showIcon
                        style={{ marginBottom: 16 }}
                        type="error"
                    />
                </Col>
            }

            {/* Create New Division */}
            <Col span={24}>
                <Form
                    form={createNewDivisionForm}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    {
                        openCreateNewDivision ?
                            <Row gutter={[16, 16]}>
                                <Col offset={1} span={6}>
                                    <Form.Item
                                        name="division"
                                        rules={[{ required: true, message: t("admin.tags.divisions.index.input.required") }]}
                                    >
                                        <Input
                                            disabled={loading || createNewDivision.inFlight}
                                            placeholder={t("admin.tags.divisions.index.input.placeholder")}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            loading={loading || createNewDivision.inFlight}
                                            title={t("admin.tags.divisions.index.actions.createDivision.submit")}
                                            type="primary"
                                        >
                                            {t("admin.tags.divisions.index.actions.createDivision.submit")}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row> :
                            <Row gutter={[16, 16]}>
                                <Col offset={1} span={6}>
                                    <Button
                                        loading={loading || createNewDivision.inFlight}
                                        onClick={() => setOpenCreateNewDivision(true)}
                                        title={t("admin.tags.divisions.index.actions.createDivision.title")}
                                        type="primary"
                                    >
                                        {t("admin.tags.divisions.index.actions.createDivision.title")}
                                    </Button>
                                </Col>
                            </Row>
                    }
                </Form>
            </Col>

            {/* View All Divisions */}
            <Col span={24}>
                <Row gutter={[16, 16]}>
                    <Divider orientation="left">{t("admin.tags.divisions.index.divider")}</Divider>
                    <Col span={6}>
                        <Search 
                            allowClear
                            disabled={loading || createNewDivision.inFlight}
                            onSearch={(input) => setSearchTerms(input.split(" "))} 
                            placeholder={t("admin.tags.divisions.index.actions.search")}
                        />
                    </Col>
                    <Col span={24}>
                        <Table 
                            dataSource={filteredDivisions}
                            columns={getColumns()}
                            loading={loading || createNewDivision.inFlight}
                            rowKey={division => division.id}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};