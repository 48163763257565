define(function() {
  'use strict';

  var nationsProducers = {
    // Permitted values for setting the Nations Producer Name
    WALES: 'wales',
    ENGLAND: 'england',
    SCOTLAND: 'scotland',
    NORTHERN_IRELAND: 'northern_ireland',
    NONE: '',

    getName: function(nationsProducer) {
      var name;
      if (nationsProducer !== undefined) {
        if (nationsProducer) {
          name = this[nationsProducer.toUpperCase()];
        } else {
          name = this.NONE;
        }
      }

      return name;
    }
  };

  return nationsProducers;
});
