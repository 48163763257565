import { gql } from "@apollo/client";

export const ADMIN_GET_DEPARTMENT = gql`
  query AdminGetDepartment($id: ID!) {
    department(id: $id) {
      id
      name
      division {
        id
        name
      }
      programs {
        id
        name
        datasets {
          id
          name
        }
      }
    }
  }
`;
