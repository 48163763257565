import { gql } from "@apollo/client";

export const ADMIN_GET_TAG = gql`
  query AdminGetTag($id: ID!) {
    tag(id: $id) {
      id
      name
      programs {
        id
        name
        datasets {
          id
          name
        }
      }
    }
  }
`;
