import { gql } from "@apollo/client";

export const GET_SYSTEM_MESSAGES = gql`
  query GetSystemMessages {
    systemMessages {
      id
      message
      description
      type
    }
  }
`;
