import { Theme } from "@nivo/core";


const DEFAULT_FONT_SIZE = 14;
const LEGEND_FONT_SIZE_LIMIT = 10;
const FONT_SIZE_LIMIT = 6;


export const getBarChartTheme = (fontSizeModification = 0): Theme => ({
    background: "#FFFFFF",
    axis: {
        domain: { 
            line: { 
                strokeWidth: "1px",
                stroke: "#777777",
            },
        },
        ticks: { 
            line: { 
                strokeWidth: "1.5px",
                stroke: "#777777",
            },
            text: {
                fontSize: (fontSizeModification / 4) + DEFAULT_FONT_SIZE,
            },
        },
        legend: { 
            text: { 
                fontSize: (fontSizeModification / 4) + DEFAULT_FONT_SIZE,
                fill: "#000000",
            },
        },
    },
    grid: {
        line: {
            stroke: "#DDDDDD",
            strokeWidth: "1px",
        },
    },
    legends: {
        text: {
            fontSize: DEFAULT_FONT_SIZE + fontSizeModification < LEGEND_FONT_SIZE_LIMIT
                ? LEGEND_FONT_SIZE_LIMIT : DEFAULT_FONT_SIZE + fontSizeModification,
            fill: "#000000",    
        },
    },
    labels: {
        text: {
            fontSize: DEFAULT_FONT_SIZE + fontSizeModification < FONT_SIZE_LIMIT
                ? FONT_SIZE_LIMIT : DEFAULT_FONT_SIZE + fontSizeModification,
            fill: "#000000",    
        },
    },
});