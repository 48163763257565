/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient, FetchResult, useApolloClient } from "@apollo/client";
import { message } from "antd";
import { LabeledValue } from "antd/lib/select";
import { Dayjs } from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { 
    AdminBulkEditReportingPeriods,
    AdminBulkEditReportingPeriodsVariables
} from "../graphql/__generated__/AdminBulkEditReportingPeriods";
import { AdminCreateContentType, AdminCreateContentTypeVariables } from "../graphql/__generated__/AdminCreateContentType";
import { AdminCreateDepartment, AdminCreateDepartmentVariables } from "../graphql/__generated__/AdminCreateDepartment";
import { AdminCreateDiversityCategory, AdminCreateDiversityCategoryVariables } from "../graphql/__generated__/AdminCreateDiversityCategory";
import { AdminCreateDivision, AdminCreateDivisionVariables } from "../graphql/__generated__/AdminCreateDivision";
import { AdminCreatePlatform, AdminCreatePlatformVariables } from "../graphql/__generated__/AdminCreatePlatform";
import { AdminCreateTag, AdminCreateTagVariables } from "../graphql/__generated__/AdminCreateTag";
import { AdminCreateUserRoles, AdminCreateUserRolesVariables } from "../graphql/__generated__/AdminCreateUserRoles";
import { AdminDeleteContentType, AdminDeleteContentTypeVariables } from "../graphql/__generated__/AdminDeleteContentType";
import { AdminDeleteDepartment, AdminDeleteDepartmentVariables } from "../graphql/__generated__/AdminDeleteDepartment";
import { AdminDeleteDiversityCategory, AdminDeleteDiversityCategoryVariables } from "../graphql/__generated__/AdminDeleteDiversityCategory";
import { AdminDeleteDivision, AdminDeleteDivisionVariables } from "../graphql/__generated__/AdminDeleteDivision";
import { AdminDeletePlatform, AdminDeletePlatformVariables } from "../graphql/__generated__/AdminDeletePlatform";
import {
    AdminDeleteProgram,
    AdminDeleteProgramVariables,
} from "../graphql/__generated__/AdminDeleteProgram";
import { AdminDeleteTag, AdminDeleteTagVariables } from "../graphql/__generated__/AdminDeleteTag";
import { AdminDeleteUserRoles, AdminDeleteUserRolesVariables } from "../graphql/__generated__/AdminDeleteUserRoles";
import { AdminRestoreDiversityCategory, AdminRestoreDiversityCategoryVariables } from "../graphql/__generated__/AdminRestoreDiversityCategory";
import {
    AdminRestoreProgram,
    AdminRestoreProgramVariables,
} from "../graphql/__generated__/AdminRestoreProgram";
import { AdminUpdateContentType, AdminUpdateContentTypeVariables } from "../graphql/__generated__/AdminUpdateContentType";
import { AdminUpdateDepartment, AdminUpdateDepartmentVariables } from "../graphql/__generated__/AdminUpdateDepartment";
import { AdminUpdateDiversityCategory, AdminUpdateDiversityCategoryVariables } from "../graphql/__generated__/AdminUpdateDiversityCategory";
import { AdminUpdateDivision, AdminUpdateDivisionVariables } from "../graphql/__generated__/AdminUpdateDivision";
import { AdminUpdatePlatform, AdminUpdatePlatformVariables } from "../graphql/__generated__/AdminUpdatePlatform";
import {
    AdminUpdateProgram,
    AdminUpdateProgramVariables,
} from "../graphql/__generated__/AdminUpdateProgram";
import { AdminUpdateTag, AdminUpdateTagVariables } from "../graphql/__generated__/AdminUpdateTag";
import { AdminUpdateUserRoles, AdminUpdateUserRolesVariables } from "../graphql/__generated__/AdminUpdateUserRoles";
import { 
    BulkEditReportingPeriodsInput,
    CreateCategoryInput, 
    CreateDepartmentInput,
    CreateUserRolesInput, 
    DeleteUserRolesInput, 
    ReportingPeriodType, 
    UpdateCategoryInput, 
    UpdateContentTypeInput, 
    UpdateDepartmentInput, 
    UpdateDivisionInput, 
    UpdatePlatformInput, 
    UpdateProgramInput, 
    UpdateTagInput, 
    UpdateUserRolesInput
} from "../graphql/__generated__/globalTypes";
import { ADMIN_BULK_EDIT_REPORTING_PERIODS } from "../graphql/__mutations__/AdminBulkEditReportingPeriods.gql";
import { ADMIN_CREATE_CONTENT_TYPE } from "../graphql/__mutations__/AdminCreateContentType.gql";
import { ADMIN_CREATE_DEPARTMENT } from "../graphql/__mutations__/AdminCreateDepartment.gql";
import { ADMIN_CREATE_DIVERSITY_CATEGORY } from "../graphql/__mutations__/AdminCreateDiversityCategory.gql";
import { ADMIN_CREATE_DIVISION } from "../graphql/__mutations__/AdminCreateDivision.gql";
import { ADMIN_CREATE_PLATFORM } from "../graphql/__mutations__/AdminCreatePlatform.gql";
import { ADMIN_CREATE_TAG } from "../graphql/__mutations__/AdminCreateTag.gql";
import { ADMIN_CREATE_USER_ROLES } from "../graphql/__mutations__/AdminCreateUserRoles.gql";
import { ADMIN_DELETE_CONTENT_TYPE } from "../graphql/__mutations__/AdminDeleteContentType.gql";
import { ADMIN_DELETE_DEPARTMENT } from "../graphql/__mutations__/AdminDeleteDepartment.gql";
import { ADMIN_DELETE_DIVERSITY_CATEGORY } from "../graphql/__mutations__/AdminDeleteDiversityCategory.gql";
import { ADMIN_DELETE_DIVISION } from "../graphql/__mutations__/AdminDeleteDivision.gql";
import { ADMIN_DELETE_PLATFORM } from "../graphql/__mutations__/AdminDeletePlatform.gql";
import { ADMIN_DELETE_PROGRAM } from "../graphql/__mutations__/AdminDeleteProgram.gql";
import { ADMIN_DELETE_TAG } from "../graphql/__mutations__/AdminDeleteTag.gql";
import { ADMIN_DELETE_USER_ROLES } from "../graphql/__mutations__/AdminDeleteUserRoles.gql";
import { ADMIN_RESTORE_DIVERSITY_CATEGORY } from "../graphql/__mutations__/AdminRestoreDiversityCategory.gql";
import { ADMIN_RESTORE_PROGRAM } from "../graphql/__mutations__/AdminRestoreProgram.gql";
import { ADMIN_UPDATE_CONTENT_TYPE } from "../graphql/__mutations__/AdminUpdateContentType.gql";
import { ADMIN_UPDATE_DEPARTMENT } from "../graphql/__mutations__/AdminUpdateDepartment.gql";
import { ADMIN_UPDATE_DIVERSITY_CATEGORY } from "../graphql/__mutations__/AdminUpdateDiversityCategory.gql";
import { ADMIN_UPDATE_DIVISION } from "../graphql/__mutations__/AdminUpdateDivision.gql";
import { ADMIN_UPDATE_PLATFORM } from "../graphql/__mutations__/AdminUpdatePlatform.gql";
import { ADMIN_UPDATE_PROGRAM } from "../graphql/__mutations__/AdminUpdateProgram.gql";
import { ADMIN_UPDATE_TAG } from "../graphql/__mutations__/AdminUpdateTag.gql";
import { ADMIN_UPDATE_USER_ROLES } from "../graphql/__mutations__/AdminUpdateUserRoles.gql";
import { GET_DATASET } from "../graphql/__queries__/GetDataset.gql";

/**
 * Form values filled out by the UI for editing datasetes.
 */
export type DatasetFormValues = Readonly<{
  id?: string;
  name: string;
  description: string | null;
  personTypes: string[];
  customColumns: string[] | undefined;
}>;

/*type customColumn = Readonly<{
  id?: string;
  name: string;
  type: CustomColumnType | null;
  description: string | null;
}>;*/

/**
 * Form values to represent a tag for a program.
 */
export type TagFormValues = Readonly<{
  name: string;
  tagType: string | null;
  id: string;
  description: string | null;
}>;

/**
 * Form values filled out by the UI for updating the program.
 */
export type ProgramUpdateFormValues = Readonly<{
  name: string;
  description: string;
  platforms: LabeledValue[] | undefined;
  contentTypes: LabeledValue[] | undefined;
  divisionAndDepartmentIds: string[] | null;
  teamId?: string;
  tags: LabeledValue[] | undefined;
  datasets: DatasetFormValues[];
  targets: Target[];
  reportingPeriodType: ReportingPeriodType,
  reportingPeriods: ReportingPeriod[]
}>;

export type ReportingPeriod = Readonly<{
  id: string;
  begin: Date;
  end: Date;
  range: [Dayjs, Dayjs];
  description: string | null;
}>

/**
 * Represent a single track in a target, like  "non-binary" in Gender.
 */
export type TargetTrack = Readonly<{
  id: string;
  categoryValue: CategoryValue;
  targetMember: boolean;
}>;

export type Category = Readonly<{
    id: string;
    name: string;
    description: string;
    displayName: string;
    priority: number | null;
}>

export type CategoryValue = Readonly<{
  id: string;
  name: string;
  category: { id: string }
}>

/**
 * Represent a target
 */
export type Target = Readonly<{
  id: string | null;
  category: Category;
  target: number;
  tracks: TargetTrack[];
}>;

/**
 * Type of an async function that accepts the apollo client and any number of
 * other parameters.
 */
export type HandlerFunction<
  A extends [ApolloClient<any>] = any,
  R extends FetchResult = FetchResult
> = (...args: A) => Promise<R>;

/**
 * Type of the "other" parameters that can be passed through to a handler
 * function when it's called.
 */
export type HandlerArgs<F extends HandlerFunction> = F extends (
  a: ApolloClient<any>,
  ...args: infer U
) => Promise<any>
  ? U
  : never;


/**
 * Higher-order hook factory for network operations.
 *
 * Generates a hook that runs the given handler and manages loading and error
 * handling. This is similar to just using the useMutation hook, but gives
 * more error handling and also lets us use a full function rather than just
 * the mutation itself.
 */
const getOpHook = <F extends HandlerFunction>(
    handler: F,
    successKeyPath: string
) => {
    return () => {
        const apolloClient = useApolloClient();
        const { t } = useTranslation();
        const [inFlight, setInFlight] = useState(false);
        const [error, setError] = useState<Error | null>(null);
        return {
            inFlight,
            error,
            /**
       * Function to run the operation and manage state around it. Returns a
       * boolean indicating whether operation succeeded without error.
       */
            run: async (...args: HandlerArgs<F>) => {
                setInFlight(true);
                setError(null);
                let success = false;

                try {
                    const result = await handler(apolloClient, ...args);
                    if (result.errors) {
                        result.errors.map((error) => {
                            console.error(error);
                        });
                        throw new Error("MUTATION_ERROR");
                    }

                    if (!result.data) {
                        throw new Error("MUTATION_ERROR_MISSING_DATA");
                    }

                    message.success(t(successKeyPath));
                    success = true;
                } catch (e: unknown) {
                    if (e instanceof Error) {
                        console.error(e);
                        setError(e);
                    }
                } finally {
                    setInFlight(false);
                }
                return success;
            },
        };
    };
};

/**
 * State and functions related to deactivating a program.
 */
export const useDeactivate = getOpHook(
    async (apolloClient: ApolloClient<any>, id: string) =>
        apolloClient.mutate<AdminDeleteProgram, AdminDeleteProgramVariables>({
            mutation: ADMIN_DELETE_PROGRAM,
            variables: { input: id },
        }),
    "admin.program.edit.deactivateSuccess"
);

/**
 * State and functions related to saving a program.
 */
export const useSave = getOpHook(
    async (apolloClient: ApolloClient<any>, input: UpdateProgramInput) => 
        apolloClient.mutate<AdminUpdateProgram, AdminUpdateProgramVariables>({
            mutation: ADMIN_UPDATE_PROGRAM,
            variables: { input },
            // Reload the dataset in case it's been loaded previously, otherwise the
            // changes won't be reflected if the admin navigates to the dataset page.
            refetchQueries: (result) =>
                result.data?.updateProgram.datasets.map((ds) => ({
                    query: GET_DATASET,
                    variables: { id: ds.id },
                })) || [],
        })
    ,
    "admin.program.edit.saveSuccess"
);

/**
 * State and functions related to restoring a deleted program.
 */
export const useRestore = getOpHook(
    async (apolloClient: ApolloClient<any>, id: string) =>
        apolloClient.mutate<AdminRestoreProgram, AdminRestoreProgramVariables>({
            mutation: ADMIN_RESTORE_PROGRAM,
            variables: {
                input: id,
            },
        }),
    "admin.program.edit.restoreSuccess"
);

/**
 * State and functions related to bulk editing reporting periods for a list of programs.
 */
export const useBulkEditReportingPeriods = getOpHook(
    async (apolloClient: ApolloClient<any>, input: BulkEditReportingPeriodsInput) =>
        apolloClient.mutate<AdminBulkEditReportingPeriods, AdminBulkEditReportingPeriodsVariables>({
            mutation: ADMIN_BULK_EDIT_REPORTING_PERIODS,
            variables: { input },
        }),
    "admin.program.edit.bulkEditSuccess"
);

/**
 * State and functions related to creating new user roles.
 */
export const useCreateUserRoles = getOpHook(
    async (apolloClient: ApolloClient<any>, input: CreateUserRolesInput) =>
        apolloClient.mutate<AdminCreateUserRoles, AdminCreateUserRolesVariables>({
            mutation: ADMIN_CREATE_USER_ROLES,
            variables: { input },
        }),
    "admin.program.edit.createUserRoleSuccess"
);

/**
 * State and functions related to updating user roles.
 */
export const useUpdateUserRoles = getOpHook(
    async (apolloClient: ApolloClient<any>, input: UpdateUserRolesInput) =>
        apolloClient.mutate<AdminUpdateUserRoles, AdminUpdateUserRolesVariables>({
            mutation: ADMIN_UPDATE_USER_ROLES,
            variables: { input },
        }),
    "admin.program.edit.updateUserRoleSuccess"
);

/**
 * State and functions related to deleting user roles.
 */
export const useDeleteUserRoles = getOpHook(
    async (apolloClient: ApolloClient<any>, input: DeleteUserRolesInput) =>
        apolloClient.mutate<AdminDeleteUserRoles, AdminDeleteUserRolesVariables>({
            mutation: ADMIN_DELETE_USER_ROLES,
            variables: { input },
        }),
    "admin.program.edit.deleteUserRoleSuccess"
);

/**
 * State and functions related to creating new diversity categories.
 */
export const useCreateDiversityCategory = getOpHook(
    async (apolloClient: ApolloClient<any>, input: CreateCategoryInput) =>
        apolloClient.mutate<AdminCreateDiversityCategory, AdminCreateDiversityCategoryVariables>({
            mutation: ADMIN_CREATE_DIVERSITY_CATEGORY,
            variables: { input },
        }),
    "Successfully created new diversity category"
);

/**
 * State and functions related to updating diversity categories.
 */
export const useUpdateDiversityCategory = getOpHook(
    async (apolloClient: ApolloClient<any>, input: UpdateCategoryInput) =>
        apolloClient.mutate<AdminUpdateDiversityCategory, AdminUpdateDiversityCategoryVariables>({
            mutation: ADMIN_UPDATE_DIVERSITY_CATEGORY,
            variables: { input },
        }),
    "Successfully updated diversity category"
);

/**
 * State and functions related to deleting diversity categories.
 */
export const useDeleteDiversityCategory = getOpHook(
    async (apolloClient: ApolloClient<any>, id: string) =>
        apolloClient.mutate<AdminDeleteDiversityCategory, AdminDeleteDiversityCategoryVariables>({
            mutation: ADMIN_DELETE_DIVERSITY_CATEGORY,
            variables: { id },
        }),
    "Successfully deleted diversity category"
);

/**
 * State and functions related to restoring diversity categories.
 */
export const useRestoreDiversityCategory = getOpHook(
    async (apolloClient: ApolloClient<any>, id: string) =>
        apolloClient.mutate<AdminRestoreDiversityCategory, AdminRestoreDiversityCategoryVariables>({
            mutation: ADMIN_RESTORE_DIVERSITY_CATEGORY,
            variables: { id },
        }),
    "Successfully restored diversity category"
);

/**
 * State and functions related to creating divisions.
 */
export const useCreateDivision = getOpHook(
    async (apolloClient: ApolloClient<any>, name: string) =>
        apolloClient.mutate<AdminCreateDivision, AdminCreateDivisionVariables>({
            mutation: ADMIN_CREATE_DIVISION,
            variables: { name },
        }),
    "admin.tags.divisions.index.actions.createDivision.success"
);

/**
 * State and functions related to updating a division.
 */
export const useUpdateDivision = getOpHook(
    async (apolloClient: ApolloClient<any>, input: UpdateDivisionInput) =>
        apolloClient.mutate<AdminUpdateDivision, AdminUpdateDivisionVariables>({
            mutation: ADMIN_UPDATE_DIVISION,
            variables: { input },
        }),
    "admin.tags.divisions.edit.actions.save.success"
);

/**
 * State and functions related to deleting a division.
 */
export const useDeleteDivision = getOpHook(
    async (apolloClient: ApolloClient<any>, id: string) =>
        apolloClient.mutate<AdminDeleteDivision, AdminDeleteDivisionVariables>({
            mutation: ADMIN_DELETE_DIVISION,
            variables: { id },
        }),
    "admin.tags.divisions.edit.actions.delete.success"
);

/**
 * State and functions related to creating departments.
 */
export const useCreateDepartment = getOpHook(
    async (apolloClient: ApolloClient<any>, input: CreateDepartmentInput) =>
        apolloClient.mutate<AdminCreateDepartment, AdminCreateDepartmentVariables>({
            mutation: ADMIN_CREATE_DEPARTMENT,
            variables: { input },
        }),
    "admin.tags.departments.index.actions.createDepartment.success"
);

/**
 * State and functions related to updating a department.
 */
export const useUpdateDepartment = getOpHook(
    async (apolloClient: ApolloClient<any>, input: UpdateDepartmentInput) =>
        apolloClient.mutate<AdminUpdateDepartment, AdminUpdateDepartmentVariables>({
            mutation: ADMIN_UPDATE_DEPARTMENT,
            variables: { input },
        }),
    "admin.tags.departments.edit.actions.save.success"
);

/**
 * State and functions related to deleting a department.
 */
export const useDeleteDepartment = getOpHook(
    async (apolloClient: ApolloClient<any>, id: string) =>
        apolloClient.mutate<AdminDeleteDepartment, AdminDeleteDepartmentVariables>({
            mutation: ADMIN_DELETE_DEPARTMENT,
            variables: { id },
        }),
    "admin.tags.departments.edit.actions.delete.success"
);

/**
 * State and functions related to creating platforms.
 */
export const useCreatePlatform = getOpHook(
    async (apolloClient: ApolloClient<any>, name: string) =>
        apolloClient.mutate<AdminCreatePlatform, AdminCreatePlatformVariables>({
            mutation: ADMIN_CREATE_PLATFORM,
            variables: { name },
        }),
    "admin.tags.platforms.index.actions.createPlatform.success"
);

/**
 * State and functions related to updating a platform.
 */
export const useUpdatePlatform = getOpHook(
    async (apolloClient: ApolloClient<any>, input: UpdatePlatformInput) =>
        apolloClient.mutate<AdminUpdatePlatform, AdminUpdatePlatformVariables>({
            mutation: ADMIN_UPDATE_PLATFORM,
            variables: { input },
        }),
    "admin.tags.platforms.edit.actions.save.success"
);

/**
 * State and functions related to deleting a platform.
 */
export const useDeletePlatform = getOpHook(
    async (apolloClient: ApolloClient<any>, id: string) =>
        apolloClient.mutate<AdminDeletePlatform, AdminDeletePlatformVariables>({
            mutation: ADMIN_DELETE_PLATFORM,
            variables: { id },
        }),
    "admin.tags.platforms.edit.actions.delete.success"
);

/**
 * State and functions related to creating content types.
 */
export const useCreateContentType = getOpHook(
    async (apolloClient: ApolloClient<any>, name: string) =>
        apolloClient.mutate<AdminCreateContentType, AdminCreateContentTypeVariables>({
            mutation: ADMIN_CREATE_CONTENT_TYPE,
            variables: { name },
        }),
    "admin.tags.contentTypes.index.actions.createContentType.success"
);

/**
 * State and functions related to updating a content type.
 */
export const useUpdateContentType = getOpHook(
    async (apolloClient: ApolloClient<any>, input: UpdateContentTypeInput) =>
        apolloClient.mutate<AdminUpdateContentType, AdminUpdateContentTypeVariables>({
            mutation: ADMIN_UPDATE_CONTENT_TYPE,
            variables: { input },
        }),
    "admin.tags.contentTypes.edit.actions.save.success"
);

/**
 * State and functions related to deleting a content type.
 */
export const useDeleteContentType = getOpHook(
    async (apolloClient: ApolloClient<any>, id: string) =>
        apolloClient.mutate<AdminDeleteContentType, AdminDeleteContentTypeVariables>({
            mutation: ADMIN_DELETE_CONTENT_TYPE,
            variables: { id },
        }),
    "admin.tags.contentTypes.edit.actions.delete.success"
);

/**
 * State and functions related to creating misellaneous tags.
 */
export const useCreateMiscellaneousTag = getOpHook(
    async (apolloClient: ApolloClient<any>, name: string) =>
        apolloClient.mutate<AdminCreateTag, AdminCreateTagVariables>({
            mutation: ADMIN_CREATE_TAG,
            variables: { name },
        }),
    "admin.tags.miscellaneousTags.index.actions.createMiscellaneousTag.success"
);

/**
 * State and functions related to updating a miscellaneous tag.
 */
export const useUpdateMiscellaneousTag = getOpHook(
    async (apolloClient: ApolloClient<any>, input: UpdateTagInput) =>
        apolloClient.mutate<AdminUpdateTag, AdminUpdateTagVariables>({
            mutation: ADMIN_UPDATE_TAG,
            variables: { input },
        }),
    "admin.tags.miscellaneousTags.edit.actions.save.success"
);

/**
 * State and functions related to deleting a miscellaneous tag.
 */
export const useDeleteMiscellaneousTag = getOpHook(
    async (apolloClient: ApolloClient<any>, id: string) =>
        apolloClient.mutate<AdminDeleteTag, AdminDeleteTagVariables>({
            mutation: ADMIN_DELETE_TAG,
            variables: { id },
        }),
    "admin.tags.miscellaneousTags.edit.actions.delete.success"
);