/** START BBC ADDITION **/
define( function(){
/** END BBC ADDITION **/

function SpringStreams(i) {
    this.namespace = "com.kantarmedia.sensors.javascript";
    this.version = "2.5.9";
    var y = "2cnt.net";
    var ah = "barb";
    var g = 200;
    var v = 2000;
    var ar = "default";
    var Z = new Array();
    this.namespace = "com.kma.springstreams";
    this.updateTimer = false;
    this.syncTimer = false;
    this.syncrate = 20;
    this.pausesync = 6;
    this.maxstates = 50;
    this.workerRunning = false;
    var f = true;
    var I;
    this.nextuid = "";
    var ak = {
        "+": "%2B",
        ",": "%2C",
        ";": "%3B",
        "=": "%3D",
        "~": "%7E",
        "*": "%2A",
        "'": "%27",
        "!": "%21",
        "(": "%28",
        ")": "%29"
    };
    var a = this;
    var al;
    var ab = true;
    P(w());
    if (i) {
        ar = i
    }
    var W = 'onmessage = function (evt) {if(evt.data == "start"){postMessage("open");if(!self.updateTimer)self.updateTimer = setInterval(()=>{postMessage("update")},200);if(!self.syncTimer)self.syncTimer = setInterval(()=>postMessage("sync"),2000);}if(evt.data == "stop"){clearInterval(self.updateTimer);clearInterval(self.syncTimer);postMessage("close");self.close();}};';
    var af = window.navigator.userAgent;
    var d = af.indexOf("MSIE ");
    var s = false;
    if (d > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        s = true
    }
    if (!window.URL || !window.Blob || !window.Worker || s) {
        ab = false
    }
    if (ab) {
        var ap = new Blob([W], {
            type: "text/javascript"
        });
        var n = URL.createObjectURL(ap)
    }

    function M() {
        I = new Worker(n);
        a.workerRunning = true;
        I.onmessage = function(m) {
            if (m.data == "open") {
                a.updateTimer = a.syncTimer = true
            }
            if (m.data == "sync") {
                A()
            }
            if (m.data == "update") {
                r()
            }
            if (m.data == "close") {
                a.workerRunning = false
            }
        };
        I.onerror = function(m) {
            a.workerRunning = false;
            I.postMessage("stop");
            console.error(m.message);
            an()
        }
    }
    if (ab) {
        try {
            M()
        } catch (ao) {
            a.workerRunning = false;
            ab = false
        }
    }

    function l(m) {
        console.debug(m)
    }

    function V(m) {
        console.warn(m)
    }

    function w() {
        var m = {
            "2cnt.net": {
                syncrates: [3, 7, 10, 10, 10, 10, 10, 60]
            }
        };
        return m
    }

    function P(at) {
        var au;
        if (at) {
            for (var m in at) {
                if (m === y && ah === "barb") {
                    au = at[m]
                }
            }
            if (au) {
                Q(au.syncrates)
            }
        }
    }

    function Q(m) {
        if (m) {
            al = m
        }
    }

    function aa() {
        return al
    }
    this.getSyncRates = aa;

    function D(aw, at, av) {
        if (ab) {
            H()
        } else {
            an()
        }
        var m = av;
        if (a.pageContext === undefined) {
            if (!!window.cordova) {
                if (device.platform === "browser") {
                    a.debug("Detected cordova browser-mode falling back to HTML5 context / adapter");
                    a.pageContext = a.defaultPageContext;
                    m = this.HTML5Adapter
                } else {
                    a.nlso = true;
                    a.debug("Detected cordova context");
                    a.pageContext = a.cordovaPageContext
                }
            } else {
                a.pageContext = a.defaultPageContext
            }
        } else {}
        if (a.pageContext.generateId !== undefined) {
            a.pageContext.generateId()
        }
        if (!aw) {
            return
        }
        if (typeof(a.pageContext) !== "undefined" && typeof(a.pageContext.initHooks) !== "undefined") {
            a.pageContext.initHooks()
        }
        var au = new E(this, aw, at, m);
        Z.push(au);
        return au
    }
    this.track = D;

    function aq(av, at, m, au) {
        if (au != this.nextuid) {
            return
        } else {
            return this.track(av, at, m)
        }
    }
    this.trackWithUID = aq;

    function q() {
        if ("" == this.nextuid || null == this.nextuid) {
            this.nextuid = X()
        }
        return this.nextuid
    }
    this.getNextUID = q;

    function X() {
        var m = Math.round((Math.random() * 10000000000)).toString(36);
        var at = new Date().getTime().toString(36);
        return at + m
    }

    function H() {
        if (!a.workerRunning) {
            M()
        }
        I.postMessage("start")
    }

    function an() {
        if (!a.updateTimer) {
            a.updateTimer = setInterval(r, g)
        }
        if (!a.syncTimer) {
            a.syncTimer = setInterval(A, v)
        }
    }

    function b() {
        this.nextuid = "";
        clearInterval(a.updateTimer);
        clearInterval(a.syncTimer);
        a.updateTimer = false;
        a.syncTimer = false;
        this.workerRunning = false;
        if (ab) {
            I.postMessage("stop")
        }
        for (var m = 0; m < Z.length; ++m) {
            try {
                Z[m].stop()
            } catch (at) {
                T(at)
            }
        }
    }
    this.unload = b;

    function r(au) {
        for (var m = 0; m < Z.length; ++m) {
            try {
                Z[m].update(au)
            } catch (at) {
                T(at)
            }
        }
    }
    this.doUpdate = r;

    function A(au) {
        for (var m = 0; m < Z.length; ++m) {
            try {
                Z[m].sync()
            } catch (at) {
                T(at)
            }
        }
    }
    this.doSync = A;

    function T(m) {
        U("", m)
    }
    this.error = T;

    function C(at, m) {
        return F([
            [at, m]
        ])
    }

    function Y(m) {
        if (m && m.site !== undefined) {
            return m.site
        }
        return ar
    }

    function J(m) {
        console.debug(m)
    }
    this.debug = J;

    function u(m) {
        console.warn(m)
    }
    this.warn = u;

    function t() {
        if (a.pageContext.getWindowLocationURL() === undefined) {
            return "http://"
        }
        if ("https" === a.pageContext.getWindowLocationURL().slice(0, 5)) {
            return "https://"
        } else {
            return "http://"
        }
    }

    function ad() {
        if (!a.nlso) {
            try {
                var m = "";
                var au = "i00";
                if (a.pageContext.getUniqueId !== undefined) {
                    au = y
                }
                m = a.pageContext.getLocalStorageItem(au);
                if (m) {
                    return "&c=" + m
                } else {
                    var at = "0000",
                        aw = at + Math.ceil((new Date()).getTime() / 1000).toString(16) + (32768 | Math.random() * 65535).toString(16) + at;
                    a.pageContext.setLocalStorageItem(au, aw)
                }
            } catch (av) {
                a.warn("error" + av)
            }
        }
        return ""
    }

    function U(au, at) {
        if (typeof(a.pageContext.tracking) != "undefined") {
            f = a.pageContext.tracking
        }
        if (f) {
            if (typeof(at.stream) == "undefined" || at.stream == "") {
                a.warn("Stream is mandatory for measurement")
            } else {
                var m;
                if (y === "") {
                    m = t() + Y(at) + "/j0=" + C(au, at) + "?lt=" + (new Date()).getTime().toString(36) + ad()
                } else {
                    m = t() + Y(at) + "." + y + "/j0=" + C(au, at) + "?lt=" + (new Date()).getTime().toString(36) + ad()
                }
                a.pageContext.preloadImage(m);
                a.debug(m)
            }
        }
    }
    this.send = U;

    function F(av) {
        var at;
        var aw;
        var au;
        var m;
        var ax = /[+&,;=~!*'()]/g;
        var ay;
        switch (typeof av) {
            case "string":
                return ax.test(av) ? encodeURIComponent(av).replace(ax, function(az) {
                    var aA = ak[az];
                    if (aA) {
                        return aA
                    }
                    return az
                }) : encodeURIComponent(av);
            case "number":
                return isFinite(av) ? av.toString() : "null";
            case "boolean":
            case "null":
                return av.toString();
            case "object":
                if (!av) {
                    return "null"
                }
                at = [];
                if (typeof av.length === "number" && !(av.propertyIsEnumerable("length"))) {
                    m = av.length;
                    for (aw = 0; aw < m; aw += 1) {
                        at.push(F(av[aw]) || "null")
                    }
                    return "," + at.join("+") + ";"
                }
                for (au in av) {
                    if (typeof au === "string") {
                        if (au !== "site") {
                            ay = F(av[au]);
                            if (ay) {
                                at.push(F(au) + "=" + ay)
                            }
                        }
                    }
                }
                return "," + at.join("+") + ";";
            default:
        }
        return ""
    }

    function E(aQ, aN, aO, aR) {
        function aP() {
            var aX = Math.round((Math.random() * 10000000000)).toString(36);
            var aY = new Date().getTime().toString(36);
            return aY + aX
        }
        var aK = "";
        aK = aQ.getNextUID();
        aQ.nextuid = null;
        if (aK == "") {
            aK = aP()
        }
        var ay = Math.round(new Date().getTime() / 1000);
        var aF;
        var aV;
        var aI;
        var aD;
        var az = [0, 0, ay.toString(36)];
        var aA = 0;
        var aW = 0;
        var aB = true;
        var av = false;
        var aJ;
        var ax;
        var au;
        var m;
        var aU = 0;
        aF = aQ;
        aV = aN;
        if (aR) {
            aJ = aR
        } else {
            aJ = aF.HTML5Adapter
        }
        aD = aL(aO);
        aI = new Array();
        aI.push(az);
        m = 0;
        ax = aQ.syncrate;
        au = aQ.getSyncRates();
        aH();

        function aS() {
            return aK
        }

        function aL(aZ) {
            var aX = new Object();
            for (var aY in aZ) {
                aX[aY] = aZ[aY]
            }
            return aX
        }

        function aT(aX) {
            if (aI.length < aF.maxstates) {
                aI.push(aX)
            }
        }

        function aw(aX) {
            az = [aX, aX, Math.round(new Date().getTime() / 1000).toString(36)];
            aT(az);
            aB = true;
            aA = 0
        }

        function aH() {
            if (au != null) {
                if (m < au.length) {
                    ax = au[m++];
                    aF.debug("switch syncrate to " + ax)
                }
            }
        }

        function aG(aX) {
            setTimeout(function() {
                if (aX == Math.round(aJ.getPosition(aV)) || aX + 1 == Math.round(aJ.getPosition(aV))) {
                    aU++;
                    if (aU >= 5) {
                        aU = 0;
                        if (aX - az[1] > 1 || az[1] - aX > 1) {
                            aw(aX)
                        }
                    }
                } else {
                    aU = 0
                }
            }, 200)
        }

        function aC(a0) {
            if (av) {
                return
            }
            var aX = az[1];
            var aY = aX;
            try {
                aY = Math.round(aJ.getPosition(aV))
            } catch (a1) {}
            try {
                if (aX === aY) {
                    if (aA >= 0) {
                        aA++;
                        if (aA === aF.pausesync) {
                            aB = true
                        }
                    }
                    return
                }
                if (aA >= aF.pausesync) {
                    aw(aY)
                } else {
                    if (aX < aY - 1 || aX > aY) {
                        aG(aY)
                    } else {
                        az[1] = aY;
                        if (az[1] - az[0] >= ax && aY - aW >= ax) {
                            aB = true;
                            aH()
                        }
                        aA = 0
                    }
                }
            } catch (aZ) {
                T = true;
                aF.error(aZ)
            }
        }
        this.update = aC;

        function aM() {
            if (av) {
                return
            }
            aB = true;
            av = true;
            aE(null)
        }
        this.stop = aM;

        function aE() {
            if (a.pageContext.getSerialNumber !== undefined) {
                a.pageContext.getSerialNumber(at)
            } else {
                at()
            }
        }
        this.sync = aE;

        function at() {
            if (aB) {
                try {
                    if (aV.width) {
                        aD.sx = aV.width
                    }
                    if (aV.videoWidth) {
                        aD.sx = aV.videoWidth
                    }
                    if (aV.height) {
                        aD.sy = aV.height
                    }
                    if (aV.videoHeight) {
                        aD.sy = aV.videoHeight
                    }
                } catch (aY) {}
                aD.uid = aK;
                aD.pst = aI;
                var aX;
                try {
                    if (!aD.dur || aD.dur === 0) {
                        aD.dur = aJ.getDuration(aV)
                    }
                } catch (aY) {}
                try {
                    aX = aJ.getMeta(aV);
                    if (a.pageContext.getDeviceID !== undefined && a.pageContext.getDeviceID() !== undefined) {
                        aX[a.pageContext.getDeviceID()["id_name"]] = a.pageContext.getDeviceID()["id_value"]
                    }
                } catch (aY) {}
                if (aX.v === undefined) {
                    aX.v = "js " + a.version
                }
                aD.vt = (Math.round(new Date().getTime() / 1000)) - ay;
                if (a.pageContext.getUniqueId !== undefined) {
                    aX.psid = a.pageContext.getUniqueId()
                }
                if (typeof(a.pageContext) !== "undefined" && typeof(a.pageContext.addElectronParameters) !== "undefined") {
                    a.pageContext.addElectronParameters(aX);
                    aX.v = "E " + a.version
                }
                if (typeof(a.pageContext) !== "undefined" && typeof a.pageContext.isCasting !== "undefined") {
                    a.pageContext.isCasting()
                }
                if (typeof(a.pageContext) !== "undefined" && typeof a.pageContext.getChromecastID !== "undefined") {
                    aX.v = "C " + a.version;
                    aX.cid = a.pageContext.getChromecastID()
                }
                aF.send(aX, aD);
                aW = az[1]
            }
            aB = false
        }
    }
    this.defaultPageContext = {
        getLocalStorageItem: k,
        setLocalStorageItem: c,
        preloadImage: N,
        getWindowLocationURL: e,
        initHooks: h
    };
    this.cordovaPageContext = {
        getLocalStorageItem: k,
        setLocalStorageItem: c,
        preloadImage: K,
        getWindowLocationURL: G,
        initHooks: ai
    };
    this.HTML5Adapter = {
        getMeta: O,
        getPosition: z,
        getDuration: x
    };
    this.DefaultAdapter = {
        getMeta: p,
        getPosition: ag,
        getDuration: aj
    };
    this.WMStreamAdapter = {
        getMeta: B,
        getPosition: o,
        getDuration: j
    };
    this.RVStreamAdapter = {
        getMeta: am,
        getPosition: ae,
        getDuration: ac
    };

    function O(m) {
        return {
            pl: "HTML 5 Video",
            plv: "HTML 5",
            sx: screen.width,
            sy: screen.height
        }
    }

    function x(m) {
        return Math.round(m.duration)
    }

    function z(m) {
        return m.currentTime
    }
    this.HTML5CordovaAdapter = {
        getMeta: S,
        getPosition: R,
        getDuration: L
    };

    function S(at) {
        var m = "CDV " + a.version;
        if (a.cordovaAppName === undefined) {
            a.cordovaAppName = "";
            cordova.getAppVersion.getAppName().then(function(au) {
                a.cordovaAppName = au
            })
        }
        if (a.cordovaAppVersion === undefined) {
            a.cordovaAppVersion = "";
            cordova.getAppVersion.getVersionNumber().then(function(au) {
                a.cordovaAppVersion = au
            })
        }
        if (device.platform == "Android") {
            a.cordovaGID = md5(device.uuid).substring(0, 16);
            if (a.cordovaAID === undefined) {
                a.cordovaAID = "";
                if (cordova.plugins.idfa !== undefined) {
                    cordova.plugins.idfa.getInfo().then(function(au) {
                        a.cordovaAID = md5(au.aaid).substring(0, 16);
                        a.cordovaLimitAdTracking = au.limitAdTracking
                    })
                }
            }
            return {
                pl: "HTML 5 Video",
                plv: "HTML 5",
                v: m,
                sx: screen.width,
                sy: screen.height,
                aid: a.cordovaAID,
                gid: a.cordovaGID,
                os: device.platform,
                osv: device.version,
                av: a.cordovaAppVersion,
                an: a.cordovaAppName,
                app: a.cordovaAppName
            }
        } else {
            if (device.platform == "iOS") {
                a.cordovaIFV = md5(device.uuid).substring(0, 16);
                return {
                    pl: "HTML 5 Video",
                    plv: "HTML 5",
                    v: m,
                    sx: screen.width,
                    sy: screen.height,
                    ifv: a.cordovaIFV,
                    os: device.platform,
                    osv: device.version,
                    av: a.cordovaAppVersion,
                    an: a.cordovaAppName,
                    app: a.cordovaAppName
                }
            } else {
                return {
                    pl: "HTML 5 Video",
                    plv: "HTML 5",
                    v: m,
                    sx: screen.width,
                    sy: screen.height,
                    os: device.platform,
                    osv: device.version,
                    an: a.cordovaAppName,
                    av: a.cordovaAppVersion
                }
            }
        }
    }

    function L(m) {
        return Math.round(m.duration)
    }

    function R(m) {
        return m.currentTime
    }

    function p(m) {
        return {
            pl: "DEF",
            plv: version,
            sx: screen.width,
            sy: screen.height
        }
    }

    function ag(m) {
        return new Date().getTime() / 1000
    }

    function aj(m) {
        return 0
    }

    function B(m) {
        return {
            pl: "MSWM",
            plv: m.versionInfo,
            sx: screen.width,
            sy: screen.height
        }
    }

    function o(m) {
        if (m.CurrentPosition) {
            return m.CurrentPosition
        }
        if (m.currentPosition) {
            return m.currentPosition
        }
        if (m.controls && m.controls.currentPosition) {
            return m.controls.currentPosition
        }
        if (m.Controls && m.Controls.currentPosition) {
            return m.Controls.currentPosition
        }
        return 0
    }

    function j(m) {
        if (m.currentMedia) {
            if (m.currentMedia.Duration) {
                return m.currentMedia.Duration
            }
            if (m.currentMedia.duration) {
                return m.currentMedia.duration
            }
        } else {
            if (m.CurrentMedia) {
                if (m.CurrentMedia.duration) {
                    return m.CurrentMedia.duration
                }
                if (m.CurrentMedia.Duration) {
                    return m.CurrentMedia.Duration
                }
            }
        }
    }

    function am(m) {
        return {
            pl: "RV",
            plv: m.GetVersionInfo(),
            sx: screen.width,
            sy: screen.height
        }
    }

    function ae(m) {
        return (m.GetPosition() / 1000)
    }

    function ac(m) {
        return (m.GetLength() / 1000)
    }

    function c(m, at) {
        localStorage.setItem(m, at)
    }

    function k(m) {
        return localStorage.getItem(m)
    }

    function N(m) {
        (new Image()).src = m
    }

    function h() {}

    function ai() {
        if (a.cordovaSetCloseHook == undefined) {
            a.cordovaSetCloseHook == true;
            document.addEventListener("pause", m, false);

            function m() {
                b()
            }
        }
    }

    function G() {
        return "https://"
    }

    function K(au) {
        var at = {
            method: "get"
        };
        var m = "Cordova <" + device.platform + " " + device.version + ">";
        cordova.plugin.http.setHeader("User-Agent", m);
        cordova.plugin.http.sendRequest(au, at, function(av) {
            a.debug("Request respopnse: " + av.status)
        }, function(av) {
            a.warn("Request respopnse: " + av.status + ":" + av.error)
        })
    }

    function e() {
        return document.location.href
    }
}
SpringStreams.prototype.setPageContext = function(a) {
    this.pageContext = a
};

function md5(D) {
    var E;
    var y = function(b, a) {
        return (b << a) | (b >>> (32 - a))
    };
    var I = function(k, b) {
        var W, a, d, x, c;
        d = (k & 2147483648);
        x = (b & 2147483648);
        W = (k & 1073741824);
        a = (b & 1073741824);
        c = (k & 1073741823) + (b & 1073741823);
        if (W & a) {
            return (c ^ 2147483648 ^ d ^ x)
        }
        if (W | a) {
            if (c & 1073741824) {
                return (c ^ 3221225472 ^ d ^ x)
            } else {
                return (c ^ 1073741824 ^ d ^ x)
            }
        } else {
            return (c ^ d ^ x)
        }
    };
    var r = function(a, c, b) {
        return (a & c) | ((~a) & b)
    };
    var q = function(a, c, b) {
        return (a & b) | (c & (~b))
    };
    var p = function(a, c, b) {
        return (a ^ c ^ b)
    };
    var n = function(a, c, b) {
        return (c ^ (a | (~b)))
    };
    var v = function(X, W, ab, aa, k, Y, Z) {
        X = I(X, I(I(r(W, ab, aa), k), Z));
        return I(y(X, Y), W)
    };
    var f = function(X, W, ab, aa, k, Y, Z) {
        X = I(X, I(I(q(W, ab, aa), k), Z));
        return I(y(X, Y), W)
    };
    var G = function(X, W, ab, aa, k, Y, Z) {
        X = I(X, I(I(p(W, ab, aa), k), Z));
        return I(y(X, Y), W)
    };
    var u = function(X, W, ab, aa, k, Y, Z) {
        X = I(X, I(I(n(W, ab, aa), k), Z));
        return I(y(X, Y), W)
    };
    var e = function(W) {
        var X;
        var d = W.length;
        var c = d + 8;
        var b = (c - (c % 64)) / 64;
        var x = (b + 1) * 16;
        var Y = new Array(x - 1);
        var a = 0;
        var k = 0;
        while (k < d) {
            X = (k - (k % 4)) / 4;
            a = (k % 4) * 8;
            Y[X] = (Y[X] | (W.charCodeAt(k) << a));
            k++
        }
        X = (k - (k % 4)) / 4;
        a = (k % 4) * 8;
        Y[X] = Y[X] | (128 << a);
        Y[x - 2] = d << 3;
        Y[x - 1] = d >>> 29;
        return Y
    };
    var s = function(d) {
        var a = "",
            b = "",
            k, c;
        for (c = 0; c <= 3; c++) {
            k = (d >>> (c * 8)) & 255;
            b = "0" + k.toString(16);
            a = a + b.substr(b.length - 2, 2)
        }
        return a
    };
    var t = function(d) {
        d = (d + "").replace(/\r\n/g, "\n").replace(/\r/g, "\n");
        var b = "";
        var X, a;
        var x = 0;
        X = a = 0;
        x = d.length;
        for (var W = 0; W < x; W++) {
            var k = d.charCodeAt(W);
            var c = null;
            if (k < 128) {
                a++
            } else {
                if ((k > 127) && (k < 2048)) {
                    c = String.fromCharCode((k >> 6) | 192) + String.fromCharCode((k & 63) | 128)
                } else {
                    c = String.fromCharCode((k >> 12) | 224) + String.fromCharCode(((k >> 6) & 63) | 128) + String.fromCharCode((k & 63) | 128)
                }
            }
            if (c != null) {
                if (a > X) {
                    b += d.substring(X, a)
                }
                b += c;
                X = a = W + 1
            }
        }
        if (a > X) {
            b += d.substring(X, d.length)
        }
        return b
    };
    var F = [],
        M, h, H, w, g, V, U, T, S, P = 7,
        N = 12,
        K = 17,
        J = 22,
        C = 5,
        B = 9,
        A = 14,
        z = 20,
        o = 4,
        m = 11,
        l = 16,
        j = 23,
        R = 6,
        Q = 10,
        O = 15,
        L = 21;
    D = t(D);
    F = e(D);
    V = 1732584193;
    U = 4023233417;
    T = 2562383102;
    S = 271733878;
    E = F.length;
    for (M = 0; M < E; M += 16) {
        h = V;
        H = U;
        w = T;
        g = S;
        V = v(V, U, T, S, F[M + 0], P, 3614090360);
        S = v(S, V, U, T, F[M + 1], N, 3905402710);
        T = v(T, S, V, U, F[M + 2], K, 606105819);
        U = v(U, T, S, V, F[M + 3], J, 3250441966);
        V = v(V, U, T, S, F[M + 4], P, 4118548399);
        S = v(S, V, U, T, F[M + 5], N, 1200080426);
        T = v(T, S, V, U, F[M + 6], K, 2821735955);
        U = v(U, T, S, V, F[M + 7], J, 4249261313);
        V = v(V, U, T, S, F[M + 8], P, 1770035416);
        S = v(S, V, U, T, F[M + 9], N, 2336552879);
        T = v(T, S, V, U, F[M + 10], K, 4294925233);
        U = v(U, T, S, V, F[M + 11], J, 2304563134);
        V = v(V, U, T, S, F[M + 12], P, 1804603682);
        S = v(S, V, U, T, F[M + 13], N, 4254626195);
        T = v(T, S, V, U, F[M + 14], K, 2792965006);
        U = v(U, T, S, V, F[M + 15], J, 1236535329);
        V = f(V, U, T, S, F[M + 1], C, 4129170786);
        S = f(S, V, U, T, F[M + 6], B, 3225465664);
        T = f(T, S, V, U, F[M + 11], A, 643717713);
        U = f(U, T, S, V, F[M + 0], z, 3921069994);
        V = f(V, U, T, S, F[M + 5], C, 3593408605);
        S = f(S, V, U, T, F[M + 10], B, 38016083);
        T = f(T, S, V, U, F[M + 15], A, 3634488961);
        U = f(U, T, S, V, F[M + 4], z, 3889429448);
        V = f(V, U, T, S, F[M + 9], C, 568446438);
        S = f(S, V, U, T, F[M + 14], B, 3275163606);
        T = f(T, S, V, U, F[M + 3], A, 4107603335);
        U = f(U, T, S, V, F[M + 8], z, 1163531501);
        V = f(V, U, T, S, F[M + 13], C, 2850285829);
        S = f(S, V, U, T, F[M + 2], B, 4243563512);
        T = f(T, S, V, U, F[M + 7], A, 1735328473);
        U = f(U, T, S, V, F[M + 12], z, 2368359562);
        V = G(V, U, T, S, F[M + 5], o, 4294588738);
        S = G(S, V, U, T, F[M + 8], m, 2272392833);
        T = G(T, S, V, U, F[M + 11], l, 1839030562);
        U = G(U, T, S, V, F[M + 14], j, 4259657740);
        V = G(V, U, T, S, F[M + 1], o, 2763975236);
        S = G(S, V, U, T, F[M + 4], m, 1272893353);
        T = G(T, S, V, U, F[M + 7], l, 4139469664);
        U = G(U, T, S, V, F[M + 10], j, 3200236656);
        V = G(V, U, T, S, F[M + 13], o, 681279174);
        S = G(S, V, U, T, F[M + 0], m, 3936430074);
        T = G(T, S, V, U, F[M + 3], l, 3572445317);
        U = G(U, T, S, V, F[M + 6], j, 76029189);
        V = G(V, U, T, S, F[M + 9], o, 3654602809);
        S = G(S, V, U, T, F[M + 12], m, 3873151461);
        T = G(T, S, V, U, F[M + 15], l, 530742520);
        U = G(U, T, S, V, F[M + 2], j, 3299628645);
        V = u(V, U, T, S, F[M + 0], R, 4096336452);
        S = u(S, V, U, T, F[M + 7], Q, 1126891415);
        T = u(T, S, V, U, F[M + 14], O, 2878612391);
        U = u(U, T, S, V, F[M + 5], L, 4237533241);
        V = u(V, U, T, S, F[M + 12], R, 1700485571);
        S = u(S, V, U, T, F[M + 3], Q, 2399980690);
        T = u(T, S, V, U, F[M + 10], O, 4293915773);
        U = u(U, T, S, V, F[M + 1], L, 2240044497);
        V = u(V, U, T, S, F[M + 8], R, 1873313359);
        S = u(S, V, U, T, F[M + 15], Q, 4264355552);
        T = u(T, S, V, U, F[M + 6], O, 2734768916);
        U = u(U, T, S, V, F[M + 13], L, 1309151649);
        V = u(V, U, T, S, F[M + 4], R, 4149444226);
        S = u(S, V, U, T, F[M + 11], Q, 3174756917);
        T = u(T, S, V, U, F[M + 2], O, 718787259);
        U = u(U, T, S, V, F[M + 9], L, 3951481745);
        V = I(V, h);
        U = I(U, H);
        T = I(T, w);
        S = I(S, g)
    }
    var i = s(V) + s(U) + s(T) + s(S);
    return i.toLowerCase()
};
/** START BBC ADDITION **/
return SpringStreams;
});
/** END BBC ADDITION **/
