define(function(require) {
  'use strict';

  var Enums = require('../../enumerations');
  var Utils = require('../../util/methods');

  function isValidManagedLabel(label, value) {
    // validate store labels
    switch (label) {
      case Enums.ManagedLabels.IPLAYER_STATE:
        if (value !== 'purchased' && value !== 'free') {
          return false;
        }

        break;

      case Enums.ManagedLabels.STORE_ED_TYPE:
        if (value !== 'archive' && value !== 'standard') {
          return false;
        }

        break;

      case Enums.ManagedLabels.IPLAYER_AVAIL:
        if (value !== 'avail' && value !== 'unavail' && value !== 'soon') {
          return false;
        }

        break;

      case Enums.ManagedLabels.STORE_REFERRAL:
        if (value !== 'iplayer' && value !== 'store') {
          return false;
        }

        break;

      case Enums.ManagedLabels.BBC_HASHED_ID:
        if (typeof value !== 'string' || Utils.trim(value).length === 0) {
          return false;
        }

        break;
    }

    return true;
  }

  return {
    isValidManagedLabel: isValidManagedLabel
  };
});
