define(function(require) {
  'use strict';

  var packageJson = require('../../../package.json');

  var LIBRARY_VERSION = packageJson.version;
  var LIRBARY_NAME = 'echo_js';

  var libraryInfo = {

    getLibraryName: function() {
      return LIRBARY_NAME;
    },

    getLibraryVersion: function() {
      return LIBRARY_VERSION;
    }
  };

  return libraryInfo;
});
