define([
  './playhead'
], function(Playhead) {

  'use strict';

  var UPDATE_INTERVAL = 1000;
  var POSITION_DRIFT_TOLERANCE = 1500;
  var CLOCK_JUMP_TOLERANCE = 1500;

  /**
   * @param {Object} playerDelegate
   * @constructor
   */
  function OnDemandBroker(playerDelegate, media, echoClient) {
    this._playerDelegate = playerDelegate;
    this._media = media;
    this._echoClient = echoClient;
    this._playhead = new Playhead();
    this._isStarted = false;
    this._interval = null;
    this._playerDelegatePosition = 0;
    this._playingTime = 0;
    this._currentIntervalMaxPosition = 0;
    this._hb3NotSentYet = true;
    this._hb5NotSentYet = true;
    this._previousPlayingTime = 0;
  }

  /**
   * Set the playhead.
   */
  OnDemandBroker.prototype.setPlayhead = function(playhead) {
    this._playhead = playhead;
  };

  /**
   * Start up the broker
   */
  OnDemandBroker.prototype.start = function() {

    var self = this;

    if (this._isStarted) {
      return;
    }

    this._interval = setInterval(function() {
      self._update();
    }, UPDATE_INTERVAL);

    this._isStarted = true;
    this._playhead.start();
  };

  /**
   * Stop the broker
   */
  OnDemandBroker.prototype.stop = function() {
    clearInterval(this._interval);
    this._playhead.stop();

    this._isStarted = false;
  };

  /**
   * Set the current position in ms.
   *
   * Should be called inside an EchoClient av Event that
   * accepts a position eg avPlay, avPause, avSeek etc.
   *
   * @param {Number} position
   */
  OnDemandBroker.prototype.setPosition = function(position) {
    if (
      (typeof position === 'number') &&
      position >= 0 &&
      !this.arePositionsWithinTolerance(this.getPosition(), position)
    ) {
      this._playerDelegatePosition = position;
      this._playingTime += this._playhead.getPosition();
      this._restartPlayhead();
      this._currentIntervalMaxPosition = position;
    }
  };

  /**
   * Get the maximum position sampled from the player delegate in
   * the current interval.
   *
   * @return {Number}
   */
  OnDemandBroker.prototype.getCurrentIntervalMaxPosition = function() {
    return this._currentIntervalMaxPosition;
  };

  /**
   * Check to see if the broadcast has changed based on the current player time.
   *
   * @private
   */
  OnDemandBroker.prototype._update = function() {
    var playerDelegatePosition = parseInt(this._playerDelegate.getPosition(), 10);
    var position = this.getPosition();
    var playingTime = this._playingTime + this._playhead.getPosition();
    var playingTimeDiff = Math.abs(playingTime - (this._previousPlayingTime || playingTime));
    this._previousPlayingTime = playingTime;

    if ((typeof playerDelegatePosition === 'number') &&
        playerDelegatePosition !== this._playerDelegatePosition &&
        playerDelegatePosition > 0
    ) {
      if (
        this._playerDelegatePosition === 0 ||
        !this.arePositionsWithinTolerance(
          playerDelegatePosition, position
        )
      ) {
        this._playerDelegatePosition = playerDelegatePosition;
        position = this._playerDelegatePosition;
        this._restartPlayhead();
      }
    }

    if (position > this._currentIntervalMaxPosition) {
      this._currentIntervalMaxPosition = position;
    }

    // send 3 second heartbeat
    if (playingTime >= 3000 && this._hb3NotSentYet && playingTimeDiff < CLOCK_JUMP_TOLERANCE) {
      this._echoClient.avUserActionEvent('echo_hb', 'echo_hb_3', position);
      this._hb3NotSentYet = false;
    }

    // send 5 second heartbeat
    if (playingTime >= 5000 && this._hb5NotSentYet && playingTimeDiff < CLOCK_JUMP_TOLERANCE) {
      this._echoClient.avUserActionEvent('echo_hb', 'echo_hb_5', position);
      this._hb5NotSentYet = false;
    }

    if (this._media.length > 0 && position >= this._media.length) {
      this._echoClient.avPauseEvent(this._media.length, {
        echo_pause_at_media_length: 1
      });
    }

  };

  /**
   * Convenience method for reseting and playing the playhead.
   *
   * @private
   */
  OnDemandBroker.prototype._restartPlayhead = function() {
    this._playhead.reset();
    this._playhead.start();
  };

  /**
   * Represents the true position a player is at within a piece of media.
   *
   * @returns {Number}
   */
  OnDemandBroker.prototype.getPosition = function() {
    var playheadPosition = this._playhead.getPosition();
    var position =  this._playerDelegatePosition + playheadPosition;
    return position;
  };

  /**
   * Represents the timestamp a player is at within a piece of media.
   *
   * @returns {Number}
   */
  OnDemandBroker.prototype.getTimestamp = function() {
    return 0;
  };

  /**
   * Are two positions within allowable tolerance
   *
   * @private
   */
  OnDemandBroker.prototype.arePositionsWithinTolerance = function(position1, position2) {
    var positionDelta = position1 - position2;
    return positionDelta >= -POSITION_DRIFT_TOLERANCE &&
      positionDelta <= POSITION_DRIFT_TOLERANCE;
  };

  return OnDemandBroker;

});
