import { BarChartOutlined, CloudDownloadOutlined, HomeOutlined, SettingOutlined, TeamOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Button, Layout, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./AppSidebar.css";
import { useAuth } from "../hooks/AuthProvider";
import { GetUser, GetUserVariables } from "../graphql/__generated__/getUser";
import { GET_USER } from "../graphql/__queries__/GetUser.gql";

const { Sider } = Layout;
const { SubMenu } = Menu;


interface AppSidebarProps {
    setCollapseState: (newState: boolean) => void
    collapsed: boolean
}


const AppSidebarMenu = () => {
    const { t } = useTranslation();
    const userId = useAuth().getUserId();
    const auth = useAuth();
    const { data } = useQuery<GetUser, GetUserVariables>(GET_USER, { variables: { id: userId } });
    
    const sidebarTeams = data?.user?.teams.flatMap(({ id, name, programs }) => (
        {
            key: id,
            name: name,
            programmes: programs.map(({ id, name, datasets }) => (
                {
                    key: id,
                    team: name,
                    datasets: datasets.map(({ id, name }) => (
                        { 
                            id, 
                            title: name 
                        }
                    )),
                }
            ))
        }
    ));

    return (
        <Menu
            mode="inline"
            theme="dark"
            style={{ height: "100%", borderRight: 0, paddingTop: "10px" }}
        >
            <Menu.Item key="home" icon={<HomeOutlined />} role="menuitem">
                <Link to="/">{t("admin.sidebar.home")}</Link>
            </Menu.Item>
            <SubMenu
                key="my-datasets"
                title="My Datasets"
                icon={<TeamOutlined />}
            >
                {
                    sidebarTeams?.flatMap((x) => x.programmes)
                        .flatMap(x => x.datasets)
                        .map((dataset) => (
                            <Menu.Item key={dataset.id}>
                                <Link to={{ pathname: `/dataset/${dataset.id}/details` }}>
                                    {dataset.title}
                                </Link>
                            </Menu.Item>
                        ))
                }
            </SubMenu>
            <Menu.Item key="reports" role="menuitem" icon={<BarChartOutlined />}>
                <Link to="/reports">{t("admin.sidebar.reports")}</Link>
            </Menu.Item>
            {
                auth.isAdmin() &&
                <SubMenu key="admin" title="Admin" icon={<SettingOutlined />}>
                    <Menu.Item key="users" role="menuitem">
                        <Link to="/admin/users">{t("admin.sidebar.userManagement")}</Link>
                    </Menu.Item>
                    <Menu.Item key="roles" role="menuitem">
                        <Link to="/admin/users/roles">{t("admin.sidebar.roleManagement")}</Link>
                    </Menu.Item>
                    <Menu.Item key="teams" role="menuitem">
                        <Link to="/admin/teams">{t("admin.sidebar.teamManagement")}</Link>
                    </Menu.Item>
                    <Menu.Item key="programs" role="menuitem">
                        <Link to="/admin/programs">{t("admin.sidebar.datasetManagement")}</Link>
                    </Menu.Item>
                    <Menu.Item key="tags" role="menuitem">
                        <Link to="/admin/tags">{t("admin.sidebar.tagManagement")}</Link>
                    </Menu.Item>
                    <Menu.Item key="diversity-categories" role="menuitem">
                        <Link to="/admin/diversity-categories">{t("admin.sidebar.diversityCategoryManagement")}</Link>
                    </Menu.Item>
                    <Menu.Item key="admin.performance" role="menuitem" icon={<BarChartOutlined />}>
                        <Link to="/admin/performance">{t("admin.sidebar.performance")}</Link>
                    </Menu.Item>
                    <Menu.Item key="admin.exports" role="menuitem" icon={<CloudDownloadOutlined />}>
                        <Link to="/admin/exports">{t("admin.sidebar.exports")}</Link>
                    </Menu.Item>
                </SubMenu>
            }
        </Menu>
    );
};


const AppSidebar = ({ setCollapseState, collapsed }: AppSidebarProps): JSX.Element => {
    return (
        <Sider
            collapsed={collapsed}
            width={300}
            className="sidebar"
            breakpoint="md"
            theme="dark"
            collapsible
            style={{
                overflow: "auto",
                height: "100vh",
                position: "fixed",
                left: 0,
                top: 0,
                bottom: 0,
            }}
            trigger={
                <Button
                    type="text"
                    style={{ color: "white", fontSize: "xx-large", width: "100%" }}
                    onClick={() => setCollapseState(!collapsed)}
                >
                    {collapsed ? <>&gt;</> : <>&lt;</>}
                </Button>
            }
        >
            <AppSidebarMenu />
        </Sider>
    );
};


export { AppSidebar };
