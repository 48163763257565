import dayjs from "dayjs";

import { IPublishedRecordSetDocument, isIPublishedTarget } from "./PublishedRecordSet";
import {
    AdminGetDatasetToExport_dataset,
    AdminGetDatasetToExport_dataset_program,
    AdminGetDatasetToExport_dataset_program_targets_category,
    AdminGetDatasetToExport_dataset_publishedRecordSets
} from "../../graphql/__generated__/AdminGetDatasetToExport";
import { CsvExportItem } from "../../utils/csvExport";
import { sortDiversityCategories } from "../../utils/sortDiversityCategories";


type MappedCsvContent = {
    titleKeys?: string[];
    mappedRows?: CsvExportItem[];
};


const mapReportingPeriodColumns = (
    publishedRecordSet: AdminGetDatasetToExport_dataset_publishedRecordSets,
    program: AdminGetDatasetToExport_dataset_program | null,
): CsvExportItem => {
    const reportingPeriod = (program?.reportingPeriods || [])
        .find(({id}) => id === publishedRecordSet.reportingPeriodId);
    const reportingPeriodStart = reportingPeriod ? dayjs(reportingPeriod?.range[0]).format("DD/MM/YYYY"): "";
    const reportingPeriodEnd = reportingPeriod ? dayjs(reportingPeriod?.range[1]).format("DD/MM/YYYY") : "";
    const reportingPeriodColumns: CsvExportItem = {
        "Reporting Period Start": reportingPeriodStart,
        "Reporting Period End": reportingPeriodEnd,
    };

    return reportingPeriodColumns;
};

const publishedRecordSetDocumentContainsTargetCategoryEntries = (
    publishedRecordSetDocument: unknown,
    targetCategory: string
): publishedRecordSetDocument is IPublishedRecordSetDocument => (
    !!(publishedRecordSetDocument as IPublishedRecordSetDocument).record && 
    !!(publishedRecordSetDocument as IPublishedRecordSetDocument).record["Everyone"] &&
    !!(publishedRecordSetDocument as IPublishedRecordSetDocument).record["Everyone"][targetCategory] &&
    !!(publishedRecordSetDocument as IPublishedRecordSetDocument).record["Everyone"][targetCategory].entries
);

const getCategoryAttributes = (
    categoryName: string,
    programCategories: AdminGetDatasetToExport_dataset_program_targets_category[],
): AdminGetDatasetToExport_dataset_program_targets_category | undefined => programCategories.find(category => category.name ===  categoryName);

const mapCategoryColumns = (
    publishedRecordSet: AdminGetDatasetToExport_dataset_publishedRecordSets,
    program: AdminGetDatasetToExport_dataset_program | null,
) => {
    const programCategories = (program?.targets || [])
        .map(({ category }) => category)
        .filter(category => category.deleted === null);

    const categoryColumns: CsvExportItem = {};
    publishedRecordSet.document.targets
        .forEach(target => {
            if (!isIPublishedTarget(target)) {
                return;
            }

            if (!programCategories.map(({ name }) => name).includes(target.category)) {
                return;
            }

            const { target: targetValue, category } = target;
            const categoryDisplayName = getCategoryAttributes(category, programCategories)?.displayName || category;
    
            categoryColumns[`${categoryDisplayName} Target`] = String(targetValue);
            categoryColumns[`${categoryDisplayName} Result`] =  "";

            if (publishedRecordSetDocumentContainsTargetCategoryEntries(publishedRecordSet.document, category)) {
                const entries = publishedRecordSet.document.record["Everyone"][category].entries;
                const result = Object.values(entries)
                    .filter(entry => entry.targetMember === true)
                    .map(({ percent }) => percent)
                    .reduce((sum, current) => sum + current, 0);
                const resultString = String(result);
                if (resultString !== "null") {
                    categoryColumns[`${categoryDisplayName} Result`] = resultString;
                }
            }
        });

    return categoryColumns;
};

export const mapCsvPublishedRecordSets = (dataset: AdminGetDatasetToExport_dataset | undefined): MappedCsvContent => {
    if (!dataset || !dataset.publishedRecordSets) {
        return {
            titleKeys: undefined,
            mappedRows: undefined,
        };
    }

    const { customColumns, publishedRecordSets, program, records } = dataset;

    const programCategories = (program?.targets || [])
        .map(({ category }) => category)
        .filter(category => category.deleted === null);

    const titleKeys: string[] = [];
    let titleKeysSpliceIndex = 2;
    const unsortedMappedRows = [...publishedRecordSets]
        .sort((outerA, outerB) => {
            const highestPriorityA = Math.min(
                ...(outerA).document.targets
                    .map(target => getCategoryAttributes(target.category, programCategories)?.priority || null)
                    .filter(priority => priority !== null)
            );
            const highestPriorityB = Math.min(
                ...(outerB).document.targets
                    .map(target => getCategoryAttributes(target.category, programCategories)?.priority || null)
                    .filter(priority => priority !== null)
            );

            return sortDiversityCategories(highestPriorityA, highestPriorityB);
        })
        .flatMap(publishedRecordSet => {
            const reportingPeriodColumns = mapReportingPeriodColumns(publishedRecordSet, program);
            const categoryColumns = mapCategoryColumns(publishedRecordSet, program);

            Object.keys({...reportingPeriodColumns, ...categoryColumns}).forEach(columnHeader => {
                if (!titleKeys.includes(columnHeader)) {
                    titleKeys.push(columnHeader);
                }
            });

            const unsortedMappedRows: CsvExportItem[] = customColumns && customColumns.length > 0
                ? records
                    .filter(record => dayjs(record.publicationDate)
                        .isBetween(dayjs(publishedRecordSet.begin), dayjs(publishedRecordSet.end), "day", "[]")
                    )
                    .map(record => {
                        const recordPublicationDate = dayjs(record.publicationDate).format("DD/MM/YYYY");
                        const customColumns = {};
                        record.customColumnValues?.forEach(customColumnValue => {
                            customColumns["Date"] = recordPublicationDate;
                            customColumns[customColumnValue.customColumn.name] = `"${customColumnValue.value}"`;
                        });

                        Object.keys(customColumns).forEach(columnHeader => {
                            if (!titleKeys.includes(columnHeader)) {
                                titleKeys.splice(titleKeysSpliceIndex, 0, columnHeader);
                            
                                if (titleKeysSpliceIndex === 2) {
                                    titleKeysSpliceIndex += 1;
                                }
                            }
                        });
        
                        return {
                            ...reportingPeriodColumns,
                            ...customColumns,
                            ...categoryColumns,
                        };
                    })
                : [{
                    ...reportingPeriodColumns,
                    ...categoryColumns,
                }];

            return unsortedMappedRows;
        });

    const mappedRows = unsortedMappedRows.sort((a, b) => {
        const aTime = dayjs(a["Reporting Period Start"], "DD/MM/YYYY").toDate().getTime();
        const bTime = dayjs(b["Reporting Period Start"], "DD/MM/YYYY").toDate().getTime();
        return aTime > bTime ? -1 : 1;
    });

    return {
        titleKeys,
        mappedRows,
    };
};
