import { 
    GithubOutlined,
    InfoCircleOutlined,
    PlusCircleOutlined,
    ProjectOutlined,
    QuestionCircleOutlined,
    TeamOutlined
} from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { ConfigKeys, Enums, EchoClient } from "@bbc/echo-client-js";
import { Alert, Button, Col, Divider, Empty, Form, Input, Layout, Popover, Radio, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { Suspense, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

import "./App.css";
import { useAuth } from "./hooks/AuthProvider";
import CustomHelmet from "./components/CustomHelmet";
import { ErrorBoundary } from "./components/Error/ErrorBoundary";
import { Loading } from "./components/Loading/Loading";
import { GetSystemMessages } from "./graphql/__generated__/GetSystemMessages";
import { ADMIN_CREATE_SYSTEM_MESSAGE } from "./graphql/__mutations__/AdminCreateSystemMessage.gql";
import { ADMIN_DELETE_SYSTEM_MESSAGE } from "./graphql/__mutations__/AdminDeleteSystemMessage.gql";
import { GET_SYSTEM_MESSAGES } from "./graphql/__queries__/GetSystemMessages.gql";
import AppHeader from "./layout/AppHeader";
import { AppSidebar } from "./layout/AppSidebar";
import { AddUsers } from "./pages/Admin/Users/AddUsers";
import { ManageDiversityCategories } from "./pages/Admin/Categories/ManageDiversityCategories";
import { BulkEditReportingPeriods } from "./pages/Admin/Datasets/BulkEditReportingPeriods";
import { EditProgram } from "./pages/Admin/Datasets/EditProgram";
import { ManageDatasets } from "./pages/Admin/Datasets/ManageDatasets";

import { Exports } from "./pages/Admin/Exports/Exports";
import { Performance } from "./pages/Admin/Performance/Performance";
import { ManageUserRoles } from "./pages/Admin/Roles/ManageUserRoles";
import { EditContentType } from "./pages/Admin/Tags/EditContentType";
import { EditDepartment } from "./pages/Admin/Tags/EditDepartment";
import { EditDivision } from "./pages/Admin/Tags/EditDivision";
import { EditMiscellaneousTag } from "./pages/Admin/Tags/EditMiscellaneousTag";
import { EditPlatform } from "./pages/Admin/Tags/EditPlatform";
import { ManageContentTypes } from "./pages/Admin/Tags/ManageContentTypes";
import { ManageDepartments } from "./pages/Admin/Tags/ManageDepartments";
import { ManageDivisions } from "./pages/Admin/Tags/ManageDivisions";
import { ManageMiscellaneousTags } from "./pages/Admin/Tags/ManageMiscellaneousTags";
import { ManagePlatforms } from "./pages/Admin/Tags/ManagePlatforms";
import { ManageTags } from "./pages/Admin/Tags/ManageTags";
import { EditTeam } from "./pages/Admin/Teams/EditTeam";
import { ManageTeams } from "./pages/Admin/Teams/ManageTeams";
import { EditUser } from "./pages/Admin/Users/EditUser";
import { ManageUsers } from "./pages/Admin/Users/ManageUsers";
import { DatasetDetails } from "./pages/DatasetDetails/DatasetDetails";
import { Home } from "./pages/Home/Home";
import { Login } from "./pages/Login/Login";
import { Reports } from "./pages/Reports/Reports";

const { Footer, Content } = Layout;


function Redirecter(props: { from: string }) {
    useEffect(() => {
        console.log(props.from);
        window.location.href = "/api/bbc-login";
    });

    return <React.Fragment />;
}

const NotFound = () => {
    const { t } = useTranslation();

    return (
        <div style={{ display: "inline-flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <Empty description={<span>{t("pageNotFound")}</span>} />
        </div>
    );
};

export const footerHeight = "48px";


/**
 * Top-level app layout.
 */
function App() {
    const [sidebarCollapsed, setSidebarCollapse] = useState(false);

    /**
     * Layout container for an authenticated user.
     */
    const ProtectedAppContainer: React.FC = ({ children }) => {
        const location = useLocation();
        const { t } = useTranslation();

        const auth = useAuth();
        const { 
            data: systemMessagesData, 
            loading: systemMessagesLoading, 
            refetch: refetchSystemMessages 
        } = useQuery<GetSystemMessages>(GET_SYSTEM_MESSAGES, { pollInterval: 60000 });
        const [createSystemMessage] = useMutation(ADMIN_CREATE_SYSTEM_MESSAGE);
        const [deleteSystemMessage] = useMutation(ADMIN_DELETE_SYSTEM_MESSAGE);

        const [newSystemMessageOpenState, setNewSystemMessageOpenState] = useState(false);

        return (
            <Layout hasSider>
                <AppSidebar setCollapseState={setSidebarCollapse} collapsed={sidebarCollapsed} />
                <Layout
                    style={{
                        background: "#fff",
                        marginLeft: sidebarCollapsed ? 80 : 300,
                        overflowY: "auto",
                        overflowX: "auto",
                        height: `calc(100% - ${footerHeight})`,
                        width: `calc(100% - ${sidebarCollapsed ? 80 : 300}px)`,
                    }}
                >
                    <AppHeader />
                    <Divider style={{ margin: 0, borderTop: "15px solid #f0f2f5" }} />
                    <Content
                        style={{
                            marginBottom: footerHeight,
                            padding: "6px 20px 20px 20px",
                            width: "100%"
                        }}
                    >
                        {
                            !systemMessagesLoading && systemMessagesData &&
                            <Row style={{ marginBottom: "8px" }} justify="center">
                                <Col span={24}>
                                    {
                                        systemMessagesData.systemMessages.map((x) =>
                                            <Alert
                                                action={
                                                    auth.isAdmin() && location.pathname === "/" &&
                                                        <Button
                                                            danger
                                                            onClick={() => {
                                                                deleteSystemMessage({ variables: { id: x.id } });
                                                                refetchSystemMessages();
                                                            }}
                                                            size="small"
                                                        >
                                                            Delete
                                                        </Button>
                                                }
                                                banner
                                                description={x.description}
                                                key={x.id}
                                                message={x.message}
                                                showIcon
                                                type={x.type as "info" | "error" | "warning" | "success"}
                                            />
                                        )
                                    }
                                </Col>
                            </Row>
                        }
                        {
                            location.pathname === "/" && 
                            auth.isAdmin() && 
                            !systemMessagesLoading && 
                            !systemMessagesData?.systemMessages.length &&
                                <Popover
                                    title="Create new Banner Message"
                                    content={
                                        <Form
                                            name="basic"
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 16 }}
                                            style={{ maxWidth: 600 }}
                                            initialValues={{ type: "info" }}
                                            onFinish={(values) => {
                                                createSystemMessage({
                                                    variables: {
                                                        input: {
                                                            message: values.message,
                                                            type: values.type,
                                                            description: values.description
                                                        }
                                                    }
                                                })
                                                    .then(() => {
                                                        setNewSystemMessageOpenState(false);
                                                        refetchSystemMessages();
                                                    });
                                            }}
                                            autoComplete="off"
                                        >
                                            <Form.Item
                                                label="Message"
                                                name="message"
                                                rules={[{ required: true, message: "A message is required" }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label="Description"
                                                name="description"
                                            >
                                                <TextArea rows={4} />
                                            </Form.Item>
                                            <Form.Item
                                                name="type"
                                                label="Type"
                                                wrapperCol={{ offset: 8, span: 16 }}
                                                rules={[{ required: true, message: "Type of message is required" }]}
                                                required
                                            >
                                                <Radio.Group>
                                                    <Radio value={"info"}>Info</Radio>
                                                    <Radio value={"success"}>Success</Radio>
                                                    <Radio value={"warning"}>Warning</Radio>
                                                    <Radio value={"error"}>Error</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                                <Button type="primary" htmlType="submit">
                                                    Publish
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    }
                                    trigger="click"
                                    open={newSystemMessageOpenState}
                                    onOpenChange={(visible) => setNewSystemMessageOpenState(visible)}
                                >
                                    <Button
                                        type="primary"
                                        icon={<PlusCircleOutlined />}
                                    >
                                        Create Banner Message
                                    </Button>
                                </Popover>
                        }
                        {
                            children
                        }
                    </Content>
                    <Footer
                        style={{
                            padding: "14px",
                            height: footerHeight,
                            textAlign: "left",
                            position: "fixed",
                            zIndex: 10,
                            bottom: 0,
                            boxShadow: "0.5em 0.5em 0.5em rgba(0,0,50,0.6)",
                            width: `calc(100% - ${sidebarCollapsed ? 80 : 300}px)`,
                            background: "#002140",
                            display: "flex",
                            alignItems: "center"

                        }}
                    >
                        <Button
                            type="link"
                            href="https://som-myit.onbmc.com/dwp/app/#/itemprofile/19509"
                            style={{
                                color: "white"
                            }}
                        >
                            <span style={{ fontFamily: "monospace", color: "#33FF33" }}>
                                &gt;_&nbsp;{t("footer")}
                            </span>
                        </Button>
                        <div style={{ flexGrow: 1 }} />
                        <Button
                            type="link"
                            href="https://50-50.esyscloud.io/Contributors/"
                            icon={<TeamOutlined />}
                            style={{ color: "white" }}
                        >
                            Contributors Database
                        </Button>

                        <Button
                            type="link"
                            href="https://staff.bbc.com/gateway/northern-ireland/documents/5050-tracker-user-guide-quick-start-09.pdf"
                            icon={<InfoCircleOutlined />}
                            style={{ color: "white" }}
                        >
                            {t("userGuide")}
                        </Button>
                        <Button
                            type="link"
                            href="https://confluence.dev.bbc.co.uk/display/NIPRODOPS/BBC+50%3A50+Portal"
                            icon={<ProjectOutlined />}
                            style={{ color: "white" }}
                        >
                            {t("project")}
                        </Button>
                        <Button
                            type="link"
                            href="https://github.com/BBCNI/bbc-50-50"
                            icon={<GithubOutlined />}
                            style={{ color: "white" }}
                        >
                            v1.3
                        </Button>
                        <Button
                            type="link"
                            href="https://som-myit.onbmc.com/dwp/rest/share/OJSXG33VOJRWKVDZOBST2U2CL5IFET2GJFGEKJTUMVXGC3TUJFSD2MBQGAYDAMBQGAYDAMBQGEYDCJTSMVZW65LSMNSUSZB5GE4TKMBZEZRW63TUMV4HIVDZOBST2Q2BKRAUYT2HL5EE6TKF"
                            icon={<QuestionCircleOutlined />}
                            style={{ color: "white" }}
                        >
                            Help
                        </Button>
                    </Footer>
                </Layout>
            </Layout >
        );
    };

    // Additional routes that only authed admins can visit.
    // When a user is logged in but lacks permission, they will see an error
    // telling them that they can't view the requested site.
    //
    // If a user is *not* logged in, they will be redirected to login screen.
    const WrappedPrivateAdmin = () => {
        const location = useLocation();
        const auth = useAuth();
        const { t } = useTranslation();

        if (!auth.isLoggedIn()) {
            if (process.env.NODE_ENV !== "production") {
                return <Navigate to="/login" state={{ from: location }} />;
            }

            return <Redirecter from={location.pathname} />;
        }

        return (
            <ProtectedAppContainer>
                <ErrorBoundary>
                    { auth.isAdmin() ? <Outlet /> : <div>{t("notAuthorized")}</div> }
                </ErrorBoundary>
            </ProtectedAppContainer>
        );
    };

    // Routes that a normal authed user can visit.
    // If the user is not logged in, they will be redirected to the login screen.
    const WrappedPrivate = () => {
        const location = useLocation();
        const auth = useAuth();

        if (!auth.isLoggedIn()) {
            if (process.env.NODE_ENV !== "production") {
                return <Navigate to="/login" state={{ from: location }} />;
            }

            return <Redirecter from={location.pathname} />;
        }
        return (
            <ProtectedAppContainer>
                <ErrorBoundary>
                    <Outlet />
                </ErrorBoundary>
            </ProtectedAppContainer>
        );
    };

    const AnalyticsWrapper: React.FC = ({ children }) => {
        const echo = useRef<EchoClient | undefined>();
        const location = useLocation();

        useEffect(() => {
            if (!echo.current) {
                const conf = {};
                conf[ConfigKeys.DESTINATION] = Enums.Destinations.GATEWAY_TEST;
                conf[ConfigKeys.PRODUCER] = Enums.Producers.NORTHERN_IRELAND;
                const echoClient = new EchoClient("5050", Enums.ApplicationType.WEB, conf, null, () => {
                    echoClient.viewEvent(`bbcni.5050.${location.pathname}.page`);
                    echo.current = echoClient;
                });
                return;
            }
            echo.current.viewEvent(`bbcni.5050.${location.pathname}.page`);
        }, [location]);

        return (
            <>
                {children}
            </>
        );
    };

    const createLoginRoute = () => {
        if (process.env.NODE_ENV !== "production") {
            return <Route path="/login" element={<Login />} />;
        }
    };

    return (
        <Suspense fallback={<Loading />}>
            <CustomHelmet />
            <BrowserRouter>
                <AnalyticsWrapper>
                    <Routes>
                        {createLoginRoute()}
                        <Route path="/" element={<WrappedPrivate />}>
                            <Route index element={<Home />} />
                            <Route path="dataset/:datasetId/details" element={<DatasetDetails />} />
                            <Route path="reports" element={<Reports />} />
                        </Route>
                        <Route path="/admin/" element={<WrappedPrivateAdmin />}>
                            <Route path="diversity-categories" element={<ManageDiversityCategories />} />
                            <Route path="exports" element={<Exports />} />
                            <Route path="performance" element={<Performance />} />
                            <Route path="programs" element={<ManageDatasets />} />
                            <Route path="programs/:programId" element={<EditProgram />} />
                            <Route path="programs/reporting-periods" element={<BulkEditReportingPeriods />} />
                            <Route path="tags" element={<ManageTags />} />
                            <Route path="tags/content-types" element={<ManageContentTypes />} />
                            <Route path="tags/content-types/:contentTypeId" element={<EditContentType />} />
                            <Route path="tags/departments" element={<ManageDepartments />} />
                            <Route path="tags/departments/:departmentId" element={<EditDepartment />} />
                            <Route path="tags/divisions" element={<ManageDivisions />} />
                            <Route path="tags/divisions/:divisionId" element={<EditDivision />} />
                            <Route path="tags/miscellaneous-tags" element={<ManageMiscellaneousTags />} />
                            <Route path="tags/miscellaneous-tags/:miscellaneousTagId" element={<EditMiscellaneousTag />} />
                            <Route path="tags/platforms" element={<ManagePlatforms />} />
                            <Route path="tags/platforms/:platformId" element={<EditPlatform />} />
                            <Route path="teams" element={<ManageTeams />} />
                            <Route path="teams/:teamId" element={<EditTeam />} />
                            <Route path="users" element={<ManageUsers />} />
                            <Route path="users/:userId" element={<EditUser />} />
                            <Route path="users/add" element={<AddUsers />} />
                            <Route path="users/roles" element={<ManageUserRoles />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </AnalyticsWrapper>
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
