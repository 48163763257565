export const createRandomPassword = (): string => {
    const sym =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!%,.^*()?".split(
            ""
        );
    let pw = "";
    for (let i = 0; i < 16; i++) {
        const rand = Math.floor(Math.random() * sym.length);
        pw += sym[rand];
    }
    return pw;
};

export const forceStringLowercase = (string: string) => string.trim().toLowerCase();

export const forceStringUppercase = (string: string) => string.trim().toUpperCase();

export const capitaliseFirstLetterOfEachWord = (string: string) => (
    string.split(" ").reduce((acc, val) => (acc + " " + val[0].toUpperCase() + val.slice(1)).trim(), "")
);