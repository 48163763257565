import { gql } from "@apollo/client";

export const ADMIN_CREATE_DIVISION = gql`
  mutation AdminCreateDivision($name: String!) {
    createDivision(name: $name) {
      id
      name
    }
  }
`;
