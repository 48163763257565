import { Spin } from "antd";
import "./Loading.css";

type LoadingProps = {
    tip?: string;
}

/**
 * Full-screen loading spinner.
 */
export const Loading = ({ tip = "Loading..." }: LoadingProps) => (
    <div
        id="loading-container"
        aria-hidden="false"
        aria-valuetext="Loading"
        aria-busy="true"
        role="progressbar"
        aria-label="Loading"
    >
        <Spin size="large" tip={tip} />
    </div>
);
