define(['./util/methods', './util/debug', './enumerations'], function(Utils, Debug, Enums) {

  'use strict';

  /**
   * Perform a lookup for the TV schedule for a specific service.
   *
   * @param {Media} media
   * @param {Environment} environment
   * @constructor
   */
  function Schedule(media, environment, echoClient) {

    this._schedule = [];
    this.serviceId = null;
    this._media = media;
    this._environment = environment;
    this._echoClient = echoClient;

    this._fetchData();
  }

  /**
   * Get the broadcast for the given timestamp.
   *
   * @param {Number} timestamp
   */
  Schedule.prototype.getBroadcast = function(timestamp) {

    var i;
    var item;

    for (i = 0; i < this._schedule.length; i++) {
      item = this._schedule[i].published_time;
      if ((timestamp < item.end) && (timestamp >= item.start)) {
        return this._schedule[i];
      }
    }
  };

  // static property to set the ESS host globally.
  Schedule.essHost = 'ess.api.bbci.co.uk';

  /**
   * @private
   */
  Schedule.prototype._fetchData = function() {

    var self = this;
    var executeCrossDomainGet;
    var protocol;
    executeCrossDomainGet = this._environment.getExecuteCrossDomainGet();
    protocol = this._environment.getProtocol();

    var url = protocol + Schedule.essHost.replace(/^(\/+)?/, '//') + '/schedules';

    if (!this._media) {
      return;
    }

    if (this._media.getServiceId()) {
      url += '?serviceId=' + encodeURIComponent(this._media.getServiceId());
      Debug.info('Get ServiceID: ' + url);
    } else if (this._media.getVersionId()) {
      url += '?versionId=' + encodeURIComponent(this._media.getVersionId());
      Debug.info('Get VersionID: ' + url);
    } else if (this._media.getVpId()) {
      Debug.info('Get VPid: ' + url);
      url += '?vpid=' + encodeURIComponent(this._media.getVpId());
    } else {
      Debug.info(
        'Unable to determine serviceId, versionId or VpId for schedule'
      );
      Debug.info(url);
      return;
    }

    executeCrossDomainGet(url, {
      onSuccess: function(data) {
        self._dataSuccessHandler(data);
      },
      onError: function(error, status) {
        var errorType = 'error';
        if (error === 'json') {
          errorType = 'json';
        } else if (typeof error === 'object' && error.type === 'timeout') {
          errorType = 'timeout';
        }

        self._dataErrorHandler(errorType, status);
      }
    });
  };

  /**
   * Indicates when the fetching of data has completed.
   *
   * @returns {Boolean}
   */
  Schedule.prototype.hasData = function() {
    return this._schedule.length > 0;
  };

  /**
   * @param {Object} data Json data string from ESS.
   * @private
   */
  Schedule.prototype._dataSuccessHandler = function(data) {

    var i;
    var items = [];
    var item;
    var start;
    var end;

    Debug.info('Cross-domain schedule request success.');

    if (data) {

      this._echoClient.setEssSuccess(true);

      this.serviceId = data.service.id;

      data.items = data.items || [];

      for (i = 0; i < data.items.length; i++) {
        start = data.items[i].published_time.start;
        end = data.items[i].published_time.end;

        item = data.items[i];
        item.published_time.start = (new Date(start)).getTime();
        item.published_time.end = (new Date(end)).getTime();
        items.push(item);
      }

      this._schedule = items;

    }
  };

  /**
   * @private
   */
  Schedule.prototype._dataErrorHandler = function(errorType, code) {
    this._schedule = [];
    Debug.warn('There was a problem requesting the schedule from ESS: ' + errorType);

    this._echoClient.setEssSuccess(false);

    if (errorType === 'json') {
      this._echoClient.setEssError(Enums.EssError.JSON, code);
    } else if (errorType === 'timeout') {
      this._echoClient.setEssError(Enums.EssError.TIMEOUT, code);
    } else if (errorType === 'error' && code !== undefined) {
      this._echoClient.setEssError(Enums.EssError.STATUS_CODE, code);
    } else {
      this._echoClient.setEssError(Enums.EssError.RUNTIME, '0');
    }
  };

  return Schedule;
});
