import { Dayjs } from "dayjs";
import { v4 as uuidv4 } from "uuid";

import { 
    describeNumericChange,
    filterReportsDataByFilterState, 
    getPercentTeamsOnTargetFromGroupedPublishedRecords, 
    getTotalNumberOfTeams, 
    groupPublishedRecords, 
    NO_DATA_TO_DISPLAY_SECTION, 
    ResultSummary,
    ResultSummaryData, 
    ResultSummarySection,
    ResultSummarySectionType,
    stringIsNotUndefined
} from "./index";
import { joinArrayStringItems } from "../../../utils/array";


const getFilterSelectionsAsText = (data: ResultSummaryData): string => {
    const filterSelections = [...data.filterState.divisions, ...data.filterState.miscTags];
    return joinArrayStringItems(filterSelections.map(({ name }) => name));
};

const addDivisionSection = (
    data: ResultSummaryData,
    date: Dayjs,
    filterSelectionsAsText: string,
    sections: ResultSummarySection[]
): void => {
    const { categories, reportsData, tz } = data;
    const { currentMonth, previousMonth } = reportsData;

    const totalNumberOfTeams = getTotalNumberOfTeams(currentMonth);
    const previousTotalNumberOfTeams = getTotalNumberOfTeams(previousMonth);

    const currentGroupedPublishedRecords = groupPublishedRecords(categories.map(({ name }) => name), currentMonth.publishedRecordSets, tz);
    const previousGroupedPublishedRecords = groupPublishedRecords(categories.map(({ name }) => name), previousMonth.publishedRecordSets, tz);

    sections.push({
        id: uuidv4(),
        type: ResultSummarySectionType.NORMAL,
        content: [
            {
                id: uuidv4(),
                lines: [{
                    id: uuidv4(),
                    text: `There are ${totalNumberOfTeams} team(s) in the whole of ${filterSelectionsAsText}. This is ${describeNumericChange(totalNumberOfTeams, previousTotalNumberOfTeams)} from ${previousTotalNumberOfTeams} in ${date.subtract(1, "month").format("MMMM")}.`,
                }],
            },
            {
                id: uuidv4(),
                lines: categories.map(category => {
                    const numberOfTeamsPublishing = currentGroupedPublishedRecords && category.name in currentGroupedPublishedRecords 
                        ? currentGroupedPublishedRecords[category.name].teams.size : 0;
                    const numberOfDatasetsPublishing = currentGroupedPublishedRecords && category.name in currentGroupedPublishedRecords 
                        ? currentGroupedPublishedRecords[category.name].datasets.size : 0;
                    const percentOfTeamsOnTarget = (
                        getPercentTeamsOnTargetFromGroupedPublishedRecords(currentGroupedPublishedRecords, category.name)
                    );
                    const percentOfTeamsOnTargetPreviousMonth = (
                        getPercentTeamsOnTargetFromGroupedPublishedRecords(previousGroupedPublishedRecords, category.name)
                    );

                    return {
                        id: uuidv4(),
                        text: `${numberOfTeamsPublishing} team(s) counted ${category.displayName} across ${numberOfDatasetsPublishing} dataset(s) - ${percentOfTeamsOnTarget}% reached their target, ${describeNumericChange(percentOfTeamsOnTarget, percentOfTeamsOnTargetPreviousMonth)} from ${percentOfTeamsOnTargetPreviousMonth}% in ${date.subtract(1, "month").format("MMMM")}.`    
                    };
                }),
            },
        ]
    });
};

const addAdminSection = (data: ResultSummaryData, date: Dayjs, sections: ResultSummarySection[]): void => {
    const { isAdmin, reportsData } = data;

    if (isAdmin) {
        const { publishedRecordSets, unpublishedDatasets, offAirDatasets } = reportsData.currentMonth;
        const publishedTeamIds = publishedRecordSets
            .map(published => published.dataset?.program?.team?.id)
            .filter(stringIsNotUndefined);
        const unpublishedTeamIds = unpublishedDatasets
            .map(unpublished => unpublished.dataset.program?.team?.id)
            .filter(stringIsNotUndefined);
        const offairDatasetIds = offAirDatasets
            .map(offair => offair.id)
            .filter(stringIsNotUndefined);

        const numberOfTeamsUnpublished = new Set(unpublishedTeamIds).size;
        const numberOfTeamsShouldPublish = new Set([...publishedTeamIds, ...unpublishedTeamIds]).size;
        const numberOfDatasetsOffAir = new Set(offairDatasetIds).size;

        sections.push({
            id: uuidv4(),
            type: ResultSummarySectionType.ADMIN,
            header: "For Administrators only:",
            content: [{
                id: uuidv4(),
                lines: [
                    {
                        id: uuidv4(),
                        text: `${numberOfTeamsUnpublished} of ${numberOfTeamsShouldPublish} team(s) who should have published their data didn't do so in ${date.format("MMMM")}.`,
                    },
                    {
                        id: uuidv4(),
                        text: `${numberOfDatasetsOffAir} dataset(s) were off air or not expected to file.`,
                    },
                ]
            }],
        });
    }
};

export default (data: ResultSummaryData, date: Dayjs): ResultSummary => {
    const overwriteData: ResultSummaryData = { 
        ...data, 
        filterState: { ...data.filterState, departments: [], programs: [], teams: [] }
    };
    const filteredData = filterReportsDataByFilterState(overwriteData);
    
    const filterSelectionsAsText = getFilterSelectionsAsText(filteredData);
    const title = `The 50:50 Project Division Results for ${date.format("MMMM YYYY")} - ${filterSelectionsAsText}`;

    const sections: ResultSummarySection[] = [];
    addDivisionSection(filteredData, date, filterSelectionsAsText, sections);
    addAdminSection(filteredData, date, sections);

    if (sections.length === 0) {
        sections.push(NO_DATA_TO_DISPLAY_SECTION);
    }

    return { title, sections };
};
