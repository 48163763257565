import { Dayjs } from "dayjs";
import { RangeValueType } from "rc-picker/lib/PickerInput/RangePicker";
import React from "react";

export type DateRange = RangeValueType<Dayjs> | null;

export interface IDatasetDetailsFilter {
  DateRange: DateRange;
  categories: string[];
  PublishedDateRange: PublishedDateRange
}

export enum PublishedDateRange {
  last3Periods = 3,
  last6Periods = 6
}

const DatasetDetailsFilterContext = React.createContext<
  IDatasetDetailsFilter | undefined | null
>(null);

export default DatasetDetailsFilterContext;
