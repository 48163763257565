import { gql } from "@apollo/client";

export const ADMIN_BULK_EDIT_REPORTING_PERIODS = gql`
  mutation AdminBulkEditReportingPeriods($input: BulkEditReportingPeriodsInput!) {
    bulkEditReportingPeriods(input: $input) {
      id
      name
      reportingPeriodType
      reportingPeriods {
        id
        description
        begin
        end
      }
    }
  }
`;
