import { gql } from "@apollo/client";

export const ADMIN_GET_CONTENT_TYPES = gql`
  query AdminGetContentTypes {
    contentTypes {
      id
      name
      programs {
        id
        name
        datasets {
          id
          name
        }
      }
    }
  }
`;
