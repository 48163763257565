import { EditOutlined, DeleteOutlined, QuestionCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { ApolloError } from "@apollo/client";
import { Alert, Button, Col, Divider, Form, Input, List, Modal, Popconfirm, Row, Select, Space, message } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import CustomHelmet from "../../../components/CustomHelmet";
import { Loading } from "../../../components/Loading/Loading";
import { AdminGetDepartment } from "../../../graphql/__generated__/AdminGetDepartment";
import { AdminGetDivisions, AdminGetDivisions_divisions } from "../../../graphql/__generated__/AdminGetDivisions";
import { ADMIN_GET_DEPARTMENT } from "../../../graphql/__queries__/AdminGetDepartment.gql";
import { ADMIN_GET_DIVISIONS } from "../../../graphql/__queries__/AdminGetDivisions.gql";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";
import { useDeleteDepartment, useUpdateDepartment } from "../../../hooks/programHooks";

const { Search } = Input;


type Entity = {
    id: string;
    name: string;
}

type Dataset = Entity & { 
    programId: string;
};

type Department = Entity & {
    datasets: Dataset[];
    division: Entity;
};

type EditDepartmentForm = {
    department: Department;
};

type QueryParams = {
    departmentId: string;
};

type QueryResult = EditDepartmentForm & {
    divisions: AdminGetDivisions_divisions[];
    error: ApolloError | undefined;
    loading: boolean;
    refresh: () => Promise<void>;
};


const useQueries = ({ departmentId }: QueryParams): QueryResult => {
    const {
        data: departmentData, error: departmentError, loading: departmentLoading, refetch: departmentRefetch
    } = useQueryWithErrorHandling<AdminGetDepartment>(
        ADMIN_GET_DEPARTMENT,
        "department",
        { variables: { id: departmentId }, fetchPolicy: "network-only" }
    );

    const {
        data: divisionsData, error: divisionsError, loading: divisionsLoading, refetch: divisionsRefetch
    } = useQueryWithErrorHandling<AdminGetDivisions>(
        ADMIN_GET_DIVISIONS,
        "divisions",
        { fetchPolicy: "network-only" }
    );

    const error = departmentError || divisionsError;
    const loading = departmentLoading || divisionsLoading;
    const refresh = async () => {
        await departmentRefetch();
        await divisionsRefetch();
    };

    const datasets = [...(departmentData?.department.programs || [])
        .flatMap(program => program.datasets.map(({ id, name }) => ({ id, name, programId: program.id })))
    ].sort((a, b) => a.name.localeCompare(b.name));

    const department = {
        id: departmentData?.department.id || departmentId,
        name: departmentData?.department.name || "",
        datasets,
        division: {
            id: departmentData?.department.division.id || "",
            name: departmentData?.department.division.name || "",
        }
    };

    const divisions = [...(divisionsData?.divisions || [])].sort((a, b) => a.name.localeCompare(b.name));

    return { department, divisions, error, loading, refresh };
};

const applySearchFilter = (datasets: Dataset[], searchTerms: string[]) => (
    datasets?.filter(({ name }) => (
        searchTerms.every(term => name.toLocaleLowerCase().includes(term.toLocaleLowerCase()))
    )) || []
);


export const EditDepartment = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { departmentId } = useParams() as QueryParams;
    const [editDepartmentForm] = Form.useForm<EditDepartmentForm>();

    const [searchTerms, setSearchTerms] = useState<string[]>([]);
    const [showUserGuideModal, setShowUserGuideModal] = useState<boolean>(false);

    const { department, divisions, error, loading, refresh } = useQueries({ departmentId });
    const updateDepartment = useUpdateDepartment();
    const deleteDepartment = useDeleteDepartment();

    const initialValues: EditDepartmentForm = useMemo(() => ({ department }), [department]);

    const filteredDatasets = useMemo(() => (
        searchTerms.length === 0 ? department.datasets : applySearchFilter(department.datasets, searchTerms)
    ), [department, searchTerms]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        throw error;
    }

    const onDelete = async () => {
        if (department.datasets && department.datasets.length > 0) {
            message.error(t("admin.tags.departments.edit.actions.delete.warning"));
            return;
        }
        await deleteDepartment.run(departmentId);
        navigate("/admin/tags/departments");
    };

    const onFinish = async (values: EditDepartmentForm) => {
        const departmentName = values.department.name;
        const divisionId = values.department.division.id;

        if (departmentName !== initialValues.department.name || divisionId !== initialValues.department.division.id) {
            await updateDepartment.run({ id: departmentId, name: departmentName, divisionId });
            refresh();    
        }
    };

    return (
        <Row gutter={[16, 16]}>
            {/* Page Title */}
            <CustomHelmet title={`${t("admin.tags.departments.edit.title")} - ${initialValues.department.name}`} />

            {/* Page Header */}
            <Col span={24}>
                <PageHeader
                    onBack={() => navigate("/admin/tags/departments")}
                    subTitle={initialValues.department.name}
                    title={t("admin.tags.departments.edit.title")}
                    extra={[
                        <Button
                            key="open-user-guide"
                            onClick={() => setShowUserGuideModal(true)}
                            icon={<QuestionCircleOutlined />}
                            title={t("admin.tags.divisions.edit.userGuide.title")}
                        >
                            {t("admin.tags.divisions.edit.userGuide.title")}
                        </Button>
                    ]}
                />
            </Col>

            {/* Errors */}
            {
                [
                    { action: "save", error: updateDepartment.error },
                    { action: "delete", error: deleteDepartment.error }
                ]
                    .filter(err => !!err.error)
                    .map(({ action, error }) => (
                        <Col key={action} span={24}>
                            <Alert
                                closable
                                description={error?.message}
                                message={t(`admin.tags.departments.edit.actions.${action}.error`)}
                                showIcon
                                style={{ marginBottom: 16 }}
                                type="error"
                            />
                        </Col>
                    ))
            }

            {/* User Guide Modal */}
            <Modal
                title={t("admin.tags.departments.edit.userGuide.title")}
                onCancel={() => setShowUserGuideModal(false)}
                open={showUserGuideModal}
                footer={[
                    <Button
                        key="close-user-guide"
                        onClick={() => setShowUserGuideModal(false)}
                        title={t("admin.tags.departments.edit.userGuide.close")}
                    >
                        {t("admin.tags.departments.edit.userGuide.close")}
                    </Button>
                ]}
            >
                <p><b>{t("admin.tags.departments.edit.userGuide.info.editDepartment.title")}</b></p>
                <p><em>{t("admin.tags.departments.edit.userGuide.info.editDepartment.description")}</em></p>
                <p><b>{t("admin.tags.departments.edit.userGuide.info.addDataset.title")}</b></p>
                <p><em>{t("admin.tags.departments.edit.userGuide.info.addDataset.description")}</em></p>
                <p><b>{t("admin.tags.departments.edit.userGuide.info.removeDataset.title")}</b></p>
                <p><em>{t("admin.tags.departments.edit.userGuide.info.removeDataset.description")}</em></p>
                <p><b>{t("admin.tags.departments.edit.userGuide.info.deleteDepartment.title")}</b></p>
                <p><em>{t("admin.tags.departments.edit.userGuide.info.deleteDepartment.description")}</em></p>
            </Modal>

            {/* Edit Department Form */}
            <Col span={24}>
                <Form
                    form={editDepartmentForm}
                    initialValues={initialValues}
                    onFinish={async (values) => await onFinish(values)}
                    scrollToFirstError
                >
                    <Row gutter={[16, 16]}>
                        <Col offset={1} span={10}>
                            {/* Edit Department Name */}
                            <Form.Item
                                label={t("admin.tags.departments.edit.inputs.department.label")}
                                labelAlign="right"
                                labelCol={{ span: 7 }}
                                name={["department", "name"]}
                                rules={[
                                    { required: true, message: t("admin.tags.departments.edit.inputs.department.required") }
                                ]}
                                validateTrigger="onBlur"
                            >
                                <Input
                                    disabled={updateDepartment.inFlight || deleteDepartment.inFlight}
                                    placeholder={t("admin.tags.departments.edit.inputs.department.placeholder")}
                                    title={t("admin.tags.departments.edit.inputs.department.label")}        
                                />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            {/* Assign Division */}
                            <Form.Item
                                label={t("admin.tags.departments.edit.inputs.division.label")}
                                labelAlign="right"
                                labelCol={{ span: 7 }}
                                name={["department", "division", "id"]}
                                rules={[
                                    { required: true, message: t("admin.tags.departments.edit.inputs.division.required") }
                                ]}
                                validateTrigger="onBlur"
                            >
                                <Select
                                    disabled={updateDepartment.inFlight || deleteDepartment.inFlight}
                                    filterOption={(input, option) => (option?.label ?? "")
                                        .toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={divisions.map(({ id, name }) => ({ label: name, value: id }))}
                                    placeholder={t("admin.tags.departments.edit.inputs.division.placeholder")}
                                    showSearch
                                    title={t("admin.tags.departments.edit.inputs.division.label")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col span={24}>
                        <Divider orientation="left">{t("admin.tags.departments.edit.divider")}</Divider>
                    </Col>

                    <Row gutter={[16, 32]}>
                        <Col offset={1} span={10}>
                            <Row align="middle" gutter={[16, 16]} justify="start">
                                <Col span={16}>
                                    <Search
                                        allowClear
                                        disabled={updateDepartment.inFlight || deleteDepartment.inFlight}
                                        onSearch={(input) => setSearchTerms(input.split(" "))} 
                                        placeholder={t("admin.tags.departments.edit.actions.search")}
                                        title={t("admin.tags.departments.edit.actions.search")}
                                    />
                                </Col>
                                <Col span={24}>
                                    <List
                                        bordered
                                        dataSource={filteredDatasets}
                                        itemLayout="horizontal"
                                        pagination={{ position: "bottom", align: "end" }}
                                        renderItem={(item) => (
                                            <List.Item
                                                actions={[
                                                    <a
                                                        href={`/admin/programs/${item.programId}`}
                                                        key={item.id}
                                                        title={t("admin.tags.departments.edit.actions.editDataset")}
                                                    >
                                                        {<EditOutlined />}
                                                    </a>                                            
                                                ]}
                                            >
                                                {item.name}
                                            </List.Item>
                                        )}
                                        rowKey={(item) => item.id}
                                        size="small"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col offset={4} span={16}>
                            <Row align="middle" gutter={[16, 16]} justify="space-evenly">
                                <Form.Item>
                                    <Space size={[64, 64]}>
                                        <Space>
                                            {/* Save Button */}
                                            <Button
                                                disabled={deleteDepartment.inFlight}
                                                htmlType="submit"
                                                icon={<SaveOutlined />}
                                                loading={updateDepartment.inFlight}
                                                title={t("admin.tags.departments.edit.actions.save.title")}
                                                type="primary"
                                            >
                                                {t("admin.tags.departments.edit.actions.save.title")}
                                            </Button>

                                            {/* Cancel Button */}
                                            <Button
                                                disabled={updateDepartment.inFlight || deleteDepartment.inFlight}
                                                onClick={() => navigate("/admin/tags/departments")}
                                                title={t("admin.tags.departments.edit.actions.cancel")}
                                            >
                                                {t("admin.tags.departments.edit.actions.cancel")}
                                            </Button>
                                        </Space>

                                        {/* Delete Button */}
                                        <Popconfirm
                                            cancelText={t("admin.tags.departments.edit.actions.delete.cancelText")}
                                            description={t("admin.tags.departments.edit.actions.delete.description")}
                                            disabled={
                                                initialValues.department.datasets.length > 0
                                                || updateDepartment.inFlight
                                                || deleteDepartment.inFlight
                                            }
                                            okText={t("admin.tags.departments.edit.actions.delete.confirmText")}
                                            onConfirm={async () => await onDelete()}
                                            title={t("admin.tags.departments.edit.actions.delete.title")}
                                        >
                                            <Space>
                                                <Button
                                                    danger
                                                    disabled={
                                                        initialValues.department.datasets.length > 0 || 
                                                        updateDepartment.inFlight
                                                    }
                                                    icon={<DeleteOutlined />}
                                                    loading={deleteDepartment.inFlight}
                                                    title={t("admin.tags.departments.edit.actions.delete.title")}
                                                >
                                                    {t("admin.tags.departments.edit.actions.delete.title")}
                                                </Button>
                                            </Space>
                                        </Popconfirm>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};