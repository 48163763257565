define(function(require) {
  'use strict';

  /**
   * Exports the EchoClient class, used for sending usage events
   * @exports Echo
   * @author James Skinner <james.skinner1@bbc.co.uk>
   * @example
  require(['echo'],function(Echo){
    var Media = Echo.Media,             // Media class
      EchoClient = Echo.EchoClient,   // Echo Client class
      Enums = Echo.Enums,             // Enums
      ConfigKeys = Echo.ConfigKeys,   // Key names to use in config
      Environment = Echo.Environment;

    // ** Enable Debug mode (for testing)
    Echo.Debug.enable();
  });

 */
  var meta = require('./echo/meta');

  var Environment = require('./echo/environment');

  var exports = {
    /** EchoClient class */
    EchoClient: require('./echo/client'),
    /** Media class */
    Media: require('./echo/media'),
    /** Media Id class */
    MediaId: require('./echo/media-id'),
    /** Environment class */
    Environment: Environment,
    /** Enumerations */
    Enums: require('./echo/enumerations'),
    /** Keys to use in config for EchoClient */
    ConfigKeys: require('./echo/config/keys'),
    /** Used to turn debugging on */
    Debug: require('./echo/util/debug'),

    getImplementationVersion: function() {
      return meta.VERSION;
    }
  };

  return exports;
});
