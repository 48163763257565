define(function() {
  'use strict';

  var ATILabelKeys = require('./label-keys');
  var LabelCleanser = require('../../util/cleansing/label-cleanser');
  var DEBUG = require('../../util/debug');

  var SEPARATOR_CHAR = '~';

  var EventDefaults = {
    EVENT_ACTION: 'unknown',
    CAMPAIGN_ID: 'Page',
    VARIANT: '',
    FORMAT: '',
    GENERAL_PLACEMENT: 'unknown',
    DETAILED_PLACEMENT: '',
    ADVERTISER_ID: '',
    URL: 'unknown'
  };

  var EventType = {
    CLICK: 'click',
    IMPRESSION: 'impression'
  };

  function UserActionEvent() {}

  UserActionEvent.sendClickEvent = function(tag, eventValues) {
    this._sendEvent(tag, eventValues, EventType.CLICK);
  };

  UserActionEvent.sendImpressionEvent = function(tag, eventValues) {
    this._sendEvent(tag, eventValues, EventType.IMPRESSION);
  };

  UserActionEvent._sendEvent = function(tag, eventValues, eventType) {
    if (!eventValues) {
      eventValues = {};
    }

    var eventPayload = {
      onsitead_creation:           this._formatEventValue(UserActionEvent._buildCreation(eventValues)),
      onsitead_campaign:         this._formatEventValue(eventValues[ATILabelKeys.CONTAINER] || EventDefaults.CAMPAIGN_ID),
      onsitead_variant:            this._formatEventValue(eventValues[ATILabelKeys.PERSONALISATION] || EventDefaults.VARIANT),
      onsitead_format:             this._formatEventValue(eventValues[ATILabelKeys.METADATA] || EventDefaults.FORMAT),
      onsitead_general_placement:   this._formatEventValue(eventValues[ATILabelKeys.BBC_COUNTER_NAME] || EventDefaults.GENERAL_PLACEMENT),
      onsitead_detailed_placement:  this._formatEventValue(eventValues.user || EventDefaults.DETAILED_PLACEMENT),
      onsitead_advertiser:       this._formatEventValue(eventValues[ATILabelKeys.SOURCE] || EventDefaults.ADVERTISER_ID),
      onsitead_url:                this._formatEventValue(eventValues[ATILabelKeys.RESULT] || EventDefaults.URL)
    };

    if (tag) {
      // If eventLabels have a (container, personalisation, metadata, source, or result) key value then it is a publisher event.
      // We are currently checking for Personalisation.
      var isPublisherEvent = (eventValues[ATILabelKeys.IS_BACKGROUND] !== undefined ||
        eventValues[ATILabelKeys.PERSONALISATION] !== undefined ||
        eventValues[ATILabelKeys.METADATA] !== undefined ||
        eventValues[ATILabelKeys.SOURCE] !== undefined ||
        eventValues[ATILabelKeys.RESULT] !== undefined);

      if (isPublisherEvent) {
        var publisherEventName = eventType === EventType.CLICK ? 'publisher.click' : 'publisher.display';
        tag.events.send(publisherEventName, eventPayload);
      } else {
        // - Custom events path
        if (eventValues[ATILabelKeys.EVENT_CATEGORY] !== undefined && eventValues[ATILabelKeys.EVENT_ACTION] !== undefined) {
          var eventName = eventValues[ATILabelKeys.EVENT_CATEGORY] + '.' + eventValues[ATILabelKeys.EVENT_ACTION];
          tag.events.send(eventName, eventValues);
        } else {
          DEBUG.warn('Event not sent, event_category and event_action are required.');
        }
      }

      // we can't use tag.dispatch() with publisher due to the keepalive event setting the old page params back on the tag
      // and this causing an issue with the user action event when it dispatches
      // instead we need to manually remove any properties that were passed to the action event
      for (var key in eventValues) {
        tag.delParam(key);
      }
    }
  };

  UserActionEvent._buildCreation = function(eventValues) {
    var creation = (eventValues && eventValues[ATILabelKeys.EVENT_ACTION]) || EventDefaults.EVENT_ACTION;
    if (eventValues && eventValues[ATILabelKeys.EVENT_CATEGORY]) {
      creation += (SEPARATOR_CHAR + eventValues[ATILabelKeys.EVENT_CATEGORY]);
    }

    return creation;
  };

  UserActionEvent._formatEventValue = function(value) {
    return LabelCleanser.cleanCustomVariable(value);
  };

  return UserActionEvent;
});
