import { PictureOutlined } from "@ant-design/icons";
import { PieCustomLayerProps, PieTooltipProps, ResponsivePie } from "@nivo/pie";
import { BasicTooltip } from "@nivo/tooltip";
import { Button, Col, Row } from "antd";
import { Dayjs } from "dayjs";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import "./charts.css";
import { FilterState } from "../Reports";
import { GetAllCategories_categories } from "../../../graphql/__generated__/GetAllCategories";
import { ICategoryGroup } from "../../../selectors/ChartData";
import { joinArrayStringItems } from "../../../utils/array";
import { createExportFilename, downloadSvgAsImage, ImageFormat } from "../../../utils/imageExport";


enum InOutTarget {
    IN_TARGET,
    OUT_OF_TARGET
}

type CircleXYCoords = {
    x: number;
    y: number;
}

type ImageDescriptionProps = TopDescription & {
    filterState: FilterState;
    pieChartData: PieChartDatum[];
}

type OverallPercentagePieChartProps = {
    category: GetAllCategories_categories;
    data: Record<string, ICategoryGroup>;
    filterState: FilterState;
}

type PieChartDataProps = OverallPercentagePieChartProps & SectionDescriptionParts & {
    containerId: string;
};

type PieChartDatum = PieChartDatumProps & {
    id: string;
    color: string;
    label: string;
    textColor: string;
    goal?: number;
};

type PieChartDatumProps = PieChartDataProps & {
    isTarget: boolean;
    value: number;
}

type SectionDescriptionParts = {
    topDescription: TopDescription;
    filterDescription: string;
}

type TopDescription = {
    categoryPrefix: string;
    mainDescription: string;
    dateDescription: string;
    datasetsDescription: string;
}


const DEGREES_PER_PERCENT = 360 / 100;
const HEIGHT_PER_TEXT_LINE = 20;
const IMAGE_FORMATS = [ImageFormat.PNG, ImageFormat.JPEG];
const PERCENTAGE_THRESHOLD = 6.5;
const WIDTH_PER_CHAR = 9;


const isDateSelectionSingleMonth = (begin: Dayjs, end: Dayjs): boolean => (
    begin.month() === end.month() && begin.year() === end.year()
);

const getDateDescription = (begin: Dayjs, end: Dayjs): string => {
    const format = "MMMM YYYY";
    const isSingleMonth = isDateSelectionSingleMonth(begin, end);
    return isSingleMonth ? begin.format(format) : `${begin.format(format)} - ${end.format(format)}`;
};

const getShortFilterDescription = (filterState: FilterState): string => {
    const { divisions, departments, miscTags, programs } = filterState;

    const filters = [
        ...divisions.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...departments.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...miscTags.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...programs.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
    ].filter(name => !!name);

    const filterStringBase = filters.length > 0 ? filters[0] : "the whole BBC";
    const filterString = filters.length > 1 ? `${filterStringBase} and ${filters.length - 1} other(s)` : filterStringBase;

    return filterString;
};

const getFilterDescription = (filterState: FilterState): string => {
    const { categories, contentTypes, departments, divisions, miscTags, platforms, programs, teams } = filterState;

    const filters = [
        ...divisions.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...departments.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...miscTags.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...programs.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...categories.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...contentTypes.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...platforms.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...teams.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
    ].filter(name => !!name);

    return filters.length === 0 ? "Datasets: BBC Overall" : `Datasets: ${joinArrayStringItems(filters)}.`;
};

const getSectionDescriptionParts = (
    { category, data, filterState }: OverallPercentagePieChartProps
): SectionDescriptionParts => ({
    topDescription: {
        categoryPrefix: category.displayName,
        mainDescription: "Combined overall percentage of contributors",
        dateDescription: getDateDescription(filterState.range.begin, filterState.range.end),
        datasetsDescription: category.name in data 
            ? `${data[category.name].datasets.size} datasets (${data[category.name].teams.size} teams) who published` : "",
    },
    filterDescription: getFilterDescription(filterState),
});

const getPieChartDatum = (props: PieChartDatumProps): PieChartDatum => {
    const { category, isTarget, value } = props;

    const { name, inTargetColor, outTargetColor, targets } = category;
    
    const defaultId = isTarget ? "In Target" : "Out of Target";
    const color = isTarget ? inTargetColor : outTargetColor;
    const textColor = isTarget ? "#FFFFFF": "#000000";

    switch (name) {
    case "Gender": {
        const id = isTarget ? "Women": "Men, non-binary";
        const label = value >= PERCENTAGE_THRESHOLD ? id : `${id}; ${Math.round(value)}%`;
        return { id, color, label, textColor, goal: 50, ...props };
    }
    case "Ethnicity": {
        const id = isTarget ? "B.A.M.E." : "White";
        const label = value >= PERCENTAGE_THRESHOLD ? id : `${id}; ${Math.round(value)}%`;
        return { id, color, label, textColor, goal: 20, ...props };
    }
    case "Disability": {
        const id = isTarget ? "Disabled" : "Non-disabled";
        const label = value >= PERCENTAGE_THRESHOLD ? id : `${id}; ${Math.round(value)}%`;
        return { id, color, label, textColor, goal: 12, ...props };
    }
    default: {
        const categoryValues = new Set<string>();
        (targets || []).forEach(({ tracks }) => {
            const filtered = tracks.filter(({ targetMember }) => targetMember === isTarget);
            filtered.forEach(({ categoryValue }) => categoryValues.add(categoryValue.name));
        });
        const id = categoryValues.size > 0 ? joinArrayStringItems([...categoryValues]) : defaultId;
        const label = value >= PERCENTAGE_THRESHOLD ? id : `${id}; ${Math.round(value)}%`;
        return { id, color, label, textColor, ...props };
    }
    }
};

const getPieChartData = (props: PieChartDataProps): PieChartDatum[] => {
    const { category, data } = props;

    const pieChartData: PieChartDatum[] = [];
    if (category.name in data) {
        [InOutTarget.IN_TARGET, InOutTarget.OUT_OF_TARGET].forEach(type => {
            const isTarget = type === InOutTarget.IN_TARGET;
            const value = isTarget ? data[category.name].percent : 100 - data[category.name].percent;

            const pieChartDatum = getPieChartDatum({ ...props , isTarget, value });
            
            pieChartData.push(pieChartDatum);
        });
    }
    
    return pieChartData;
};

const calculcateCircleXYCoords = (
    radius: number, theta: number, adjustX: number, adjustY: number
): CircleXYCoords => {
    const adjustedTheta = (theta - 90) * (Math.PI / 180);

    return {
        x: radius * Math.cos(adjustedTheta) + adjustX,
        y: (radius * Math.sin(adjustedTheta)) + adjustY,
    };
};

const splitTextByMaxLength = (text: string, maxLength: number): string[] => {
    const words = text.split(" ");

    const lines: string[] = [];
    let currentString = "";
    for (const word of words) {
        if (currentString.length + 1 + word.length > maxLength) {
            lines.push(currentString);
            currentString = word;
        } else {
            currentString += " " + word;
        }
    }
    lines.push(currentString);

    return lines;
};

const getImageDescription = (
    { categoryPrefix, datasetsDescription, dateDescription, filterState, mainDescription, pieChartData }: ImageDescriptionProps
): string => {
    const shortFilterDescription = getShortFilterDescription(filterState);

    const dataContext = `A pie chart that shows the ${mainDescription.toLowerCase()} for ${categoryPrefix}`;
    const chartContext = `The chart includes data from ${datasetsDescription.toLowerCase()} data for ${dateDescription}, and applies to ${shortFilterDescription}`;

    const inTargetDatum = pieChartData.find(datum => !!datum.isTarget);
    const resultStrings = pieChartData.map(datum => `${Math.round(datum.value)}% ${datum.id}`);
    
    let chartDescription = "";
    if (inTargetDatum) {
        const targetDescription = `For ${dateDescription}, the target for ${categoryPrefix} was ${inTargetDatum.goal}% ${inTargetDatum.id}`;
        chartDescription = `${targetDescription} and the selected groups achieved ${joinArrayStringItems(resultStrings)}.`;
    }

    return `${dataContext}. ${chartContext}. ${chartDescription}`;
};


const PieChartGoalLine = (
    { dataWithArc, centerX, centerY, radius, innerRadius }: PieCustomLayerProps<PieChartDatum>
): JSX.Element | null => {
    const goal = dataWithArc[0]?.data?.goal;

    if (goal) {
        const theta = DEGREES_PER_PERCENT * goal;
        const innerLineCoords = calculcateCircleXYCoords((innerRadius - 5), theta, centerX, centerY);
        const outerLineCoords = calculcateCircleXYCoords((radius + 10), theta, centerX, centerY);
        const textCoords = calculcateCircleXYCoords((radius + 12), theta, centerX, centerY);
    
        return (
            <>
                <line
                    x1={innerLineCoords.x}
                    y1={innerLineCoords.y}
                    x2={outerLineCoords.x}
                    y2={outerLineCoords.y}
                    style={{ stroke: "grey", strokeWidth: 1.5 }}
                />
                <text
                    x={textCoords.x}
                    y={textCoords.y}
                    dominantBaseline={theta === 180 ? "hanging" : "auto"}
                    style={{ fill: "grey", fontSize: 12, fontFamily: "sans-serif" }}
                >
                    {goal}%
                </text>
            </>
        );
    } else {
        return null;
    }
};

const PieChartTopDescription = ({ dataWithArc, centerX }: PieCustomLayerProps<PieChartDatum>): JSX.Element | null => {
    if (dataWithArc.length > 0) {
        const maxLength = (centerX * 2) / WIDTH_PER_CHAR;
        const { categoryPrefix, mainDescription, dateDescription, datasetsDescription } = dataWithArc[0].data.topDescription;
        const mainDescriptionLines = splitTextByMaxLength(`${categoryPrefix}: ${mainDescription}`, maxLength);
        const dateDescriptionLines = splitTextByMaxLength(dateDescription, maxLength);
        const datasetsDescriptionLines = splitTextByMaxLength(datasetsDescription, maxLength);
        const lines = [...mainDescriptionLines, ...dateDescriptionLines, ...datasetsDescriptionLines];

        return (
            <>
                {
                    lines.map((line, index, array) => (
                        <text
                            key={uuidv4()}
                            className={`${dataWithArc[0].data.containerId} svg-chart-top-text`}
                            x={0}
                            y={(HEIGHT_PER_TEXT_LINE * -1) - ((array.length - index) * HEIGHT_PER_TEXT_LINE)}
                            style={{ fontFamily: "sans-serif", fontSize: 16 }}
                        >
                            {line}
                        </text>
                    ))
                }
            </>
        );
    } else {
        return null;
    }
};

const PieChartBottomDescription = (
    { dataWithArc, centerX, centerY }: PieCustomLayerProps<PieChartDatum>
): JSX.Element | null => {
    if (dataWithArc.length > 0) {
        const maxLength = (centerX * 2) / WIDTH_PER_CHAR;
        const lines = splitTextByMaxLength(dataWithArc[0].data.filterDescription, maxLength);

        return (
            <>
                {
                    lines.map((line, index) => (
                        <text
                            key={uuidv4()}
                            className={`${dataWithArc[0].data.containerId} svg-chart-bottom-text`}
                            aria-hidden
                            x={0}
                            y={(centerY * 2) + HEIGHT_PER_TEXT_LINE + ((index + 1) * HEIGHT_PER_TEXT_LINE)}
                            style={{ fill: "#757575", fontFamily: "sans-serif", fontSize: 16 }}
                        >
                            {line}
                        </text>
                    ))
                }
            </>
        );
    } else {
        return null;
    }
};

const PieChartTooltip = ({ datum }: PieTooltipProps<PieChartDatum>): JSX.Element => (
    <BasicTooltip
        id={datum.id}
        value={`${datum.formattedValue}%`}
        enableChip={true}
        color={datum.color}
    />
);


export const OverallPercentagePieChart = ({ category, data, filterState }: OverallPercentagePieChartProps) => {
    const id = `${category.name}-overall-percentage-pie-chart`;
    const containerId = `${id}-container`;

    const [imageLoading, setImageLoading] = useState<boolean>(false);

    const { topDescription, filterDescription } = getSectionDescriptionParts({ category, data, filterState });
    const { categoryPrefix, mainDescription, dateDescription, datasetsDescription } = topDescription;

    const pieChartData = getPieChartData({ containerId, category, data, filterState, topDescription, filterDescription });
    const pieChartCustomLayers = [PieChartGoalLine, PieChartTopDescription, PieChartBottomDescription];

    const onClickDownloadImage = async (imageFormat: ImageFormat): Promise<void> => {
        setImageLoading(true);
        const filename = createExportFilename({ suffix: id, filterState });
        await downloadSvgAsImage(containerId, filename, imageFormat);
        setImageLoading(false);
    };

    const imageDescription = getImageDescription({ filterState, pieChartData, ...topDescription });

    return (
        <Row align="top" id={id}>
            <Col span={21} style={{ marginBottom: 16 }}>
                <p aria-hidden style={{ marginBottom: 0 }}><b>{categoryPrefix}</b>: {mainDescription}</p>
                <p aria-hidden style={{ marginBottom: 0 }}>{dateDescription}</p>
                <p aria-hidden style={{ marginBottom: 0 }}>{datasetsDescription}</p>
            </Col>
            <Col span={3}>
                {
                    IMAGE_FORMATS.map(format => (
                        <Button
                            key={`download-${id}-${format}`}
                            disabled={imageLoading}
                            icon={<PictureOutlined />}
                            loading={imageLoading}
                            onClick={async () => await onClickDownloadImage(format)}
                            size="small"
                            style={{ marginBottom: 10, width: "100%" }}
                        >
                            {`.${format}`}
                        </Button>
                    ))
                }
            </Col>
            <Col span={24} id={containerId} style={{ height: 240, width: "100%" }}>
                <ResponsivePie
                    // Base
                    data={pieChartData}
                    id="id"
                    value="value"
                    valueFormat=">-d"
                    margin={{ top: 25, right: 25, bottom: 25, left: 25 }}
                    innerRadius={0.4}
                    cornerRadius={3}
                    // Style
                    theme={{
                        background: "#FFFFFF",
                        text: { fontSize: 14, fill: "#000000" }
                    }}
                    colors={(datum) => datum.data.color}
                    borderWidth={1}
                    borderColor={{ 
                        from: "color",
                        modifiers: [[ "darker", 0.8 ]]
                    }}
                    // Arc Labels
                    enableArcLabels
                    arcLabel={(datum) => `${datum.formattedValue}%`}
                    arcLabelsSkipAngle={DEGREES_PER_PERCENT * PERCENTAGE_THRESHOLD}
                    arcLabelsTextColor={(datum) => datum.data.textColor}
                    // Arc Link Labels
                    enableArcLinkLabels
                    arcLinkLabel="label"
                    arcLinkLabelsDiagonalLength={12}
                    arcLinkLabelsStraightLength={12}
                    arcLinkLabelsTextOffset={4}
                    arcLinkLabelsTextColor="#000000"
                    arcLinkLabelsColor="#000000"
                    // Customisation
                    layers={["arcs", "arcLinkLabels", "arcLabels", "legends", ...pieChartCustomLayers]}
                    // Interactivity
                    tooltip={PieChartTooltip}
                />
                <span className="sr-only">{imageDescription}</span>
            </Col>
            <Col span={24} style={{ marginTop: 16 }}>
                <p aria-hidden style={{ color: "#757575", marginBottom: 0 }}>{filterDescription}</p>
            </Col>
        </Row>
    );
};