import { gql } from "@apollo/client";

export const ADMIN_GET_DEPARTMENTS = gql`
  query AdminGetDepartments {
    departments {
      id
      name
      division {
        id
        name
      }
      programs {
        id
        name
        datasets {
          id
          name
        }
      }
    }
  }
`;
