import { gql } from "@apollo/client";

export const ADMIN_UPDATE_CONTENT_TYPE = gql`
  mutation AdminUpdateContentType($input: UpdateContentTypeInput!) {
    updateContentType(input: $input) {
      id
      name
      programs {
        id
        name
        datasets {
          id
          name
        }
      }
    }
  }
`;
