import { Col, message, Radio, Row, Typography } from "antd";
import Title from "antd/lib/typography/Title";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { getCategoryAttributes } from "./DatasetDetails";
import { Pie5050 } from "../../components/Charts/Pie";
import { GetAllCategories_categories } from "../../graphql/__generated__/GetAllCategories";
import { GetDataset_dataset, GetDataset_dataset_program_reportingPeriods, GetDataset_dataset_publishedRecordSets } from "../../graphql/__generated__/GetDataset";
import { groupedByCategory } from "../../selectors/ChartData";
import { sortDiversityCategories } from "../../utils/sortDiversityCategories";

const { Text } = Typography;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);


export const isIPublishedEntry = (entry: unknown): entry is IPublishedEntry => (
    !!entry &&
    !!(entry as IPublishedEntry)?.attribute &&
    !!(entry as IPublishedEntry)?.category &&
    !!(entry as IPublishedEntry)?.personType &&
    !!(entry as IPublishedEntry)?.targetMember &&
    !!(entry as IPublishedEntry)?.percent
);

export interface IPublishedEntry {
    attribute: string,
    category: string,
    personType: string,
    targetMember: boolean,
    percent: number,
    count?: number
}

interface IPublishedTag {
    name: string,
    group: string
}

export const isIPublishedTarget = (target: unknown): target is IPublishedTarget => (
    !!target &&
    !!(target as IPublishedTarget)?.category &&
    !!(target as IPublishedTarget).target
);

interface IPublishedTarget {
    category: string,
    target: number
}

type IMungedPublishedRecordSetDocument = {
    [key: string]: string,
}

export const isIPublishedRecordSetDocument = (document: unknown): document is IPublishedRecordSetDocument => (
    !!document &&
    !!(document as IPublishedRecordSetDocument)?.datasetName &&
    !!(document as IPublishedRecordSetDocument)?.datasetGroup &&
    !!(document as IPublishedRecordSetDocument)?.reportingPeriodDescription &&
    !!(document as IPublishedRecordSetDocument)?.begin &&
    !!(document as IPublishedRecordSetDocument)?.end &&
    (
        !!(document as IPublishedRecordSetDocument)?.record &&
        !!(document as IPublishedRecordSetDocument)?.record?.["Everyone"]
    ) &&
    !!(document as IPublishedRecordSetDocument)?.segmentedRecord &&
    !!(document as IPublishedRecordSetDocument)?.targets &&
    !!(document as IPublishedRecordSetDocument)?.datasetTags &&
    !!(document as IPublishedRecordSetDocument)?.datasetGroupTags &&
    !!(document as IPublishedRecordSetDocument)?.teamName
);

export interface IPublishedRecordSetDocument {
    datasetName: string,
    datasetGroup: string,
    reportingPeriodDescription: string,
    begin: string,
    end: string,
    record: Record<string, Record<string, { total?: number, entries: Record<string, IPublishedEntry> }>>,
    segmentedRecord: Record<string, Record<string, { total?: number, entries: Record<string, IPublishedEntry> }>>,
    targets: IPublishedTarget[],
    tags?: string[],
    datasetTags: IPublishedTag[],
    datasetGroupTags: IPublishedTag[],
    platforms?: string[],
    contentTypes?: string[],
    division?: string,
    department?: string,
    teamName: string
}

interface IProps {
    activeCategories: GetAllCategories_categories[];
    dataset?: GetDataset_dataset,
    publishedRecordSet?: GetDataset_dataset_publishedRecordSets,
    reportingPeriod?: GetDataset_dataset_program_reportingPeriods,
    summary?: boolean,
}

interface ITarget {
    category: { 
        name: string;
        displayName: string
    }
    target: number
}

const isIProgram = (program: unknown): program is IProgram => (
    !!program &&
    !!(program as IProgram)?.name &&
    !!(program as IProgram)?.tags &&
    !!(
        (program as IProgram)?.platforms === null ||
        !!(program as IProgram)?.platforms
    ) &&
    !!(
        (program as IProgram)?.contentTypes === null ||
        !!(program as IProgram)?.contentTypes
    ) &&
    !!(
        (program as IProgram)?.department === null ||
        (
            !!(program as IProgram)?.department &&
            !!(program as IProgram)?.department?.name &&
            !!(program as IProgram)?.department?.division &&
            !!(program as IProgram)?.department?.division?.name
        )
    ) &&
    !!(
        (program as IProgram)?.team === null ||
        (
            !!(program as IProgram)?.team &&
            !!(program as IProgram)?.team?.id &&
            !!(program as IProgram)?.team?.name
        )
    )
);

interface IProgram {
    name: string
    targets?: readonly ITarget[]
    tags: readonly { name: string }[]
    platforms: readonly { name: string }[] | null
    contentTypes: readonly { name: string }[] | null
    department: { name: string, division: { name: string } } | null
    team: { id: string, name: string } | null
}

export const isIDataset = (dataset: unknown): dataset is IDataset => (
    !!dataset &&
    !!(dataset as IDataset)?.id &&
    !!(dataset as IDataset)?.name &&
    !!(
        (dataset as IDataset)?.program === null ||
        isIProgram((dataset as IDataset)?.program)
    )
);

export interface IDataset {
    id: string
    name: string
    program: IProgram | null
    end?: string
    targetValue?: number | undefined
    category?: string
}

export const isIPublishedRecordset = (publishedRecordSet: unknown): publishedRecordSet is IPublishedRecordset => (
    !!publishedRecordSet &&
    !!(publishedRecordSet as IPublishedRecordset)?.id &&
    !!(publishedRecordSet as IPublishedRecordset)?.datasetId &&
    !!(publishedRecordSet as IPublishedRecordset)?.begin &&
    !!(publishedRecordSet as IPublishedRecordset)?.end &&
    !!(
        (publishedRecordSet as IPublishedRecordset)?.dataset === null ||
        isIDataset((publishedRecordSet as IPublishedRecordset)?.dataset)
    ) &&
    !!(publishedRecordSet as IPublishedRecordset)?.document &&
    !!isIPublishedRecordSetDocument((publishedRecordSet as IPublishedRecordset).document)
);

export interface IPublishedRecordset {
    document: IPublishedRecordSetDocument,
    id: string,
    begin: dayjs.Dayjs,
    end: dayjs.Dayjs,
    datasetId: string,
    dataset?: IDataset | null

}

export interface ISerialisedPublishedRecordset {
    document: IPublishedRecordSetDocument,
    id: string,
    begin: string,
    end: string,
    datasetId: string,
    dataset?: IDataset | null
}


const EXPECTED_DATE_TIME_FORMAT = "YYYY-M-DTHH:mm:ss";

export const csvIse = (objectKey: string, obj: object, csv: Record<string, string>): Record<string, string> => {
    if (!obj) {
        return csv;
    }
    
    Object.entries(obj).forEach((kv) => {
        let key = objectKey ? objectKey + "_" + kv[0] : kv[0];

        if (
            (kv[0] === "category" && objectKey.indexOf("targets") === -1) || 
            kv[0] === "attribute" || kv[0] === "personType"
        ) {
            return csv;
        }
        
        if (kv[0] === "segmentedRecord" || kv[0] === "entries" || kv[0] === "record") {
            key = objectKey;
        }

        if (
            typeof (kv[0]) === "string" &&
            (typeof (kv[1]) === "string" || typeof (kv[1]) === "number" || typeof (kv[1]) === "boolean")
        ) {
            csv[key] = kv[1].toString();
        }

        if (typeof (kv[0]) === "string" && typeof (kv[1]) === "object") {
            csvIse(key, kv[1], csv);
        }

    }, {} as Record<string, string>);

    return csv;
};

export const arrayToCsv = (data: Array<Array<string | null>>) => {
    return data.map(row => {
        return row
            .map(String)  // convert every value to String
            .map(v => v ? v.replaceAll("\"", "\"\"") : "")  // escape double quotes
            .map(v => `"${v}"`)  // quote it
            .join("\t");  // tab-separated
    }
    ).join("\n");  // rows starting on new lines
};

export function downloadBlob(content: string, filename: string, contentType: string) {
    // Create a blob
    contentType;
    const byteArray = new Array<number>();
    byteArray.push(255, 254);
    content.split("").forEach(x => {
        const charCode = x.charCodeAt(0);
        byteArray.push(charCode & 0xff);
        byteArray.push(charCode / 256 >>> 0);
    });
    const blob = new Blob([new Uint8Array(byteArray)], { type: "text/csv;charset=UTF-16LE;" });
    const url = URL.createObjectURL(blob);

    // Create a link to download it
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", filename);
    pom.click();
}

export const exportCSV = (publishedRecordSetDoc: IPublishedRecordSetDocument, filename: string | undefined): void => {
    if (!publishedRecordSetDoc) {
        message.error("No Published Record sets");
        return;
    }

    const csvLikeRecords = csvIse("", publishedRecordSetDoc, {} as Record<string, string>);

    const csvLikeArray = new Array<Array<string>>();

    csvLikeArray.push(Object.keys(csvLikeRecords));
    csvLikeArray.push(Object.values(csvLikeRecords));

    const csv = arrayToCsv(csvLikeArray);

    if (!csv) {
        message.error("No data in published record sets");
        return;
    }

    if (!filename) {
        filename = `${new Date(publishedRecordSetDoc[0].begin).toLocaleString(navigator.language, { day: "2-digit", month: "short", year: "numeric" } as Intl.DateTimeFormatOptions)}-${new Date(publishedRecordSetDoc.end).toLocaleString(navigator.language, { day: "2-digit", month: "short", year: "numeric" } as Intl.DateTimeFormatOptions)}`;
    }

    downloadBlob(
        csv,
        `${filename}.csv`,
        "text/csv");
};

export const exportCSVTwo = (mungedPublishedRecordSets: IMungedPublishedRecordSetDocument[] | undefined, filename: string | undefined): void => {
    if (!mungedPublishedRecordSets) {
        message.error("No Published Record sets");
        return;
    }

    const csvArray = mungedPublishedRecordSets.
        reduce((csv, prs) => {
            csv.push(csvIse("", prs, {} as Record<string, string>));
            return csv;
        }, [] as Record<string, string>[]);

    const headings = csvArray.reduce((keys, curr) => {
        Object.keys(curr).forEach(x => x in keys || keys.add(x));
        return keys;
    }, new Set<string>());

    const csvArrayCombined = csvArray.reduce((csv, curr, i) => {
        i === 0 && csv.push(Array.from(headings));
        const row = new Array<string | null>();
        headings.forEach(x => Object.keys(curr).includes(x) ? row.push(curr[x]) : row.push(""));
        csv.push(row);
        return csv;
    }, new Array<Array<string | null>>());

    const csv = arrayToCsv(csvArrayCombined);

    if (!csv) {
        message.error("No data in published record sets");
        return;
    }

    if (!filename) {
        filename = `${mungedPublishedRecordSets.at(-1)?.Reporting_Period_End}`;
    }

    downloadBlob(
        csv,
        `${filename}.csv`,
        "text/csv");
};

export const stripCountsFromEntries = (record: Record<string, Record<string, { total?: number, entries: Record<string, IPublishedEntry> }>>) => {
    for (const personType in record) {
        for (const category in record[personType]) {
            delete record[personType][category]["total"];
            for (const entry in record[personType][category]["entries"]) {
                delete record[personType][category]["entries"][entry]["count"];
            }
        }
    }
    return record;
};

export const flattenPublishedDocumentEntries = (
    record: Record<string, Record<string, { total?: number, entries: Record<string, IPublishedEntry> }>>
) => Object.values(record)
    .flatMap(x => Object.values(x))
    .flatMap(x => Object.values(x.entries));

const reduceRecordsToOverallPercentages = (
    dataset: GetDataset_dataset,
    reportingPeriod: GetDataset_dataset_program_reportingPeriods,
    ignorePersonType: boolean,
    timezoneName: string
) => {
    return dataset.records
        .filter(x => (
            reportingPeriod.range && dayjs(x.publicationDate, EXPECTED_DATE_TIME_FORMAT, timezoneName)
                .isBetween(reportingPeriod.range[0], reportingPeriod.range[1], "day", "[]")
        ))
        .sort((a, b) => (
            dayjs(b.publicationDate, EXPECTED_DATE_TIME_FORMAT, timezoneName).unix() - 
            dayjs(a.publicationDate, EXPECTED_DATE_TIME_FORMAT, timezoneName).unix()
        ))
        .reduce((groupedByPersonTypeCategoryAttribute, record, _recordIndex, allRecords) => {
            record.entries
                .flat() //just makes it sortable
                .sort((a, b) => a.categoryValue.name.localeCompare(b.categoryValue.name))
                .forEach(currEntry => {
                    const personType = ignorePersonType ? "Everyone" : (currEntry.personType?.personTypeName ?? "Everyone");
                    const category = currEntry.categoryValue.category.name;
                    const attribute = currEntry.categoryValue.name;

                    if (!groupedByPersonTypeCategoryAttribute[personType]) {
                        groupedByPersonTypeCategoryAttribute[personType] = {} as Record<string, { total: number, entries: Record<string, IPublishedEntry> }>;
                    }

                    if (!groupedByPersonTypeCategoryAttribute[personType][category]) {
                        groupedByPersonTypeCategoryAttribute[personType][category] = {} as { total: number, entries: Record<string, IPublishedEntry> };
                        groupedByPersonTypeCategoryAttribute[personType][category]
                            .total = allRecords
                                .flatMap(x => x.entries)
                                .filter(x => (ignorePersonType || (x.personType?.personTypeName ?? "Everyone") === personType) && x.categoryValue.category.name === category)
                                .reduce((total, curr) => { return total + curr.count; }, 0);
                        groupedByPersonTypeCategoryAttribute[personType][category].entries = {} as Record<string, IPublishedEntry>;
                    }

                    if (!groupedByPersonTypeCategoryAttribute[personType][category].entries[attribute]) {
                        groupedByPersonTypeCategoryAttribute[personType][category].entries[attribute] = {} as IPublishedEntry;
                        const total = groupedByPersonTypeCategoryAttribute[personType][category].total ?? 0;
                        groupedByPersonTypeCategoryAttribute[personType][category].entries[attribute] = {
                            attribute: attribute,
                            category: currEntry.categoryValue.category.name,
                            count: currEntry.count,
                            personType: personType,
                            targetMember: dataset?.program?.targets
                                .flatMap(x => x.tracks)
                                .some(track => track.categoryValue.id === currEntry.categoryValue.id && track.targetMember) ?? false,
                            percent: (
                                currEntry.count /
                                total
                            ) * 100
                        };
                    } else {
                        const newCount = (groupedByPersonTypeCategoryAttribute[personType][category].entries[attribute].count ?? 0) + currEntry.count;
                        const total = groupedByPersonTypeCategoryAttribute[personType][category].total ?? 0;
                        groupedByPersonTypeCategoryAttribute[personType][category].entries[attribute].count = newCount;
                        groupedByPersonTypeCategoryAttribute[personType][category].entries[attribute].percent =
                            (
                                newCount /
                                total
                            ) * 100;

                    }
                });

            return groupedByPersonTypeCategoryAttribute;
        }, {} as Record<string, Record<string, { total?: number, entries: Record<string, IPublishedEntry> }>>);
};

export const getRecordSetDocument = (
    dataset: GetDataset_dataset,
    reportingPeriod: GetDataset_dataset_program_reportingPeriods,
    timezoneName: string
) =>({
    datasetGroup: dataset?.program?.name,
    datasetName: dataset.name,
    teamName: dataset?.program?.team?.name,
    reportingPeriodDescription: reportingPeriod.description ?? "",
    begin: reportingPeriod.range[0],
    end: reportingPeriod.range[1],
    department: dataset.program?.department?.name,
    division: dataset.program?.department?.division?.name,
    platforms: dataset.program?.platforms?.map(x => x.name),
    contentTypes: dataset.program?.contentTypes?.map(x => x.name),
    tags: dataset.program?.tags.map(x => ({ name: x.name })),
    datasetTags: dataset.tags
        .map(x => ({ name: x.name, group: x.tagType })),
    datasetGroupTags: dataset?.program?.tags
        .map(x => ({ name: x.name, group: x.tagType })),
    targets: [...(dataset?.program?.targets || [])]
        .sort((a, b) => sortDiversityCategories(a.category.priority, b.category.priority))
        .map(x => ({ category: x.category.name, target: x.target * 100 })),
    record: stripCountsFromEntries(reduceRecordsToOverallPercentages(dataset, reportingPeriod, true, timezoneName)),
    segmentedRecord: stripCountsFromEntries(reduceRecordsToOverallPercentages(dataset, reportingPeriod, false, timezoneName))
}) as IPublishedRecordSetDocument;

const mungedRow = (
    prsDoc: IPublishedRecordSetDocument,
    currentDataset: IDataset,
    categories: GetAllCategories_categories[],
) => {
    const calculateTargetStatistic = (prsDoc: IPublishedRecordSetDocument, category: string, targetMemberCheck: boolean) => (
        prsDoc.record["Everyone"] && category in prsDoc.record["Everyone"] ? String(
            Object
                .values(prsDoc.record["Everyone"][category].entries)
                .filter(x => x.targetMember === targetMemberCheck)
                .reduce((sum, curr) => sum + curr.percent, 0)
        ) : ""
    );
    
    const formatDateTime = (x: string) => dayjs(x.slice(0, 10), ["YYYY-MM-DD", "DD/MM/YYYY"]).format("DD/MM/YYYY");

    const categoryStatistics = prsDoc.targets
        .filter(({ category }) => categories.map(({ name }) => name).includes(category))
        .reduce((collection, target) => {
            const { target: targetValue, category } = target;

            const categoryDisplayName = getCategoryAttributes(category, categories)?.displayName || category;

            collection[`${categoryDisplayName}_TARGET`] = String(targetValue);
            collection[`${categoryDisplayName}_TARGET_CURRENT`] = String(
                (currentDataset.program?.targets?.find(x => x.category.name == category)?.target ?? 0) * 100
            );
            const actual = calculateTargetStatistic(prsDoc, category, true);
            const actualOot = calculateTargetStatistic(prsDoc, category, false);
            /* if both are zero it means nothing was recorded */
            collection[`${categoryDisplayName}_ACTUAL`] = !actual && !actualOot ? "" : actual;

            return collection;
        }, {} as Record<string, string>);

    return {
        Reporting_Period_Begin: formatDateTime(prsDoc.begin),
        Reporting_Period_End: formatDateTime(prsDoc.end),
        Name: currentDataset.name,
        Group: currentDataset.program?.name || "",
        Department: currentDataset.program?.department?.name ?? "",
        Division: currentDataset.program?.department?.division?.name ?? "",
        Platforms: currentDataset.program?.platforms?.map(x => x.name).join(";") || "",
        ContentTypes: currentDataset.program?.contentTypes?.map(x => x.name).join(";") || "",
        MISC_TAGS: prsDoc.datasetGroupTags.map(x => x.name).join(";"),
        MISC_TAGS_CURRENT: currentDataset.program?.tags.map(x => x.name).join(";") || "",
        ...categoryStatistics,
    };
};

export const mungedFilteredData = (
    publishedRecordSets: IPublishedRecordset[] | undefined,
    dataset: GetDataset_dataset | null,
    categories: GetAllCategories_categories[],
) => (
    publishedRecordSets
        ?.sort((a, b) => Math.round(a.end.valueOf() / 1000) - Math.round(b.end.valueOf() / 1000))
        .reduce((x, curr) => {
            const thisDataset = "dataset" in curr && curr.dataset && !dataset ?
                curr.dataset : dataset;
            if (!thisDataset?.program) {
                message.error("Dataset was unexpectedly undefined");
                return x;
            }
            const row = mungedRow(curr.document as IPublishedRecordSetDocument, thisDataset, categories);
            x.push(row);
            return x;
        }, new Array<IMungedPublishedRecordSetDocument>())
);


export const PublishedRecordSet = ({ publishedRecordSet, activeCategories, dataset, reportingPeriod, summary }: IProps) => {
    const { t } = useTranslation();

    const [showByPersonType, setShowByPersonType] = useState(false);

    const tz = useMemo(() => dayjs.tz.guess(), []);

    const document = (publishedRecordSet?.document as IPublishedRecordSetDocument) || (
        dataset && reportingPeriod ? getRecordSetDocument(dataset, reportingPeriod, tz) : undefined
    );

    const noDataAvailable = () => (
        <div style={{ marginTop: "auto", marginBottom: "auto", textAlign: "center" }}>
            <Text strong>{t("noDataAvailable")}</Text>
        </div>
    );

    if (!document) {
        return null;
    }

    return (
        <div style={{ padding: "5px" }}>
            <Row>
                <Col span={24}>
                    <Title level={2}>
                        {document.reportingPeriodDescription}
                        {
                            !publishedRecordSet?.deleted &&
                            <Radio.Group
                                style={{ float: "right" }}
                                defaultValue={showByPersonType}
                                onChange={(e) => setShowByPersonType(e.target.value)}
                            >
                                <Radio value={false}>{t("everyone")}</Radio>
                                <Radio value={true}>{t("personType")}</Radio>
                            </Radio.Group>
                        }
                    </Title>
                    <Title level={4}>Summary</Title>
                </Col>
            </Row>
            <Row justify="center" gutter={[16, 16]}>
                {
                    Object
                        .entries(groupedByCategory([
                            {
                                id: "",
                                datasetId: dataset?.id ?? "datasetId",
                                begin: document.begin,
                                end: document.end,
                                document: document
                            }
                        ], tz) ?? {})
                        .filter(([categoryName]) => activeCategories.map(({ name }) => name).includes(categoryName))
                        .sort((a, b) => {
                            const priorityA = getCategoryAttributes(a[0], activeCategories)?.priority || null;
                            const priorityB = getCategoryAttributes(b[0], activeCategories)?.priority || null;
                            return sortDiversityCategories(priorityA, priorityB);
                        })
                        .map(([categoryName, categoryValue]) => categoryValue && (
                            <Col key={categoryName}>
                                {
                                    !isNaN(categoryValue.percent) ? (
                                        <Pie5050
                                            attribute={categoryName}
                                            categoryName={categoryName}
                                            legend={false}
                                            inTargetColor={getCategoryAttributes(categoryName, activeCategories)?.inTargetColor || "#000000"}
                                            outTargetColor={getCategoryAttributes(categoryName, activeCategories)?.outTargetColor || "#FFFFFF"}
                                            status={categoryValue.percent}
                                        />
                                    ) : (
                                        noDataAvailable()
                                    )
                                }
                                <h2 style={{ textAlign: "center" }}>
                                    {getCategoryAttributes(categoryName, activeCategories)?.displayName || categoryName}
                                </h2>
                            </Col>
                        ))
                }
            </Row>
            {
                !publishedRecordSet?.deleted &&
                Object
                    .entries(showByPersonType ? document.segmentedRecord : document.record)
                    .filter(([, docCategories]) => (
                        Object.values(docCategories).some(record => !Object.values(record.entries).every(e => !e.percent))
                    ))
                    .map(([personType, docCategories]) => (
                        <Row key={personType}>
                            <Title level={3}>{personType}</Title>
                            <Col span={24}>
                                {
                                    Object
                                        .entries(docCategories)
                                        .filter(([categoryName])=> activeCategories.map(({ name }) => name).includes(categoryName))
                                        .filter(([, v]) => !Object.values(v.entries).every(e => !e.percent))
                                        .sort((a, b) => {
                                            const priorityA = getCategoryAttributes(a[0], activeCategories)?.priority || null;
                                            const priorityB = getCategoryAttributes(b[0], activeCategories)?.priority || null;
                                            return sortDiversityCategories(priorityA, priorityB);
                                        })
                                        .map(([category, v]) => (
                                            <Row justify="center" key={category} gutter={[16, 0]}>
                                                <Col span={24}>
                                                    <Title level={4}>
                                                        {getCategoryAttributes(category, activeCategories)?.displayName || category}
                                                    </Title>
                                                </Col>
                                                {
                                                    Object
                                                        .entries(v.entries)
                                                        .filter(([, v]) => v.percent)
                                                        .map(([attribute, entry], i) => (
                                                            <Col 
                                                                sm={summary ? 16 : 8} 
                                                                lg={summary ? 8 : 4} key={i} 
                                                                style={{ opacity: entry.targetMember ? "unset" : 0.5 }}
                                                            >
                                                                <div style={{ textAlign: "center" }}>
                                                                    <b>{attribute}</b>
                                                                </div>
                                                                <Pie5050
                                                                    attribute={entry.attribute}
                                                                    categoryName={entry.category}
                                                                    legend={false}
                                                                    inTargetColor={getCategoryAttributes(entry.category, activeCategories)?.inTargetColor || "#000000"}
                                                                    outTargetColor={getCategoryAttributes(entry.category, activeCategories)?.outTargetColor || "#FFFFFF"}                        
                                                                    status={entry.percent}
                                                                    target={
                                                                        document.targets.find(x => x.category === entry.category)?.target
                                                                    }
                                                                />
                                                            </Col>
                                                        ))
                                                }
                                            </Row>
                                        ))
                                }
                            </Col>
                        </Row>
                    ))
            }
        </div>
    );
};