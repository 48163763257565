define(function(require) {
  'use strict';

  var SmartTag = require('smart-tag');
  var uuid = require('uuid');
  var ATILabelKeys = require('./label-keys');
  var ConfigKeys = require('../../config/keys');
  var CustomEvent = require('./custom-event');
  var CustomVariables = require('./custom-variables');
  var MediaThemes = require('./media-themes');
  var MediaEvent = require('./media-event');
  var UserActionEvent = require('./user-action-event');

  var Destinations = require('../../config/destinations');
  var Producers = require('../../config/producers');

  var RemoteConfigManager = require('../../remote/remote-config-manager');

  var Utils = require('../../util/methods');
  var LibraryInfo = require('../../util/library-info');
  var DEBUG = require('../../util/debug');
  var LabelCleanser = require('../../util/cleansing/label-cleanser');
  var Enums = require('../../enumerations');
  var Environment = require('../../environment');
  var CookieHelper = require('../../util/cookies');

  var HOUR_MILISECONDS = 3600000;
  var AV_EVENT_TYPES = {
    INFO: 'info',
    MOVE: 'move',
    PAUSE: 'pause',
    PLAY: 'play',
    STOP: 'stop'
  };

  var BROADCAST_MODE = {
    CLIP: 'clip',
    LIVE: 'live'
  };

  var COLLECTION_DOMAIN = {
    testHost: 'ati-host.net',
    test: 'logw363.ati-host.net',
    testSSL: 'logws1363.ati-host.net',
    liveHost: 'api.bbc.co.uk',
    live: 'a1.api.bbc.co.uk',
    liveSSL: 'a1.api.bbc.co.uk'
  };

  var KEEPALIVE_DURATION_DEFAULT = 300000;
  var KEEPALIVE_COUNTERNAME = 'keepalive';

  var GLOBAL_APP_TYPE = 'app_type';
  var GLOBAL_APP_NAME = 'app_name';
  var GLOBAL_CONTENT_TYPE = 'content_type';

  var GLOBAL_LIBRARY_VERSION = 'library_version';

  var GLOBAL_USER_ID = 'user_id';

  //function to use when we are ignoring a function
  function dummy() {
    return -1;
  }

  function AtiDelegate(appName, appType, config, env) { // jshint ignore:line
    this._tag = null;
    this._broker = null;
    this._media = null;
    this._mediaLength = null;
    this._commonMediaParams = null;
    this._env = env;
    this._pageValues = {};
    this._customParams = {};
    this._appName = appName;
    this._appType = appType;
    this._config = config;
    this._bbc_hid = null;
    this._lastHitUrl = null;
    this._lastPlayHitUrl = null;
    this._lastPage = null;
    this._counterName = null;
    this._orbitVars = null;
    this._trace = null;
    var self = this;

    setInterval(function() {
      self._remoteConfigManager = new RemoteConfigManager(config, self._env);
    }, HOUR_MILISECONDS);

    this._remoteConfigManager = this._config[ConfigKeys.REMOTE_CONFIG_MANAGER] || new RemoteConfigManager(config, this._env);

    // set device id from config value
    this._deviceId = config[ConfigKeys.ECHO_DEVICE_ID] || undefined;

    //set random device id if cookie blocked
    if (config[ConfigKeys.ATI_DISABLE_COOKIE] === true && !this._deviceId) {
      this._deviceId = uuid.v4();
    }

    // or from 3rd party cookie
    if (!this._deviceId) {
      this._deviceId = CookieHelper.getCookieValueByName('idrxvr');
    }

    // or from 1st party cookie
    if (!this._deviceId) {
      var cookieValue = CookieHelper.getCookieValueByName('atuserid');
      if (cookieValue) {
        this._deviceId = JSON.parse(decodeURIComponent(cookieValue)).val;
      }
    }

    if (config[ConfigKeys.ECHO_TRACE]) {
      this._trace = config[ConfigKeys.ECHO_TRACE];
    }

    this._isEnabled = (config && config[ConfigKeys.ECHO_ENABLED]);

    if (config && config[ConfigKeys.KEEPALIVE_DURATION]) {
      this._keepAliveDuration = Number(config[ConfigKeys.KEEPALIVE_DURATION]);
    } else {
      this._keepAliveDuration = KEEPALIVE_DURATION_DEFAULT;
    }
  }

  AtiDelegate.SmartTag = SmartTag;

  AtiDelegate.prototype._getHttpGetMethod = function() {
    return this._env.getHttpGet() || Environment._defaultHttpGet;
  };

  // Provide ability to use a custom HTTP GET method so that cross-domain clients can use another method instead (e.g. JSONP)
  AtiDelegate.prototype._setHTTPGetMethod = function(callback) {
    var httpGet = this._getHttpGetMethod();

    var self = this;

    // Override the ATI sendUrl method so that it uses the custom GET method. This implementation preserves the ATI event caching feature
    // whilst still allowing the custom GET method to be used
    var sendUrl = function(hit, tagCallback) {

      var makeTriggerCallback = function (trigger, hit, level) {
        return (function () {
          return function (evt) {

            self._tag.emit(trigger, {
              lvl: level,
              details: {
                hit: hit,
                event: evt
              }
            });

            if (tagCallback && typeof tagCallback === 'function') {
              tagCallback();
            }

            if (callback && typeof callback === 'function') {
              callback();
            }
          };
        })();
      };

      hit = self._addLengthToMediaHit(hit);

      var onSuccess = makeTriggerCallback('Tracker:Hit:Sent:Ok', hit, 'INFO');
      var onError = makeTriggerCallback('Tracker:Hit:Sent:Error', hit, 'ERROR');

      httpGet(hit, onSuccess, onError);
    };

    // if cache mode is set to never _tag.sendUrl is not called
    // set _tag.builder.sendUrl to custom function instead
    if (this._tag.getConfig('Offline').storageMode === 'never') {
      this._tag.builder.sendUrl = sendUrl;
    } else {
      this._tag.sendUrl = sendUrl;
    }
  };

  AtiDelegate.prototype._getDestination = function(config, orbitVars) {
    var destination;

    if (config) {
      destination = Destinations.get(config[ConfigKeys.DESTINATION]);
    }

    if (!destination && orbitVars) {
      destination = Destinations.get(orbitVars.destination);
    }

    if (!destination) {
      // Log the fact that the default Destination is being used instead
      DEBUG.warn('Destination not found, using default site');

      // Set to the default ATI site
      destination = Destinations.DEFAULT;
    }

    return destination;
  };

  // Logic to get correct producer depending on what data exists and what attributes are more important
  AtiDelegate.prototype._getProducer = function(config, orbitVars, destination) {
    var producer;
    var remoteProducers;

    remoteProducers = this._remoteConfigManager.getProducers();

    // if page values are already set it would take producer from them
    if (this._pageValues[ATILabelKeys.LEVEL_2_SITE]) {
      producer = this._pageValues[ATILabelKeys.LEVEL_2_SITE];
    }

    // if there is no producer set the lookup will be done on remote with failover to local enum, using config information
    if (!producer && config) {
      var remoteAvailable = (remoteProducers && remoteProducers[config[ConfigKeys.PRODUCER]]) ? true : false;
      producer = remoteAvailable ? remoteProducers[config[ConfigKeys.PRODUCER]] : Producers.getId(config[ConfigKeys.PRODUCER]);
    }

    // if producer is not set orbit vars will be used to look at remote or local enum to get information
    if (!producer && orbitVars) {
      producer = (remoteProducers && remoteProducers[orbitVars.producer]) ? remoteProducers[orbitVars.producer] : Producers.getId(orbitVars.producer);
    }

    // if producer not found the default producer will be used
    if (!producer && destination) {
      DEBUG.warn('Producer not found, using default producer');
      producer = destination.defaultProducer;
    }

    return producer;
  };

  AtiDelegate.prototype.setDestination = function(destination) {
    if (!destination) {
      // Use the default destination if the passed in destination code was not found or empty
      destination = Destinations.DEFAULT;

      // Log the fact that the default Destination is being used instead
      DEBUG.warn('Destination not found, using default site instead');
    }

    if (destination) {
      this.setProducer(destination.defaultProducer);
      this._setDomain(destination.isTest);
      this._tag.setConfig(ATILabelKeys.LEVEL_1_SITE, destination.id);
    }
  };

  AtiDelegate.prototype.setProducer = function(producerId) {
    this.addLabel(ATILabelKeys.LEVEL_2_SITE, producerId);
  };

  AtiDelegate.prototype._getCookieDomain = function(window) {
    var cookieDomain;

    // Look for .bbc.com at the end of the hostname
    if (window && window.location && window.location.hostname && window.location.hostname.match(/.bbc.com$/)) {
      cookieDomain = 'bbc.com';
    } else if (window && window.location && window.location.hostname.match(/.britbox.com$/)) {
      cookieDomain = 'britbox.com';
    } else {
      if (window && window.location && !window.location.hostname.match(/.bbc.co.uk$/)) {
        DEBUG.info('Cookie hostname is: ' + window.location.hostname + ' failing back to bbc.co.uk');
      }

      cookieDomain = 'bbc.co.uk';
    }

    // To test cookie domain value in Hendricks we set the cookieDomain value
    // on a property called testCookieDomain
    if (this._config[ConfigKeys.TEST_SERVICE_ENABLED] === true) {
      this.addProperty(ATILabelKeys.TEST_COOKIE_DOMAIN, cookieDomain);
    }

    return cookieDomain;
  };

  AtiDelegate.prototype._setDomain = function(isTest) {
    if (isTest) {
      this._tag.setConfig('domain', COLLECTION_DOMAIN.testHost);
      this._tag.setConfig('collectDomain', COLLECTION_DOMAIN.test);
      this._tag.setConfig('collectDomainSSL', COLLECTION_DOMAIN.testSSL);
    } else {
      this._tag.setConfig('domain', COLLECTION_DOMAIN.liveHost);
      this._tag.setConfig('collectDomain', COLLECTION_DOMAIN.live);
      this._tag.setConfig('collectDomainSSL', COLLECTION_DOMAIN.liveSSL);
    }

    if (this._config) {
      if (this._config[ConfigKeys.ATI_COLLECT_DOMAIN]) {
        this._tag.setConfig('collectDomain', this._config[ConfigKeys.ATI_COLLECT_DOMAIN]);
      }

      if (this._config[ConfigKeys.ATI_COLLECT_DOMAIN_SSL]) {
        this._tag.setConfig('collectDomainSSL', this._config[ConfigKeys.ATI_COLLECT_DOMAIN_SSL]);
      }
    }
  };

  AtiDelegate.prototype._setSection = function(clonedLabels, eventValues, orbitVars) {
    if (!eventValues) {
      return;
    }

    if (clonedLabels && clonedLabels[ATILabelKeys.SECTION]) {
      // Use the label value for the section if it is provided
      var section = LabelCleanser.cleanCustomVariable(clonedLabels[ATILabelKeys.SECTION]);
      var sectionArray = section.split('::', 3);
      eventValues[ATILabelKeys.CHAPTER_1] = sectionArray[0];
      eventValues[ATILabelKeys.CHAPTER_2] = sectionArray[1];
      eventValues[ATILabelKeys.CHAPTER_3] = sectionArray[2];

      if (eventValues) {
        delete eventValues[ATILabelKeys.SECTION];
      }

    } else if (orbitVars && orbitVars.section) {
      // Otherwise, default to the Orbit section (if there is one)
      var orbitSection = LabelCleanser.cleanCustomVariable(orbitVars.section);
      var orbitSectionArray = orbitSection.split('::', 3);
      eventValues[ATILabelKeys.CHAPTER_1] = orbitSectionArray[0];
      eventValues[ATILabelKeys.CHAPTER_2] = orbitSectionArray[1];
      eventValues[ATILabelKeys.CHAPTER_3] = orbitSectionArray[2];
    }

    DEBUG.info('Setting page view chapter 1: ' + eventValues[ATILabelKeys.CHAPTER_1]);
    DEBUG.info('Setting page view chapter 2: ' + eventValues[ATILabelKeys.CHAPTER_2]);
    DEBUG.info('Setting page view chapter 3: ' + eventValues[ATILabelKeys.CHAPTER_3]);
  };

  AtiDelegate.prototype._createAtiOptions = function(config, destination) {
    var atiOpts;
    if (config) {
      atiOpts = {
        collectDomain: destination.isTest ? COLLECTION_DOMAIN.test : COLLECTION_DOMAIN.live,
        collectDomainSSL: destination.isTest ? COLLECTION_DOMAIN.testSSL : COLLECTION_DOMAIN.liveSSL,
        disableCookie: config[ConfigKeys.ATI_DISABLE_COOKIE] === true ? true : false,
        cookieDomain: config[ConfigKeys.ATI_USE_DEFAULT_COOKIE_DOMAIN] === true ? null : this._getCookieDomain(window),
        domain: destination.isTest ? COLLECTION_DOMAIN.testHost : COLLECTION_DOMAIN.liveHost,
        pixelPath: config[ConfigKeys.ATI_PIXEL_PATH] || '/hit.xiti',
        secure: config[ConfigKeys.ATI_FORCE_HTTPS] === false ? false : true,
        forceHttp: config[ConfigKeys.ATI_FORCE_HTTP] === true ? true : false,
        site: destination.id
      };

      if (config[ConfigKeys.ATI_FORCE_HTTPS] === true) {
        DEBUG.warn('Forcing https for ATI is now deprecated and has no effect, https is the default protocol now');
      }

      if (config[ConfigKeys.ATI_LOG]) {
        DEBUG.warn('Setting ATI_LOG for ATI is now deprecated, please use ATI_COLLECT_DOMAIN');
        atiOpts.collectDomain = config[ConfigKeys.ATI_LOG] + '.' + atiOpts.collectDomain;
      }

      if (config[ConfigKeys.ATI_SSL_LOG]) {
        DEBUG.info('Setting ATI_SSL_LOG for ATI is now deprecated, please use ATI_COLLECT_DOMAIN_SSL');
        atiOpts.collectDomainSSL = config[ConfigKeys.ATI_SSL_LOG] + '.' + atiOpts.collectDomainSSL;
      }

      if (config[ConfigKeys.ATI_COLLECT_DOMAIN]) {
        atiOpts.collectDomain = config[ConfigKeys.ATI_COLLECT_DOMAIN];
      }

      // MYSTATS-4806 work around for ATI bug - set the SSL subdomain as non-SSL subdomain if HTTP is forced
      if (config[ConfigKeys.ATI_FORCE_HTTP] === true) {
        atiOpts.collectDomainSSL = atiOpts.collectDomain;
      } else if (config[ConfigKeys.ATI_COLLECT_DOMAIN_SSL]) {
        atiOpts.collectDomainSSL = config[ConfigKeys.ATI_COLLECT_DOMAIN_SSL];
      }

      if (config[ConfigKeys.ECHO_DEVICE_ID]) {
        atiOpts.ClientSideUserId = {
          clientSideMode: 'required',
          userIdCookieDuration: 397,
          userIdExpirationMode: 'fixed'
        };
      }

      // Configure the cache mode
      switch (config[ConfigKeys.ECHO_CACHE_MODE]) {
        case Enums.EchoCacheMode.ALL:
          atiOpts.Offline = {
            storageMode: ConfigKeys.ATI_CACHE_MODE_ALWAYS
          };
          break;
        case Enums.EchoCacheMode.OFFLINE:
          atiOpts.Offline = {
            storageMode: ConfigKeys.ATI_CACHE_MODE_REQUIRED
          };
          break;
        default:
          atiOpts.Offline = {
            storageMode: ConfigKeys.ATI_CACHE_MODE_NEVER
          };
          break;
      }
    }

    return atiOpts;
  };

  // PRIVATE
  AtiDelegate.prototype._createTag = function(atiOpts) {
    return new AtiDelegate.SmartTag.Tracker.Tag(atiOpts);
  };

  AtiDelegate.prototype._initTag = function(orbitVars) {
    if (this._tag) {
      return;
    }

    var config = this._config;

    var destination = this._getDestination(config, orbitVars);
    var atiOpts = this._createAtiOptions(config, destination);

    this._tag = this._createTag(atiOpts);
    this._tag.onTrigger('Tracker:Hit:Sent:Ok', this._onHitSent.bind(this));

    if (this._debug) {
      this._tag.debug = function(info) {
        DEBUG.info('ATI DEBUG: ' + info);
      };
    }

    if (this._deviceId) {
      this._setSmartTagDeviceId(this._tag, this._deviceId);
    }

    if (this._bbc_hid) {
      this._tag.setParam(ATILabelKeys.HASHED_ID, this._bbc_hid, { hitType: ['all'], permanent: true });
      this._tag.setProp(GLOBAL_USER_ID, this._bbc_hid, true);
    }

    if (config && config[ConfigKeys.APP_CATEGORY]) {
      this.addLabel(ATILabelKeys.APP_CATEGORY, config[ConfigKeys.APP_CATEGORY]);
    }

    // Provide custom GET method if specified
    this._setHTTPGetMethod();

    //set pagename if already set
    if (this._counterName) {
      this.setCounterName(this._counterName);
    }

    if (this._trace) {
      this.setTraceId(this._trace);
    }

    for (var key in this._customParams) {
      this._tag.setParam(key, LabelCleanser.cleanCustomVariable(this._customParams[key]), { hitType: ['all'], permanent: true });
    }

    var appProps = {};
    appProps[ATILabelKeys.APP_NAME] = this._appName;
    appProps[ATILabelKeys.APP_TYPE] = this._appType;
    appProps[ATILabelKeys.LIBRARY_VERSION] = LibraryInfo.getLibraryName() + '-' + LibraryInfo.getLibraryVersion();
    appProps = Utils.combineObjects(appProps, this._customParams, false);
    appProps = Utils.combineObjects(appProps, orbitVars, false);
    this.setGlobalProps(appProps);

    DEBUG.info('Initialised ATI SmartTag with options:' + JSON.stringify(atiOpts));
  };

  AtiDelegate.prototype._setSmartTagDeviceId = function(tag, deviceId) {
    tag.clientSideUserId.set(deviceId);
  };

  AtiDelegate.prototype.start = function(orbitVars) {
    this._orbitVars = orbitVars;
    if (this._isEnabled && !this._hasStarted) {
      this._hasStarted = true;
      this._initTag(orbitVars);
    }
  };

  AtiDelegate.prototype.enable = function() {
    if (!this._isEnabled) {
      this._isEnabled = true;
    }
  };

  AtiDelegate.prototype.disable = function() {
    if (this._isEnabled) {
      this._isEnabled = false;
      this.clearMedia();
    }
  };

  AtiDelegate.prototype._onHitSent = function(trigger, hit) {
    if (hit && hit.details && hit.details.hit) {
      DEBUG.info('Did send hit: ', hit.details.hit);
      this._setLastHitUrl(hit.details.hit);
    }
  };

  AtiDelegate.prototype._addLengthToMediaHit = function(hitUrl) {
    //If the hit is a rich media event, make sure we have the most recent media length (m1)
    if (hitUrl && typeof hitUrl === 'string' && hitUrl.match(/a\=(play|pause|stop|info|refresh|move)/)) {
      if (this._mediaLength) {
        var length = 'm1=' + Math.ceil(this._mediaLength / 1000);
        hitUrl = hitUrl.replace(/m1\=\d+/g, length);
      }
    }

    return hitUrl;
  };

  AtiDelegate.prototype._setLastHitUrl = function(hitUrl) {
    if (hitUrl && typeof hitUrl === 'string' && hitUrl.match(/a\=play/)) {
      this._lastPlayHitUrl = hitUrl;
    } else {
      this._lastHitUrl = hitUrl;
    }
  };

  AtiDelegate.prototype.setTraceId = function(id) {
    if (id && this._tag) {
      this._tag.setParam(ConfigKeys.ECHO_TRACE, id, { hitType: ['all'], permanent: true });
    }
  };

  // Application State Methods
  AtiDelegate.prototype.addLabels = function(labels) {
    this.addProperties(labels);
  };

  AtiDelegate.prototype.addLabel = function(label, value) {
    this.addProperty(label, value);
  };

  AtiDelegate.prototype.addProperties = function(properties) {
    if (typeof properties === 'object') {
      if (properties.bbc_identity) {

        this.setBBCUser({
          bbc_hid: properties.bbc_hid,
          signed_in: properties.bbc_identity === '1'
        });

        delete properties.bbc_hid;
        delete properties.bbc_identity;
      }

      for (var property in properties) {
        if (properties.hasOwnProperty(property)) {
          this.addProperty(property, properties[property]);
        }
      }
    }
  };

  AtiDelegate.prototype.addProperty = function(property, value) {
    if (property === ATILabelKeys.BBC_COUNTER_NAME || property === ATILabelKeys.LEVEL_2_SITE) {
      this._pageValues[property] = value;
    } else {
      this._customParams[property] = value;
    }

    if (this._tag) {
      var globalProps = {};
      globalProps[property] = value;
      this.setGlobalProps(globalProps);
    }
  };

  AtiDelegate.prototype.addCampaignProperties = function(properties) {
    if (!this._tag) {
      DEBUG.error('Cannot set campaign properties: please enable ATI and start Echo before setting campaign properties.');
      return;
    }

    if (properties && typeof properties === 'object') {
      this._tag.setProps(properties, true);
    }
  };

  AtiDelegate.prototype.setLoggedInToBBCId = function(hid) {
    this.setBBCUser({
      bbc_hid: hid,
      signed_in: true
    });
    DEBUG.info('Setting BBC user with hashed ID: ' + hid);
  };

  AtiDelegate.prototype.setLoggedOutOfBBCId = function() {
    if (this._tag) {
      this._tag.delParam(ATILabelKeys.HASHED_ID);
      this._tag.delProp(GLOBAL_USER_ID);
    }

    this._bbc_hid = null;
    DEBUG.info('Removing User ID');
  };

  AtiDelegate.prototype.setAppVersion = function (version) {
    if (version && this._tag) {
      this._tag.setParam(ATILabelKeys.APP_VERSION, '[' + version + ']', { hitType: ['all'], permanent: true });
    }
  };

  AtiDelegate.prototype.setBBCUser = function(userInfo) {
    if (userInfo.bbc_hid) {
      this._bbc_hid = userInfo.bbc_hid;
    } else if (userInfo.signed_in) {
      this._bbc_hid = 'unidentified-user';
    }

    if (this._tag && !this._customParams.bbc_id_wait) {
      this._tag.setParam(ATILabelKeys.HASHED_ID, this._bbc_hid, { hitType: ['all'], permanent: true });
      this._tag.setProp(GLOBAL_USER_ID, this._bbc_hid, true);
    }

    DEBUG.info('Setting BBC user with hashed ID: ' + this._bbc_hid);
  };

  AtiDelegate.prototype.addManagedLabel = dummy;

  AtiDelegate.prototype.removeLabels = function(keysArray) {
    this.removeProperties(keysArray);
  };

  AtiDelegate.prototype.removeLabel = function(label) {
    this.removeProperty(label);
  };

  AtiDelegate.prototype.removeProperties = function(keysArray) {
    for (var i = 0, len = keysArray.length; i < len; i++) {
      this.removeProperty(keysArray[i]);
    }
  };

  AtiDelegate.prototype.removeProperty = function(property) {
    if (property === ATILabelKeys.BBC_COUNTER_NAME || property === ATILabelKeys.LEVEL_2_SITE) {
      delete this._pageValues[property];
    } else {
      if (this._tag) {
        this._tag.delParam(property);
      }

      delete this._customParams[property];
    }
  };

  AtiDelegate.prototype.setCounterName = function (counterName) {

    // Producer needs to be set on pageValues on start
    var destination = this._getDestination(this._config, this._orbitVars);
    var producer = this._getProducer(this._config, this._orbitVars, destination);
    this.setProducer(producer);

    this._counterName = counterName;

    var pageValues = {
      name: counterName,
      level2: this._pageValues[ATILabelKeys.LEVEL_2_SITE]
    };

    if (this._tag) {
      this._tag.page.set(pageValues);
      this._lastPage = pageValues;
    }
  };

  AtiDelegate.prototype.setContentLanguage = dummy;
  AtiDelegate.prototype.setCacheMode = dummy;

  AtiDelegate.prototype.clearCache = function() {
    this._tag.offline.remove();
    DEBUG.info('Clearing cache: this will not send cached events');
  };

  AtiDelegate.prototype.flushCache = function() {
    this._tag.offline.send();
    DEBUG.info('Flushing cache: this will send cached events');
  };

  AtiDelegate.prototype.setESSEnabled = dummy;

  // Analytics Methods
  AtiDelegate.prototype.viewEvent = function(counterName, eventLabels) {
    if (!this._eventsEnabled()) {
      return;
    }

    // Reset the tag so we do not retain any previously provided properties (such as SECTION)
    this._tag.page.reset();

    // Set countername as a persistent label so it's included on all subsequent events.
    this.addLabel(ATILabelKeys.BBC_COUNTER_NAME, counterName);

    this._counterName = counterName;
    var destination = this._getDestination(this._config, this._orbitVars);

    // Producer needs to be set on pageValues object on view event
    var producer = this._getProducer(this._config, this._orbitVars, destination);
    this.setProducer(producer);

    var clonedCustomParams =  Utils.clone(this._customParams);
    var customVars = CustomVariables.create(this._appName, this._appType, clonedCustomParams, eventLabels, this._orbitVars, document);

    // This sets custom/event labels.
    var clonedLabels = Utils.extend(
      eventLabels, true,
      ATILabelKeys.ECHO_EVENT_NAME, 'view'
    );

    var combinedLabels = Utils.combineObjects(clonedCustomParams, clonedLabels, false);

    // Empty object to hold cleaned key/values https://jira.dev.bbc.co.uk/browse/MYSTATS-3817.
    var customObject = {};

    // We want to keep trace and ess keys for testing
    for (var key in combinedLabels) {
      if (key === 'trace') {
        var traceId = combinedLabels[key];
        this.setTraceId(traceId);
        continue;
      }

      // startsWith is actually a Util function, not the ES6 method that the TV linter thinks it is
      // eslint-disable-next-line tv/es2015/no-es6-methods
      if (Utils.startsWith(key, 'ess_')) {
        customObject[key] = combinedLabels[key];
        continue;
      }

      if (this._tag) {
        this._tag.setParam(key, LabelCleanser.cleanCustomVariable(combinedLabels[key]), { hitType: ['all'], permanent: false });
      }
    }

    var clonedPageValues = Utils.clone(this._pageValues);
    var eventValues = Utils.combineObjects(clonedPageValues, customObject);
    eventValues.echo_event = 'view';
    if (customVars) {
      this._tag.customVars.set(customVars);
    }

    // Set the section
    this._setSection(clonedLabels, eventValues, this._orbitVars);
    this._tag.page.set(eventValues);
    this._tag.dispatch();
    this._lastPage = eventValues;
    DEBUG.info('Sending page view event with countername: ' + counterName);

    return this;
  };

  AtiDelegate.prototype.errorEvent = dummy;

  AtiDelegate.prototype.setGlobalProps = function(properties) {
    var globalPropertiesKeyMap = {};
    globalPropertiesKeyMap[ATILabelKeys.APP_NAME] = GLOBAL_APP_NAME;
    globalPropertiesKeyMap[ATILabelKeys.APP_TYPE] = GLOBAL_APP_TYPE;
    globalPropertiesKeyMap[ATILabelKeys.CONTENT_TYPE] = GLOBAL_CONTENT_TYPE;
    globalPropertiesKeyMap.contentType = GLOBAL_CONTENT_TYPE;
    globalPropertiesKeyMap[ATILabelKeys.LIBRARY_VERSION] = GLOBAL_LIBRARY_VERSION;

    for (var property in properties) {
      var key = globalPropertiesKeyMap[property];
      if (key) {
        this._tag.setProp(key, properties[property], true);
      }
    }

  };

  AtiDelegate.prototype.userActionEvent = function(actionType, actionName, eventLabels) {
    if (!this._eventsEnabled()) {
      return;
    }

    // This sets custom/event labels
    var clonedLabels = Utils.extend(
      eventLabels, true,
      ATILabelKeys.ECHO_EVENT_NAME, 'userAct'
    );

    var customObject = Utils.combineObjects(this._customParams, clonedLabels, false);
    var clonedPageValues = Utils.clone(this._pageValues);
    var eventValues = Utils.combineObjects(clonedPageValues, customObject);

    if (eventValues) {
      eventValues[ATILabelKeys.EVENT_ACTION] = actionName;
      eventValues[ATILabelKeys.EVENT_CATEGORY] = actionType;
      eventValues.user = this._bbc_hid;
      eventValues[ATILabelKeys.BBC_COUNTER_NAME] = this._counterName;

      var isBackgroundEventValue = eventValues[ATILabelKeys.IS_BACKGROUND];
      var isBackgroundEvent;
      if (isBackgroundEventValue === false || isBackgroundEventValue === 'false') {
        isBackgroundEvent = false;
      } else if (Boolean(eventValues[ATILabelKeys.IS_BACKGROUND])) {
        isBackgroundEvent = true;
      } else {
        DEBUG.info('Unknown value for is_background: ' + eventValues[ATILabelKeys.IS_BACKGROUND]);
      }

      for (var key in eventValues) {
        if (this._tag) {
          this._tag.setParam(key, LabelCleanser.cleanCustomVariable(eventValues[key]), { hitType: ['all'], permanent: true });
        }
      }

      if (isBackgroundEvent) {
        UserActionEvent.sendImpressionEvent(this._tag, eventValues);
        DEBUG.info('Sent Background action event: ' + actionName);
      } else {
        UserActionEvent.sendClickEvent(this._tag, eventValues);
        DEBUG.info('Sent user action event: ' + actionName);
      }
    }
  };

  // Media Player attributes
  AtiDelegate.prototype.setPlayerName = function(mediaPlayerName) {
    this._mediaPlayerName = mediaPlayerName;
  };

  AtiDelegate.prototype.setPlayerVersion = function(mediaPlayerVersion) {
    this._mediaPlayerVersion = mediaPlayerVersion;
  };

  AtiDelegate.prototype.setPlayerIsPopped = dummy;
  AtiDelegate.prototype.setPlayerWindowState = dummy;
  AtiDelegate.prototype.setPlayerVolume = dummy;
  AtiDelegate.prototype.setPlayerIsSubtitled = dummy;

  /**
   * Sets the broker.
   *
   * This method should only be called by the EchoClient class to
   * ensure the interface is implemented.
   *
   * @param {Object} broker An object conforming to the broker interface.
   *
   * @return {AtiDelegate}
   */
  AtiDelegate.prototype.setBroker = function(broker) {
    this._broker = broker;
    return this;
  };

  // Media Attributes
  AtiDelegate.prototype.setMedia = function(media) {
    if (!this._eventsEnabled()) {
      return;
    }

    // If the media player name has previously provided and isn't set in this media object
    // then use the previously provided value
    if (this._mediaPlayerName && !media.getMediaPlayerName()) {
      media.setMediaPlayerName(this._mediaPlayerName);
    }

    // If the media player version has previously provided and isn't set in this media object
    // then use the previously provided value
    if (this._mediaPlayerVersion && !media.getMediaPlayerVersion()) {
      media.setMediaPlayerVersion(this._mediaPlayerVersion);
    }

    // Set appName and appType on media object so can reference later when constructing media themes
    if (this._appName) {
      media.appName = this._appName;
    } else {
      media.appName = '';
    }

    if (this._appType) {
      media.appType = this._appType;
    } else {
      media.appType = '';
    }

    this.clearMedia();
    this._media = media;
    this.addMedia(this._media);
  };

  AtiDelegate.prototype.setMediaLength = function(length) {
    this._mediaLength = length;
    this._media.setLength(length);
  };

  AtiDelegate.prototype.setMediaBitrate = dummy;
  AtiDelegate.prototype.setMediaCodec = dummy;
  AtiDelegate.prototype.setMediaCDN = dummy;

  /**
   * Handles an updated clip from the LiveBroker
   *
   * @param {Media} newMedia
   * @param {Number} newPosition
   * @param {Number} oldPosition
   */
  AtiDelegate.prototype.liveMediaUpdate = function(newMedia, newPosition, oldPosition) {

    Utils.assert(
      typeof newPosition === 'number',
      'newPosition must be a number'
    );

    Utils.assert(
      typeof oldPosition === 'number',
      'oldPosition must be a number'
    );
    newMedia.setProducerById(this._media.getProducer());

    // stop playback
    this._sendMediaEvent(AV_EVENT_TYPES.STOP);
    this._media = newMedia;
    this.addMedia(this._media);

    this._sendMediaEvent(AV_EVENT_TYPES.PLAY);
  };

  AtiDelegate.prototype.liveEnrichmentFailed = dummy;

  AtiDelegate.prototype._getIdObjectValue = function(_idObject) {
    if (_idObject !== null && _idObject.isValueValid()) {
      return _idObject.getValue();
    }

    return '';
  };

  AtiDelegate.prototype._getMostPrecedentIdObject = function() {
    var versionId = this._media.getVersionIdObject();
    var episodeId = this._media.getEpisodeIdObject();
    var clipId    = this._media.getClipIdObject();
    var vpId      = this._media.getVpIdObject();
    var serviceId = this._media.getServiceIdObject();

    if (versionId.isValueValid()) {
      return versionId;
    } else if (episodeId.isValueValid()) {
      return episodeId;
    } else if (clipId.isValueValid()) {
      return clipId;
    } else if (vpId.isValueValid()) {
      return vpId;
    } else if (serviceId.isValueValid()) {
      return serviceId;
    }

    return null;
  };

  AtiDelegate.prototype._eventsEnabled = function() {
    return this._isEnabled && this._hasStarted;
  };

  AtiDelegate.prototype.addMedia = function(media) {
    if (this._tag && this._tag.richMedia) {

      // Build the set of common media params that are sent with every event
      this._commonMediaParams = {
        playerId: media.getPlayerId(),
        mediaLabel: this._getIdObjectValue(this._getMostPrecedentIdObject()),
        mediaTheme1: MediaThemes.constructThemeOne(media, this._config[ConfigKeys.USE_ESS]),
        mediaTheme2: MediaThemes.constructThemeTwo(media),
        mediaTheme3: MediaThemes.constructThemeThree(media)
      };

      var richMediaParams = {
        mediaType: media.getAvType(),
        playerId: this._commonMediaParams.playerId,
        duration: (!media || !media.getLength()) ?
          0 : Math.ceil(media.getLength() / 1000),
        mediaLabel: this._commonMediaParams.mediaLabel,
        mediaTheme1: this._commonMediaParams.mediaTheme1,
        mediaTheme2: this._commonMediaParams.mediaTheme2,
        mediaTheme3: this._commonMediaParams.mediaTheme3,
        isEmbedded: media.isEmbedded(),
        broadcastMode: media.getMediaConsumptionMode() === BROADCAST_MODE.LIVE ? BROADCAST_MODE.LIVE : BROADCAST_MODE.CLIP,
        refreshDuration: {
          0: 10,
          1: 60
        }
      };
      if (media.getProducerId()) {
        richMediaParams.mediaLevel2 = media.getProducerId();
      } else {
        richMediaParams.mediaLevel2 = 0;
      }

      // we have this here for any properties added after tag initialisation to ensure they are added to the media events
      for (var key in this._customParams) {
        if (this._tag) {
          this._tag.setParam(key, LabelCleanser.cleanCustomVariable(this._customParams[key]), { hitType: ['all'], permanent: true });
        }
      }

      this._tag.richMedia.add(richMediaParams);
    }
  };

  // Media Events

  AtiDelegate.prototype._sendMediaEvent = function(action, additionalEventArgs) {
    if (this._commonMediaParams) {
      MediaEvent.sendEvent(this._tag, this._commonMediaParams, action, additionalEventArgs);
    }
  };

  AtiDelegate.prototype.avPlayEvent = function(position, eventLabels) { // jshint ignore:line
    if (!this._eventsEnabled() || this._media.getPlaying()) {
      // return if events disabled or already playing
      return;
    }

    if (this._media.getBuffering()) {
      this._sendMediaEvent(AV_EVENT_TYPES.INFO, {
        isBuffering: false
      });
    } else {
      this._sendMediaEvent(AV_EVENT_TYPES.PLAY);
      this._addKeepAliveEventSchedule();
    }
  };

  AtiDelegate.prototype._addKeepAliveEventSchedule = function() {
    if (!this._eventsEnabled()) {
      return;
    }

    if (!this._keepAliveIntervalId) {
      this._keepAliveIntervalId = setInterval(
        this._sendKeepAliveEvent.bind(this),
        this._keepAliveDuration);
    }
  };

  AtiDelegate.prototype._removeKeepAliveEventSchedule = function() {
    if (this._keepAliveIntervalId) {
      clearInterval(this._keepAliveIntervalId);
      this._keepAliveIntervalId = undefined;
    }
  };

  AtiDelegate.prototype._sendKeepAliveEvent = function() {
    if (!this._eventsEnabled()) {
      return;
    }

    var producerId =  (this._media && this._media.getProducerId()) ? this._media.getProducerId() : 0;

    var event = {
      name: KEEPALIVE_COUNTERNAME,
      level2: producerId
    };

    this._tag.page.send(event);
    DEBUG.info('Sending keepalive');

    //re-add the previous page so subsequent events are correctly attributed
    if (this._lastPage) {
      this._tag.page.set(this._lastPage);
    }
  };

  AtiDelegate.prototype.avPauseEvent = function() {
    if (!this._eventsEnabled()) {
      return;
    }

    // we have this here for any properties added after tag initialisation to ensure they are added to the media events
    for (var key in this._customParams) {
      if (this._tag) {
        this._tag.setParam(key, LabelCleanser.cleanCustomVariable(this._customParams[key]), { hitType: ['all'], permanent: true });
      }
    }

    this._sendMediaEvent(AV_EVENT_TYPES.PAUSE);
  };

  AtiDelegate.prototype.avBufferEvent = function() {
    if (!this._eventsEnabled()) {
      return;
    }

    this._sendMediaEvent(AV_EVENT_TYPES.INFO, {
      isBuffering: true
    });
  };

  AtiDelegate.prototype.avEndEvent = function() {
    if (!this._eventsEnabled()) {
      return;
    }

    this._isMediaLoaded = false;

    this._removeKeepAliveEventSchedule();

    this._sendMediaEvent(AV_EVENT_TYPES.STOP);

    if (this.isValidMedia()) {
      this.clearMedia();
    }
  };

  AtiDelegate.prototype.avRewindEvent = function() {
    if (!this._eventsEnabled()) {
      return;
    }

    this._sendMediaEvent(AV_EVENT_TYPES.MOVE);
  };

  AtiDelegate.prototype.avFastForwardEvent = function() {
    if (!this._eventsEnabled()) {
      return;
    }

    this._sendMediaEvent(AV_EVENT_TYPES.MOVE);
  };

  AtiDelegate.prototype.avSeekEvent = function() {
    if (!this._eventsEnabled()) {
      return;
    }

    this._sendMediaEvent(AV_EVENT_TYPES.MOVE);
  };

  AtiDelegate.prototype.avUserActionEvent = function(actionType, actionName, position, eventLabels) { // jshint ignore:line
    if (!this._eventsEnabled()) {
      return;
    }

    if (actionType === 'echo_hb' && actionName === 'echo_hb_5') {
      var hitUrl = this._addLengthToMediaHit(this._lastPlayHitUrl);
      CustomEvent.sendRefreshEvent(this._tag, this._getHttpGetMethod(), hitUrl);
      DEBUG.info('Performed 5 second heartbeat');
    }
  };

  AtiDelegate.prototype.clearMedia = function() {
    if (this.isValidMedia()) {
      if (this._isMediaLoaded) {
        this.avEndEvent();
      }

      this._stream = null;
      this._media = null;
      this._commonMediaParams = null;
      this._tag.richMedia.removeAll();
    }
  };

  AtiDelegate.prototype.isValidMedia = function() {
    return this._media !== null && this._media.avType === Enums.AvType.VIDEO;
  };

  AtiDelegate.prototype.isValidLiveMedia = function() {
    return this.isValidMedia() && this._media.isLive();
  };

  /**
   * Gets the device ID that was used to report to ATI.
   *
   * @returns {String|null}
   */
  AtiDelegate.prototype.getDeviceId = function() {
    return this._deviceId;
  };

  /**
   * Sets a new device ID that is used to report to ATI.
   * @param {string} deviceId New device id
   */
  AtiDelegate.prototype.setDeviceId = function(deviceId) {
    this._deviceId = deviceId;
    if (this._tag) {
      DEBUG.info('Updating ATI SmartTag device id:' + deviceId);
      this._setSmartTagDeviceId(this._tag, deviceId);
    }
  };

  AtiDelegate.prototype.requiresLabelCleansing = function() {
    return true;
  };

  return AtiDelegate;
});
