import { gql } from "@apollo/client";

export const GET_ALL_CATEGORIES = gql`
  query GetAllCategories($includeHidden: Boolean) {
    categories(includeHidden: $includeHidden) {
      id
      name
      categoryValues {
        name
      }
      deleted
      description
      displayName
      inTargetColor
      outTargetColor
      priority
      targets {
        tracks {
          categoryValue {
            name
          }
          targetMember
        }
      }
    }
  }
`;
