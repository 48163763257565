import { 
    CheckCircleOutlined,
    CheckCircleTwoTone,
    CloseCircleOutlined,
    DownloadOutlined,
    EditOutlined,
    MailOutlined,
    UsergroupAddOutlined
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout/";
import { useQuery } from "@apollo/client";
import { Button, Col, Input, List, Modal, Row, Table, TableColumnsType } from "antd";
import { FilterValue, TableCurrentDataSource, TableRowSelection } from "antd/lib/table/interface";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./ManageUsers.css";
import CustomHelmet from "../../../components/CustomHelmet";
import { AdminGetAllRoles, AdminGetAllRoles_roles } from "../../../graphql/__generated__/AdminGetAllRoles";
import { AdminGetAllTeams, AdminGetAllTeams_teams } from "../../../graphql/__generated__/AdminGetAllTeams";
import { 
    GetUserList, 
    GetUserList_users, 
    GetUserList_users_roles,
    GetUserList_users_teams,
    GetUserList_users_teams_programs,
    GetUserList_users_teams_programs_department, 
} from "../../../graphql/__generated__/GetUserList";
import { ADMIN_GET_ALL_ROLES } from "../../../graphql/__queries__/AdminGetAllRoles.gql";
import { ADMIN_GET_ALL_TEAMS } from "../../../graphql/__queries__/AdminGetAllTeams.gql";
import { GET_USER_LIST } from "../../../graphql/__queries__/GetUserList.gql";
import { isUserRoleWithPermissions } from "../../../typeguards";
import { capitaliseFirstLetterOfEachWord } from "../../../utils/string";

const { Search } = Input;
const { confirm } = Modal;


enum ToggleSelectAll {
    SELECT_ALL = "selectAll",
    DESELECT_ALL = "deselectAll",
}

type BatchedMailto = {
    description: string;
    href: string;
    id: string;
};

type DisplayUser = 
    Omit<GetUserList_users, "__typename" | "roles" | "teams">
    & MappedTeams 
    & MappedRolesAndPermissions;

type MappedRolesAndPermissions = {
    permissions: string[];
    roles: string[];
};

type MappedTeams = {
    departments: string[];
    divisions: string[];
    teams: string[];
};

type ModalContentProps = {
    batchedMailtos: BatchedMailto[];
    selectedUserIds: string[];
};

type ProgramWithNonNullDepartment = 
    GetUserList_users_teams_programs
    & { readonly department: GetUserList_users_teams_programs_department };

type TableFilters = Record<string, FilterValue | null>;


const MAIL_TO_SEPARATOR = ";";
const MAX_EMAIL_RECIPIENTS = 499;
const TABLE_FILTERS_DEFAULT: TableFilters = { active: ["true"] };

const filterOutProgramsWithNullDepartment = (
    program: GetUserList_users_teams_programs
): program is ProgramWithNonNullDepartment => program.department !== null;

const filterOutDuplicateStrings = (value: string, index: number, array: string[]) => array.indexOf(value) === index;

const mapRawRoles = (
    rawRoles: readonly AdminGetAllRoles_roles[] | readonly GetUserList_users_roles[]
): MappedRolesAndPermissions => ({
    permissions: rawRoles
        .filter(({ name }) => isUserRoleWithPermissions(name))
        .map(perm => perm.name),
    roles: rawRoles
        .filter(({ name }) => !isUserRoleWithPermissions(name))
        .map(role => role.name),
});

const mapRawTeams = (
    rawTeams: readonly AdminGetAllTeams_teams[] | readonly GetUserList_users_teams[]
): MappedTeams => {
    const rawDepartments = rawTeams
        .flatMap(team => team.programs.filter(filterOutProgramsWithNullDepartment))
        .map(program => program.department);
    
    const departments = rawDepartments
        .map(dept => dept.name)
        .filter(filterOutDuplicateStrings)
        .sort((a, b) => a.localeCompare(b));
    const divisions = rawDepartments
        .map(dept => dept.division.name)
        .filter(filterOutDuplicateStrings)
        .sort((a, b) => a.localeCompare(b));
    const teams = rawTeams
        .map(team => team.name)
        .sort((a, b) => a.localeCompare(b));

    return { departments, divisions, teams };
};

const mapDisplayUsers = (
    users: readonly GetUserList_users[]
): DisplayUser[] => users
    .map(user => {
        const { id, firstName, lastName, email, active } = user;
        const { departments, divisions, teams } = mapRawTeams(user.teams);
        const { permissions, roles } = mapRawRoles(user.roles);

        return { email, firstName, lastName, permissions, roles, teams, departments, divisions, active, id };
    })
    .sort((a, b) => a.email.localeCompare(b.email));


const applySearchFilter = (users: DisplayUser[] | undefined, searchTerms: string[]) => (
    users?.filter(({ firstName, lastName, email, permissions, roles, teams, departments, divisions }) => (
        searchTerms.every(term => (
            `${firstName}
            :${lastName}
            :${email}
            :${permissions.join(":")}
            :${roles.join(":")}
            :${teams.join(":")}
            :${departments.join(":")}
            :${divisions.join(":")}`
        ).toLocaleLowerCase().includes(term.toLocaleLowerCase()))
    )) || []
);

const convertUsersToCsv = (users: DisplayUser[]) => {
    const titleKeys = Object.keys(users[0]);
    const exportUsers: string[][] = [];

    exportUsers.push(titleKeys);
    users.forEach(user => {
        const attributes = Object.values(user).map(attr => {
            if (typeof attr !== "string" && typeof attr !== "boolean" && attr.length > 1) {
                return `"${attr.join(", ")}"`;
            } else {
                return String(attr);
            }
        });
        exportUsers.push(attributes);
    });

    let csvContent = "";
    exportUsers.forEach(row => {
        csvContent += (row.join(",") + "\n");
    });
    
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    return URL.createObjectURL(blob);
};

const getModalContent = ({ batchedMailtos, selectedUserIds }: ModalContentProps) => (
    <>
        <p>
            You have selected <b>{selectedUserIds.length}</b> {selectedUserIds.length > 1 ? "users" : "user"}.
            <b>{` ${batchedMailtos.length}`}</b> {
                batchedMailtos.length > 1 ? "email links have been created" : "email link has been created"
            }.
        </p>
        <p>
            <b>Click on each link below</b> to open a blank email addressed to that batch of recipients.
        </p>
        <Row gutter={[12, 12]} style={{ marginBottom: 12 }}>
            {batchedMailtos.map(({ description, href, id }) => (
                <Col key={id} span={24}>
                    <input type="checkbox" id={id} name={id} />
                    <label className="strikethrough" htmlFor={id}>
                        <a href={href}>{description}</a>
                    </label>
                </Col>
            ))}
        </Row>
        <p>
            <b>&quot;Clear Selections & Filters&quot;</b> will close this window, deselect all selected users and reset all filters.
        </p>
        <p>
            <b>&quot;Close&quot;</b> will close this window and keep your user selection and filters intact.
        </p>
    </>
);

export const ManageUsers = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [activeTableFilters, setActiveTableFilters] = useState<TableFilters>(TABLE_FILTERS_DEFAULT);
    const [displayedUserIds, setDisplayedUserIds] = useState<string[]>([]);
    const [exportAllUsersUrl, setExportAllUsersUrl] = useState<string>("");
    const [exportSelectedUsersUrl, setExportSelectedUsersUrl] = useState<string>("");
    const [searchTerms, setSearchTerms] = useState<string[]>([]);
    const [selectAllToggle, setSelectAllToggle] = useState<ToggleSelectAll>(ToggleSelectAll.SELECT_ALL);
    const [selectedUserIds, setSelectedUsersIds] = useState<string[]>([]);
    const [tableFilteredUserIds, setTableFilteredUserIds] = useState<string[]>([]);

    const rawRoles = useQuery<AdminGetAllRoles>(ADMIN_GET_ALL_ROLES, { fetchPolicy: "network-only" });
    const rawTeams = useQuery<AdminGetAllTeams>(ADMIN_GET_ALL_TEAMS, { fetchPolicy: "network-only" });
    const rawUsers = useQuery<GetUserList>(GET_USER_LIST, { fetchPolicy: "network-only" });

    const { permissions, roles } = useMemo(() => mapRawRoles(rawRoles.data?.roles || []), [rawRoles]);
    const { departments, divisions } = useMemo(() => mapRawTeams(rawTeams.data?.teams || []), [rawTeams]);
    const users = useMemo(() => mapDisplayUsers(rawUsers.data?.users || []), [rawUsers]);
    const usersFilteredBySearch = useMemo(() => (
        searchTerms.length === 0 ? users : applySearchFilter(users, searchTerms)
    ), [users, searchTerms]);

    useMemo(() => {
        setTableFilteredUserIds(usersFilteredBySearch.filter(({ active }) => active === true).map(({ id }) => id));
    }, [usersFilteredBySearch]);
    
    useMemo(() => {
        const searchFilteredUserIds = usersFilteredBySearch.map(({ id }) => id);
        const newDisplayedUserIds = tableFilteredUserIds.length > 0 
            ? searchFilteredUserIds
                .filter(searchFilterId => tableFilteredUserIds.some(tableFilterId => tableFilterId === searchFilterId)) 
            : searchFilteredUserIds;
        setDisplayedUserIds(newDisplayedUserIds);
    }, [tableFilteredUserIds, usersFilteredBySearch]);

    const columns: TableColumnsType<DisplayUser> = [
        {
            title: t("admin.user.index.columns.email"),
            key: "email",
            dataIndex: "email",
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.email.localeCompare(b.email),
            width: "20%",
        },
        {
            title: t("admin.user.index.columns.firstName"),
            key: "firstName",
            dataIndex: "firstName",
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
            width: "10%",
        },
        {
            title: t("admin.user.index.columns.lastName"),
            key: "lastName",
            dataIndex: "lastName",
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
            width: "10%",
        },
        {
            title: "",
            key: "action",
            render: (record: DisplayUser) => (
                <a href={`/admin/users/${record.id}`}>
                    <EditOutlined />
                </a>
            ),    
            width: "2%",
        },
        {
            title: t("admin.user.index.columns.permissions"),
            key: "permissions",
            dataIndex: "permissions",
            filters: permissions.map(perm => ({ text: capitaliseFirstLetterOfEachWord(perm), value: perm })),
            filteredValue: activeTableFilters.permissions || null,
            onFilter: (value: boolean | string | number, record: DisplayUser) => (
                record.permissions.some(perm => perm === value)
            ),
            render: (perms: string[]) => (perms.length > 0 &&
                <List 
                    dataSource={perms}
                    renderItem={(item) => <List.Item>{capitaliseFirstLetterOfEachWord(item)}</List.Item>}
                    size="small"
                />
            ),
            width: "11%",
        },
        {
            title: t("admin.user.index.columns.roles"),
            key: "roles",
            dataIndex: "roles",
            filters: roles.map(role => ({ text: capitaliseFirstLetterOfEachWord(role), value: role })),
            filterSearch: true,
            filteredValue: activeTableFilters.roles || null,
            onFilter: (value: boolean | string | number, record: DisplayUser) => (
                record.roles.some(role => role === value)
            ),
            render: (roles: string[]) => (roles.length > 0 &&
                <List 
                    dataSource={roles}
                    renderItem={(item) => <List.Item>{capitaliseFirstLetterOfEachWord(item)}</List.Item>}
                    size="small"
                />
            ),
            width: "10%",
        },
        {
            title: t("admin.user.index.columns.teams"),
            key: "teams",
            dataIndex: "teams",
            render: (teams: string[]) => (teams.length > 0 &&
                <List 
                    dataSource={teams}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                    size="small"
                />
            ),
            width: "15%",
        },
        {
            title: t("admin.user.index.columns.departments"),
            key: "departments",
            dataIndex: "departments",
            filters: departments.map(department => ({ text: department, value: department })),
            filterSearch: true,
            filteredValue: activeTableFilters.departments || null,
            onFilter: (value: boolean | string | number, record: DisplayUser) => (
                record.departments.some(department => department === value)
            ),
            render: (departments: string[]) => (departments.length > 0 &&
                <List 
                    dataSource={departments}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                    size="small"
                />
            ),
            width: "12%",
        },
        {
            title: t("admin.user.index.columns.divisions"),
            key: "divisions",
            dataIndex: "divisions",
            filters: divisions.map(division => ({ text: division, value: division })),
            filteredValue: activeTableFilters.divisions || null,
            onFilter: (value: boolean | string | number, record: DisplayUser) => (
                record.divisions.some(division => division === value)
            ),
            render: (divisions: string[]) => (divisions.length > 0 &&
                <List 
                    dataSource={divisions}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                    size="small"
                />
            ),
            width: "9%",
        },
        {
            title: t("admin.user.index.columns.active"),
            key: "active",
            dataIndex: "active",
            filters: [{ text: "Active", value: "true" }, { text: "Inactive", value: "false" }],
            filteredValue: activeTableFilters.active || null,
            onFilter: (value: boolean | string | number, record: DisplayUser) => `${record.active}` === value,
            render: (active: boolean) => (active ? <CheckCircleOutlined /> : <CloseCircleOutlined />),
            width: "8%",
        },
    ];

    const rowSelection: TableRowSelection<DisplayUser> = {
        columnWidth: "3%",
        selectedRowKeys: selectedUserIds,
        type: "checkbox",
        onChange: (selectedRowKeys: React.Key[]) => {
            const selectedRowKeysStringified = selectedRowKeys.map(key => String(key));

            const newSelectedUserIds = selectedRowKeysStringified.filter(key => !selectedUserIds.some(id => id === key));
            const newDeselectedUserIds = selectedUserIds
                .filter(id => displayedUserIds.some(displayId => displayId === id))
                .filter(id => !selectedRowKeysStringified.some(key => key === id));
            const updatedSelectedUserIds = selectedUserIds
                .concat(newSelectedUserIds)
                .filter(id => !newDeselectedUserIds.some(deselectId => deselectId === id));
            
            setSelectedUsersIds(updatedSelectedUserIds);
        },
    };

    const toggleSelectAll = () => {
        if (selectAllToggle === ToggleSelectAll.SELECT_ALL) {
            const updatedSelectedUserIds = selectedUserIds.concat(displayedUserIds).filter(filterOutDuplicateStrings);
            setSelectedUsersIds(updatedSelectedUserIds);
            setSelectAllToggle(ToggleSelectAll.DESELECT_ALL);
        } else {
            const updatedSelectedUserIds = selectedUserIds.filter(id => !displayedUserIds.some(displayId => displayId === id));
            setSelectedUsersIds(updatedSelectedUserIds);
            setSelectAllToggle(ToggleSelectAll.SELECT_ALL);
        }
    };

    const handleTableColumnFiltering = (
        _pagination, filters: TableFilters, _sorter, extra: TableCurrentDataSource<DisplayUser>
    ) => {
        setActiveTableFilters(filters);
        setTableFilteredUserIds(extra.currentDataSource.map(({ id }) => id));
    };

    const exportAllUsers = () => {
        const url = convertUsersToCsv(users);
        setExportAllUsersUrl(url);
    };

    const exportSelectedUsers = () => {
        const selectedUsers = users.filter(user => selectedUserIds.some(id => id === user.id));
        const url = convertUsersToCsv(selectedUsers);
        setExportSelectedUsersUrl(url);
    };

    const createMailtos = () => {
        const selectedUserEmails = users
            .filter(user => selectedUserIds.some(id => id === user.id))
            .map(({ email }) => email.toLocaleLowerCase());
        
        const batchedMailtos: BatchedMailto[] = [];
        for (let i = 0; i < selectedUserEmails.length; i += MAX_EMAIL_RECIPIENTS) {
            const batchNumber = (i / MAX_EMAIL_RECIPIENTS) + 1;
            const chunk = selectedUserEmails.slice(i, i + MAX_EMAIL_RECIPIENTS);
            
            const description = `Email Link ${batchNumber} - containing users [${i + 1} - ${i + chunk.length}]`;
            const href = `mailto:${chunk.join(MAIL_TO_SEPARATOR)}`;
            const id = `batch-${batchNumber}`;
            
            batchedMailtos.push({ description, href, id });
        }

        confirm({
            title: t("admin.user.index.actions.openEmailSelectedUsers.title"),
            cancelText: t("admin.user.index.actions.openEmailSelectedUsers.cancelText"),
            centered: true,
            closable: true,
            content: getModalContent({ batchedMailtos, selectedUserIds }),
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
            okButtonProps: { danger: true, type: "default" },
            okText: t("admin.user.index.actions.openEmailSelectedUsers.okText"),
            onOk: () => clearSelectionsAndFilters(),
            width: "50%"
        });
    };

    const resetFilters = () => {
        setActiveTableFilters(TABLE_FILTERS_DEFAULT);
        setTableFilteredUserIds(usersFilteredBySearch.filter(({ active }) => active === true).map(({ id }) => id));
    };

    const clearSelectionsAndFilters = () => {
        resetFilters();
        setSelectedUsersIds([]);
        setSelectAllToggle(ToggleSelectAll.SELECT_ALL);
    };

    return (
        <Row gutter={[16, 32]}>

            {/* Page Title */}
            <CustomHelmet title={t("admin.user.index.title")} />
            
            {/* Page Header */}
            <Col span={24}>
                <PageHeader 
                    title={t("admin.user.index.title")}
                    extra={[
                        // Export All Users //
                        <Button
                            title={t("admin.user.index.actions.exportAllUsers")}
                            key="export-all-users"
                            type="primary"
                            shape="round"
                            disabled={rawUsers.loading}
                            download="all-users.csv"
                            href={exportAllUsersUrl}
                            icon={<DownloadOutlined />}
                            onClick={exportAllUsers}
                        >
                            {t("admin.user.index.actions.exportAllUsers")}
                        </Button>,
                        // Export Selected Users //
                        <Button
                            title={t("admin.user.index.actions.exportSelectedUsers")}
                            key="export-selected-users"
                            type="primary"
                            shape="round"
                            disabled={rawUsers.loading || selectedUserIds.length < 1}
                            download="selected-users.csv"
                            href={exportSelectedUsersUrl}
                            icon={<DownloadOutlined />}
                            onClick={exportSelectedUsers}
                        >
                            {t("admin.user.index.actions.exportSelectedUsers")}
                        </Button>,
                        // Email Selected Users //
                        <Button
                            title={t("admin.user.index.actions.emailSelected")}
                            key="email-selected-users"
                            type="primary"
                            shape="round"
                            disabled={rawUsers.loading || selectedUserIds.length < 1}
                            icon={<MailOutlined />}
                            onClick={createMailtos}
                        >
                            {t("admin.user.index.actions.emailSelected")}
                        </Button>                                        
                    ]}
                />
            </Col>

            <Col span={24}>
                <Row gutter={[16, 16]}>
                    {/* Search Users */}
                    <Col span={8}>
                        <Search 
                            allowClear
                            disabled={rawUsers.loading}
                            onSearch={(input) => setSearchTerms(input.split(" "))} 
                            placeholder={t("admin.user.index.actions.search")}
                        />
                    </Col>

                    {/* Add New Users */}
                    <Col span={4}>
                        <Button
                            title={t("admin.user.add.title")}
                            key="add-users"
                            type="primary"
                            icon={<UsergroupAddOutlined />}
                            onClick={() => navigate("/admin/users/add")}
                        >
                            {t("admin.user.add.title")}
                        </Button>
                    </Col>
                </Row>
            </Col>

            <Col span={24}>
                <Row justify="start" gutter={[16, 16]}>
                    {/* Toggle Select All Users */}
                    <Col>
                        <Button
                            title={t(`admin.user.index.actions.${selectAllToggle}`)}
                            key="toggle-select-all"
                            type="primary"
                            disabled={rawUsers.loading}
                            ghost={true}
                            onClick={toggleSelectAll}
                            size="small"
                        >
                            {t(`admin.user.index.actions.${selectAllToggle}`)}
                        </Button>
                    </Col>

                    {/* Clear Selections & Filters */}
                    <Col>
                        <Button
                            title={t("admin.user.index.actions.clearSelectionsAndFilters")}
                            key="reset-filters-and-selections"
                            type="primary"
                            disabled={rawUsers.loading}
                            ghost={true}
                            onClick={clearSelectionsAndFilters}
                            size="small"
                        >
                            {t("admin.user.index.actions.clearSelectionsAndFilters")}
                        </Button>
                    </Col>

                    {/* Reset Filters */}
                    <Col>
                        <Button
                            title={t("admin.user.index.actions.resetFiltersOnly")}
                            key="reset-filters"
                            type="primary"
                            disabled={rawUsers.loading}
                            ghost={true}
                            onClick={resetFilters}
                            size="small"
                        >
                            {t("admin.user.index.actions.resetFiltersOnly")}
                        </Button>
                    </Col>

                    {/* View Users */}
                    <Col span={24}>
                        <Table
                            columns={columns}
                            dataSource={usersFilteredBySearch}
                            loading={rawUsers.loading}
                            onChange={handleTableColumnFiltering}
                            rowKey={({ id }) => id}
                            rowSelection={rowSelection}
                            size="small"
                            tableLayout="fixed"
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};