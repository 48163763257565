import { CloudDownloadOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout/";
import { Button, Col, Row, message } from "antd";
import useAxios from "axios-hooks";
import { useTranslation } from "react-i18next";

import CustomHelmet from "../../../components/CustomHelmet";


export const Exports = () => {
    const { t } = useTranslation();

    const [{ loading: loadingAnomalies, error: anomaliesError }, getAnomaliesCsv] = useAxios(
        { url: "/api/admin-anomalies", responseType: "blob", timeout: 30000 },
        { manual: true },
    );
    const [{ loading: loadingEngagementHistory, error: engagementHistoryError }, getEngagementHistoryCsv] = useAxios(
        { url: "/api/engagement-history", responseType: "blob", timeout: 30000 },
        { manual: true },
    );

    const onClickExportAnomalies = async () => {
        const response = await getAnomaliesCsv();
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", t("admin.exports.anomalies.filename"));
        document.body.appendChild(link);
        link.click();
    };

    const onClickExportEngagementHistory = async () => {
        const response = await getEngagementHistoryCsv();
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", t("admin.exports.engagement.filename"));
        document.body.appendChild(link);
        link.click();
    };

    if (!!anomaliesError || !!engagementHistoryError) {
        message.error(t("admin.exports.error"));
    }

    return (
        <Row gutter={[16, 16]}>
            <CustomHelmet title={t("admin.exports.title")} />

            <Col span={24}>
                <PageHeader title={t("admin.exports.title")} subTitle={t("admin.exports.subtitle")}/>
            </Col>

            <Col offset={1} span={4}>
                <Button
                    loading={loadingAnomalies}
                    icon={<CloudDownloadOutlined />}
                    onClick={onClickExportAnomalies}
                >
                    {t("admin.exports.anomalies.title")}
                </Button>
            </Col>
            <Col span={4}>
                <Button
                    loading={loadingEngagementHistory}
                    icon={<CloudDownloadOutlined />}
                    onClick={onClickExportEngagementHistory}
                >
                    {t("admin.exports.engagement.title")}
                </Button>
            </Col>
        </Row>
    );
};