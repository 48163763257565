import { gql } from "@apollo/client";

export const ADMIN_GET_TAGS = gql`
  query AdminGetTags {
    tags {
      id
      name
      programs {
        id
        name
        datasets {
          id
          name
        }
      }
    }
  }
`;
