define(function() {
  'use strict';

  var producers = {
    // Permitted values for setting the Producer Name
    ACADEMY: 125,
    ACCOUNT: 1,
    AFAAN_OROMOO: 2,
    AFRIQUE: 3,
    AMHARIC: 4,
    ARABIC: 5,
    AZERI: 6,
    BBC: 7,
    BBC_ALBA: 8,
    BBC_ARCHIVE: 127,
    BBC_ARTS: 9,
    BBC_ASIAN_NETWORK: 10,
    BBC_FOOD: 11,
    BBC_FOUR: 12,
    BBC_HD: 13,
    BBC_MUSIC: 14,
    BBC_NEWS_CHANNEL: 15,
    BBC_ONE: 16,
    BBC_PARLIAMENT_CHANNEL: 17,
    BBC_RADIO: 18,
    BBC_RADIO_1: 19,
    BBC_RADIO_1XTRA: 20,
    BBC_RADIO_2: 21,
    BBC_RADIO_3: 22,
    BBC_RADIO_4: 23,
    BBC_RADIO_4_EXTRA: 24,
    BBC_RADIO_5_LIVE: 25,
    BBC_RADIO_6_MUSIC: 26,
    BBC_SCOTLAND: 120,
    BBC_SEND: 121,
    BBC_STUDIOS: 128,
    BBC_THREE: 28,
    BBC_TWO: 29,
    BBC_WORLD_NEWS: 30,
    BENGALI: 31,
    BITESIZE: 32,
    BRASIL: 33,
    BRITBOX: 34,
    BRITBOX_AU: 132,
    BURMESE: 35,
    CBBC: 36,
    CBEEBIES: 37,
    CHINESE: 38,
    ENGLISH_REGIONS: 39,
    GAHUZA: 40,
    GATEWAY: 41,
    GNL_AUTOS: 42,
    GNL_CAPITAL: 43,
    GNL_CULTURE: 44,
    GNL_EARTH: 45,
    GNL_FUTURE: 46,
    GNL_HOMEPAGE: 47,
    GNL_STORYWORKS: 48,
    GNL_TRAVEL: 49,
    GUJARATI: 50,
    HAUSA: 51,
    HINDI: 52,
    IGBO: 53,
    INDONESIAN: 54,
    IPLAYER: 55,
    JAPANESE: 56,
    KOREAN: 57,
    KYRGYZ: 58,
    MARATHI: 59,
    MEDIA_ACTION: 60,
    MONITORING: 61,
    MUNDO: 62,
    NEPALI: 63,
    NEWS: 64,
    NEWS_LABS: 129,
    NEWSROUND: 65,
    NORTHERN_IRELAND: 66,
    OTHER: 67,
    OWN_IT: 118,
    PARTICIPATION: 130,
    PASHTO: 68,
    PERSIAN: 69,
    PIDGIN: 70,
    PROGRAMMES: 71,
    PS_HOMEPAGE: 72,
    PUNJABI: 73,
    RD: 126,
    RECEPTION: 124,
    REEL: 74,
    RUSSIAN: 75,
    S4C: 76,
    SCHOOL_RADIO: 77,
    SCHOOL_REPORT: 78,
    SCOTLAND: 79,
    SEARCH: 80,
    SERBIAN: 81,
    SINHALA: 82,
    SOMALI: 83,
    SOUNDS: 84,
    SPORT: 85,
    SWAHILI: 86,
    TAMIL: 87,
    TASTER: 88,
    TEACH: 119,
    TELUGU: 89,
    THAI: 90,
    TIGRINYA: 91,
    TURKISH: 92,
    TV_CERTIFICATION: 131,
    UK_CHINA: 93,
    UKRAINIAN: 94,
    URDU: 95,
    UZBEK: 96,
    VIETNAMESE: 97,
    VOICE: 98,
    VOICE_FIRST_FORMATS: 99,
    VOLT: 122,
    WALES: 100,
    WEATHER: 101,
    WEATHER_WATCHERS: 102,
    WORLD_SERVICE_ENGLISH: 103,
    WORKLIFE: 104,
    WS_LEARNING_ENGLISH: 105,
    WS_PARTNERS_B2B: 106,
    YORUBA: 107,
    NEWS_ENGLISH_REGIONS: 108,
    SPORT_ENGLISH_REGIONS: 109,
    NEWS_SCOTLAND: 110,
    SPORT_SCOTLAND: 111,
    NEWS_WALES: 112,
    SPORT_WALES: 113,
    NEWS_NORTHERN_IRELAND: 114,
    SPORT_NORTHERN_IRELAND: 115,
    AUDIENCE_SERVICES: 116,
    WORLD_NEWS_PROGRAMMES: 117,

    // Returns the ID associated with a given producer code
    // (e.g. passing in 'WORLD_NEWS_PROGRAMMES' returns 117.
    getId: function(producerCode) {
      var id;
      if (producerCode) {
        id = this[producerCode.toUpperCase()];
      }

      return id;
    }
  };

  return producers;
});
