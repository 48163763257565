define(function() {
  'use strict';

  /**
   * CookiesModule is a helper module to obtain cookie values from the
   * stored cookie string on the window.document
   *
   * @type {{getCookieValueByName: getCookieValueByName}}
   */
  var CookiesModule = {

    /**
     *
     * @function getCookieValueByName
     * @description - helper function to get the cookie
     * value from the Browser's cookies
     *
     * name - name of cookie you require the value off
     * _window - Browser Window (optional parameter)
     * @returns {string} - The cookie value requested or
     * null if does not exist
     */
    getCookieValueByName: function(name, _window) {
      var win = _window || window;
      var cookieName = name + '=';
      var document = win.document;
      var cookiesArray = {};

      if (typeof document === 'undefined' || typeof document.cookie !== 'string') {
        return null;
      } else {
        cookiesArray = document.cookie.split(';');
      }

      for (var i = 0, length = cookiesArray.length; i < length; i++) {
        var cookie = cookiesArray[i];

        if (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1, cookie.length);
        }

        if (cookie.indexOf(cookieName) === 0) {
          return cookie.substring(cookieName.length, cookie.length);
        }
      }

      return null;
    }
  };

  return CookiesModule;
});
