define(function(require) {
  'use strict';

  var Util = require('../methods');
  var DEBUG = require('../debug');
  var Enums = require('../../enumerations');
  var LabelRules = require('./label-rules');

  var strictLabels = [
    'player_name', 'ns_st_ws', 'bbc_site', 'app_type', 'app_name',
    'bbc_store_pf', 'bbc_store_ed', 'bbc_store_fr', 'bbc_store_src'
  ];

  var sensitiveParams = ['token', 'guardianToken', 'nonce', 'dateOfBirthDay', 'dateOfBirthMonth', 'dateOfBirthYear', 'dateOfBirth', 'permissionToken'];

  var labelRuleMap = {
    name: LabelRules.COUNTERNAME
  };

  labelRuleMap[Enums.ManagedLabels.EVENT_MASTER_BRAND] =
    LabelRules.EVENT_MASTER_BRAND;

  // Initialise rule map for later use
  for (var i = 0; i < strictLabels.length; i++) {
    labelRuleMap[strictLabels[i]] = LabelRules.STRICT;
  }

  function _lookupRule(key) {
    if (labelRuleMap.hasOwnProperty(key)) {
      return labelRuleMap[key];
    }

    return LabelRules.CUSTOM;
  }

  /**
   * Cleans the label using base (event mb/label key) rules
   * @param {string} item - The label key/value to clean
   * @returns {string} a cleaned label key/value
   */
  function _cleanBase(item) {
    return item && item
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '_') // Caret matches opposite
      .replace(/(^_+)|(_+$)/g, '');
  }

  /**
   * Cleans the key used for a particular label
   * @param {string} labelKey - The label key to clean
   * @returns {string} a cleaned label value
   */
  function cleanLabelKey(labelKey) {
    var newkey = _cleanBase(labelKey);

    Util.assert(labelKey === newkey,
      'Label key can only contain: [a-z0-9_], and no consecutive "_"s or ' +
      '"_" at the begining or end, got "' + labelKey +
      '". This will be sent as "' + newkey + '"', true);

    return newkey;
  }

  /**
   * Cleans the label using event master brand rules
   * @param {string} labelValue - The label value to clean
   * @returns {string} a cleaned label value
   */
  function _cleanEventMasterBrand(labelValue) {
    return _cleanBase(labelValue);
  }

  /**
   * Cleans the label using strict rules
   * @param {string} labelValue   - The label value to clean
   * @returns {string} a cleaned label value
   */
  function _cleanStrict(labelValue) {
    return labelValue && labelValue
      .toLowerCase()
      .replace(/(^\s+)|(\s+$)/g, '')
      .replace(/[^a-z0-9]+/g, '-') // Caret matches opposite
      .replace(/(^-+)|(-+$)/g, '');
  }

  /**
   * Cleans the label using custom rules
   * @param {string} labelValue   - The label value to clean
   * @returns {string} a cleaned label value
   */
  function _cleanCustom(labelValue) {
    return labelValue && labelValue
      .replace(/(^\s+)|(\s+$)/g, '')
      .replace(/\|/g, '!');
  }

  /**
   * Cleans the label using countername rules
   * @param {string} name - The countername to clean
   * @returns {string} a cleaned label value
   */
  function cleanCounterName(name) {
    var countername = name && name
      .toLowerCase()
      .replace(/[^a-z0-9\.]+/g, '_') // Caret matches opposite
      .replace(/\.+/g, '.')
      .replace(/(^_+)|(_+$)/g, '') || 'no.name.page';

    if (name !== countername) {
      // If there are dashes, print warning as it is actualy OK
      if (name && name.search(/-/) !== -1) {
        DEBUG.warn('Submitted countername ("' + name + '") contains at least ' +
          'one "-". Dashes are not permitted but, for legacy reasons, "-" ' +
          'and "_" are equivalent in DAx. Echo converts dashes to ' +
          'underscores, so the countername will be sent as "' +
          countername + '"');
      } else {
        DEBUG.warn('countername may only contain: [a-z0-9._], got "' +
          name + '". This will be cleaned up and sent as "' +
          countername + '"');
      }
    }

    // counternames must end in .page, silently add this
    if (countername.search(/\.page$/) === -1) {
      countername += '.page';
    }

    return countername;
  }

  /**
   * Helper method to determine rules and
   * clean label values based on set rules
   *
   * @param {string} labelKey   - The label key to help look up rules
   * @param {string} labelValue - The label value you need to clean
   * @returns {string} a cleaned label value
   */
  function cleanLabelValue(labelKey, labelValue) {
    var debugInfo = '';
    var newValue = labelValue;
    var cleanType = _lookupRule(labelKey);

    // Countername appends page
    if (cleanType === LabelRules.COUNTERNAME) {
      labelValue = cleanCounterName(labelValue);
    }

    if (typeof labelValue === 'number' || typeof labelValue === 'boolean') {
      return labelValue.toString();
    }

    // Block non-strings that don't co-erce to empty
    if (typeof labelValue !== 'string') {
      DEBUG.error('Label ' + labelKey + ' must have number, boolean or string value');
      return;
    }

    switch (cleanType) {
      case LabelRules.EVENT_MASTER_BRAND:
        debugInfo =  'Label value can only contain [0-9a-z], and ' +
          'no consecutive "_"s and no "_"s or whitespace at the start or end.';
        newValue = _cleanEventMasterBrand(labelValue);
        break;

      case LabelRules.STRICT:
        debugInfo = 'Strict Label values can only contain [ 0-9a-z-], and no ' +
          'consecutive "-"s and no "-"s or whitespace at the start or end.';
        newValue = _cleanStrict(labelValue);
        break;

      case LabelRules.NO_CLEANSING:
        break;

      default:
        debugInfo = 'Custom Label value cannot contain whitespace ' +
          'at start or end or pipes (|).';
        newValue = _cleanCustom(labelValue);
    }

    debugInfo += 'Got "' + labelValue + '", will be ' + 'replaced with "' + newValue + '"';
    Util.assert(labelValue === newValue, debugInfo, true);

    return newValue;
  }

  function cleanLanguage(value) { // Same as managed label except allows CAPS
    if (typeof value === 'number' || typeof value === 'boolean') {
      return value.toString();
    }

    var newVal = value && value
      .replace(/(^\s*)|(\s*$)/g, '')
      .replace(/[^0-9a-zA-Z._]+/g, '-')
      .replace(/(^-)|(-$)/g, '') || '';

    Util.assert(
      value === newVal,
      'Language label value can only contain [0-9a-zA-Z._-], and no ' +
      'consecutive "-"s and no - at the start or end. Got "' + value +
      '", will be replaced with "' + newVal + '"', true
    );

    return newVal;
  }

  /**
   * Cleans the string of any unauthorized characters by removing leading and trailing whitespace characters,
   * removing square brackets and replacing one or more consecutive whitespace characters with a single
   * whitespace.
   * @param {string} value
   * @returns {string} a cleaned label value
   */
  function cleanCustomVariable(value) {
    return value && value

      // Ensure the object is a string
      .toString()

      // Strip zero-width spaces (which don't count as whitespace characters to trim)
      .replace(/[\u200B-\u200D\uFEFF]+/g, '')

      // Strip leading and trailing whitespace
      .trim()

      // Strip square brackets
      .replace(/\[|\]/g, '')

      // Replace & with $
      .replace(/&/g, '$')

      // Replace consecutive whitespace characters with a single space
      .replace(/\s{1,}/g, ' ');
  }

  function cleanMediaId(value) {
    if (typeof value !== 'string') {
      return value;
    }

    return value && value

      // Strip leading and trailing whitespace
      .trim()

      // Replace whitespace or multiple whitespaces with an underscore
      .replace(/\s+/g, '_')

       // Remove non-authorised characters (authorised characters are a-z A-Z 0-9 / . _ ~ -)
      .replace(/[^a-zA-Z0-9/.~\-_]/g, '');
  }

  function cleanLabels(labels) {
    var newLabels = {};
    var newKey;

    for (var key in labels) {
      newKey = cleanLabelKey(key);
      newLabels[newKey] = cleanLabelValue(newKey, labels[key]);
    }

    return newLabels;
  }

  /**
   * Removes potentially senstive information from URL parameters in a custom variable.
   * Must be called before cleanCustomVariable.
   * @param {string} value
   * @returns {string} a string with any sensitive url params obfuscated
   */
  function removeSensitiveInfo(value) {
    if (value && typeof value === 'string') {
      for (i = 0; i < sensitiveParams.length; i++) {
        var regex = new RegExp(sensitiveParams[i] + '=[^\&]*', 'g');
        value = value.replace(regex, sensitiveParams[i] + '=XXXX');
      }
    }

    return value;
  }

  function removeUndefined(obj) {
    Object.keys(obj).forEach(function(key) {
      if (obj[key] === undefined) {
        DEBUG.info('Removing key ' + key + ' as value is undefined');
        delete obj[key];
      }
    });
    return obj;
  }

  return {
    cleanCounterName: cleanCounterName,
    cleanLabelKey: cleanLabelKey,
    cleanLabelValue: cleanLabelValue,
    cleanLanguage: cleanLanguage,
    cleanLabels: cleanLabels,
    cleanCustomVariable: cleanCustomVariable,
    cleanMediaId: cleanMediaId,
    removeSensitiveInfo: removeSensitiveInfo,
    removeUndefined: removeUndefined
  };
});
