import { gql } from "@apollo/client";

export const ADMIN_CREATE_DEPARTMENT = gql`
  mutation AdminCreateDepartment($input: CreateDepartmentInput!) {
    createDepartment(input: $input) {
      id
      name
    }
  }
`;
