import { EditOutlined, DeleteOutlined, QuestionCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { ApolloError, ApolloQueryResult } from "@apollo/client";
import { Alert, Button, Col, Divider, Form, Input, List, Modal, Popconfirm, Row, Space, message } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import CustomHelmet from "../../../components/CustomHelmet";
import { Loading } from "../../../components/Loading/Loading";
import { AdminGetDivision, AdminGetDivision_division_departments } from "../../../graphql/__generated__/AdminGetDivision";
import { ADMIN_GET_DIVISION } from "../../../graphql/__queries__/AdminGetDivision.gql";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";
import { useDeleteDivision, useUpdateDivision } from "../../../hooks/programHooks";

const { Search } = Input;


type EditDivisionForm = {
    division: {
        id: string;
        name: string;
        departments: AdminGetDivision_division_departments[];
    };
};

type MappedDepartment = {
    id: string;
    name: string;
};

type QueryParams = {
    divisionId: string;
};

type QueryResult = EditDivisionForm & {
    error: ApolloError | undefined;
    loading: boolean;
    refresh: () => Promise<ApolloQueryResult<AdminGetDivision>>;
};


const useQueries = ({ divisionId }: QueryParams): QueryResult => {
    const { data, error, loading, refetch } = useQueryWithErrorHandling<AdminGetDivision>(
        ADMIN_GET_DIVISION,
        "division",
        { variables: { id: divisionId }, fetchPolicy: "network-only" }
    );

    const division = {
        id: data?.division.id || divisionId,
        name: data?.division.name || "",
        departments: [...(data?.division.departments || [])].sort((a, b) => a.name.localeCompare(b.name)),
    };

    return { error, loading, division, refresh: () => refetch() };
};

const applySearchFilter = (departments: MappedDepartment[], searchTerms: string[]) => (
    departments?.filter(({ name }) => (
        searchTerms.every(term => name.toLocaleLowerCase().includes(term.toLocaleLowerCase()))
    )) || []
);


export const EditDivision = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { divisionId } = useParams() as QueryParams;
    const [editDivisionForm] = Form.useForm<EditDivisionForm>();

    const [searchTerms, setSearchTerms] = useState<string[]>([]);
    const [showUserGuideModal, setShowUserGuideModal] = useState<boolean>(false);

    const { division, error, loading, refresh } = useQueries({ divisionId });
    const updateDivision = useUpdateDivision();
    const deleteDivision = useDeleteDivision();

    const initialValues: EditDivisionForm = useMemo(() => ({ division }), [division]);

    const filteredDepartments = useMemo(() => {
        const sortedDepartments = division.departments
            .map(({ id, name }) => ({ id, name }))
            .sort((a, b) => a.name.localeCompare(b.name));
        return searchTerms.length === 0 ? sortedDepartments : applySearchFilter(sortedDepartments, searchTerms);
    }, [division, searchTerms]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        throw error;
    }

    const onDelete = async () => {
        if (division.departments && division.departments.length > 0) {
            message.error(t("admin.tags.divisions.edit.actions.delete.warning"));
            return;
        }
        await deleteDivision.run(divisionId);
        navigate("/admin/tags/divisions");
    };

    const onFinish = async (values: EditDivisionForm) => {
        if (values.division.name !== initialValues.division.name) {
            await updateDivision.run({ id: divisionId, name: values.division.name });
            refresh();    
        }
    };

    return (
        <Row gutter={[16, 16]}>
            {/* Page Title */}
            <CustomHelmet title={`${t("admin.tags.divisions.edit.title")} - ${initialValues.division.name}`} />

            {/* Page Header */}
            <Col span={24}>
                <PageHeader
                    onBack={() => navigate("/admin/tags/divisions")}
                    subTitle={initialValues.division.name}
                    title={t("admin.tags.divisions.edit.title")}
                    extra={[
                        <Button
                            key="open-user-guide"
                            onClick={() => setShowUserGuideModal(true)}
                            icon={<QuestionCircleOutlined />}
                            title={t("admin.tags.divisions.edit.userGuide.title")}
                        >
                            {t("admin.tags.divisions.edit.userGuide.title")}
                        </Button>
                    ]}
                />
            </Col>

            {/* Errors */}
            {
                [
                    { action: "save", error: updateDivision.error },
                    { action: "delete", error: deleteDivision.error }
                ]
                    .filter(err => !!err.error)
                    .map(({ action, error }) => (
                        <Col key={action} span={24}>
                            <Alert
                                closable
                                description={error?.message}
                                message={t(`admin.tags.divisions.edit.actions.${action}.error`)}
                                showIcon
                                style={{ marginBottom: 16 }}
                                type="error"
                            />
                        </Col>
                    ))
            }

            {/* User Guide Modal */}
            <Modal
                title={t("admin.tags.divisions.edit.userGuide.title")}
                onCancel={() => setShowUserGuideModal(false)}
                open={showUserGuideModal}
                footer={[
                    <Button
                        key="close-user-guide"
                        onClick={() => setShowUserGuideModal(false)}
                        title={t("admin.tags.divisions.edit.userGuide.close")}
                    >
                        {t("admin.tags.divisions.edit.userGuide.close")}
                    </Button>
                ]}
            >
                <p><b>{t("admin.tags.divisions.edit.userGuide.info.editDivision.title")}</b></p>
                <p><em>{t("admin.tags.divisions.edit.userGuide.info.editDivision.description")}</em></p>
                <p><b>{t("admin.tags.divisions.edit.userGuide.info.addDepartment.title")}</b></p>
                <p><em>{t("admin.tags.divisions.edit.userGuide.info.addDepartment.description")}</em></p>
                <p><b>{t("admin.tags.divisions.edit.userGuide.info.removeDepartment.title")}</b></p>
                <p><em>{t("admin.tags.divisions.edit.userGuide.info.removeDepartment.description")}</em></p>
                <p><b>{t("admin.tags.divisions.edit.userGuide.info.deleteDivision.title")}</b></p>
                <p><em>{t("admin.tags.divisions.edit.userGuide.info.deleteDivision.description")}</em></p>
            </Modal>

            {/* Edit Division Form */}
            <Col span={24}>
                <Form
                    form={editDivisionForm}
                    initialValues={initialValues}
                    onFinish={async (values) => await onFinish(values)}
                    scrollToFirstError
                >
                    <Row gutter={[16, 16]}>
                        <Col offset={1} span={11}>
                            {/* Edit Division Name */}
                            <Form.Item
                                label={t("admin.tags.divisions.edit.input.label")}
                                labelAlign="right"
                                labelCol={{ span: 7 }}
                                name={["division", "name"]}
                                rules={[{ required: true, message: t("admin.tags.divisions.edit.input.required") }]}
                                validateTrigger="onBlur"
                            >
                                <Input
                                    disabled={updateDivision.inFlight || deleteDivision.inFlight}
                                    placeholder={t("admin.tags.divisions.edit.input.placeholder")}
                                    title={t("admin.tags.divisions.edit.input.label")}        
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col span={24}>
                        <Divider orientation="left">{t("admin.tags.divisions.edit.divider")}</Divider>
                    </Col>

                    <Row gutter={[16, 32]}>
                        <Col offset={1} span={10}>
                            <Row align="middle" gutter={[16, 16]} justify="start">
                                <Col span={16}>
                                    <Search
                                        allowClear
                                        disabled={updateDivision.inFlight || deleteDivision.inFlight}
                                        onSearch={(input) => setSearchTerms(input.split(" "))} 
                                        placeholder={t("admin.tags.divisions.edit.actions.search")}
                                        title={t("admin.tags.divisions.edit.actions.search")}
                                    />
                                </Col>
                                <Col span={24}>
                                    <List
                                        bordered
                                        dataSource={filteredDepartments}
                                        itemLayout="horizontal"
                                        pagination={{ position: "bottom", align: "end" }}
                                        renderItem={(item) => (
                                            <List.Item
                                                actions={[
                                                    <a
                                                        href={`/admin/tags/departments/${item.id}`}
                                                        key={item.id}
                                                        title={t("admin.tags.divisions.edit.actions.editDepartment")}
                                                    >
                                                        {<EditOutlined />}
                                                    </a>                                            
                                                ]}
                                            >
                                                {item.name}
                                            </List.Item>
                                        )}
                                        rowKey={(item) => item.id}
                                        size="small"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col offset={4} span={16}>
                            <Row align="middle" gutter={[16, 16]} justify="space-evenly">
                                <Form.Item>
                                    <Space size={[64, 64]}>
                                        <Space>
                                            {/* Save Button */}
                                            <Button
                                                disabled={deleteDivision.inFlight}
                                                htmlType="submit"
                                                icon={<SaveOutlined />}
                                                loading={updateDivision.inFlight}
                                                title={t("admin.tags.divisions.edit.actions.save.title")}
                                                type="primary"
                                            >
                                                {t("admin.tags.divisions.edit.actions.save.title")}
                                            </Button>

                                            {/* Cancel Button */}
                                            <Button
                                                disabled={updateDivision.inFlight || deleteDivision.inFlight}
                                                onClick={() => navigate("/admin/tags/divisions")}
                                                title={t("admin.tags.divisions.edit.actions.cancel")}
                                            >
                                                {t("admin.tags.divisions.edit.actions.cancel")}
                                            </Button>
                                        </Space>

                                        {/* Delete Button */}
                                        <Popconfirm
                                            cancelText={t("admin.tags.divisions.edit.actions.delete.cancelText")}
                                            description={t("admin.tags.divisions.edit.actions.delete.description")}
                                            disabled={
                                                initialValues.division.departments.length > 0
                                                || updateDivision.inFlight
                                                || deleteDivision.inFlight
                                            }
                                            okText={t("admin.tags.divisions.edit.actions.delete.confirmText")}
                                            onConfirm={async () => await onDelete()}
                                            title={t("admin.tags.divisions.edit.actions.delete.title")}
                                        >
                                            <Space>
                                                <Button
                                                    danger
                                                    disabled={
                                                        initialValues.division.departments.length > 0 || 
                                                        updateDivision.inFlight
                                                    }
                                                    icon={<DeleteOutlined />}
                                                    loading={deleteDivision.inFlight}
                                                    title={t("admin.tags.divisions.edit.actions.delete.title")}
                                                >
                                                    {t("admin.tags.divisions.edit.actions.delete.title")}
                                                </Button>
                                            </Space>
                                        </Popconfirm>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};