import { FilterState } from "../pages/Reports/Reports";


export enum ImageFormat {
    PNG = "png",
    JPEG = "jpeg",
}

export type FilenameProps = {
    suffix: string;
    filterState: FilterState;
}


export const createExportFilename = ({ suffix, filterState }: FilenameProps): string => {
    const { divisions, departments, miscTags, programs, range } = filterState;

    const dateFormat = "MMMYY";
    const dateString = range.begin.month() === range.end.month() ? `${range.begin.format(dateFormat)}` 
        : `${range.begin.format(dateFormat)} to ${range.end.format(dateFormat)}`;
    
    const filterConcat = [
        ...divisions.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...departments.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...miscTags.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...programs.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
    ];
    const filterStringBase = filterConcat.length > 0 ? filterConcat[0] : "";
    const filterString = filterConcat.length > 1 ? `${filterStringBase} and others` : filterStringBase;
    
    const filename = filterString ? `${dateString}-${filterString}` : `${dateString}`;
    return `${filename.toLowerCase()}-${suffix.toLowerCase()}`;
};

// TODO - Convert to web workers
export const downloadSvgAsImage = async (containerId: string, filename: string, imageFormat: ImageFormat): Promise<void> => {
    return new Promise((resolve, reject) => {
        const svgContainer = document.getElementById(containerId);
        if (!svgContainer) {
            return reject();
        }

        const svg = svgContainer.querySelector("svg");
        if (!svg) {
            return reject();
        }

        // Get initial properties of the SVG and child "rect" element
        const svgMinX = svg.x.baseVal.value;
        const svgMinY = svg.y.baseVal.value;
        const svgWidth = svg.width.baseVal.value;
        const svgHeight = svg.height.baseVal.value;

        const svgRect = Array.from(svg.children).map(el => {
            const fill = el.getAttribute("fill");
            const height = el.getAttribute("height");
            const width = el.getAttribute("width");

            if (el.nodeName === "rect" && fill && height === svgHeight.toString() && width === svgWidth.toString()) {
                return { el, fill, height, width };
            }
        }).filter(item => !!item)[0];
        
        // Override SVG and child "rect" properties prior to image creation
        const svgTopTextElements = document.getElementsByClassName(`${containerId} svg-chart-top-text`).length;
        const svgBottomTextElements = document.getElementsByClassName(`${containerId} svg-chart-bottom-text`).length;
        const heightAdjustmentTop = (svgTopTextElements && svgTopTextElements + 1) * 20;
        const heightAdjustmentBottom = (svgBottomTextElements && svgBottomTextElements + 1) * 20;

        const newMinX = svgMinX;
        const newMinY = svgMinY - heightAdjustmentTop;
        const newHeight = svgHeight + heightAdjustmentTop + heightAdjustmentBottom;
        const newWidth = svgWidth;

        svg.removeAttribute("width");
        svg.removeAttribute("height");
        svg.setAttribute("viewBox", `${newMinX} ${newMinY} ${newWidth} ${newHeight}`);

        if (svgRect) {
            svgRect.el.setAttribute("height", newHeight.toString());
            svgRect.el.setAttribute("y", newMinY.toString());

            if (imageFormat === ImageFormat.PNG) {
                svgRect.el.setAttribute("fill", "transparent");
            }
        }

        // Create image and download to browser
        const svgString = (new XMLSerializer()).serializeToString(svg);
        const svgBlob = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
        const url = URL.createObjectURL(svgBlob);
        
        const image = new Image();
        image.width = newWidth;
        image.height = newHeight;
        image.src = url;
        image.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;

            const context = canvas.getContext("2d");
            if (!context) {
                return reject();
            }

            context.drawImage(image, 0, 0);
            canvas.toBlob((blob) => {
                if (!blob) {
                    return reject();
                }

                const anchor = document.createElement("a");
                const url = URL.createObjectURL(blob);
                anchor.download = `${filename}.${imageFormat}`;
                anchor.target = "_blank";
                anchor.href = url;

                anchor.dispatchEvent(
                    new MouseEvent("click", { view: window, bubbles: false, cancelable: true })
                );
            }, `image/${imageFormat}`, 1.0);
        };

        // Reset SVG and child "rect" properties to initial values
        svg.removeAttribute("viewBox");
        svg.setAttribute("width", svgWidth.toString());
        svg.setAttribute("height", svgHeight.toString());

        if (svgRect) {
            svgRect.el.setAttribute("height", svgHeight.toString());
            svgRect.el.setAttribute("y", svgMinY.toString());
            svgRect.el.setAttribute("fill", svgRect.fill);
        }

        return resolve();
    });
};