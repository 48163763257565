import { PictureOutlined } from "@ant-design/icons";
import { setLang, Data, DataCell, S2DataConfig, SpreadSheet, S2Options } from "@antv/s2";
import { SheetComponent, SheetComponentOptions } from "@antv/s2-react";
import "@antv/s2-react/dist/style.min.css";
import { Button } from "antd";
import React, { RefObject, useEffect, useState } from "react";

import { sortDiversityCategories } from "../../utils/sortDiversityCategories";

setLang("en_US");


enum ImageFormat {
    PNG = "png",
    JPEG = "jpeg",
}

type CanvasState = [Dimensions, React.Dispatch<React.SetStateAction<Dimensions>>]

type Dimensions = {
    height: number;
    width: number;
}

type ReportsTableProps = { 
    data: Data[];
    parentRef: RefObject<Element>;
}


const DEFAULT_CANVAS_DIMENSIONS: Dimensions = { width: 640, height: 640 };


const getSheetDataConfig = (data: Data[]): S2DataConfig => ({ 
    data,
    fields: {
        rows: ["DATASET"],
        columns: ["Category", "Metric"],
        values: ["%"],
    },
    meta: [
        {
            field: "%",
            formatter: (value: unknown, data?: Data | Data[]) => {
                if ((data as Data)?.offAir) {
                    return "off air";
                } else if ((data as Data)?.unPublished) {
                    return "no data";
                } else if (!Number.isNaN(Number(value)) && Number(value) < 0) {
                    return "no data";
                } else if (value === null) {
                    return "-";
                } else {
                    return String(value);
                }
            }
        },
        {
            field: "Category",
            formatter: (value: unknown) => (value as string).split("#")[1],
        },
    ],
});

const calculateScreenshotDimensions = (s2: SpreadSheet): Dimensions => {
    const { container, dataCfg, options } = s2;

    // Calculate the number of rows and columns displayed.
    const rows = new Set();
    const columns = new Set();
    dataCfg.data.forEach(item => {
        rows.add(item["DATASET"]);
        columns.add(`${item["Category"]}_${item["Metric"]}`);
    });

    // Calculate the height required to display all data.
    const defaultCellHeight = 30;
    const cellHeight = s2.options.style?.cellCfg?.height || defaultCellHeight;

    const numberOfHeaderRows = 4;
    const numberOfDataRows = rows.size;
    const totalNumberOfRows = numberOfHeaderRows + numberOfDataRows;

    const height = (totalNumberOfRows * cellHeight) - 20;

    // Calculate the width required to display all data.    
    const numberOfCols = columns.size;
    const totalNumberOfCols = numberOfCols + 1;
    const totalWidth = (options.width || container.cfg.width) as number;

    const headerColWidth = options.style?.rowCfg?.widthByField?.["DATASET"];
    const hasHeaderColWidthChanged = !!headerColWidth;

    const colWidth = options.style?.colCfg?.widthByFieldValue?.["%"];
    const hasColWidthChanged = !!colWidth;
    
    let width = totalWidth;
    if (hasHeaderColWidthChanged && hasColWidthChanged) {
        width = headerColWidth + (colWidth * numberOfCols);
    } else if (hasHeaderColWidthChanged && !hasColWidthChanged) {
        width = headerColWidth * totalNumberOfCols;
    } else if (!hasHeaderColWidthChanged && hasColWidthChanged) {
        width = colWidth * totalNumberOfCols;
    }

    return { height, width };
};

const captureScreenshot = async (
    imageFormat: ImageFormat,
    s2: SpreadSheet,
    [canvasDimensions, setCanvasDimensions]: CanvasState,
): Promise<void> => {
    return new Promise((resolve) => {
        // Get the properties of the canvas, container and the SpreadSheet before making any changes.
        const initialCanvasDimensions = { ...canvasDimensions };

        // Adjust the dimensions of the canvas and SpreadSheet, and re-render.
        const { height, width } = calculateScreenshotDimensions(s2);
        setCanvasDimensions({ width: width, height: height });
        s2.setOptions({ ...s2.options, height: height }, true);
        s2.render(false);

        // Wait a small amount of time to allow the container and SpreadSheet to be redrawn.
        setTimeout(() => {
            // Get the underlying canvas element on which the SpreadSheet is painted.
            const canvas = s2.getCanvasElement();
            
            // Conver the canvas image to a blob.
            canvas.toBlob((blob) => {
                if (!blob) {
                    return;
                }

                // Create an anchor tag and set its "href" attribute to a URL created from the blob.
                const anchor = document.createElement("a");
                const url = URL.createObjectURL(blob);
                anchor.download = `reports-table.${imageFormat}`;
                anchor.href = url;

                // Click the anchor tag, resulting in the "screenshot" being downloaded.
                anchor.click();

                // Wait a small amount of time to allow the previous block to execute.
                setTimeout(() => {
                    // Re-set the height of the canvas and the SpreadSheet and re-render.
                    setCanvasDimensions(initialCanvasDimensions);
                    s2.setOptions({ ...s2.options }, true);
                    s2.render(true);
                    resolve();
                }, 50);
            }, `image/${imageFormat}`, 1.0);
        }, 50);
    });
};

const getSheetOptions = (canvasDimensions: Dimensions): S2Options => ({
    conditions: {
        background: [
            {
                field: "%",
                mapping: (value, cellInfo) => {
                    if (typeof (value) !== "number" || !("exceeded" in cellInfo)) {
                        return { fill: "#FFF" };
                    } else {
                        return {
                            fill: cellInfo.exceeded ? "#d8e4bc" : "#FFF"
                        };
                    }
                },
            },
        ],
        icon: [
            {
                field: "%",
                mapping: (value, cellInfo) => {
                    if (typeof (value) !== "number" || !("exceeded" in cellInfo)) {
                        return { fill: "#000" };
                    } else {
                        return {
                            fill: (() => {
                                if (cellInfo.improved > 0)
                                    return "#0000FF";
                                else if (cellInfo.improved < 0)
                                    return "#FF0000";
                                else {
                                    return "#000000";
                                }
                            })(),
                            icon: (() => {
                                if (cellInfo.improved > 0)
                                    return "CellUp";
                                else if (cellInfo.improved < 0)
                                    return "CellDown";
                                else {
                                    return;
                                }
                            })()
                        };
                    }
                },
            },
        ],
        text: [
            {
                field: "%",
                mapping: () => ({ fill: "#000" })
            }
        ]
    },
    height: canvasDimensions.height,
    width: canvasDimensions.width,
    dataCell: (viewMeta) =>  new DataCell(viewMeta, viewMeta?.spreadsheet),
    layoutArrange: (_s2, _parent, field, fieldValues) => {
        let newFieldValues = fieldValues;
        
        if (field === "Category") {
            newFieldValues = fieldValues.sort((a, b) => {
                const rawPriorityA = a.split("#")[0];
                const rawPriorityB = b.split("#")[0];
                const priorityA = !Number.isNaN(Number(rawPriorityA)) ? Number(rawPriorityA) : 0;
                const priorityB = !Number.isNaN(Number(rawPriorityB)) ? Number(rawPriorityB) : 0;

                return sortDiversityCategories(priorityA || null, priorityB || null);
            });
        }

        if (field === "Metric") {
            newFieldValues = fieldValues.sort((a) => a === "Target" ? -1 : 1);
        }
        
        return newFieldValues;
    }
});


export const ReportsTable = (props: ReportsTableProps) => {
    const s2Ref = React.useRef<SpreadSheet>(null);

    const [canvasDimensions, setCanvasDimensions] = useState<Dimensions>(DEFAULT_CANVAS_DIMENSIONS);
    const [screenshotLoading, setScreenshotLoading] = useState<boolean>(false);

    // Resize the table when the parent container changes in size
    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            setTimeout(() => {
                for (const entry of entries) {
                    const { blockSize, inlineSize } = entry.contentBoxSize[0];
                    setCanvasDimensions({ height: blockSize - 68, width: inlineSize });
                }
            }, 100);
        });

        if (props.parentRef?.current) {
            resizeObserver.observe(props.parentRef?.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [props]);

    const onClickDownloadImage = async (imageFormat: ImageFormat) => {
        const s2 = s2Ref.current;

        if (s2) {
            setScreenshotLoading(true);
            await captureScreenshot(imageFormat, s2, [canvasDimensions, setCanvasDimensions]);
            setScreenshotLoading(false);
        }
    };

    const sheetDataConfig = getSheetDataConfig(props.data);

    const imageFormats: ImageFormat[] = [ImageFormat.PNG, ImageFormat.JPEG];
    const sheetHeader = { 
        exportCfg: { open: true, icon: "Download" },
        extra: imageFormats.map(format => (
            <Button
                key={`download-reports-table-${format}`}
                disabled={screenshotLoading}
                icon={<PictureOutlined />}
                loading={screenshotLoading}
                onClick={async () => await onClickDownloadImage(format)}
            >
                {`.${format}`}
            </Button>
        )),
    };

    const sheetOptions = getSheetOptions(canvasDimensions);

    return (
        <SheetComponent ref={s2Ref} dataCfg={sheetDataConfig} header={sheetHeader} options={sheetOptions as SheetComponentOptions}/>
    );
};
