define(function() {
  'use strict';

  var DEBUG = require('../util/debug');
  var EchoConfigKeys = require('../config/keys');
  var urlProducers = '';
  var urlMasterbrands =  '';
  var producers = {};
  var masterbrands = {};

  function RemoteConfigManager(config, environment) {

    this._config = config;
    this._env = environment;
    var remoteConfigBaseUrl = config[EchoConfigKeys.REMOTE_CONFIG_URL];
    if (!remoteConfigBaseUrl) {
      remoteConfigBaseUrl = 'https://mybbc-analytics.files.bbci.co.uk/analytics-remote-config/';
    }

    var producerConfigFilename = config[EchoConfigKeys.PRODUCER_CONFIG_FILENAME];
    if (!producerConfigFilename) {
      producerConfigFilename = 'producers.json';
    }

    var masterbrandConfigFilename = config[EchoConfigKeys.MASTERBRAND_CONFIG_FILENAME];
    if (!masterbrandConfigFilename) {
      masterbrandConfigFilename = 'masterbrands.json';
    }

    urlProducers = remoteConfigBaseUrl + producerConfigFilename;
    urlMasterbrands = remoteConfigBaseUrl + masterbrandConfigFilename;

    this.fetchRemoteData(urlProducers, urlMasterbrands);
  }

  RemoteConfigManager.prototype.getData = function(url, done) {
    var executeCrossDomainGet  = this._env.getExecuteCrossDomainGet();
    executeCrossDomainGet(url, {
      onSuccess: function(data) {
        done(data);
      },
      onError: function(error, status) {
        var errorType = 'error';
        if (error === 'json') {
          errorType = 'json';
        } else if (typeof error === 'object' && error.type === 'timeout') {
          errorType = 'timeout';
        }

        DEBUG.error('There was a problem requesting the remote config data', errorType, status);
        done();
      }
    });
  };

  RemoteConfigManager.prototype.fetchRemoteData = function(urlProducers, urlMasterbrands) {
    if (!urlProducers) {
      DEBUG.error('There was no Producer URL found');
    }

    this.getData(urlProducers, function(data) {
      producers = data;
    });

    if (!urlMasterbrands) {
      DEBUG.error('There was no Masterbrand URL found');
    }

    this.getData(urlMasterbrands, function(data) {
      masterbrands = data;
    });
  };

  RemoteConfigManager.prototype.getProducers = function() {
    return producers;
  };

  RemoteConfigManager.prototype.getMasterbrands = function() {
    return masterbrands;
  };

  return RemoteConfigManager;
});
