import { gql } from "@apollo/client";

export const GET_OVERVIEWS = gql`
  query GetOverviews {
      overviews {
        category {
          name
          displayName
          priority
        }
        date
        value
        targetState
        filter
    }
  }
`;
