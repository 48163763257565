import { DownloadOutlined, EditOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Alert, Button, Col, Divider, Form, FormProps, Input, Row, Table, TableColumnsType } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useCreateContentType } from "../../../hooks/programHooks";
import CustomHelmet from "../../../components/CustomHelmet";
import { AdminGetContentTypes, AdminGetContentTypes_contentTypes } from "../../../graphql/__generated__/AdminGetContentTypes";
import { ADMIN_GET_CONTENT_TYPES } from "../../../graphql/__queries__/AdminGetContentTypes.gql";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";

const { Search } = Input;


type ExportContentType = {
    "Content Type": string;
    "Dataset": string;
};

type FormField = {
    contentType: string;
}

type MappedContentType = {
    id: string;
    name: string;
    numberOfDatasets: number;
};


const useQueries = () => {
    const contentTypesResponse = useQueryWithErrorHandling<AdminGetContentTypes>(
        ADMIN_GET_CONTENT_TYPES,
        "contentTypes",
        { fetchPolicy: "network-only" }
    );

    return {
        loading: contentTypesResponse.loading,
        contentTypes: [...(contentTypesResponse.data?.contentTypes || [])],
        refresh: () => contentTypesResponse.refetch(),
    };
};

const applySearchFilter = (contentTypes: MappedContentType[], searchTerms: string[]) => (
    contentTypes?.filter(({ name }) => (
        searchTerms.every(term => name.toLocaleLowerCase().includes(term.toLocaleLowerCase()))
    )) || []
);

const convertContentTypesToCsv = (contentTypes: ExportContentType[]) => {
    const titleKeys = Object.keys(contentTypes[0]);
    const exportContentTypes: string[][] = [];

    exportContentTypes.push(titleKeys);
    contentTypes.forEach(contentType => {
        const attributes = Object.values(contentType).map(attr => `"${attr}"`);
        exportContentTypes.push(attributes);
    });

    let csvContent = "";
    exportContentTypes.forEach(row => {
        csvContent += (row.join(",") + "\n");
    });
    
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    return URL.createObjectURL(blob);
};

const getExportContentTypes = (contentTypes: AdminGetContentTypes_contentTypes[]): ExportContentType[] => (
    contentTypes.flatMap(contentType => {
        const exportContentType: ExportContentType = { 
            "Content Type": contentType.name,
            "Dataset": ""
        };
        return contentType.programs && contentType.programs.length > 0
            ? contentType.programs.flatMap(program => program.datasets.map(dataset => (
                { ...exportContentType, "Dataset": dataset.name }
            ))) : [exportContentType];
    })
);

const mapContentTypes = (contentTypes: AdminGetContentTypes_contentTypes[]): MappedContentType[] => (
    contentTypes
        .map(contentType => (
            {
                id: contentType.id,
                name: contentType.name,
                numberOfDatasets: (contentType.programs || []).flatMap(program => program.datasets.length)
                    .reduce((acc, curr) => acc + curr ,0)
            }
        ))
);

const getColumns = (): TableColumnsType<MappedContentType> => [
    {
        title: "Content Type Name",
        dataIndex: "name",
        key: "name",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.name.localeCompare(b.name),
        width: "40%",
    },
    {
        title: "Number of Datasets",
        dataIndex: "numberOfDatasets",
        key: "datasets",
        sorter: (a, b) =>  a.numberOfDatasets - b.numberOfDatasets,
    },
    {
        title: "Edit Content Type",
        key: "edit",
        render: (record: MappedContentType) => (
            <a href={`/admin/tags/content-types/${record.id}`}>
                <EditOutlined />
            </a>
        ),
    },
];


export const ManageContentTypes = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [createNewContentTypeForm] = Form.useForm<FormField>();

    const [searchTerms, setSearchTerms] = useState<string[]>([]);
    const [openCreateNewContentType, setOpenCreateNewContentType] = useState<boolean>(false);
    const [exportAllContentTypesUrl, setExportAllContentTypesUrl] = useState<string>("");

    const { contentTypes, loading, refresh } = useQueries();
    const createNewContentType = useCreateContentType();

    const filteredContentTypes = useMemo(() => {
        const mappedContentTypes = mapContentTypes(contentTypes);
        return searchTerms.length === 0 ? mappedContentTypes : applySearchFilter(mappedContentTypes, searchTerms);
    }, [searchTerms, contentTypes]);

    const exportAllContentTypes = () => {
        const exportContentTypes = getExportContentTypes(contentTypes);
        const url = convertContentTypesToCsv(exportContentTypes);
        setExportAllContentTypesUrl(url);
    };

    const onFinish: FormProps<FormField>["onFinish"] = async (values: FormField): Promise<void> => {
        const success = await createNewContentType.run(values.contentType);

        if (success) {
            createNewContentTypeForm.resetFields();
            setOpenCreateNewContentType(false);
            refresh();    
        }
    };

    return (
        <Row gutter={[16, 32]}>
            {/* Page Title */}
            <CustomHelmet title={t("admin.tags.contentTypes.index.title")} />

            {/* Page Header */}
            <Col span={24}>
                <PageHeader 
                    onBack={() => navigate("/admin/tags")}
                    title={t("admin.tags.contentTypes.index.title")}
                    // Export All Content Types
                    extra={[
                        <Button
                            title={t("admin.tags.contentTypes.index.actions.export")}
                            key="export-all-content-types"
                            type="primary"
                            shape="round"
                            disabled={loading || createNewContentType.inFlight}
                            download="all-content-types.csv"
                            href={exportAllContentTypesUrl}
                            icon={<DownloadOutlined />}
                            onClick={exportAllContentTypes}
                        >
                            {t("admin.tags.contentTypes.index.actions.export")}
                        </Button>
                    ]}
                />
            </Col>

            {/* Error */}
            {
                !!createNewContentType.error && 
                <Col key="create-new-content-type-error" span={24}>
                    <Alert
                        closable
                        description={createNewContentType.error?.message}
                        message={t("admin.tags.contentTypes.index.actions.createContentType.error")}
                        showIcon
                        style={{ marginBottom: 16 }}
                        type="error"
                    />
                </Col>
            }

            {/* Create New Content Type */}
            <Col span={24}>
                <Form
                    form={createNewContentTypeForm}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    {
                        openCreateNewContentType ? 
                            <Row gutter={[16, 16]}>
                                <Col offset={1} span={6}>
                                    <Form.Item
                                        name="contentType"
                                        rules={[{ required: true, message: t("admin.tags.contentTypes.index.input.required") }]}
                                    >
                                        <Input
                                            disabled={loading || createNewContentType.inFlight}
                                            placeholder={t("admin.tags.contentTypes.index.input.placeholder")}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item>
                                        <Button 
                                            htmlType="submit"
                                            loading={loading || createNewContentType.inFlight}
                                            title={t("admin.tags.contentTypes.index.actions.createContentType.submit")}
                                            type="primary"
                                        >
                                            {t("admin.tags.contentTypes.index.actions.createContentType.submit")}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row> : 
                            <Row gutter={[16, 16]}>
                                <Col offset={1} span={6}>
                                    <Button
                                        loading={loading || createNewContentType.inFlight}
                                        onClick={() => setOpenCreateNewContentType(true)}
                                        title={t("admin.tags.contentTypes.index.actions.createContentType.title")}
                                        type="primary"
                                    >
                                        {t("admin.tags.contentTypes.index.actions.createContentType.title")}
                                    </Button>
                                </Col>
                            </Row>
                    }
                </Form>
            </Col>

            {/* View All Content Types */}
            <Col span={24}>
                <Row gutter={[16, 16]}>
                    <Divider orientation="left">{t("admin.tags.contentTypes.index.divider")}</Divider>
                    <Col span={6}>
                        <Search 
                            allowClear
                            disabled={loading || createNewContentType.inFlight}
                            onSearch={(input) => setSearchTerms(input.split(" "))} 
                            placeholder={t("admin.tags.contentTypes.index.actions.search")}
                        />
                    </Col>
                    <Col span={24}>
                        <Table 
                            dataSource={filteredContentTypes}
                            columns={getColumns()}
                            loading={loading || createNewContentType.inFlight}
                            rowKey={contentType => contentType.id}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};