/**
 * This module is meant as a replacement for comScore's UDM tag
 * It extends the udm by:
 *  - adding the option to define the httpGet method
 *  - managing persistant labels
 */

/**
 * TODO
 * * Tests
 * * "comScore=" cookie
 */

define(['./label-keys'], function(CSLabelKeys) {
  'use strict';

  function AppTag(customHttpGet, isApp, isScorecardDomain) {
    // init persistent labels
    this._persistantLabels = {};
    this._urlParams = '';
    this._cut = '';
    this._httpGet = customHttpGet;
    this._eventCount = 0;
    this._isApp = isApp;
    this._isScorecardDomain = (isScorecardDomain === true);
    this._hasStarted = false;
  }

  AppTag.IS_ECHO_APP_TAG = true;
  AppTag.URL_LIMIT = 2048;

  AppTag.prototype.setPixelURL = function(pixelURL) {
    this._url = this._makeBaseUrl(pixelURL);
  };

  AppTag.prototype.setCustomerC2 = function(customerC2) {
    this.setLabel(CSLabelKeys.COMSCORE_CUSTOMER, customerC2);
  };

  AppTag.prototype.start = function() {
    if (!this._hasStarted) {
      this._hasStarted = true;
    }
  };

  /** Public **/

  /**
   * Set single persistant label
   */
  AppTag.prototype.setLabel = function(key, val) {
    if (val === null) {
      this.removeLabel(key);
    } else {
      this._persistantLabels[key] = val;
    }
  };

  /**
   * Delete persistant Labels
   */
  AppTag.prototype.removeLabels = function(keys) {
    for (var i in keys) {
      if (typeof this._persistantLabels[keys[i]] !== 'undefined') {
        delete this._persistantLabels[keys[i]];
      }
    }
  };

  /**
   * Delete single persistant label
   */
  AppTag.prototype.removeLabel = function(label) {
    if (typeof this._persistantLabels[label] !== 'undefined') {
      delete this._persistantLabels[label];
    }
  };

  AppTag.prototype.view = function(eventLabels) {
    this.send('view', eventLabels);
  };

  AppTag.prototype.hidden = function(eventLabels) {
    this.send('hidden', eventLabels);
  };

  /**
   * Send an event,
   * type should be "hidden" or "view"
   */
  AppTag.prototype.send = function(type, eventLabels, callback) {
    var pLabels = this._persistantLabels;
    var allLabels = {};
    var finalUrl;
    var k;

    if (!this._hasStarted) {
      return;
    }

    this._eventCount++;

    for (k in pLabels) {
      allLabels[k] = pLabels[k];
    }

    for (k in eventLabels) {
      allLabels[k] = eventLabels[k];
    }

    /* jshint ignore:start */
    allLabels.ns_type = type;
    if (this._isApp) { // ECHO-56
      allLabels.ns_ap_ev = type;
      allLabels.ns_ap_ec = this._eventCount;
    }
    /* jshint ignore:end */

    // ECHO-56
    allLabels[this._isApp ? 'ns_ts' : 'ns__t'] = (+new Date());

    finalUrl = this._createUrl(allLabels);

    this._httpGet(finalUrl, callback);
  };

  /** Private **/

  /**
   * Turn kv pairs (in an object) into a url param string
   * if the string is to exceed limit, the params are
   * encoded and added against the "ns_cut=" key
   */
  AppTag.prototype._createUrl = function(params) {
    var encode = window.encodeURIComponent || escape; // jshint ignore:line
    var url = this._url;
    var k;

    this._urlParams = '';
    this._cut = '';

    // Name *must* be first param in url
    if (params.hasOwnProperty('name')) {
      this._addUrlParam('name', params.name);
    }

    for (k in params) {
      if (k !== 'name' && k !== 'ns_referrer') {
        this._addUrlParam(k, params[k]);
      }
    }

    // ns_referrer *must* be last param in url
    if (params.hasOwnProperty('ns_referrer')) {
      // Should us c9 when reporting to scorecard research MYSTATS-1427
      this._addUrlParam(
        this._isScorecardDomain ? CSLabelKeys.ENV_REFERRER : 'ns_referrer',
        params.ns_referrer // jshint ignore:line
      );
    }

    url += this._urlParams;

    if (this._cut.length) {
      url += 'ns_cut=' + encode(this._cut.replace(/&$/, ''));
    } else {
      url = url.replace(/&$/, '');
    }

    return url;
  };

  AppTag.prototype._addUrlParam = function(key, val) {
    var encode = window.encodeURIComponent || escape; // jshint ignore:line
    var kv = encode(key) + '=' + encode(val) + '&';
    var limit = AppTag.URL_LIMIT - 8;

    if ((this._url.length + this._urlParams.length + kv.length) < limit) {
      this._urlParams += kv;
    } else {
      this._cut += kv;
    }
  };

  /**
   * If the url does not have a trailing '?' or '&', add one
   */
  AppTag.prototype._makeBaseUrl = function(url) {
    var mark = -1;
    var last = url.charAt(url.length - 1);

    for (var i = 0, j = url.length; i < j; i++) {
      if (url.charAt(i) === '?') {
        mark = i;
      }
    }

    if (mark === -1) {
      url += '?';
    } else if (last !== '?' && last !== '&') {
      url += '&';
    }

    return url;
  };

  return AppTag;
});
