import { gql } from "@apollo/client";

export const ADMIN_CREATE_USER_ROLES = gql`
  mutation AdminCreateUserRoles($input: CreateUserRolesInput!) {
    createUserRoles(input: $input) {
      id
      name
      description
    }
  }
`;
