/**
 * Simply wraps and 'console.x' method into a module
 * this way any 'console' functionality can be used without
 * worrying about cases where the 'console' object is undefined
 */

/**
 * Module used to print debug/error statements to the console.
 * Exports a method "enable"
 * @exports Echo/Debug
 * @example
 * Echo.Debug.enable();
 */
define(function(require) { // jshint ignore:line
  'use strict';
  var ENABLED = false; // Is DEBUG enabled?
  var DebugLevels = {
    INFO: 0,
    WARN: 1,
    ERROR: 2
  };

  var level = DebugLevels.ERROR;

  /**
   * Returns a function calling the console
   * method sent as a argument. Passes any arguments
   * from the final calling method onto the console.
   */
  function callConsole(method, message) {
    if (typeof console === 'object' && typeof console[method] === 'function') {
      console[method](message);
    }

  }

  /**
   * Enable debug mode. Calling this method will result in errors
   * encountered by Echo (due to bad config/use) to be printed
   * to the console. The default behaviour is to silently fail.
   */
  function enable() {
    ENABLED = true;
  }

  function disable() {
    ENABLED = false;
  }

  function getState() {
    return ENABLED;
  }

  function getLevel() {
    return level;
  }

  function setLevel(newLevel) {
    if (newLevel > DebugLevels.ERROR) {
      level = DebugLevels.ERROR;
    } else if (newLevel < DebugLevels.INFO) {
      level = DebugLevels.INFO;
    } else {
      level = newLevel;
    }
  }

  function info(message) {
    if (ENABLED && level === DebugLevels.INFO) {
      callConsole('log', 'ECHO_DEBUG [info]: ' + message);
    }
  }

  function warn(message) {
    if (ENABLED && level <= DebugLevels.WARN) {
      callConsole('log', 'ECHO_DEBUG [warning]: ' + message);
    }
  }

  function error(message) {
    if (ENABLED && level <= DebugLevels.ERROR) {
      throw new Error('ECHO_DEBUG: ' + message);
    }
  }

  // Map warn to info to keep backward compatibility
  return {
    enable: enable,
    disable: disable,
    getState: getState,
    getLevel: getLevel,
    setLevel: setLevel,
    info: info,
    warn: warn,
    error: error,
    DebugLevels: DebugLevels
  };
});
