
import { Table } from "antd";
import dayjs from "dayjs";

import "./DatasetDetailsRecordsTable.css";
import { GetAllCategories_categories } from "../../graphql/__generated__/GetAllCategories";
import { GetDataset, GetDataset_dataset_records } from "../../graphql/__generated__/GetDataset";


interface DatasetRecordsTableProps {
    activeCategories: GetAllCategories_categories[];
    datasetId: string;
    datasetData: GetDataset | undefined;
    records: readonly GetDataset_dataset_records[] | undefined;
    isLoading: boolean;
}

interface TableData {
    id: string;
    key: string;
    publicationDate: string;
    [key: string]: string | number;
}

export const DatasetDetailsRecordsTable = ({ activeCategories, datasetData, records }: DatasetRecordsTableProps): JSX.Element => {
    const tableData = records?.map((record) => (
        record.entries
            .filter(entry => activeCategories.map(({ name }) => name).includes(entry.categoryValue.category.name))
            .reduce((accumulator, currentItem) => {
                const catValName = currentItem.categoryValue.name;
                const previousCount = accumulator[catValName] as number | undefined;

                accumulator[catValName] = (previousCount || 0) + currentItem.count;
                accumulator["id"] = record.id;
                accumulator["publicationDate"] = record.publicationDate;
                record.customColumnValues?.forEach(x => accumulator[x.customColumn.name] = x.value ?? "");

                return accumulator;
            }, {} as TableData)
    ));


    return (
        <Table
            className="dataset-records-table"
            dataSource={tableData}
            bordered
            size="small"
            scroll={{ x: 1000 }}
            sticky
            pagination={{ hideOnSinglePage: true }}

            rowKey={(record) => record.id}
        >
            <Table.Column<TableData>
                title="Date"
                dataIndex="publicationDate"
                key="id"
                defaultSortOrder="descend"
                sorter={
                    (dateA: TableData, dateB: TableData) =>
                        dayjs(dateA.publicationDate).unix() - dayjs(dateB.publicationDate).unix()
                }
                fixed={true}
                width={120}
                render={(date: string) => dayjs(date).format("YYYY-MM-DD")}
            />
            {
                Array.from(new Set(records?.map(r => r.customColumnValues).flat().map(x => x?.customColumn.name)))
                    .map(x =>
                        <Table.Column<TableData>
                            title={x}
                            dataIndex={x}
                            key={x}
                        />
                    )
            }
            {
                datasetData?.dataset?.program?.targets
                    .filter(target => activeCategories.map(({ name }) => name).includes(target.category.name))
                    .flatMap((target) => target.tracks)
                    .map(track =>
                        <Table.Column<TableData>
                            title={track.categoryValue.name}
                            dataIndex={track.categoryValue.name}
                            key="id"
                        />
                    )
            }
        </Table>
    );
};
