import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const { Title } = Typography;


type UserGuideProps = {
    isAdmin: boolean;
}


export const UserGuide = ({ isAdmin }: UserGuideProps) => {
    const { t } = useTranslation();

    const [showUserGuideModal, setShowUserGuideModal] = useState<boolean>(false);

    return (
        <>
            <Button
                onClick={() => setShowUserGuideModal(true)}
                icon={<QuestionCircleOutlined />}
                title={t("reports.userGuide.title")}
            >
                {t("reports.userGuide.title")}
            </Button>
            <Modal
                title={
                    <Title level={3}>{t("reports.userGuide.title")}</Title>
                }
                closable
                footer={[
                    <Button
                        key="close-user-guide"
                        onClick={() => setShowUserGuideModal(false)}
                        title={t("reports.userGuide.close")}
                    >
                        {t("reports.userGuide.close")}
                    </Button>
                ]}
                onCancel={() => setShowUserGuideModal(false)}
                open={showUserGuideModal}
                width="50%"
            >
                <p>The Reports page might take a little while to load. Be patient. Just click once and give it time to load.</p>

                <p>
                    <div><b>Choosing a date range</b></div>
                    <div>The date range picker defaults to last month. If it&apos;s September now, it will show August.</div>
                    <div style={{ marginLeft: "5%" }}>To select 1 month, e.g. June: enter from <u>June</u> to <u>June</u>.</div>
                    <div style={{ marginLeft: "5%" }}>To select 2 months, e.g. June and July: enter from <u>June</u> to <u>July</u>.</div>
                    <div>You can select up to 12 months; however the wider your date range the longer the page might take to load. Be patient.</div>
                </p>

                <p>
                    <div><b>Selecting division, department, datasets or other tags</b></div>
                    <div>Combining several dropdown lists will narrow the search and you might end up with a blank page.</div>
                    <div>Only select multiple items from <b>one dropdown</b> list. For example, select one or more divisions, OR select one or more departments, OR select one or more datasets.</div>
                    <div>Don&apos;t select items from different dropdown lists.</div>
                </p>

                <Title level={4}>User Guide - Results Summaries</Title>
                <p>You can only select 1 month in the date picker (of any year) for the result summaries. For example, May 24 to May 24. You cannot select 2 or more months (e.g. May 24 to June 24).</p>
                <p>
                    <div><b>BBC Overall Results</b></div>
                    <div>BBC Overall Results include aggregated statistics for all teams who were expected to publish and those who did publish their results in the month you&apos;ve selected.</div>
                    <div>You can select items from dropdown lists, however they will not be considered in this summary as it applies to all teams and datasets.</div>
                </p>
                <p>
                    <div><b>Division Results</b></div>
                    <div>You should select 1 division.</div>
                    <div>Division Results include aggregated statistics for all teams in the selected division who were expected to publish and those who did publish their results in the month you&apos;ve selected.</div>
                    <div>All other selections will be ignored.</div>
                </p>
                <p>
                    <div><b>Department Results</b></div>
                    <div>You need to select at least 1 department.</div>
                    <div>Department Results include aggregated statistics for all datasets in the selected department(s) who were expected to publish and those who did publish their results in the month you&apos;ve selected.</div>
                    <div>All other selections will be ignored.</div>
                </p>
                {
                    isAdmin && 
                        <>
                            <p>
                                <div><b>Administrators</b></div>
                                <div>As Admin you can select one or more Misc Tags and generate Division Reports and/or Department Reports instead of selecting from Division or Department dropdowns.</div>
                            </p>
                            <p>
                                <div><b>Admin Report</b></div>
                                <div>You need to select at least 1 item from Division and/or Department and/or Misc Tag and/or Teams and/or Datasets. All other selections will be ignored.</div>
                                <div>The Admin Report includes aggregated statistics for all datasets in the selected division(s), department(s), team(s), dataset(s) and/or Misc Tag(s).</div>
                                <div>NB the more items you select from different dropdown lists simultaneously the fewer datasets will be included.</div>
                            </p>
                        </>
                }
            </Modal>
        </>
    );
};