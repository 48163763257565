import { gql } from "@apollo/client";

export const GET_DATASET = gql`
  query GetDataset($id: ID!) {
    dataset(id: $id) {
      id
      name
      lastUpdated
      personTypes {
        id
        personTypeName
      }
      program {
        name
        importedId
        targets {
          id
          target
          category {
              id
              name
              deleted
              description
              displayName
              inTargetColor
              outTargetColor
              priority
          }
          tracks {
            targetMember
            categoryValue {
              id
              name
            }
          }
        }
        reportingPeriods {
          id
          range
          description
        }       
        tags {
          name
          tagType
        }
        department {
          name
          division {
            name
          }
        }
        platforms {
          name
        }
        contentTypes {
          name
        }
        team {
          id
          name
        }
      }
      records {
        id
        publicationDate
        customColumnValues {
          id
          customColumn {
            id
            name
            type
          }
          value
        }
        entries {
          id
          categoryValue {
            id
            name
            category {
              id
              name
              deleted
              description
              displayName
              inTargetColor
              outTargetColor
              priority
            }
          }
          count
          personType {
            id
            personTypeName
          }
        }
      }
      tags {
        name
        tagType
      }
      publishedRecordSets {
        id
        datasetId
        reportingPeriodId
        begin
        end
        document
        deleted
      }
      customColumns {
        id
        name
        type
        description
      }
    }
  }
`;
