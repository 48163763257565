import { Button, Col, message, Modal, Row, Select, Tooltip } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import useAxios from "axios-hooks";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { Dispatch, SetStateAction, useMemo, useState } from "react";

import { DateRange, FilterState } from "./Reports";
import resultSummaries, { 
    ExtraResultSummaryStats, 
    GetExtraResultSummaryStats, 
    GetExtraResultSummaryStatsPayload, 
    ResultSummary, 
    ResultSummaryData, 
    ResultSummaryReportsData,
    ResultSummaryType
} from "./resultSummaries";
import { GetAllCategories_categories } from "../../graphql/__generated__/GetAllCategories";
import { GetReportsData_reportsData } from "../../graphql/__generated__/GetReportsData";

const { 
    generateAdminReport, 
    generateDepartmentResultSummary, 
    generateDivisionResultSummary, 
    generateOverallResultSummary
} = resultSummaries;

dayjs.extend(utc);


type GenerateResultSummaryPropsReportsData = {
    currentMonth: GetReportsData_reportsData | undefined;
    previousMonth: GetReportsData_reportsData | undefined;    
}

type GenerateResultSummaryProps = {
    categories: GetAllCategories_categories[];
    dateRange: DateRange
    filterState: FilterState;
    isAdmin: boolean;
    pageLoading: boolean;
    reportsData: GenerateResultSummaryPropsReportsData;
    tz: string;
}

type ResultSummaryModalProps = {
    resultSummary: ResultSummary;
    showResultSummaryModal: boolean;
    setShowResultSummaryModal: Dispatch<SetStateAction<boolean>>;
}


const SELECT_RESULT_SUMMARY_TYPE_OPTIONS: DefaultOptionType[] = [
    { value: ResultSummaryType.Overall, label: "BBC Overall Results" },
    { value: ResultSummaryType.Division, label: "Division Results" },
    { value: ResultSummaryType.Department, label: "Department Results" },
];

const SELECT_RESULT_SUMMARY_TYPE_ADMIN_OPTIONS: DefaultOptionType[] = [
    ...SELECT_RESULT_SUMMARY_TYPE_OPTIONS,
    { value: ResultSummaryType.Admin, label: "Admin Report" },
];

const resultSummaryTypeMap = {
    [ResultSummaryType.Admin]: {
        generateResultSummary: (data: ResultSummaryData, date: Dayjs, getExtraStats: GetExtraResultSummaryStats) => (
            generateAdminReport(data, date, getExtraStats)
        ),
        getButtonTooltip: () => "",
        isFilterStateValid: () => true,
    },
    [ResultSummaryType.Department]: {
        generateResultSummary: (data: ResultSummaryData, date: Dayjs) => generateDepartmentResultSummary(data, date),
        getButtonTooltip: (filterState: FilterState, isAdmin: boolean) => {
            if (resultSummaryTypeMap[ResultSummaryType.Department].isFilterStateValid(filterState, isAdmin)) {
                return "";
            }
            return isAdmin ? "You need to select at least 1 Department or Misc. Tag" 
                : "You need to select at least 1 Department";
        },
        isFilterStateValid: (filterState: FilterState, isAdmin: boolean) => (
            isAdmin ? (filterState.departments.length + filterState.miscTags.length) > 0 
                : filterState.departments.length > 0
        ),
    },
    [ResultSummaryType.Division]: {
        generateResultSummary: (data: ResultSummaryData, date: Dayjs) => generateDivisionResultSummary(data, date),
        getButtonTooltip: (filterState: FilterState, isAdmin: boolean) => {
            if (resultSummaryTypeMap[ResultSummaryType.Division].isFilterStateValid(filterState, isAdmin)) {
                return "";
            }

            return isAdmin ? "You need to select at least 1 Division or Misc. Tag" 
                : "You need to select at least 1 Division";
        },
        isFilterStateValid: (filterState: FilterState, isAdmin: boolean) => (
            isAdmin ? (filterState.divisions.length + filterState.miscTags.length) > 0 
                : filterState.divisions.length > 0
        ),
    },
    [ResultSummaryType.Overall]: {
        generateResultSummary: (data: ResultSummaryData, date: Dayjs) => generateOverallResultSummary(data, date),
        getButtonTooltip: () => "",
        isFilterStateValid: () => true,
    },
};


const ResultSummaryModal = (
    { resultSummary, showResultSummaryModal, setShowResultSummaryModal }: ResultSummaryModalProps
) => {
    const onClickHandleClose = () => setShowResultSummaryModal(false);

    return (
        <Modal
            title={resultSummary.title}
            closable
            footer={[
                <Button key="Close" onClick={onClickHandleClose}>
                        Close
                </Button>
            ]}
            open={showResultSummaryModal}
            onCancel={onClickHandleClose}
            width="75%"
        >
            {
                resultSummary.sections.map(({ id, header, content }, index) => (
                    <div key={`section-${id}`} style={{paddingTop: (index > 0 ? 16 : 0)}}>
                        <p><b>{header}</b></p>
                        {
                            content.map(({ id, lines }, index) => (
                                <div key={`content-${id}`} style={{paddingTop: (index > 0 ? 8 : 0)}}>
                                    {lines.map(({ id, text }) => (
                                        <p key={`line-${id}`}>{text}</p>
                                    ))}
                                </div>
                            ))
                        }
                    </div>
                ))
            }
        </Modal>
    );    
};

const isReportsDataValid = (reportsData: GenerateResultSummaryPropsReportsData): reportsData is ResultSummaryReportsData => (
    !!reportsData.currentMonth && !!reportsData.previousMonth
);


export const GenerateResultSummary = (
    {
        categories,
        dateRange,
        filterState,
        isAdmin,
        pageLoading,
        reportsData,
        tz,
    }: GenerateResultSummaryProps
) => {
    const [resultSummary, setResultSummary]= useState<ResultSummary | undefined>(undefined);
    const [resultSummaryType, setResultSummaryType] = useState<ResultSummaryType>(ResultSummaryType.Overall);
    const [showResultSummaryModal, setShowResultSummaryModal] = useState<boolean>(false);

    const [
        { loading: resultSummaryStatsLoading }, 
        getExtraResultSummaryStats,
    ] = useAxios<ExtraResultSummaryStats, GetExtraResultSummaryStatsPayload>(
        { 
            url: "/api/result-summaries",
            method: "POST", 
            data: { 
                filters: filterState,
                range: { 
                    begin: dateRange.begin.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0).utc(true),
                    end: dateRange.end.set("hour", 23).set("minute", 59).set("second", 59).set("millisecond", 999).utc(true),
                },
                type: resultSummaryType,
            }
        },
        { manual: true },
    );

    const { generateResultSummary, getButtonTooltip, isFilterStateValid } = useMemo(() => {
        return resultSummaryTypeMap[resultSummaryType];
    }, [resultSummaryType]);
    
    const dateRangeValid = dayjs(dateRange.begin).month() === dayjs(dateRange.end).month();
    const dateRangeTooltip = "You can only select 1 month in the date picker (e.g. May to May). You cannot select 2 or more months";
    const filterStateValid = isFilterStateValid(filterState, isAdmin);
    
    const onClickGenerateResultSummary = async () => {
        if (filterStateValid && isReportsDataValid(reportsData)) {
            const data: ResultSummaryData = { categories, filterState, isAdmin, reportsData, tz };
            const resultSummary = await generateResultSummary(data, dateRange.begin, getExtraResultSummaryStats);

            if (resultSummary) {
                setResultSummary(resultSummary);
                setShowResultSummaryModal(true);    
            } else {
                message.error(`Something went wrong generating the ${resultSummaryType} result summary`);
            }
        }
    };

    const loading = pageLoading || resultSummaryStatsLoading;

    return (
        <Row gutter={[16, 16]}>
            <Col span={12}>
                <Tooltip title={!dateRangeValid ? dateRangeTooltip : ""}>
                    <Select
                        disabled={!dateRangeValid}
                        loading={loading}
                        options={isAdmin ? SELECT_RESULT_SUMMARY_TYPE_ADMIN_OPTIONS : SELECT_RESULT_SUMMARY_TYPE_OPTIONS}
                        onChange={(value) => setResultSummaryType(value)}
                        placeholder="Select summary type"
                        style={{ width: "100%" }}
                        title="Select a type of result summary"
                        value={resultSummaryType}
                    />
                </Tooltip>
            </Col>
            <Col span={12}>
                <Tooltip title={!dateRangeValid ? dateRangeTooltip : getButtonTooltip(filterState, isAdmin)}>
                    <Button
                        disabled={!dateRangeValid || !filterStateValid}
                        htmlType="submit"
                        loading={loading}
                        onClick={onClickGenerateResultSummary}
                        title="Generate Summary"
                        type="primary"
                    >
                        Generate Summary
                    </Button>
                </Tooltip>
            </Col>
            {
                resultSummary &&
                <ResultSummaryModal
                    resultSummary={resultSummary}
                    showResultSummaryModal={showResultSummaryModal}
                    setShowResultSummaryModal={setShowResultSummaryModal}
                />
            }
        </Row>
    );
};