/**
 * ENUM values. Available via Echo.Enums. These values are used to pass in to
 * various methods in `EchoClient` and `Media`
 * @exports Echo/Enums
 */
define(function(require) {
  'use strict';

  var Destinations = require('./config/destinations');
  var Producers = require('./config/producers');
  var NationsProducer = require('./config/nations-producer');
  var Masterbrands = require('./config/masterbrands');

  var ConfigKeys = require('./config/keys');
  var ATILabelKeys = require('./delegate/ati/label-keys');

  // Create a list of destinations that can be used as an enumeration
  var destinations = {};
  Object.keys(Destinations).map(function(key) {
    destinations[key] = key;
  });

  // Create a list of producers that can be used as an enumeration
  var producers = {};
  Object.keys(Producers).map(function(key) {
    producers[key] = key;
  });

  // Create a list of nations producers that can be used as an enumeration
  var nationsProducer = {};
  Object.keys(NationsProducer).map(function(key) {
    nationsProducer[key] = key;
  });

  // Create a list of masterbrands that can be used as an enumeration
  var masterbrands = {};
  Object.keys(Masterbrands).map(function(key) {
    masterbrands[key] = key;
  });

  var comscoreCustomerId = {
    PUBLIC_SERVICE: '16060501',
    GNL: '19293874',
    WORLD_SERVICE: '19999701',
    WORLDWIDE_FEATURES: '19774467',
    STORE_NONCOMMERCIAL: '19774474',
    STORE_COMMERCIAL: '19774472',
    AFFILIATES_ACCEPTANCE: '18897612',
    BRITBOX: '23239715',
    BRITBOX_ACCEPTANCE: '18897612'
  };

  function createReportingProfile(comscoreCustomerId, comscoreSite, comscoreHost) {
    var profile = {};
    profile[ConfigKeys.COMSCORE_CUSTOMER_ID] = comscoreCustomerId;
    profile[ConfigKeys.COMSCORE_SITE] = comscoreSite;
    profile[ConfigKeys.COMSCORE_HOST] = comscoreHost;

    return profile;
  }

  function createPublicServiceReportingProfile(comscoreSite) {
    return createReportingProfile(
      comscoreCustomerId.PUBLIC_SERVICE,
      comscoreSite,
      'sa.bbc.co.uk'
    );
  }

  function createAffiliateReportingProfile(comscoreCustomerId, comscoreSite) {
    return createReportingProfile(
      comscoreCustomerId,
      comscoreSite,
      'scorecardresearch.com'
    );
  }

  var reportingProfiles = {};
  reportingProfiles.PUBLIC_SERVICE = createPublicServiceReportingProfile('bbc');
  reportingProfiles.PUBLIC_SERVICE_STAGE = createPublicServiceReportingProfile('stage');
  reportingProfiles.PUBLIC_SERVICE_TEST = createPublicServiceReportingProfile('test');
  reportingProfiles.PUBLIC_SERVICE_INT = createPublicServiceReportingProfile('int');
  reportingProfiles.PUBLIC_SERVICE_INTRANET = createPublicServiceReportingProfile('intranet');

  reportingProfiles.GNL = createAffiliateReportingProfile(
    comscoreCustomerId.GNL, 'bbc'
  );
  reportingProfiles.GNL_ACCEPTANCE = createAffiliateReportingProfile(
    comscoreCustomerId.AFFILIATES_ACCEPTANCE, 'gnl-test'
  );
  reportingProfiles.WORLD_SERVICE = createAffiliateReportingProfile(
    comscoreCustomerId.WORLD_SERVICE, 'bbc'
  );
  reportingProfiles.WORLD_SERVICE_ACCEPTANCE = createAffiliateReportingProfile(
    comscoreCustomerId.AFFILIATES_ACCEPTANCE, 'ws-test'
  );
  reportingProfiles.WORLDWIDE_FEATURES = createAffiliateReportingProfile(
    comscoreCustomerId.WORLDWIDE_FEATURES, 'bbc'
  );
  reportingProfiles.WORLDWIDE_FEATURES_ACCEPTANCE = createAffiliateReportingProfile(
    comscoreCustomerId.AFFILIATES_ACCEPTANCE, 'features-test'
  );
  reportingProfiles.STORE_NONCOMMERCIAL = createAffiliateReportingProfile(
    comscoreCustomerId.STORE_NONCOMMERCIAL, 'bbc'
  );
  reportingProfiles.STORE_COMMERCIAL = createAffiliateReportingProfile(
    comscoreCustomerId.STORE_COMMERCIAL, 'bbc'
  );
  reportingProfiles.BRITBOX_ACCEPTANCE = createAffiliateReportingProfile(
    comscoreCustomerId.BRITBOX_ACCEPTANCE, 'bbc-global-test'
  );
  reportingProfiles.BRITBOX = createAffiliateReportingProfile(
    comscoreCustomerId.BRITBOX, 'bbc'
  );

  var exports = {
    /** COMSCORE CONFIG **/

    /** ECHO CLIENT **/
    /**
     * Type of application, used in the construction of an `EchoClient` object
     * @property WEB Standard desktop web sites
     * @property MOBILE_WEB Mobile sites
     * @property RESPONSIVE Responsive sites
     * @property MOBILE_APP Native mobile applications
     * @property BIGSCREEN_HTML Connected Tv's and STB's
     * @property BIGSCREEN_FLASH Connected Tv's and STB's
     * @property BIGSCREEN_APP TiVo Xbox Wii etc.
     * @property BIGSCREEN_CAST Chromecast Analyitics.
     * @property DESKTOP_APP Desktop apps, eg iplayer downloader
     * @property AMP Served from Google's AMP platform
     */
    ApplicationType: {
      WEB: 'web',
      MOBILE_WEB: 'mobile-web',
      RESPONSIVE: 'responsive',
      MOBILE_APP: 'mobile-app',
      BIGSCREEN_HTML: 'bigscreen-html',
      BIGSCREEN_FLASH: 'bigscreen-flash',
      BIGSCREEN_APP: 'bigscreen-app',
      BIGSCREEN_CAST: 'bigscreen-cast',
      DESKTOP_APP: 'desktop-app',
      AMP: 'amp'
    },

    /**
     * Window states, used as an argument to the EchoClient method
     * `setPlayerWindowState()`
     * @property FULL
     * @property NORMAL
     * @property MINIMISED
     * @property MAXIMISED
     * @property PICTUREINPICTURE
     */
    WindowState: {
      FULL: 'full',
      NORMAL: 'norm',
      MINIMISED: 'min',
      MAXIMISED: 'max',
      PICTUREINPICTURE: 'picture-in-picture'
    },

    /** MEDIA **/

    /**
     * Type of MediaId used by MediaID
     *
     */
    MediaIdType: {
      VERSION: 'version',
      CLIP: 'clip',
      EPISODE: 'episode',
      SERVICE: 'service',
      VPID: 'vpid',
      NONPIPSCONTENTID: 'nonpipscontentid',
      PRODUCER: 'producer',
      BRAND: 'brand',
      SERIES: 'series',
      WSPARTNERID: 'ptnrID'
    },

    MediaClipType: {
      UNDEFINED: undefined,
      CLIP: 'clip',
      EPISODE: 'episode'
    },

    MediaInitiationType: {
      UNDEFINED: undefined,
      USER: 'user',
      AUTO: 'auto'
    },

    /**
     * Type of AV content. Used in the construction of a `Media` object
     * @property AUDIO Audio content
     * @property VIDEO Video content
     */
    AvType: {
      AUDIO: 'audio',
      VIDEO: 'video'
    },

    /**
     * Retrieval Type of AV content. Used in the `Media`
     * object
     * @property STREAM Content is being streamed
     * @property DOWNLOAD Content is downloaded
     */
    RetrievalType: {
      STREAM: 'stream',
      DOWNLOAD: 'download'
    },

    /**
     * Retrieval Type of AV content. Used as an argument to `media.setForm()`
     * @property LONG Content is "long" (an episode)
     * @property SHORT Content is "short" (a clip)
     */
    Form: {
      LONG: 'long',
      SHORT: 'short'
    },

    /**
     * AV type to differentiate between episode and clip,
     * this is used to decide whether the content ID is
     * a clip or episode
     * @property CLIP Content is a clip
     * @property EPISODE Copntent is an episode
     */
    PIPsType: {
      CLIP: 'clip',
      EPISODE: 'ep'
    },

    /**
     * Used in the construction of a Media object, this value
     * indeicates whether the stream is "live" or "on-demand"
     * @property ON_DEMAND Content is On Demand
     * @property LIVE Content is Live
     */
    MediaConsumptionMode: {
      ON_DEMAND: 'ondemand',
      LIVE: 'live',
      DOWNLOAD: 'download'
    },

    EchoCacheMode: {
      NEVER: 'never',
      OFFLINE: 'offline',
      ALL: 'all'
    },

    ManagedLabels: {
      EVENT_MASTER_BRAND: 'event_master_brand',
      BBC_SITE: 'bbc_site',
      IPLAYER_STATE: 'iplayer_state',
      STORE_ED_TYPE: 'store_ed_type',
      IPLAYER_AVAIL: 'iplayer_avail',
      STORE_REFERRAL: 'store_referral',
      BBC_HASHED_ID: 'bbc_hid'
    },

    ReportingProfiles: reportingProfiles,

    EssError: {
      STATUS_CODE: 'status_code',
      TIMEOUT: 'timeout',
      JSON: 'json',
      RUNTIME: 'runtime_error'
    },

    Destinations: destinations,

    Producers: producers,

    NationsProducer: nationsProducer,

    Masterbrands: masterbrands,

    EchoLabelKeys: ATILabelKeys
  };

  return exports;
});
