import { CloseCircleOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { ApolloError } from "@apollo/client";
import { Alert, Button, Col, Divider, Form, Input, List, Popconfirm, Row, Select, Space } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import CustomHelmet from "../../../components/CustomHelmet";
import { Loading } from "../../../components/Loading/Loading";
import { AdminGetAllDatasets, AdminGetAllDatasets_datasets, AdminGetAllDatasets_datasets_program } from "../../../graphql/__generated__/AdminGetAllDatasets";
import { AdminGetContentType, AdminGetContentType_contentType } from "../../../graphql/__generated__/AdminGetContentType";
import { ADMIN_GET_ALL_DATASETS } from "../../../graphql/__queries__/AdminGetAllDatasets.gql";
import { ADMIN_GET_CONTENT_TYPE } from "../../../graphql/__queries__/AdminGetContentType.gql";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";
import { useDeleteContentType, useUpdateContentType } from "../../../hooks/programHooks";

const { Search } = Input;


type Entity = {
    id: string;
    name: string;
}

type Dataset = Entity & { 
    programId: string;
};

type ContentType = Entity & { 
    datasets: Dataset[];
};

type EditContentTypeForm = {
    contentType: ContentType;
};

type ValidDataset = AdminGetAllDatasets_datasets & {
    program: AdminGetAllDatasets_datasets_program;
};

type QueryParams = {
    contentTypeId: string;
};

type QueryResult = {
    queryDatasets: readonly AdminGetAllDatasets_datasets[] | undefined;
    queryContentType: AdminGetContentType_contentType | undefined;
    error: ApolloError | undefined;
    loading: boolean;
    refresh: () => Promise<void>;
};


const useQueries = ({ contentTypeId }: QueryParams): QueryResult => {
    const {
        data: contentTypeData, error: contentTypeError, loading: contentTypeLoading, refetch: contentTypeRefetch
    } = useQueryWithErrorHandling<AdminGetContentType>(
        ADMIN_GET_CONTENT_TYPE,
        "contentType",
        { variables: { id: contentTypeId }, fetchPolicy: "network-only" }
    );

    const { 
        data: datasetsData, error: datasetsError, loading: datasetsLoading, refetch: datasetsRefetch
    } = useQueryWithErrorHandling<AdminGetAllDatasets>(
        ADMIN_GET_ALL_DATASETS,
        "datasets",
        { fetchPolicy: "network-only", variables: { onlyUnassigned: false } }
    );

    const error = contentTypeError || datasetsError;
    const loading = contentTypeLoading || datasetsLoading;
    const refresh = async () => {
        await contentTypeRefetch();
        await datasetsRefetch();
    };

    const queryDatasets = datasetsData?.datasets;
    const queryContentType = contentTypeData?.contentType;

    return { queryDatasets, error, loading, queryContentType, refresh };
};

const applySearchFilter = (datasets: Dataset[], searchTerms: string[]) => (
    datasets?.filter(({ name }) => (
        searchTerms.every(term => name.toLocaleLowerCase().includes(term.toLocaleLowerCase()))
    )) || []
);


export const EditContentType = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { contentTypeId } = useParams() as QueryParams;
    const [editContentTypeForm] = Form.useForm<EditContentTypeForm>();

    const [searchTerms, setSearchTerms] = useState<string[]>([]);
    const [selectedDataset, setSelectedDataset] = useState<Dataset | undefined>(undefined);
    const [selectDatasets, setSelectDatasets] = useState<Dataset[]>([]);
    const [contentTypeDatasets, setContentTypeDatasets] = useState<Dataset[]>([]);

    const { queryDatasets, queryContentType, error, loading, refresh } = useQueries({ contentTypeId });
    const updateContentType = useUpdateContentType();
    const deleteContentType = useDeleteContentType();

    const contentType: ContentType = useMemo(() => ({ 
        id: queryContentType?.id || contentTypeId,
        name: queryContentType?.name || "",
        datasets: (queryContentType?.programs || []).flatMap(program => (
            program.datasets.map(({ id, name }) => ({ id, name, programId: program.id }))
        ))
    }), [contentTypeId, queryContentType]);

    const initialValues: EditContentTypeForm = useMemo(() => ({ contentType }), [contentType]);

    useMemo(() => {
        const validDatasets = (queryDatasets || []).filter((dataset): dataset is ValidDataset => !!dataset.program);
        const filteredDatasets = validDatasets.filter(dataset => !contentType.datasets.some(({ id }) => dataset.id === id));
        const mappedDatasets = filteredDatasets.map(({ id, name, program }) => ({ id, name, programId: program.id }));
        const sortedDatasets = mappedDatasets.sort((a, b) => a.name.localeCompare(b.name));
        setSelectDatasets(sortedDatasets);
    }, [contentType, queryDatasets]);

    useMemo(() => {
        const sortedContentTypeDatasets = contentType.datasets.sort((a, b) => a.name.localeCompare(b.name));
        setContentTypeDatasets(sortedContentTypeDatasets);
    }, [contentType]);

    const filteredDatasets = useMemo(() => (
        searchTerms.length === 0 ? contentTypeDatasets : applySearchFilter(contentTypeDatasets, searchTerms)
    ), [contentTypeDatasets, searchTerms]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        throw error;
    }

    const addDataset = (selectedDataset: Dataset | undefined) => {
        if (selectedDataset) {
            const selectDatasetsCopy = [...selectDatasets];
            const selectDatasetIndex = selectDatasetsCopy.findIndex(({ id }) => selectedDataset.id === id);
            if (selectDatasetIndex > -1) {
                const dataset = selectDatasetsCopy.splice(selectDatasetIndex, 1);
                setSelectDatasets(selectDatasetsCopy);

                const contentTypeDatasetsCopy = [...contentTypeDatasets];
                contentTypeDatasetsCopy.push(...dataset);
                contentTypeDatasetsCopy.sort((a, b) => a.name.localeCompare(b.name));
                setContentTypeDatasets(contentTypeDatasetsCopy);
            }

            setSelectedDataset(undefined);
        }
    };

    const removeDataset = (datasetId: string) => {
        const contentTypeDatasetsCopy = [...contentTypeDatasets];
        const contentTypeDatasetIndex = contentTypeDatasetsCopy.findIndex(({ id }) => datasetId === id);
        if (contentTypeDatasetIndex > -1) {
            const dataset = contentTypeDatasetsCopy.splice(contentTypeDatasetIndex, 1);
            setContentTypeDatasets(contentTypeDatasetsCopy);

            const selectDatasetsCopy = [...selectDatasets];
            selectDatasetsCopy.push(...dataset);
            selectDatasetsCopy.sort((a, b) => a.name.localeCompare(b.name));
            setSelectDatasets(selectDatasetsCopy);
        }
    };

    const onDelete = async () => {
        await deleteContentType.run(contentTypeId);
        navigate("/admin/tags/content-types");
    };

    const onFinish = async (values: EditContentTypeForm) => {
        const programIds = contentTypeDatasets.map(({ programId }) => programId);
        await updateContentType.run({ id: contentTypeId, name: values.contentType.name, programIds });
        refresh();    
    };

    return (
        <Row gutter={[16, 16]}>
            {/* Page Title */}
            <CustomHelmet title={`${t("admin.tags.contentTypes.edit.title")} - ${contentType.name} Content Type`} />

            {/* Page Header */}
            <Col span={24}>
                <PageHeader
                    onBack={() => navigate("/admin/tags/content-types")}
                    subTitle={`${contentType.name} Content Type`}
                    title={t("admin.tags.contentTypes.edit.title")}
                />
            </Col>

            {/* Errors */}
            {
                [
                    { action: "save", error: updateContentType.error },
                    { action: "delete", error: deleteContentType.error }
                ]
                    .filter(err => !!err.error)
                    .map(({ action, error }) => (
                        <Col key={action} span={24}>
                            <Alert
                                closable
                                description={error?.message}
                                message={t(`admin.tags.contentTypes.edit.actions.${action}.error`)}
                                showIcon
                                style={{ marginBottom: 16 }}
                                type="error"
                            />
                        </Col>
                    ))
            }

            {/* Edit Content Type Form */}
            <Col span={24}>
                <Form
                    form={editContentTypeForm}
                    initialValues={initialValues}
                    onFinish={async (values) => await onFinish(values)}
                    scrollToFirstError
                >
                    <Row gutter={[16, 16]}>
                        <Col offset={1} span={11}>
                            {/* Edit Content Type Name */}
                            <Form.Item
                                label={t("admin.tags.contentTypes.edit.input.label")}
                                labelAlign="right"
                                labelCol={{ span: 7 }}
                                name={["contentType", "name"]}
                                rules={[{ required: true, message: t("admin.tags.contentTypes.edit.input.required") }]}
                                validateTrigger="onBlur"
                            >
                                <Input
                                    disabled={updateContentType.inFlight || deleteContentType.inFlight}
                                    placeholder={t("admin.tags.contentTypes.edit.input.placeholder")}
                                    title={t("admin.tags.contentTypes.edit.input.label")}        
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[32, 64]}>
                        <Col span={12}>
                            <Row gutter={[16, 16]}>
                                <Divider orientation="left">
                                    {`${contentType.name} ${t("admin.tags.contentTypes.edit.sections.contentTypeDatasets.title")}`}
                                </Divider>
                                <Col span={16}>
                                    <Search
                                        allowClear
                                        disabled={updateContentType.inFlight || deleteContentType.inFlight}
                                        onSearch={(input) => setSearchTerms(input.split(" "))} 
                                        placeholder={
                                            t("admin.tags.contentTypes.edit.actions.search")
                                                .replace("Content Type", contentType.name)
                                        }
                                        title={t("admin.tags.contentTypes.edit.actions.search")}
                                    />
                                </Col>
                                <Col span={24}>
                                    <List
                                        bordered
                                        dataSource={filteredDatasets}
                                        itemLayout="horizontal"
                                        pagination={{ position: "bottom", align: "end" }}
                                        renderItem={(item) => (
                                            <List.Item
                                                actions={[
                                                    <a
                                                        href={`/admin/programs/${item.programId}`}
                                                        key={item.id}
                                                        title={t("admin.tags.contentTypes.edit.actions.editDataset")}
                                                    >
                                                        <EditOutlined />
                                                    </a>                                            
                                                ]}
                                            >
                                                <Space>
                                                    <Popconfirm
                                                        cancelText={t("admin.tags.contentTypes.edit.actions.removeDataset.cancelText")}
                                                        description={t("admin.tags.contentTypes.edit.actions.removeDataset.description")}
                                                        disabled={updateContentType.inFlight}
                                                        okText={t("admin.tags.contentTypes.edit.actions.removeDataset.confirmText")}
                                                        onConfirm={() => removeDataset(item.id)}
                                                        title={t("admin.tags.contentTypes.edit.actions.removeDataset.title")}
                                                    >
                                                        <Button
                                                            danger
                                                            icon={<CloseCircleOutlined />}
                                                            title={t("admin.tags.contentTypes.edit.actions.removeDataset.title")}
                                                            type="text"
                                                        />
                                                    </Popconfirm>
                                                    {item.name}
                                                </Space>
                                            </List.Item>
                                        )}
                                        rowKey={(item) => item.id}
                                        size="small"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Divider orientation="left">
                                        {t("admin.tags.contentTypes.edit.sections.otherDatasets.title")}
                                    </Divider>
                                    <em>{t("admin.tags.contentTypes.edit.sections.otherDatasets.description")}</em>
                                </Col>
                                <Col span={16}>
                                    <Select
                                        disabled={updateContentType.inFlight || deleteContentType.inFlight}
                                        filterOption={(input, option) => (
                                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                        )}
                                        onChange={(_value, option: DefaultOptionType) => {
                                            setSelectedDataset(selectDatasets.find(({ id }) => id === option.value));    
                                        }}
                                        options={selectDatasets.map(({ id, name }) => ({ label: name, value: id }))}
                                        placeholder={t("admin.tags.contentTypes.edit.actions.addDataset.select")}
                                        showSearch
                                        style={{ width: "100%" }}
                                        title={t("admin.tags.contentTypes.edit.actions.addDataset.select")}
                                        value={selectedDataset?.name}
                                    />
                                </Col>
                                <Col span={8}>
                                    <Button
                                        disabled={updateContentType.inFlight || deleteContentType.inFlight}
                                        ghost={true}
                                        key="add-dataset"
                                        onClick={() => addDataset(selectedDataset)}
                                        title={t("admin.tags.contentTypes.edit.actions.addDataset.title")}
                                        type="primary"
                                    >
                                        {t("admin.tags.contentTypes.edit.actions.addDataset.title")}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row align="middle" gutter={[16, 16]} justify="space-evenly">
                                <Form.Item>
                                    <Space size={[64, 64]}>
                                        <Space>
                                            {/* Save Button */}
                                            <Button
                                                disabled={deleteContentType.inFlight}
                                                htmlType="submit"
                                                icon={<SaveOutlined />}
                                                loading={updateContentType.inFlight}
                                                title={t("admin.tags.contentTypes.edit.actions.save.title")}
                                                type="primary"
                                            >
                                                {t("admin.tags.contentTypes.edit.actions.save.title")}
                                            </Button>

                                            {/* Cancel Button */}
                                            <Button
                                                disabled={updateContentType.inFlight || deleteContentType.inFlight}
                                                onClick={() => navigate("/admin/tags/content-types")}
                                                title={t("admin.tags.contentTypes.edit.actions.cancel")}
                                            >
                                                {t("admin.tags.contentTypes.edit.actions.cancel")}
                                            </Button>
                                        </Space>

                                        {/* Delete Button */}
                                        <Popconfirm
                                            cancelText={t("admin.tags.contentTypes.edit.actions.delete.cancelText")}
                                            description={t("admin.tags.contentTypes.edit.actions.delete.description")}
                                            disabled={updateContentType.inFlight}
                                            okText={t("admin.tags.contentTypes.edit.actions.delete.confirmText")}
                                            onConfirm={async () => await onDelete()}
                                            title={t("admin.tags.contentTypes.edit.actions.delete.title")}
                                        >
                                            <Space>
                                                <Button
                                                    danger
                                                    disabled={updateContentType.inFlight}
                                                    icon={<DeleteOutlined />}
                                                    loading={deleteContentType.inFlight}
                                                    title={t("admin.tags.contentTypes.edit.actions.delete.title")}
                                                >
                                                    {t("admin.tags.contentTypes.edit.actions.delete.title")}
                                                </Button>
                                            </Space>
                                        </Popconfirm>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};