/**
 * Keys for passing in config values. Available via Echo.ConfigKeys.
 * These valuse shoud be used as the keys in the config object passed
 * to the `EchoClient` constructor
 * @exports Echo/ConfigKeys
 * @example
 * //Example config for testing
 * var Enums = Echo.Enums,
 *     conf = {};
 *
 * // Add a "trace" so we can find the vents we generate
 * conf[Enums.ECHO.TRACE] = 'mytestabcd';
 * //Point the ComScore events at the ComScore test site (so we don't
 * //dilute the live data (also it's unsampled)
 * conf[Enums.COMSCORE.URL] = 'http://sa.bbc.co.uk/bbc/test/s';
 *
 * //...now we can pass conf into new EchoClient();
 */
define(function() {
  'use strict';

  var exports = {
    /**
     * General Echo config
     * @property [ENABLED=true] Set to false to switch off eventing in Echo
     * @property [TRACE] For testing purposes you can set this to a unique ID
     * for your instance so events can be easily found later on
     * @property [DEVICE_ID] Use to set the device ID, this can also be set via
     * a method call on the EchoClient instance. This value will override the s1
     * cookie in DAx
     */
    ECHO_ENABLED: 'enabled',
    ECHO_AUTO_START: 'auto_start',
    ECHO_TRACE: 'trace',
    ECHO_DEVICE_ID: 'device_id',
    REPORTING_PROFILE: 'reporting_profile',
    ECHO_CACHE_MODE: 'cache_mode',
    DEBUG_MODE: 'disabled',

    // These are managed internally and should not be set manually
    ECHO_NAME: 'lib_name',
    ECHO_VERSION: 'lib_version',

    // SSC
    USE_SSC: 'use_ssc',
    SSC_HOSTNAME: 'ssc_hostname',

    /**
     * ComScore specific config
     * @property [ENABLED=`true`] Set to `false` to disable eventing to ComScore
     * @property [HOST='sa.bbc.co.uk']
     * The domain for ComScore events.
     * Results in a comscore endpoint of 'http://sa.bbc.co.uk/bbc/bbc/s' by
     * default
     */
    COMSCORE_ENABLED: 'comscore.enabled',
    COMSCORE_HOST: 'comscore.host',
    COMSCORE_SITE: 'comscore.site',

    COMSCORE_CUSTOMER_ID: 'comscore.customer_id',
    COMSCORE_PUBLISHER_SECRET: 'comscore.publisher_secret',

    /**
     * AT Internet specific config
     * @property [ENABLED=`true`] Set to `false` to disable eventing to AT Internet
     * @property [SITE=`596068`] The ATI site code
     * @property [LOG=`logw363`] The subdomain for the ATI URL
     * @property [DOMAIN=`ati-host.net`] The domain for the ATI URL
     * @property [PIXEL_PATH=`hit.xiti`] The ATI pixel path
     * @property [FORCE_HTTPS=`false`] Whether https is forced
     * @property [DISABLE_COOKIE=`false`] Whether enables you to block the writing of tracker cookies
     * @property [ATI_USE_DEFAULT_COOKIE_DOMAIN=`ati.use_default_cookie_domain`] Whether to use ATI's default cookie domain instead of the BBC domain logic
     */
    ATI_ENABLED: 'ati.enabled',
    ATI_LOG: 'ati.log',
    ATI_SSL_LOG: 'ati.ssl_log',
    ATI_DOMAIN: 'ati.domain',
    ATI_PIXEL_PATH: 'ati.pixel_path',
    ATI_FORCE_HTTP: 'ati.force_http',
    ATI_FORCE_HTTPS: 'ati.force_https',
    ATI_CACHE_MODE_ALWAYS: 'always',
    ATI_CACHE_MODE_REQUIRED: 'required',
    ATI_CACHE_MODE_NEVER: 'never',
    APP_CATEGORY: 'app_category',
    ATI_DISABLE_COOKIE: 'ati.disable_cookie',
    ATI_USE_DEFAULT_COOKIE_DOMAIN: 'ati.use_default_cookie_domain',
    KEEPALIVE_DURATION: 'keepalive_duration',
    ATI_COLLECT_DOMAIN: 'ati.collect_domain',
    ATI_COLLECT_DOMAIN_SSL: 'ati.collect_domain_ssl',

    /**
    * Set the destination label for reporting
    */
    DESTINATION: 'bbc_destination',

    /**
    * Set the producer label for reporting
    */
    PRODUCER: 'bbc_producer',

    /**
     * <p>
     * The URL for the test service end point.
     * </p>
     */
    TEST_SERVICE_ENABLED: 'test.enabled',
    TEST_SERVICE_URL: 'test.url',

    /**
     * Spring-specific config (for BARB reporting).
     * There are no configurable values help
     */
    BARB_ENABLED: 'barb.enabled',
    BARB_SITE_CODE: 'barb.site_code',

    // Set to true to turn on debug statements in the spring lib
    BARB_DEBUG: 'barb.debug',

    // Set to true for Hendricks Testing to record shortform media for testing
    BARB_DEBUG_ENABLE_SHORT_MEDIA: 'barb.debug_enable_short_media',

    /**
     * ESS
     */
    USE_ESS: 'use_ess',
    ESS_HOSTNAME: 'ess.hostname',

    /**
     * REMOTE_CONFIG
     */
    REMOTE_CONFIG_MANAGER: 'remote_config_manager',
    REMOTE_CONFIG_URL: 'remote_config_url',
    PRODUCER_CONFIG_FILENAME: 'producer_config_filename',
    MASTERBRAND_CONFIG_FILENAME: 'masterbrand_config_filename'
  };

  return exports;
});
