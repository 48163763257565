import { gql } from "@apollo/client";

export const ADMIN_GET_USER_BY_EMAIL = gql`
  query AdminGetUserByEmail($email: String!) {
    userByEmail(email: $email) {
      id
      email
    }
  }
`;
