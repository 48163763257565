define({
  BBC_COUNTER_NAME: 'name',
  ECHO_EVENT_NAME: 'echo_event',
  APP_VERSION: 'apvr',
  HASHED_ID: 'at',

  // Page variables
  LEVEL_1_SITE: 'site',
  LEVEL_2_SITE: 'level2',
  SECTION: 'section',
  CHAPTER_1: 'chapter1',
  CHAPTER_2: 'chapter2',
  CHAPTER_3: 'chapter3',

  // Custom site variables
  CONTENT_ID: 'bbc_content_id',
  APP_TYPE: 'bbc_app_type',
  APP_NAME: 'bbc_app_name',
  APP_CATEGORY: 'app_category',
  LANGUAGE: 'language',
  URL: 'bbc_url',
  REFERRER_URL: 'bbc_referrer_url',
  CONTENT_TYPE: 'bbc_content_type',
  NATIONS_PRODUCER: 'nations_producer',
  LIBRARY_VERSION: 'bbc_library_version',
  PAGE_TITLE: 'page_title',
  CUSTOM_VAR_1: 'custom_var_1',
  CUSTOM_VAR_2: 'custom_var_2',
  CUSTOM_VAR_3: 'custom_var_3',
  CUSTOM_VAR_4: 'custom_var_4',
  CUSTOM_VAR_5: 'custom_var_5',
  CUSTOM_VAR_6: 'custom_var_6',
  CUSTOM_VAR_7: 'custom_var_7',
  CUSTOM_VAR_8: 'custom_var_8',
  CUSTOM_VAR_9: 'custom_var_9',
  CUSTOM_VAR_10: 'custom_var_10',

  MEDIA_PLAYER: 'bbc_media_player',

  LOGGED_IN: 'bbc_identity',
  BBC_HID: 'bbc_hid',

  MEDIA_BRAND: 'brand',
  MEDIA_SERIES: 'series',
  MEDIA_WS_PARTNER_ID: 'ptnrID',
  CASTING_DEVICE_REFERRER: 'devR',

  // Set to determine if the user action is an interaction or a background event
  IS_BACKGROUND: 'is_background',

  // Set the additional source details for the event
  SOURCE: 'source',

  // Set the container as the first level of the data hierarchy for reporting
  CONTAINER: 'container',

  // Set metadata to add additional detail to events for reporting
  METADATA: 'metadata',

  // Set personalisation for reporting
  PERSONALISATION: 'personalisation',

  // Set result of what you would like to track
  RESULT: 'result',

  // Set test cookie domain
  TEST_COOKIE_DOMAIN: 'testCookieDomain',

  // Custom Event Keys
  EVENT_CATEGORY: 'event_category',
  EVENT_ACTION: 'event_action',

  /**
   * Campaign keys
   */
  CHANNEL: 'src_channel',
  MEDIUM: 'src_medium',
  CAMPAIGN: 'src_campaign',
  CAMPAIGN_GROUP: 'src_campaign_group',
  VENDOR: 'src_vendor',
  CREATION: 'src_creation',
  FORMAT: 'src_format',
  AFFILIATE_TYPE: 'src_aff_type'
});
