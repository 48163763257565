/**
 * Wrapper for the config for each consumer
 * This approach could change, might push this all
 * into the delegates...
 */
define(function(require) {
  'use strict';

  var DEBUG = require('../util/debug');
  var Enums = require('../enumerations');
  var Utils = require('../util/methods');
  var meta = require('../meta');
  var KEYS = require('./keys');

  var defaultConfig = {};

  // Echo
  defaultConfig[KEYS.ECHO_ENABLED] = true;
  defaultConfig[KEYS.ECHO_AUTO_START] = true;
  defaultConfig[KEYS.ECHO_DEVICE_ID] = null;
  defaultConfig[KEYS.REPORTING_PROFILE] = 'PUBLIC_SERVICE';
  defaultConfig[KEYS.ECHO_CACHE_MODE] = 'NEVER';

  // ComScore
  defaultConfig[KEYS.COMSCORE_ENABLED] = false;

  // AT Internet
  defaultConfig[KEYS.ATI_ENABLED] = true;

  // BARB
  defaultConfig[KEYS.BARB_ENABLED] = false;
  defaultConfig[KEYS.BARB_DEBUG] = false;
  defaultConfig[KEYS.BARB_SITE_CODE] = 'bbcdotcom';
  defaultConfig[KEYS.BARB_DEBUG_ENABLE_SHORT_MEDIA] = false;

  // Echo chamber
  defaultConfig[KEYS.TEST_SERVICE_ENABLED] = false;

  // ESS
  defaultConfig[KEYS.USE_ESS] = false;
  defaultConfig[KEYS.ESS_HOSTNAME] = 'ess.api.bbci.co.uk';

  // SSC
  defaultConfig[KEYS.USE_SSC] = false;
  defaultConfig[KEYS.SSC_HOSTNAME] = null;

  // Config values that we do not want the user to overwrite
  var fixedConfig = {};
  fixedConfig[KEYS.ECHO_NAME] = meta.NAME;
  fixedConfig[KEYS.ECHO_VERSION] = meta.VERSION;

  function hasLength(val) {
    return (typeof val === 'string' && val.length > 0);
  }

  function isStringWithNoParams(str) {
    return str.match(/[a-zA-Z]+/);
  }

  function isValidReportingProfile(profileId) {
    if (typeof profileId === 'string') {
      profileId = profileId.toUpperCase();
      return Enums.ReportingProfiles.hasOwnProperty(profileId);
    } else {
      return false;
    }
  }

  function isValidCacheMode(mode) {
    if (typeof mode === 'string') {
      mode = mode.toUpperCase();
      return Enums.EchoCacheMode.hasOwnProperty(mode);
    } else {
      return false;
    }
  }

  function validate(conf) {
    if (!isValidCacheMode(conf[KEYS.ECHO_CACHE_MODE])) {
      DEBUG.error('Config Error: KEYS.ECHO_CACHE_MODE Invalid' +
        ' cache mode specified');
      return false;
    }

    return Utils.assert(
      typeof conf[KEYS.ECHO_ENABLED] === 'boolean',
      'Config: "enabled" must be boolean, got ' +
      conf[KEYS.ECHO_ENABLED]
    ) && Utils.assert(
      typeof conf[KEYS.ECHO_AUTO_START] === 'boolean',
      'Config: "auto_start" must be boolean, got ' +
      conf[KEYS.ECHO_AUTO_START]
    ) && Utils.assert(
      conf[KEYS.ECHO_DEVICE_ID] === null ||
      (typeof conf[KEYS.ECHO_DEVICE_ID] === 'string' &&
      conf[KEYS.ECHO_DEVICE_ID].length),
      'ECHO.DEVICE_ID must be an non-empty string'
    );
  }

  function validateComScore(config) {
    // Don't bother validating if disabled
    if (config[KEYS.COMSCORE_ENABLED] === false) {
      return true;
    }

    if (!hasLength(config[KEYS.REPORTING_PROFILE])) {
      if (!hasLength(config[KEYS.COMSCORE_HOST])) {
        DEBUG.error('Config Error: Must have config value for COMSCORE_HOST' +
          ' or REPORTING_PROFILE'
        );
        return false;
      }

      if (config[KEYS.COMSCORE_HOST] !== 'scorecardresearch.com' &&
        config[KEYS.COMSCORE_HOST] !== 'sa.bbc.co.uk') {
        DEBUG.error('Config Error: KEYS.COMSCORE_HOST Should be one of ' +
          'scorecardresearch.com or sa.bbc.co.uk');
        return false;
      }
    }

    if (!isValidReportingProfile(config[KEYS.REPORTING_PROFILE])) {
      DEBUG.error('Config Error: KEYS.REPORTING_PROFILE Invalid' +
        ' reporting profile specified');
      return false;
    }

    return Utils.assert(
      typeof config[KEYS.COMSCORE_ENABLED] === 'boolean',
      'ComScore config: "ENABLED" must be boolean'
    );
  }

  function validateSpring(config) {
    // Don't bother validating if disabled
    if (config[KEYS.BARB_ENABLED] === false) {
      return true;
    }

    if (!hasLength(config[KEYS.BARB_SITE_CODE])) {
      DEBUG.error('Config Error: KEYS.BARB_SITE_CODE must have a value');
      return false;
    }

    if (!isStringWithNoParams(config[KEYS.BARB_SITE_CODE])) {
      DEBUG.error('Config Error: KEYS.BARB_SITE_CODE must match [a-zA-Z]');
      return false;
    }

    if (typeof config[KEYS.BARB_DEBUG] !== 'boolean') {
      DEBUG.error('Config Error: KEYS.BARB_DEBUG must be a boolean');
      return false;
    }

    if (typeof config[KEYS.BARB_DEBUG_ENABLE_SHORT_MEDIA] !== 'boolean') {
      DEBUG.error('Config Error: KEYS.BARB_DEBUG_ENABLE_SHORT_MEDIA must be a boolean');
      return false;
    }

    return true;
  }

  function getReportingProfile(profileId) {
    if (typeof profileId === 'string') {
      profileId = profileId.toUpperCase();
      if (Enums.ReportingProfiles.hasOwnProperty(profileId)) {
        return Enums.ReportingProfiles[profileId];
      }
    }

    return null;
  }

  function getCacheMode(mode) {
    if (typeof mode === 'string') {
      mode = mode.toUpperCase();
      if (Enums.EchoCacheMode.hasOwnProperty(mode)) {
        return Enums.EchoCacheMode[mode];
      }
    }

    return null;
  }

  // combine and validate the config with each
  // consumer
  function combineWithBaseConfig(userConfig) {

    var reportingProfile;
    var cacheMode;

    userConfig = userConfig || {};

    if (userConfig[KEYS.REPORTING_PROFILE]) {
      reportingProfile = getReportingProfile(userConfig[KEYS.REPORTING_PROFILE]);
    }

    if (!reportingProfile) {
      reportingProfile = getReportingProfile('PUBLIC_SERVICE');
    }

    if (userConfig[KEYS.ECHO_CACHE_MODE]) {
      cacheMode = getCacheMode(userConfig[KEYS.ECHO_CACHE_MODE]);
    }

    if (!cacheMode) {
      cacheMode = getCacheMode('NEVER');
    }

    var conf = Utils.extend(defaultConfig, true, reportingProfile, userConfig || {}, fixedConfig);
    if (
      !(validate(conf) && validateComScore(conf) &&
      validateSpring(conf))
    ) {
      return null;
    }

    return conf;
  }

  return {
    defaultConfig: defaultConfig,
    fixedConfig: fixedConfig,
    generate: combineWithBaseConfig
  };
});
