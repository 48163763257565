define(function() {

  'use strict';

  var xhrTimeout = 10000;

  /**
   * Perform a http get
   *
   * @param {String} url
   * @param {Function} onSuccess
   * @param {Function} onError
   */
  function httpGet(url, onSuccess, onError) {
    var img = new Image();
    img.onload = onSuccess;
    img.onerror = onError;
    img.src = url;
  }

  /**
   * Perform a cross domain http get (CORS only).
   *
   * @param {String} url
   * @param {Object} callbacks Object containing callbacks
   * @param {Function} [callbacks.onSuccess] Will be called with the
   * decoded JSON object if the call is successful.
   * @param {Function} [callbacks.onError] Will be called with error text,
   * and HTTP status for CORS requests, if the calls fails.
   */
  function executeCrossDomainGet(url, callbacks) {
    var xhr;
    var isAbort;
    var deviceSupportsCrossDomainGetAndJsonParse;
    isAbort = false;
    deviceSupportsCrossDomainGetAndJsonParse = false;

    var abort = function() {
      isAbort = true;
      xhr.onload = xhr.onerror  = xhr.onreadystatechange = null;
      xhr.abort();
    };

    var onError = function(error, status) {
      if (callbacks.onError) {
        callbacks.onError(error, status);
      }
    };

    var handleFirefoxAccessException = function(firefoxAccessException) {
      if (!isAbort) {
        onError(firefoxAccessException);
      }
    };

    var handleResponse = function() {
      var responseData;
      if (callbacks.onSuccess) {
        try {
          responseData = window.JSON.parse(xhr.responseText);
        } catch (error) {
          onError('json');
        }

        if (typeof responseData === 'object') {
          setTimeout(function() {
            callbacks.onSuccess(responseData);
          });
        }
      }
    };

    if (
      window.JSON && window.JSON.parse &&
      typeof window.XMLHttpRequest !== 'undefined'
    ) {
      xhr = new XMLHttpRequest();
      if ('withCredentials' in xhr) {
        deviceSupportsCrossDomainGetAndJsonParse = true;
      }
    }

    if (!deviceSupportsCrossDomainGetAndJsonParse) {
      onError();
      return;
    }

    try {
      xhr.open('GET', url, true);
      xhr.onload = function() {
        if (
          (
            typeof xhr.status === 'undefined' ||
            xhr.status < 400
          ) && xhr.responseText !== null
        ) {
          handleResponse();
        } else {
          onError(xhr.responseText, xhr.status);
        }
      };

      xhr.onerror = function(error) {
        onError(error);
      };

      xhr.timeout = xhrTimeout;
      xhr.ontimeout = function(error) {
        onError(error);
      };

    } catch (firefoxAccessException) {
      handleFirefoxAccessException(firefoxAccessException);
    }

    try {
      xhr.send(null);
    } catch (error) {
      abort();
      onError(error);
    }
  }

  return {
    httpGet: httpGet,
    executeCrossDomainGet: executeCrossDomainGet
  };

});
