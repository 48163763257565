define(function() {
  'use strict';

  var Producers = require('./producers');

  var masterbrands = {

    // Common:

    BBC_NEWS: Producers.BBC_NEWS_CHANNEL,
    BBC_NEWS24: Producers.BBC_NEWS_CHANNEL,
    BBC_WORLD_NEWS: Producers.BBC_WORLD_NEWS,

    // News:

    BBC_AFRIQUE_RADIO: Producers.AFRIQUE,
    BBC_AFRIQUE_TV: Producers.AFRIQUE,
    BBC_AMHARIC_RADIO: Producers.AMHARIC,
    BBC_ARABIC_RADIO: Producers.ARABIC,
    BBC_ARABIC_TV: Producers.ARABIC,
    BBC_BENGALI_RADIO: Producers.BENGALI,
    BBC_BRASIL: Producers.BRASIL,
    BBC_BURMESES_RADIO: Producers.BURMESE,
    BBC_BURMESES_TV: Producers.BURMESE,
    BBC_CANTONESE_RADIO: Producers.CHINESE,
    BBC_DARI_RADIO: Producers.PERSIAN,
    BBC_GAHUZA_RADIO: Producers.GAHUZA,
    BBC_GUJARATI_TV: Producers.GUJARATI,
    BBC_HAUSA_RADIO: Producers.HAUSA,
    BBC_HAUSA_TV: Producers.HAUSA,
    BBC_HINDI_RADIO: Producers.HINDI,
    BBC_HINDI_TV: Producers.HINDI,
    BBC_IGBO_RADIO: Producers.IGBO,
    BBC_IGBO_TV: Producers.IGBO,
    BBC_INDONESIAN_RADIO: Producers.INDONESIAN,
    BBC_KOREAN_RADIO: Producers.KOREAN,
    BBC_KOREAN_TV: Producers.KOREAN,
    BBC_KYRGYZ_RADIO: Producers.KYRGYZ,
    BBC_KYRGYZ_TV: Producers.KYRGYZ,
    BBC_LEARNING_ENGLISH: Producers.WS_LEARNING_ENGLISH,
    BBC_MARATHI_TV: Producers.MARATHI,
    BBC_MUNDO: Producers.MUNDO,
    BBC_NEPALI_RADIO: Producers.NEPALI,
    BBC_OROMO_RADIO: Producers.AFAAN_OROMOO,
    BBC_PARLIAMENT: Producers.BBC_PARLIAMENT_CHANNEL,
    BBC_PASHTO_RADIO: Producers.PASHTO,
    BBC_PASHTO_TV: Producers.PASHTO,
    BBC_PERSIAN_RADIO: Producers.PERSIAN,
    BBC_PERSIAN_TV: Producers.PERSIAN,
    BBC_PIDGIN_RADIO: Producers.PIDGIN,
    BBC_PIDGIN_TV: Producers.PIDGIN,
    BBC_PUNJABI_TV: Producers.PUNJABI,
    BBC_RUSSIAN_RADIO: Producers.RUSSIAN,
    BBC_RUSSIAN_TV: Producers.RUSSIAN,
    BBC_SINHALA_RADIO: Producers.SINHALA,
    BBC_SOMALI_RADIO: Producers.SOMALI,
    BBC_SOMALI_TV: Producers.SOMALI,
    BBC_SWAHILI_RADIO: Producers.SWAHILI,
    BBC_SWAHILI_TV: Producers.SWAHILI,
    BBC_TAMIL_RADIO: Producers.TAMIL,
    BBC_TAMIL_TV: Producers.TAMIL,
    BBC_TELUGU_TV: Producers.TELUGU,
    BBC_THAI: Producers.THAI,
    BBC_TIGRINYA_RADIO: Producers.TIGRINYA,
    BBC_UKRAINIAN_TV: Producers.UKRAINIAN,
    BBC_URDU_RADIO: Producers.URDU,
    BBC_URDU_TV: Producers.URDU,
    BBC_UZBEK_RADIO: Producers.UZBEK,
    BBC_UZBEK_TV: Producers.UZBEK,
    BBC_WEATHER: Producers.WEATHER,
    BBC_WORLD_SERVICE: Producers.WORLD_SERVICE_ENGLISH,
    BBC_WORLD_SERVICE_TV: Producers.WORLD_SERVICE_ENGLISH,
    BBC_YORUBA_RADIO: Producers.YORUBA,
    BBC_YORUBA_TV: Producers.YORUBA,
    PARLIAMENTS_ONLINE: Producers.BBC_PARLIAMENT_CHANNEL,

    // iPlayer:

    BBC_ALBA: Producers.BBC_ALBA,
    BBC_CYMRU: Producers.WALES,
    BBC_FOUR: Producers.BBC_FOUR,
    BBC_HD: Producers.BBC_HD,
    BBC_LONDON: Producers.ENGLISH_REGIONS,
    BBC_MUSIC: Producers.BBC_MUSIC,
    BBC_MUSIC_JAZZ: Producers.BBC_MUSIC,
    BBC_ONE: Producers.BBC_ONE,
    BBC_ONE_EAST: Producers.ENGLISH_REGIONS,
    BBC_ONE_EAST_MIDLANDS: Producers.ENGLISH_REGIONS,
    BBC_ONE_EAST_YORKSHIRE: Producers.ENGLISH_REGIONS,
    BBC_ONE_LONDON: Producers.ENGLISH_REGIONS,
    BBC_ONE_NORTH_EAST: Producers.ENGLISH_REGIONS,
    BBC_ONE_NORTH_WEST: Producers.ENGLISH_REGIONS,
    BBC_ONE_NORTHERN_IRELAND: Producers.NORTHERN_IRELAND,
    BBC_ONE_SCOTLAND: Producers.SCOTLAND,
    BBC_ONE_SOUTH: Producers.ENGLISH_REGIONS,
    BBC_ONE_SOUTH_EAST: Producers.ENGLISH_REGIONS,
    BBC_ONE_SOUTH_WEST: Producers.ENGLISH_REGIONS,
    BBC_ONE_WALES: Producers.WALES,
    BBC_ONE_WEST: Producers.ENGLISH_REGIONS,
    BBC_ONE_WEST_MIDLANDS: Producers.ENGLISH_REGIONS,
    BBC_ONE_YORKS: Producers.ENGLISH_REGIONS,
    BBC_SCOTLAND: Producers.BBC_SCOTLAND,
    BBC_TEES: Producers.ENGLISH_REGIONS,
    BBC_THREE: Producers.BBC_THREE,
    BBC_TWO: Producers.BBC_TWO,
    BBC_TWO_ENGLAND: Producers.ENGLISH_REGIONS,
    BBC_TWO_NORTHERN_IRELAND: Producers.NORTHERN_IRELAND,
    BBC_TWO_NORTHERN_IRELAND_DIGITAL: Producers.NORTHERN_IRELAND,
    BBC_TWO_SCOTLAND: Producers.SCOTLAND,
    BBC_TWO_WALES: Producers.WALES,
    BBC_WALES: Producers.WALES,
    BBC_WM: Producers.ENGLISH_REGIONS,
    CBBC: Producers.CBBC,
    CBEEBIES: Producers.CBEEBIES,

    // Sounds:

    BBC_1XTRA: Producers.BBC_RADIO_1XTRA,
    BBC_6MUSIC: Producers.BBC_RADIO_6_MUSIC,
    BBC_7: Producers.BBC_RADIO_4_EXTRA,
    BBC_ASIAN_NETWORK: Producers.BBC_ASIAN_NETWORK,
    BBC_RADIO_BERKSHIRE: Producers.ENGLISH_REGIONS,
    BBC_RADIO_BRISTOL: Producers.ENGLISH_REGIONS,
    BBC_RADIO_CAMBRIDGE: Producers.ENGLISH_REGIONS,
    BBC_RADIO_CORNWALL: Producers.ENGLISH_REGIONS,
    BBC_RADIO_COVENTRY_WARWICKSHIRE: Producers.ENGLISH_REGIONS,
    BBC_RADIO_CUMBRIA: Producers.ENGLISH_REGIONS,
    BBC_RADIO_CYMRU: Producers.WALES,
    BBC_RADIO_CYMRU_2: Producers.WALES,
    BBC_RADIO_CYMRU_MWY: Producers.WALES,
    BBC_RADIO_DERBY: Producers.ENGLISH_REGIONS,
    BBC_RADIO_DEVON: Producers.ENGLISH_REGIONS,
    BBC_RADIO_ESSEX: Producers.ENGLISH_REGIONS,
    BBC_RADIO_FIVE_LIVE: Producers.BBC_RADIO_5_LIVE,
    BBC_RADIO_FIVE_LIVE_OLYMPICS_EXTRA: Producers.BBC_RADIO_5_LIVE,
    BBC_RADIO_FIVE_LIVE_SPORTS_EXTRA: Producers.BBC_RADIO_5_LIVE,
    BBC_RADIO_FOUR: Producers.BBC_RADIO_4,
    BBC_RADIO_FOUR_EXTRA: Producers.BBC_RADIO_4_EXTRA,
    BBC_RADIO_FOYLE: Producers.NORTHERN_IRELAND,
    BBC_RADIO_GLOUCESTERSHIRE: Producers.ENGLISH_REGIONS,
    BBC_RADIO_GUERNSEY: Producers.ENGLISH_REGIONS,
    BBC_RADIO_HEREFORD_WORCESTER: Producers.ENGLISH_REGIONS,
    BBC_RADIO_HUMBERSIDE: Producers.ENGLISH_REGIONS,
    BBC_RADIO_JERSEY: Producers.ENGLISH_REGIONS,
    BBC_RADIO_KENT: Producers.ENGLISH_REGIONS,
    BBC_RADIO_LANCASHIRE: Producers.ENGLISH_REGIONS,
    BBC_RADIO_LEEDS: Producers.ENGLISH_REGIONS,
    BBC_RADIO_LEICESTER: Producers.ENGLISH_REGIONS,
    BBC_RADIO_LINCOLNSHIRE: Producers.ENGLISH_REGIONS,
    BBC_RADIO_MANCHESTER: Producers.ENGLISH_REGIONS,
    BBC_RADIO_MERSEYSIDE: Producers.ENGLISH_REGIONS,
    BBC_RADIO_NAN_GAIDHEAL: Producers.SCOTLAND,
    BBC_RADIO_NEWCASTLE: Producers.ENGLISH_REGIONS,
    BBC_RADIO_NORFOLK: Producers.ENGLISH_REGIONS,
    BBC_RADIO_NORTHAMPTON: Producers.ENGLISH_REGIONS,
    BBC_RADIO_NOTTINGHAM: Producers.ENGLISH_REGIONS,
    BBC_RADIO_ONE: Producers.BBC_RADIO_1,
    BBC_RADIO_ONE_VINTAGE: Producers.BBC_RADIO_1,
    BBC_RADIO_OXFORD: Producers.ENGLISH_REGIONS,
    BBC_RADIO_SCOTLAND: Producers.SCOTLAND,
    BBC_RADIO_SCOTLAND_FM: Producers.SCOTLAND,
    BBC_RADIO_SCOTLAND_MUSIC_EXTRA: Producers.SCOTLAND,
    BBC_RADIO_SHEFFIELD: Producers.ENGLISH_REGIONS,
    BBC_RADIO_SHROPSHIRE: Producers.ENGLISH_REGIONS,
    BBC_RADIO_SOLENT: Producers.ENGLISH_REGIONS,
    BBC_RADIO_SOMERSET_SOUND: Producers.ENGLISH_REGIONS,
    BBC_RADIO_STOKE: Producers.ENGLISH_REGIONS,
    BBC_RADIO_SUFFOLK: Producers.ENGLISH_REGIONS,
    BBC_RADIO_SURREY: Producers.ENGLISH_REGIONS,
    BBC_RADIO_SUSSEX: Producers.ENGLISH_REGIONS,
    BBC_RADIO_SWINDON: Producers.ENGLISH_REGIONS,
    BBC_RADIO_THREE: Producers.BBC_RADIO_3,
    BBC_RADIO_TWO: Producers.BBC_RADIO_2,
    BBC_RADIO_TWO_COUNTRY: Producers.BBC_RADIO_2,
    BBC_RADIO_TWO_EUROVISION: Producers.BBC_RADIO_2,
    BBC_RADIO_TWO_FIFTIES: Producers.BBC_RADIO_2,
    BBC_RADIO_ULSTER: Producers.NORTHERN_IRELAND,
    BBC_RADIO_WALES: Producers.WALES,
    BBC_RADIO_WILTSHIRE: Producers.ENGLISH_REGIONS,
    BBC_RADIO_YORK: Producers.ENGLISH_REGIONS,
    BBC_SOUNDS_MIXES: Producers.SOUNDS,
    BBC_SOUNDS_PODCASTS: Producers.SOUNDS,
    BBC_SOUTHERN_COUNTIES_RADIO: Producers.ENGLISH_REGIONS,
    BBC_THREE_COUNTIES_RADIO: Producers.ENGLISH_REGIONS,
    CBEEBIES_RADIO: Producers.CBEEBIES,

    // Sport:

    BBC_SPORT: Producers.SPORT,

    getProducerId: function(masterbrand) {
      var producerId;
      if (masterbrand) {
        producerId = this[masterbrand.toUpperCase()];
      }

      return producerId;
    }
  };

  return masterbrands;
});
