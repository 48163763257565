/**
 * Exports the Media class, used for setting meta-data associated with
 * a piece of AV content
 * @exports Echo/Media
 * @author Dai Williams <dai.williams@bbc.co.uk>
 */
define(function(require) {
  'use strict';

  var Producers = require('./config/producers');
  var Masterbrands = require('./config/masterbrands');
  var MediaId = require('./media-id');
  var Enums = require('./enumerations');
  var Utils = require('./util/methods');
  var LabelKeys = require('./delegate/ati/label-keys');
  var CSLabelKeys = require('./delegate/comscore/label-keys');
  var LabelCleanser = require('./util/cleansing/label-cleanser');
  var DEBUG = require('./util/debug');
  var CONSUMPTION_MODE = Enums.MediaConsumptionMode;

  /**
   * @memberOf Media
   * @private
   * @method _isValidAvType
   *
   * @description Private helper to determine valid av type
   *
   * @param {AvType} _avType The AV type of the media,
   * one of `Echo.Enums.AvType`.
   * @returns {bool}
   */
  function _isValidAvType(_avType) {
    return (_avType === Enums.AvType.AUDIO || _avType === Enums.AvType.VIDEO);
  }

  /**
   * @memberOf Media
   * @private
   * @method _isValidConsumptionMode
   *
   * @description Private helper to determine valid av type
   *
   * @param {MediaConsumptionMode} _consumptionMode Whether the media is
   * on-demand, download or live.
   * @returns {bool}
   */
  function _isValidConsumptionMode(_consumptionMode) {
    return (
      _consumptionMode === CONSUMPTION_MODE.ON_DEMAND ||
      _consumptionMode === CONSUMPTION_MODE.DOWNLOAD ||
      _consumptionMode === CONSUMPTION_MODE.LIVE
    );
  }

  /**
   * Initialise a Media object.
   * @namespace Media
   * @constructor
   * @param {AvType} _avType The AV type of the media,
   * one of `Echo.Enums.AvType`.
   * @param {MediaConsumptionMode} _consumptionMode Whether the media is
   *                            on-demand, download or live.
   *
   * When determining how to set this flag, consider the experience
   * from the user's perspective. Did they need to plan their time
   * around the BBC in order to view this content? i.e. They had to
   * be in front of a screen / radio at 3pm on a given day in order
   * to watch it. If so, the content is live (regardless of whether
   * the 'screen' is big-screen, console, browser etc). If they
   * didn't need to be there at a particular time, rather they sat
   * down when they wanted and asked the BBC to serve the content
   * at that point (again, regardless of screen type) it is
   * on-demand.
   *
   * @example
   * var media = new Media(Echo.Enums.AvType.VIDEO,
   *                       Echo.Enums.MediaConsumptionMode.ON_DEMAND);
   */
  var Media = function(_avType, _consumptionMode) {
    this.avType = _isValidAvType(_avType) ? _avType : null;

    this.consumptionMode = _isValidConsumptionMode(_consumptionMode) ?
      _consumptionMode : null;

    Utils.assert(this.avType, 'Media: Invalid avType: ' + this.avType, true);
    Utils.assert(
      this.consumptionMode, 'Media: Invalid consumptionMode: ' +
      this.consumptionMode, true
    );

    //Create all Media Id Objects
    this.serviceIdObject = new MediaId(Enums.MediaIdType.SERVICE, true);
    this.versionIdObject = new MediaId(Enums.MediaIdType.VERSION, true);
    this.clipIdObject = new MediaId(Enums.MediaIdType.CLIP, true);
    this.episodeIdObject = new MediaId(Enums.MediaIdType.EPISODE, true);
    this.vpIdObject = new MediaId(Enums.MediaIdType.VPID, true);
    this.nonPipsContentIdObject = new MediaId(Enums.MediaIdType.NONPIPSCONTENT, false);

    this.producer = 0;
    this.masterbrand = 0;
    this.useMasterbrandProducer = false;
    this.brand = '';
    this.series = '';

    this.length = 0;

    // Optional Values
    this.bufferLength = 0;
    this.supplier = null;
    this.mediaSet = null;
    this.transferFormat = null;

    // flag to check if this media object has been enriched by ESS. a null value
    // means that this media object has not gone through the live broker, so it
    // is always safer to assert equality (===) when you want to check that the
    // media has gone through the live broker *and* whether it has been enriched
    this.essEnrichedData = false;

    // whether or not the media is currently playing
    this._isPlaying = false;

    // whether or not the media is currently buffering (for ATI)
    this._isBuffering = false;

    this._isEmbedded = false;

    this.name = '';
    this.type = Enums.MediaClipType.UNDEFINED;
    this.playlist = '';

    // Media Theme 1
    this.wsPartnerId = '';

    // Media theme 2
    this.initiationType = Enums.MediaInitiationType.UNDEFINED;
    this.adsEnabled = false;

    // Media theme 3
    this.mediaPlayerName = '';
    this.mediaPlayerVersion = '';
    this.transportMode = '';
    this.castingDeviceReferrer = '';

    this.remoteProducers = null;
    this.remoteMasterbrands = null;
  };

  /**
   * Set remote producers on the media from provided Json.
   *
   * @param {Object} producersJson
   */
  Media.prototype.setRemoteProducers = function(producersJson) {
    this.remoteProducers = producersJson;
  };

  /**
   * Set remote masterbrands on the media from provided Json.
   *
   * @param {Object} masterbrandsJson
   */
  Media.prototype.setRemoteMasterbrands = function(masterbrandsJson) {
    this.remoteMasterbrands = masterbrandsJson;
  };

  /**
   * Check to see if this media object has been enriched by ESS.
   *
   * @returns {Boolean}
   */
  Media.prototype.isEssEnriched = function() {
    return this.essEnrichedData === true;
  };

  /**
   * Set whether this media object has been enriched by ESS.
   *
   * @param {Boolean} isEnriched
   */
  Media.prototype.setEssEnriched = function(isEnriched) {
    this.essEnrichedData = isEnriched;
  };

  /**
   * @memberOf Media
   * @instance
   * @method getAvType
   *
   * @returns {AvType} The av type for the Media Object
   */
  Media.prototype.getAvType = function() {
    return this.avType;
  };

  /**
   * @memberOf Media
   * @instance
   * @method getMediaConsumptionMode
   *
   * @returns {MediaConsumptionMode} The consumption mode for the Media Object
   */
  Media.prototype.getMediaConsumptionMode = function() {
    return this.consumptionMode;
  };

  /**
   * @memberOf Media
   * @instance
   * @method getClone
   *
   * @returns {Media} A new Media object which is an exact clone of
   * itself
   */
  Media.prototype.getClone = function() {
    var clonedMedia = new Media(this.avType, this.consumptionMode);

    //Create all Media Id Objects
    clonedMedia.brand = this.brand;
    clonedMedia.series = this.series;
    clonedMedia.serviceIdObject = this.serviceIdObject;
    clonedMedia.versionIdObject = this.versionIdObject;
    clonedMedia.clipIdObject = this.clipIdObject;
    clonedMedia.episodeIdObject = this.episodeIdObject;
    clonedMedia.vpIdObject = this.vpIdObject;
    clonedMedia.name = this.name;
    clonedMedia.type = this.type;
    clonedMedia.playlist = this.playlist;
    clonedMedia.initiationType = this.initiationType;
    clonedMedia.adsEnabled = this.adsEnabled;
    clonedMedia.mediaPlayerVersion = this.mediaPlayerVersion;
    clonedMedia.mediaPlayerName = this.mediaPlayerName;
    clonedMedia.transportMode = this.transportMode;
    clonedMedia.castingDeviceReferrer = this.castingDeviceReferrer;
    clonedMedia.wsPartnerId = this.wsPartnerId;
    clonedMedia.nonPipsContentIdObject = this.nonPipsContentIdObject;
    clonedMedia.appName = this.appName;
    clonedMedia.appType = this.appType;
    clonedMedia.producer = this.producer;
    clonedMedia.masterbrand = this.masterbrand;
    clonedMedia.remoteProducers = this.remoteProducers;
    clonedMedia.remoteMasterbrands = this.remoteMasterbrands;
    clonedMedia.useMasterbrandProducer = this.useMasterbrandProducer;
    clonedMedia.bufferLength = this.bufferLength;
    clonedMedia.mediaSet = this.mediaSet;
    clonedMedia.supplier = this.supplier;
    clonedMedia.transferFormat = this.transferFormat;
    clonedMedia.length = this.length;

    clonedMedia.essEnrichedData = this.essEnrichedData;

    return clonedMedia;
  };

  // ** Object ID Setters ** //

  Media.prototype.isEmbedded = function() {
    return this._isEmbedded;
  };

  Media.prototype.setIsEmbedded = function(value) {
    this._isEmbedded = value;
    return this;
  };

  /**
   * @memberOf Media
   * @instance
   * @method setVersionId
   *
   * @description sets the value of the version id for the piece of media
   * @param {string} value The version id value
   * @returns {this} `this`
   */
  Media.prototype.setVersionId = function(value) {
    this.versionIdObject.setValue(value);
    return this;
  };

  Media.prototype.setBrand = function(value) {
    this.brand = LabelCleanser.cleanLabelValue(LabelKeys.MEDIA_BRAND, value);
    return this;
  };

  Media.prototype.setSeries = function(value) {
    this.series = LabelCleanser.cleanLabelValue(LabelKeys.MEDIA_SERIES, value);
    return this;
  };

  Media.prototype.setWsPartnerId = function(value) {
    this.wsPartnerId = LabelCleanser.cleanLabelValue(LabelKeys.MEDIA_WS_PARTNER_ID, value);
    return this;
  };

  /**
   * @memberOf Media
   * @instance
   * @method setClipId
   *
   * @description sets the value of the clip id for
   * the piece of media
   * @param {string} value The clip id value
   * @returns {this} `this`
   */
  Media.prototype.setClipId = function(value) {
    this.clipIdObject.setValue(value);
    return this;
  };

  /**
   * @memberOf Media
   * @instance
   * @method setServiceId
   *
   * @description sets the value of the service id for
   * the piece of media
   * @param {string} value The service id value
   * @returns {this} `this`
   */
  Media.prototype.setServiceId = function(value) {
    this.serviceIdObject.setValue(value);
    return this;
  };

  /**
   * @memberOf Media
   * @instance
   * @method setEpisodeId
   *
   * @description sets the value of the episode id for
   * the piece of media
   * @param {string} value The episode id value
   * @returns {this} `this`
   */
  Media.prototype.setEpisodeId = function(value) {
    this.episodeIdObject.setValue(value);
    return this;
  };

  /**
   * @memberOf Media
   * @instance
   * @method setVpId
   *
   * @description sets the value of the vp id for
   * the piece of media
   * @param {string} value The length of the media content (in ms)
   * @returns {this} `this`
   */
  Media.prototype.setVpId = function(value) {
    this.vpIdObject.setValue(value);
    return this;
  };

  /**
   * @memberOf Media
   * @instance
   * @method setNonPipsContentId
   *
   * @description sets the value of the non pips content id for
   * the piece of media
   * @param {string} value
   * @returns {this} `this`
   */
  Media.prototype.setNonPipsContentId = function(value) {
    this.nonPipsContentIdObject.setValue(LabelCleanser.cleanLabelValue(CSLabelKeys.MEDIA_NON_PIPS_CONTENT_ID, value));
    return this;
  };

  Media.prototype.getSupplier = function () {
    return this.supplier;
  };

  /**
   * Indicate the supplier of the media.
   * Calling this method is optional but products are advised to do so
   * if the information is available to improve analytics insights.
   *
   * @param {String} supplier The supplier of the media
   */
  Media.prototype.setSupplier = function (supplier) {
    if (supplier !== null) {
      var s = Utils.trim(supplier);
      if (s.length !== 0) {
        this.supplier = s;
      }
    }

    return this;
  };

  /**
   * Indicate the mediaset of the media.
   * Calling this method is optional but products are advised to do so
   * if the information is available to improve analytics insights.
   *
   * @param {String} mediaSet The mediaset of the media
   */
  Media.prototype.setMediaSet = function (mediaSet) {
    if (mediaSet !== null) {
      var s = Utils.trim(mediaSet);
      if (s.length !== 0) {
        this.mediaSet = s;
      }
    }

    return this;
  };

  Media.prototype.getTransferFormat = function () {
    return this.transferFormat;
  };

  /**
   * Indicate the transfer format of the media.
   * Calling this method is optional but products are advised to do so
   * if the information is available to improve analytics insights.
   *
   * @param {String} transferFormat The transfer format of the media
   */
  Media.prototype.setTransferFormat = function (transferFormat) {
    if (transferFormat !== null) {
      var s = Utils.trim(transferFormat);
      if (s.length !== 0) {
        this.transferFormat = s;
      }
    }

    return this;
  };

  /**
   * @memberOf Media
   * @instance
   * @method isAdsEnabled
   *
   * @description Convenience helper method to determine if ads are enabled
   *
   * @returns {bool}
   */
  Media.prototype.isAdsEnabled = function() {
    return this.adsEnabled;
  };

  Media.prototype.setAdsEnabled = function(value) {
    this.adsEnabled = value;
    return this;
  };

  Media.prototype.getName = function () {
    return this.name;
  };

  Media.prototype.setName = function(value) {
    this.name = value;
    return this;
  };

  Media.prototype.getAppName = function () {
    return this.appName;
  };

  Media.prototype.setAppName = function(value) {
    this.appName = value;
    return this;
  };

  Media.prototype.getAppType = function () {
    return this.appType;
  };

  Media.prototype.setAppType = function(value) {
    this.appType = value;
    return this;
  };

  Media.prototype.getType = function () {
    return this.type;
  };

  Media.prototype.setType = function(value) {
    this.type = value;
    return this;
  };

  Media.prototype.getPlaylist = function () {
    return this.playlist;
  };

  Media.prototype.setPlaylist = function(value) {
    this.playlist = value;
    return this;
  };

  Media.prototype.getInitiationType = function () {
    return this.initiationType;
  };

  Media.prototype.setInitiationType = function(value) {
    this.initiationType = value;
    return this;
  };

  Media.prototype.getMediaPlayerName = function () {
    return this.mediaPlayerName;
  };

  Media.prototype.setMediaPlayerName = function(value) {
    this.mediaPlayerName = value;
    return this;
  };

  Media.prototype.getMediaPlayerVersion = function () {
    return this.mediaPlayerVersion;
  };

  Media.prototype.setMediaPlayerVersion = function(value) {
    this.mediaPlayerVersion = value;
    return this;
  };

  Media.prototype.getTransportMode = function () {
    return this.transportMode;
  };

  Media.prototype.setTransportMode = function(value) {
    this.transportMode = value;
    return this;
  };

  Media.prototype.setCastingDeviceReferrer = function(value) {
    this.castingDeviceReferrer = LabelCleanser.cleanLabelValue(LabelKeys.CASTING_DEVICE_REFERRER, value);
    return this;
  };

  /**
   * @memberOf Media
   * @instance
   * @method getLength
   *
   * @returns {Number} The media length value
   */
  Media.prototype.getLength = function() {
    return this.length;
  };

  /**
   * @memberOf Media
   * @instance
   * @method setLength
   *
   * @description Set the length of the media. This attribute can also be set on
   * the EchoClient instance after calling `setMedia()` with the
   *`setMediaLength()` method.
   *
   * @param {Number} _length The length of the media content (in ms)
   * @returns {Media} `this`
   */
  Media.prototype.setLength = function(value) {

    if (value !== undefined && value !== null) {
      value = Math.floor(value);
    }

    if (value === undefined || isNaN(value) || !isFinite(value) || value < 0) {
      DEBUG.warn('Media: length must be an integer of 0 or greater. Echo will report as 0.');
      value = 0;
    }

    this.length = value;

    return this;
  };

  /**
   * @memberOf Media
   * @instance
   * @method getProducer
   *
   * @returns {string} The media producer
   */
  Media.prototype.getProducer = function() {
    return this.producer;
  };

  /**
   * @memberOf Media
   * @instance
   * @method getMasterbrand
   *
   * @returns {string} The media masterbrand
   */
  Media.prototype.getMasterbrand = function() {
    return this.masterbrand;
  };

  /**
   * @memberOf Media
   * @instance
   * @method producer
   *
   * @description sets the value of the Producer for the piece of media
   * @param {string} producerCode
   * @returns {this} `this`
   */
  Media.prototype.setProducer = function(producerCode) {
    this.useMasterbrandProducer = false;

    if (!producerCode) {
      this.producer = null;
    } else {
      this.producer = producerCode.toUpperCase();
    }
  };

  /**
   * @memberOf Media
   * @instance
   * @method getProducerId
   *
   * @description returns the producer Id based on whether masterbrand exists or not
   * @returns {String} producer Id
   */
  Media.prototype.getProducerId = function() {
    var producerId;

    if (!this.useMasterbrandProducer) {
      producerId = this.getProducerIdFromProducer();
    } else {
      producerId = this.getProducerIdFromMasterbrand();
    }

    return producerId;
  };

  /**
   * @memberOf Media
   * @instance
   * @method getProducerIdFromMasterbrand
   *
   * @description returns the producer Id from remote based on masterbrand, taking into account remote and local instances of the producer data
   * @returns {String} producer Id
   */
  Media.prototype.getProducerIdFromMasterbrand = function() {
    var remoteAvailable = this.remoteProducers &&
                          this.remoteMasterbrands &&
                          this.remoteMasterbrands[this.masterbrand] &&
                          this.remoteProducers[this.remoteMasterbrands[this.masterbrand]];
    if (remoteAvailable) {
      return this.remoteProducers[this.remoteMasterbrands[this.masterbrand]];
    } else if (Masterbrands.getProducerId(this.masterbrand)) {
      return Masterbrands.getProducerId(this.masterbrand);
    } else {
      if (!(this.masterbrand === null || (this.masterbrand && this.masterbrand.toUpperCase() === 'NULL') || this.masterbrand === '')) {

        // log out that the unrecognised and not null masterbrand results in zero being set
        DEBUG.info('Masterbrand ' + '[' + this.masterbrand + '] not recognised, producer will be set to undefined (0)');
      }

      // The producer ID should be set to 0 so that it is removed by ATI
      return 0;
    }
  };

  /**
   * @memberOf Media
   * @instance
   * @method getProducerIdFromProducer
   *
   * @description returns the producer Id from remote based on producer name, taking into account remote and local instances of the producer data
   * @returns {String} producer Id
   */
  Media.prototype.getProducerIdFromProducer = function() {
    if (this.remoteProducers && this.remoteProducers[this.producer]) {
      return this.remoteProducers[this.producer];
    } else if (Producers.getId(this.producer)) {
      return Producers.getId(this.producer);
    } else {
      return 0;
    }
  };

  /**
   * @memberOf Media
   * @instance
   * @method setProducerById
   *
   * @description sets the value of the Producer for the piece of media
   * @param {String} producerId
   * @returns {this} `this`
   */
  Media.prototype.setProducerById = function(producerId) {
    if (producerId) {
      this.producer = producerId;
    }

    return this;
  };

  /**
   * @memberOf Media
   * @instance
   * @method setProducerByMasterbrand
   *
   * @description sets the value of the Producer for the piece of media based on the masterbrand
   * @param {string} masterbrand - null or 'null' will result in no producer being set (0)
   */
  Media.prototype.setProducerByMasterbrand = function(masterbrand) {
    this.useMasterbrandProducer = true;
    if (!masterbrand) {
      this.masterbrand = null;
    } else {
      this.masterbrand = masterbrand.toUpperCase();
    }
  };

  /**
   * @memberOf Media
   * @instance
   * @method getVersionIdObject
   *
   * @returns {MediaId} The VersionId object
   */
  Media.prototype.getVersionIdObject = function() {
    return this.versionIdObject;
  };

  /**
   * @memberOf Media
   * @instance
   * @method getClipIdObject
   *
   * @returns {MediaId} The ClipId object
   */
  Media.prototype.getClipIdObject = function() {
    return this.clipIdObject;
  };

  /**
   * @memberOf Media
   * @instance
   * @method getServiceIdObject
   *
   * @returns {MediaId} The ServiceId object
   */
  Media.prototype.getServiceIdObject = function() {

    return this.serviceIdObject;

  };

  /**
   * @memberOf Media
   * @instance
   * @method getWsPartnerId
   *
   * @returns {MediaId} The wsPartnerId object
   */
  Media.prototype.getWsPartnerId = function() {
    return this.wsPartnerId;
  };

  /**
   * @memberOf Media
   * @instance
   * @method getCastingDeviceReferrer
   *
   * @returns {MediaId} The getCastingDeviceReferrer
   */
  Media.prototype.getCastingDeviceReferrer = function () {
    return this.castingDeviceReferrer;
  };

  /**
   * @memberOf Media
   * @instance
   * @method getEpisodeIdObject
   *
   * @returns {MediaId} The EpisodeId object
   */
  Media.prototype.getEpisodeIdObject = function() {
    return this.episodeIdObject;
  };

  /**
   * @memberOf Media
   * @instance
   * @method getVpIdObject
   *
   * @returns {MediaId} The VpId object
   */
  Media.prototype.getVpIdObject = function() {
    return this.vpIdObject;
  };

  /**
   * @memberOf Media
   * @instance
   * @method getNonPipsContentIdObject
   *
   * @returns {MediaId} The NonPipsContentId object
   */
  Media.prototype.getNonPipsContentIdObject = function() {
    return this.nonPipsContentIdObject;
  };

  Media.prototype.getBrand = function() {
    return this.brand;
  };

  Media.prototype.getSeries = function() {
    return this.series;
  };

  Media.prototype.getMediaSet = function () {
    return this.mediaSet;
  };

  /**
   * @memberOf Media
   * @instance
   * @method getVersionId
   *
   * @returns {string} The VersionId value
   */
  Media.prototype.getVersionId = function() {
    return this.versionIdObject.getValue();
  };

  /**
   * @memberOf Media
   * @instance
   * @method getClipId
   *
   * @returns {string} The ClipId value
   */
  Media.prototype.getClipId = function() {
    return this.clipIdObject.getValue();
  };

  /**
   * @memberOf Media
   * @instance
   * @method getServiceId
   *
   * @returns {string} The ServiceId value
   */
  Media.prototype.getServiceId = function() {
    return this.serviceIdObject.getValue();
  };

  /**
   * @memberOf Media
   * @instance
   * @method getEpisodeId
   *
   * @returns {string} The EpisodeId value
   */
  Media.prototype.getEpisodeId = function() {
    return this.episodeIdObject.getValue();
  };

  /**
   * @memberOf Media
   * @instance
   * @method getVpId
   *
   * @returns {string} The getVpId value
   */
  Media.prototype.getVpId = function() {
    return this.vpIdObject.getValue();
  };

  /**
   * @memberOf Media
   * @instance
   * @method getNonPipsContentId
   *
   * @returns {string} The getNonPipsContentId value
   */
  Media.prototype.getNonPipsContentId = function() {
    return this.nonPipsContentIdObject.getValue();
  };

  /**
   * @memberOf Media
   * @instance
   * @method getRetrievalType
   *
   * @returns {EchoRetrievalType} The retrieval type (Download or Stream)
   * or null if consumption mode is invalid
   */
  Media.prototype.getRetrievalType = function() {
    if (this.consumptionMode === null) {
      return null;
    } else {
      var retrievalType = (this.consumptionMode === CONSUMPTION_MODE.DOWNLOAD) ?
        Enums.RetrievalType.DOWNLOAD : Enums.RetrievalType.STREAM;

      return retrievalType;
    }
  };

  /**
   * @memberOf Media
   * @instance
   * @method isOnDemand
   *
   * @description Convenience helper method to determine
   * if media is onDemand
   *
   * @returns {bool}
   */
  Media.prototype.isOnDemand = function() {
    return this.consumptionMode === CONSUMPTION_MODE.ON_DEMAND;
  };

  /**
   * @memberOf Media
   * @instance
   * @method isDownload
   *
   * @description Convenience helper method to determine
   * if media is downloaded
   *
   * @returns {bool}
   */
  Media.prototype.isDownload = function() {
    return this.consumptionMode === CONSUMPTION_MODE.DOWNLOAD;
  };

  /**
   * @memberOf Media
   * @instance
   * @method isLive
   *
   * @description Convenience helper method to determine
   * if media is Live
   *
   * @returns {bool}
   */
  Media.prototype.isLive = function() {
    return this.consumptionMode === CONSUMPTION_MODE.LIVE;
  };

  /**
   * @memberOf Media
   * @instance
   * @method isVideo
   *
   * @description Convenience helper method to determine
   * if media is a video
   *
   * @returns {bool}
   */
  Media.prototype.isVideo = function() {
    return this.avType === Enums.AvType.VIDEO;
  };

  Media.prototype.getPlayerId = function() {
    return '1';
  };

  Media.prototype.setPlaying = function(isPlaying) {
    this._isPlaying = Boolean(isPlaying);

    return this;
  };

  Media.prototype.getPlaying = function() {
    return this._isPlaying;
  };

  Media.prototype.setBuffering = function(isBuffering) {
    this._isBuffering = Boolean(isBuffering);

    return this;
  };

  Media.prototype.getBuffering = function() {
    return this._isBuffering;
  };

  return Media;
});
