define(function() {
  'use strict';

  var DEBUG = require('../../util/debug');
  var LibraryInfo = require('../../util/library-info');

  var SEPARATOR_CHAR = '~';

  var keys = {
    THEME_ONE: {
      NAME: 'name',
      WS_PARTNER_ID: 'ptnrID',
      PLIST: 'pList'
    },
    THEME_TWO: {
      RETRIEVAL_TYPE: 'retType',
      APP_NAME: 'appN',
      APP_TYPE: 'appT'
    },
    THEME_THREE: {
      PLAYER_NAME: 'mpN',
      PLAYER_VERSION: 'mpV',
      LIBRARY_NAME: 'mlN',
      LIBRARY_VERSION: 'mlV',
      CASTING_DEVICE_REFERRER: 'devR'
    }
  };

  function nullToStr(value) {
    return value ? value : '';
  }

  var mediaThemes = {

    buildTheme: function(key, value, omitSeparator) {
      return key + '=' + encodeURIComponent(nullToStr(value)) + (omitSeparator ? '' : SEPARATOR_CHAR);
    },

    constructThemeOne: function(media, essEnabled) {
      var themeOne = '';
      if (media) {
        if (media.isLive() && essEnabled) {
          themeOne += this.buildTheme(keys.THEME_ONE.NAME, '');
        } else {
          themeOne += this.buildTheme(keys.THEME_ONE.NAME, media.getName());
        }

        themeOne += this.buildTheme(keys.THEME_ONE.WS_PARTNER_ID, media.getWsPartnerId());
        if (media.isLive()) {
          themeOne += this.buildTheme(keys.THEME_ONE.PLIST, media.getServiceId(), true);
        } else {
          themeOne += this.buildTheme(keys.THEME_ONE.PLIST, media.getPlaylist(), true);
        }
      }

      DEBUG.info('Setting media theme 1: ' + themeOne);
      return themeOne;
    },

    constructThemeTwo: function(media) {
      var themeTwo = '';
      if (media) {
        themeTwo = this.buildTheme(keys.THEME_TWO.RETRIEVAL_TYPE, media.getRetrievalType());
        themeTwo += this.buildTheme(keys.THEME_TWO.APP_NAME, media.getAppName());
        themeTwo += this.buildTheme(keys.THEME_TWO.APP_TYPE, media.getAppType());
      }

      DEBUG.info('Setting media theme 2: ' + themeTwo);
      return themeTwo;
    },

    constructThemeThree: function(media) {
      var themeThree = '';
      if (media) {
        themeThree = this.buildTheme(keys.THEME_THREE.PLAYER_NAME, media.getMediaPlayerName());
        themeThree += this.buildTheme(keys.THEME_THREE.PLAYER_VERSION, media.getMediaPlayerVersion());
        themeThree += this.buildTheme(keys.THEME_THREE.LIBRARY_NAME, LibraryInfo.getLibraryName());
        themeThree += this.buildTheme(keys.THEME_THREE.LIBRARY_VERSION, LibraryInfo.getLibraryVersion());
        themeThree += this.buildTheme(keys.THEME_THREE.CASTING_DEVICE_REFERRER, media.getCastingDeviceReferrer(), true);
      }

      DEBUG.info('Setting media theme 3: ' + themeThree);
      return themeThree;
    }
  };

  return mediaThemes;
});
