import { AlertOutlined, DownloadOutlined, FallOutlined, FileExclamationOutlined, MailOutlined, VerticalAlignBottomOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout/";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Card, Col, Divider, List, message, Popconfirm, Row, Select, Space, Statistic, Table, Tag, Tooltip } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import dayjs from "dayjs";
import { AlignType } from "rc-table/lib/interface";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { arrayToCsv, csvIse, downloadBlob } from "../../DatasetDetails/PublishedRecordSet";
import { cardStyle } from "../../Home/Home";
import CustomHelmet from "../../../components/CustomHelmet";
import { AdminGetTeamByDatasetId } from "../../../graphql/__generated__/AdminGetTeamByDatasetId";
import { AdminGetTeamsByDatasetIds } from "../../../graphql/__generated__/AdminGetTeamsByDatasetIds";
import { GetAdminStats } from "../../../graphql/__generated__/GetAdminStats";
import { AdminStatsInput, NeedsAttentionType, TargetStateType } from "../../../graphql/__generated__/globalTypes";
import { ADMIN_SEND_EMAIL } from "../../../graphql/__mutations__/AdminSendEmail.gql";
import { ADMIN_GET_TEAM_BY_DATASET_ID } from "../../../graphql/__queries__/AdminGetTeamByDatasetId.gql";
import { ADMIN_GET_TEAMS_BY_DATASET_IDS } from "../../../graphql/__queries__/AdminGetTeamsByDatasetIds.gql";
import { GET_ADMIN_STATS } from "../../../graphql/__queries__/GetAdminStats";


interface IDatasetList {
    datasetId: string,
    name: string,
    reportingPeriodEnd: Date,
    key: string
    category?: string,
    percent?: number,
    reportingPeriodName?: string | null | undefined
    count?: number
    needsAttentionTypes?: readonly NeedsAttentionType[]
    tags?: readonly string[] | null
    division?: string | null
    department?: string | null
}


const selectedCardStyle = {
    ...cardStyle,
    border: "1px solid blue",
    boxShadow: "0.2em 0.3em 0.75em rgba(0,0,50,0.3)"
};


export const Performance = () => {
    const { t } = useTranslation();

    const [filterState, setFilterState] = useState<AdminStatsInput>({ duration: 31 });
    const [selectedStat, setSelectedStat] = useState("failedTarget");
    const [datasetList, setDatasetList] = useState<IDatasetList[]>();
    const [activeListItem, setActiveListItem] = useState<string>();

    const { data: adminStats, loading } = useQuery<GetAdminStats>(GET_ADMIN_STATS, { variables: { input: filterState } });
    const [getTeam, { loading: teamLoading, }] = useLazyQuery<AdminGetTeamByDatasetId>(ADMIN_GET_TEAM_BY_DATASET_ID);
    const [getTeams, { loading: teamsLoading }] = useLazyQuery<AdminGetTeamsByDatasetIds>(ADMIN_GET_TEAMS_BY_DATASET_IDS);
    const [sendEmail] = useMutation(ADMIN_SEND_EMAIL);

    const allDatasets = useMemo(() => adminStats?.adminStats.targetStates, [adminStats]);
    const overdueDatasets = useMemo(() => adminStats?.adminStats.overdue ?? [], [adminStats]);
    const needsAttentionDatasets = useMemo(() => adminStats?.adminStats.needsAttention ?? [], [adminStats]);

    const failedDatasets = useMemo(() => (
        allDatasets
            ?.filter(x => x.state === TargetStateType.fails)
            .map(x => ({
                datasetId: x.datasetId,
                name: x.name,
                reportingPeriodEnd: x.reportingPeriodEnd,
                reportingPeriodName: undefined,
                percent: x.percent,
                category: x.category,
                key: `${x.category}-${x.prsId}-${x.state}`
            }))
    ), [allDatasets]);
    
    const failPercentage = useMemo(() => (
        failedDatasets && allDatasets ? (failedDatasets.length / allDatasets.length) * 100 : 0
    ), [failedDatasets, allDatasets]);

    const goodDatasets = useMemo(() => (
        allDatasets
            ?.filter(x => x.state === TargetStateType.exceeds)
            .map(x => ({
                datasetId: x.datasetId,
                name: x.name,
                reportingPeriodEnd: x.reportingPeriodEnd,
                reportingPeriodName: undefined,
                percent: x.percent,
                category: x.category,
                key: `${x.category}-${x.prsId}-${x.state}`
            }))
    ), [allDatasets]);

    const goodPercentage = useMemo(() => (
        goodDatasets && allDatasets ? (goodDatasets.length / allDatasets.length) * 100 : 0
    ), [goodDatasets, allDatasets]);

    const overdueDatasetsListItems = useMemo(() => (
        overdueDatasets.map(x => ({
            datasetId: x.datasetId,
            name: x.name,
            reportingPeriodEnd: x.reportingPeriodEnd,
            reportingPeriodName: x.reportingPeriodName,
            key: `${x.datasetId}-${x.reportingPeriodEnd}`,
            tags: x.tags,
            division: x.division,
            department: x.department
        }))
    ), [overdueDatasets]);

    const needsAttentionDatasetsListItems = useMemo(() => (
        needsAttentionDatasets.map(x => ({
            datasetId: x.datasetId,
            name: x.name,
            reportingPeriodEnd: x.reportingPeriodEnd,
            count: x.count,
            needsAttentionTypes: x.needsAttentionTypes,
            key: `${x.datasetId}`
        }))
    ), [needsAttentionDatasets]);

    const dataListBaseColumns = useMemo(() => {
        const emailBody = (lastMonth = dayjs().subtract(1, "month"), encoded = true) => {
            let body = "";
            const monthName = lastMonth.format("MMMM");
            let text = [
                "Hi,",
                `I hope you're well. This is just a gentle reminder that today is the deadline for your ${monthName} 50:50 data.`,
                "We can see you've not yet submitted your figures on the 50:50 Tracker, so please make sure you get your data in and hit the 'PUBLISH' button by 1700gmt today to ensure you're included in this month's stats.",
                "If you need any help with the Tracker, or have any questions at all, don't hesitate to get in touch.",
                "We're excited to see how you've done this month. Thank you so much and happy counting!",
                "All the best,"
            ];
            if (encoded) {
                text = text.map(x => encodeURI(x));
                body = text.join("%0D%0A%0D%0A");
            }
            else {
                body = text.join("\n\n");
            }

            return body;
        };

        const email = (emailAddresses: string[]) => {
            window.location.assign(`mailto:${emailAddresses.join(";")}?subject=Overdue&body=${emailBody()}`);
        };

        const basicColumns = [
            {
                title: t("admin.performance.datasetReportingPeriodEndColumnTitle"),
                dataIndex: "reportingPeriodEnd",
                key: "reportingPeriodEnd",
                sorter: (a: IDatasetList, b: IDatasetList) => new Date(a.reportingPeriodEnd).valueOf() - new Date(b.reportingPeriodEnd).valueOf(),
                sortDirections: ["ascend", "descend"] as SortOrder[],
                defaultSortOrder: "descend" as SortOrder,
                render: (rpe: Date) => new Date(rpe).toLocaleString(navigator.language, { day: "2-digit", month: "short", year: "numeric" } as Intl.DateTimeFormatOptions)
            },
            {
                title: t("admin.performance.datasetNameColumnTitle"),
                dataIndex: "name",
                key: "name",
                sorter: (a: IDatasetList, b: IDatasetList) => a.name.localeCompare(b.name),
                sortDirections: ["ascend", "descend"] as SortOrder[],
                render: (name: string, record: IDatasetList) => <Link to={`/dataset/${record.datasetId}/details`}>{name}</Link>
            }
        ];

        const actionsColumn = {
            key: "action",
            align: "right" as AlignType,
            render: (_: undefined, record: IDatasetList) => (
                <Space>
                    <Button
                        loading={teamLoading}
                        icon={<MailOutlined />}
                        type="text"
                        onClick={() => getTeam({ variables: { id: record.datasetId } })
                            .then(result => {
                                if (result.data?.teamByDatasetId) {
                                    const team = result.data?.teamByDatasetId;
                                    const publisherEmails = team.users
                                        .filter(x => x.active)
                                        .filter(x => x.roles.map(x => x.name).includes("publisher"))
                                        .map(x => x.email);
                                    if (publisherEmails.length === 0) {
                                        return message.error(t("admin.performance.noPublishers"));
                                    }
                                    email(publisherEmails);
                                }
                            })
                        }
                    >
                        {t("admin.performance.emailTeam")}
                    </Button>
                </Space>
            )
        };

        const overdueActionsColumn = {
            ...actionsColumn,
            title: false && <Popconfirm
                title={t("admin.performance.confirmSendEmails")}
                okText={t("confirm.yes")}
                cancelText={t("confirm.no")}
                onConfirm={
                    () => datasetList && getTeams({ variables: { ids: datasetList.map(x => x.datasetId) } })
                        .then(result => {
                            if (result.data?.teamsByDatasetIds) {
                                const lastMonth = dayjs().subtract(1, "month");
                                const teams = result.data?.teamsByDatasetIds;
                                const emailAddresses = Array.from(
                                    new Set(
                                        teams
                                            .flatMap(x => x.users)
                                            .filter(x => x.roles.map(y => y.name).includes("publisher"))
                                            .map(x => x.email)
                                    )
                                );
                                sendEmail({
                                    variables: {
                                        input: {
                                            to: emailAddresses,
                                            subject: "Overdue",
                                            body: emailBody(lastMonth, false),
                                            monthYear: lastMonth.format("M YYYY")
                                        }
                                    }
                                }).then(result => {
                                    result.data.sendEmail ? message.error(result.data.sendEmail) : message.success(t("admin.performance.sentOk"));
                                });
                            }
                        })
                }
            >
                <Button loading={teamsLoading} icon={<MailOutlined />} type="text">
                    {t("admin.performance.emailAllOverdue")}
                </Button>
            </Popconfirm>

        };

        const targetColumns = [
            {
                title: t("admin.performance.datasetCategoryColumnTitle"),
                dataIndex: "category",
                key: "category",
                sorter: (a: IDatasetList, b: IDatasetList) => (a.category && b.category) ? a.category.localeCompare(b.category) : 0,
                sortDirections: ["ascend", "descend"] as SortOrder[],
                render: (category: string) => category
            },
            {
                title: t("admin.performance.datasetPercentColumnTitle"),
                dataIndex: "percent",
                key: "percent",
                sorter: (a: IDatasetList, b: IDatasetList) => (a.percent && b.percent) ? a.percent - b.percent : 0,
                sortDirections: ["ascend", "descend"] as SortOrder[],
                render: (percent: number | undefined) => `${percent?.toFixed(2)}%`
            }
        ];

        const iconSize = 30;

        const attentionTypeColumn = {
            title: t("admin.performance.datasetAttentionTypeColumnTitle"),
            dataIndex: "needsAttentionTypes",
            key: "needsAttentionTypes",
            filters: Object.entries(NeedsAttentionType).map(([k,]) => ({ "text": t(`admin.performance.${k}`), "value": k })),
            onFilter: (value: string | number | boolean, record: IDatasetList) =>
                record.needsAttentionTypes ? 
                    Object.values(record.needsAttentionTypes)
                        .map(x => x as string)
                        .includes(NeedsAttentionType[(value as keyof typeof NeedsAttentionType)])
                    : true,
            render: (needsAttentionTypes: NeedsAttentionType[] | undefined) => <Space>
                {
                    needsAttentionTypes?.map(x => {
                        switch (x) {
                        case NeedsAttentionType.MissedATargetInAllLast3Periods:
                            return <FallOutlined style={{ fontSize: iconSize }} title={t(`admin.performance.${x}`)} />;
                        case NeedsAttentionType.MoreThan10PercentBelowATargetLastPeriod:
                            return <VerticalAlignBottomOutlined style={{ fontSize: iconSize }} title={t(`admin.performance.${x}`)} />;
                        case NeedsAttentionType.NothingPublishedLast3Periods:
                            return <FileExclamationOutlined style={{ fontSize: iconSize }} title={t(`admin.performance.${x}`)} />;
                        default:
                            return <AlertOutlined style={{ fontSize: iconSize }} title="Unknown attention reason" />;
                        }
                    })
                }
            </Space>
        };

        const tagsArray = Array.from(new Set(datasetList?.flatMap(x => "tags" in x && x.tags ? x.tags : ["None"])))
            .sort((a, b) => a.localeCompare(b));

        const tagsColumn = {
            title: "Misc. Tags",
            dataIndex: "tags",
            key: "tags",
            render: (tags: string[] | undefined) => tags?.map(tag => <Tag key={tag}>{tag}</Tag>),
            filters: tagsArray.map(y => ({ "text": y, "value": y === "None" ? "" : y })),
            filterSearch: true,
            onFilter: (value: string | number | boolean | undefined, record: IDatasetList) => (
                value ? record.tags?.some(x => x === value.toString()) ?? false : !record.tags
            )
        };

        const divisionDepartmentArray = (type: string) => Array
            .from(new Set(datasetList?.map(x => type in x && x[type] ? x[type] : "None")))
            .sort((a, b) => a.localeCompare(b));

        const divisionDepartmentColumn = (type: string) => ({
            title: type[0].toUpperCase() + type.slice(1),
            dataIndex: type,
            key: type,
            render: (x: string) => x && <Tag key={x}>{x}</Tag>,
            filters: divisionDepartmentArray(type).map(y => ({ "text": y, "value": y === "None" ? "" : y })),
            filterSearch: true,
            onFilter: (value: string | number | boolean | undefined, record: IDatasetList) => (
                value ? record[type] === value.toString() : !record[type]
            )
        });

        if (!datasetList || !datasetList.length) {
            return basicColumns;
        }

        if ("percent" in datasetList[0] && "category" in datasetList[0]) {
            return [...basicColumns, ...targetColumns];
        }
        else if ("needsAttentionTypes" in datasetList[0]) {
            return [...basicColumns, attentionTypeColumn, actionsColumn];
        }

        return [
            ...basicColumns,
            divisionDepartmentColumn("division"),
            divisionDepartmentColumn("department"),
            tagsColumn,
            overdueActionsColumn
        ];
    }, [t, datasetList, getTeam, getTeams, teamLoading, teamsLoading, sendEmail]);

    useEffect(() => {
        switch (selectedStat) {
        case "failedTarget":
            setDatasetList(failedDatasets);
            break;
        case "exceededTarget":
            setDatasetList(goodDatasets);
            break;
        case "overdue":
            setDatasetList(overdueDatasetsListItems);
            break;
        case "needsAttention":
            setDatasetList(needsAttentionDatasetsListItems);
            break;
        default:
            setDatasetList(undefined);
        }
    }, [
        selectedStat, 
        setDatasetList, 
        failedDatasets, 
        goodDatasets, 
        overdueDatasetsListItems, 
        needsAttentionDatasetsListItems
    ]);

    const exportCSVTwo = (mungedPublishedRecordSets?: readonly IDatasetList[]): void => {
        if (!mungedPublishedRecordSets) {
            message.error("No Published Record sets");
            return;
        }

        getTeams({ variables: { ids: mungedPublishedRecordSets.map(x => x.datasetId) } })
            .then(result => {
                if (result.data?.teamsByDatasetIds) {
                    const teams = result.data?.teamsByDatasetIds;
                    const datasetIds = Array.from(
                        teams.map(x => ({
                            "teamName": x.name,
                            "datasetIds": x.programs.flatMap(y => y.datasets.flatMap(z => z.id)),
                            "emails": x.users
                                .filter(x => x.active)
                                .filter(x => x.roles.map(y => y.name).includes("publisher"))
                                .map(x => x.email).join(";")
                        }))
                    );
                    const csvArray = mungedPublishedRecordSets.reduce((csv, prs) => {
                        const rowObj = {
                            "DATASET_LINK": `http://5050.ni.bbc.co.uk/dataset/${prs.datasetId}/details`,
                            "DATASET_NAME": prs.name,
                            "DIVISION": prs.division,
                            "DEPARTMENT": prs.department,
                            "MISC. TAGS": prs.tags?.join(";") ?? "",
                            "EMAILS": datasetIds.find(x => x.datasetIds.some(y => y.includes(prs.datasetId)))?.emails
                        };

                        csv.push(csvIse("", rowObj, {} as Record<string, string>));
                        return csv;
                    }, [] as Record<string, string>[]);

                    const headings = csvArray.reduce((keys, curr) => {
                        Object.keys(curr).forEach(x => x in keys || keys.add(x));
                        return keys;
                    }, new Set<string>());

                    const csvArrayCombined = csvArray.reduce((csv, curr, i) => {
                        i === 0 && csv.push(Array.from(headings));
                        const row = new Array<string | null>();
                        headings.forEach(x => Object.keys(curr).includes(x) ? row.push(curr[x]) : row.push(""));
                        csv.push(row);
                        return csv;
                    }, new Array<Array<string | null>>());

                    const csv = arrayToCsv(csvArrayCombined);

                    if (!csv) {
                        message.error("No data in published record sets");
                        return;
                    }

                    const filename = `Overdue_datasets_${new Date().getDate()}`;
                    downloadBlob(csv, `${filename}.csv`, "text/csv");
                }
            });
    };

    return (
        <>
            <CustomHelmet title={t("admin.performance.title")} />
            <PageHeader title={t("admin.performance.title")} subTitle={t("admin.performance.subtitle")} />
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <div
                        role="button"
                        tabIndex={0}
                        style={{ cursor: "pointer" }}
                        onKeyDown={(e) => { 
                            if (e.key === "Enter") { 
                                setSelectedStat("failedTarget");
                            } 
                        }}
                        onClick={() => setSelectedStat("failedTarget")}
                    >
                        <Card style={selectedStat === "failedTarget" ? selectedCardStyle : cardStyle}>
                            <Statistic
                                loading={loading}
                                title={t("admin.performance.failed")}
                                value={failPercentage}
                                precision={0}
                                valueStyle={{ color: "red" }}
                                suffix="%"
                            />
                        </Card>
                    </div>
                </Col>
                <Col span={6}>
                    <div
                        role="button"
                        tabIndex={0}
                        style={{ cursor: "pointer" }}
                        onKeyDown={(e) => { if (e.key === "Enter") { setSelectedStat("exceededTarget"); } }}
                        onClick={() => setSelectedStat("exceededTarget")}
                    >
                        <Card style={selectedStat === "exceededTarget" ? selectedCardStyle : cardStyle}>
                            <Statistic
                                loading={loading}
                                title={t("admin.performance.exceeds")}
                                value={goodPercentage}
                                precision={0}
                                valueStyle={{ color: "green" }}
                                suffix="%"
                            />
                        </Card>
                    </div>
                </Col>
                <Col span={6}>
                    <div
                        role="button"
                        tabIndex={0}
                        style={{ cursor: "pointer" }}
                        onKeyDown={(e) => { if (e.key === "Enter") { setSelectedStat("overdue"); } }}
                        onClick={() => setSelectedStat("overdue")}
                    >
                        <Card style={selectedStat === "overdue" ? selectedCardStyle : cardStyle}>
                            <Statistic
                                loading={loading}
                                title={
                                    <div>
                                        {t("admin.performance.overdueStatTitle")}
                                        <Button
                                            style={{ float: "right" }}
                                            onClick={() => exportCSVTwo(overdueDatasetsListItems)}
                                            type="primary"
                                            shape="circle"
                                            icon={<DownloadOutlined />}
                                        />
                                    </div>
                                }
                                value={overdueDatasets.length}
                                precision={0}
                                valueStyle={{ color: "red" }}
                            />
                        </Card>
                    </div>
                </Col>
                <Col span={6}>
                    <Tooltip
                        title="Datasets which fulfil one or several of the following criteria:
                            A) Missed any target by any amount for the last 3 reporting periods consistently.                
                            OR: B) Missed any target by 10% or more in the last reporting period.                
                            OR: C) Not submitted any data for last 3 reporting periods."
                    >
                        <div
                            role="button"
                            tabIndex={0}
                            style={{ cursor: "pointer" }}
                            onKeyDown={(e) => { 
                                if (e.key === "Enter") { 
                                    setSelectedStat("needsAttention"); 
                                } 
                            }}
                            onClick={() => setSelectedStat("needsAttention")}
                        >
                            <Card style={selectedStat === "needsAttention" ? selectedCardStyle : cardStyle}>
                                <Statistic
                                    loading={loading}
                                    title={t("admin.performance.reqAttentionStatTitle")}
                                    value={needsAttentionDatasets.length}
                                    precision={0}
                                    valueStyle={{ color: "red" }}
                                />
                            </Card>
                        </div>
                    </Tooltip>
                </Col>
            </Row>
            {
                selectedStat.toLowerCase().indexOf("target") !== -1 &&
                <Row justify="center">
                    <Col span={24}>
                        <Divider orientation="left">{t("admin.performance.filtersTitle")}</Divider>
                    </Col>
                    <Col span={24}>
                        <Space>
                            {t("admin.performance.selectDays")}
                            <Select
                                value={filterState.duration}
                                onChange={(e) => setFilterState((curr) => ({ ...curr, duration: e }))}
                            >
                                <Select.Option value={31}>31</Select.Option>
                                <Select.Option value={62}>62</Select.Option>
                                <Select.Option value={93}>93</Select.Option>
                            </Select>
                        </Space>
                    </Col>
                </Row >
            }
            {
                datasetList &&
                <Row justify="center">
                    <Col span={24}>
                        <Divider orientation="left">{t("admin.performance.dataTitle")}</Divider>
                        <List
                            loading={loading}
                            itemLayout="horizontal"
                            size="small"
                        >
                            <Table
                                columns={dataListBaseColumns}
                                dataSource={datasetList}
                                onRow={(record) => {
                                    return {
                                        onMouseEnter: () => setActiveListItem(record.key),
                                        onMouseLeave: () => setActiveListItem(undefined),
                                        onClick: () => setActiveListItem((curr) => curr ? undefined : record.key),
                                        background: activeListItem ? "rgba(0,0,255,0.1)" : "unset"
                                    };
                                }}
                            />
                        </List>
                    </Col>
                </Row>
            }
        </>
    );
};