import { LogoutOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import { Dropdown, Button, Col, Layout, Row, Space, MenuProps } from "antd";
import { Link, useNavigate } from "react-router-dom";

import "./AppHeader.css";
import logo from "../assets/5050logo-only.jpg";
import bbclogo from "../assets/logo_bbc_dark.png";
import { useAuth } from "../hooks/AuthProvider";

const { Header } = Layout;


const AppHeader = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    const logout = async () => {
        await auth.logout();
        if (process.env.NODE_ENV !== "production") {
            navigate("/login");
        } else {
            navigate("/");
        }
        return;
    };

    const menuProps: MenuProps = {
        onClick: logout,
        items: [
            {
                key: "logout",
                title: "Logout",
                label: "Logout",
                icon: <LogoutOutlined />,
            }
        ],
    };

    return (
        <div>
            <Header className="header">
                <Row wrap={false}>
                    <Col flex="none">
                        <div className="header__logo">
                            <Link to="/" style={{ color: "rgba(0,0,0,0.65)" }}>
                                <Space.Compact>
                                    <div>
                                        <img src={bbclogo} alt="BBC Logo" style={{ width: "120px", marginRight: "20px" }} />
                                    </div>
                                    <div>
                                        <img src={logo} alt="50:50 - The Equality Project Logo" style={{ width: "110px" }} />
                                    </div>
                                    <div style={{ fontSize: "xx-large", marginTop: "3px" }}>
                                        Tracker
                                    </div>
                                </Space.Compact>
                            </Link>
                        </div>
                    </Col>
                    <Col flex="auto">
                        <div
                            id="header__user"
                            style={{ float: "right", lineHeight: "64px" }}
                        >
                            <Dropdown menu={menuProps}>
                                <Button icon={<UserOutlined />}>
                                    {auth.getFullName()} <DownOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </Header>
        </div>
    );
};

export default AppHeader;
